import { Box, Typography } from '@mui/material';

export default function TabLabel({
  isSelected,
  label,
  color,
  count,
}: {
  color: string;
  label: string;
  isSelected: boolean;
  count?: number;
}) {
  const fontWeight = isSelected ? 700 : 500;
  const textColor = isSelected ? `${color}.main` : 'text.secondary';
  const bgColor = isSelected ? `background.${color}` : 'background.default';
  return (
    <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
      <Typography color={textColor} variant="body1" fontWeight={fontWeight}>
        {label}
      </Typography>
      {count && (
        <Typography
          bgcolor={bgColor}
          color={textColor}
          py={0.25}
          px={1}
          borderRadius={2}
          variant="caption"
          fontWeight={fontWeight}
        >
          {count}
        </Typography>
      )}
    </Box>
  );
}

import { useMemo } from 'react';

import { Submission } from '@conform-to/react';

import { EmojiObjectsOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Button } from '@mui/material';

import { IFormDefinition } from '@octopus/libs/forms';

import { FormFields } from '../../../modules/form';
import { FormFromLayout } from '../../../modules/form/FormFromLayout';
import { Form } from '../../../modules/form/NewForm';
import { useFormFromDefinition } from '../../../modules/form/useFormFromDefinition';

type Props = {
  isSmallDevice: boolean;
  disabled: boolean;
  definition: IFormDefinition;
  submitLabel: string;
  isLoading: boolean;
  stepName: string;
  id: string;
  disclaimer: string;
  goToPreviousStep: () => void;
  onSubmit: (
    _event: React.FormEvent<HTMLFormElement>,
    formData: Submission<any, string[], any>,
  ) => void;
};

export const NewAdmissionForm = ({
  isSmallDevice,
  disabled,
  definition,
  submitLabel,
  isLoading,
  stepName,
  id,
  goToPreviousStep,
  onSubmit,
  disclaimer,
}: Props) => {
  const fullForm = useFormFromDefinition(definition, {
    id,
    onSubmit,
    persistLocal: id != null,
    useNewParser: true,
  });

  const form = useMemo(
    () => ({
      payloadForm: fullForm.payloadForm,
      metaForm: fullForm.metaForm,
      id: fullForm.id,
      fieldsRenderOptions: fullForm.fieldsRenderOptions,
    }),
    [
      fullForm.id,
      fullForm.payloadForm,
      fullForm.metaForm,
      fullForm.fieldsRenderOptions,
    ],
  );

  return (
    <Form payloadForm={form.payloadForm} metaForm={form.metaForm}>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          [theme.breakpoints.down('md')]: {
            width: '100%',
            height: `calc(100svh - ${theme.spacing(36)})`,
            overflow: 'scroll',
            '::-webkit-scrollbar': { display: 'none' },
          },
        })}
      >
        <FormFromLayout
          fields={FormFields({
            fields: form.fieldsRenderOptions,
            formId: form.id,
          })}
          fieldsetInline={false}
        />
        {disclaimer && (
          <Alert
            severity="warning"
            icon={<EmojiObjectsOutlined fontSize="large" />}
            sx={{ mt: 4 }}
          >
            {disclaimer}
          </Alert>
        )}
      </Box>

      {submitLabel && (
        <Box
          sx={(theme) => ({
            width: '100%',
            [theme.breakpoints.up('md')]: { display: 'flex', paddingTop: 3 },
            [theme.breakpoints.down('md')]: {
              position: 'fixed',
              bottom: 0,
              paddingBottom: 3,
              left: 0,
              background: theme.palette.background.paper,
              border: 'none',
              px: 2,
            },
          })}
        >
          <LoadingButton
            fullWidth={isSmallDevice}
            color="primaryAlt"
            variant="contained"
            disabled={disabled}
            loading={isLoading}
            sx={(theme) => ({
              py: 1.25,
              [theme.breakpoints.up('md')]: {
                px: 6,
                marginLeft: 'auto',
              },
              [theme.breakpoints.down('md')]: {
                px: 2,
                display: 'block',
                width: 'calc(100svw - 32px)',
              },
            })}
            type="submit"
            form={form.id}
            data-testid={`submit-${stepName}`}
          >
            {submitLabel}
          </LoadingButton>
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            disabled={disabled}
            sx={(theme) => ({
              [theme.breakpoints.up('md')]: {
                display: 'none',
              },
              [theme.breakpoints.down('md')]: {
                py: 1.25,
                px: 2,
                mt: 1.5,
                display: 'block',
                width: 'calc(100svw - 32px)',
              },
            })}
            onClick={() => goToPreviousStep()}
          >
            Voltar
          </Button>
        </Box>
      )}
    </Form>
  );
};

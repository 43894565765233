import { FieldMetadata, getSelectProps } from '@conform-to/react';

import {
  IFieldCollectionValueWithOptionsDefinition,
  IFieldSingleValueWithOptionsDefinition,
  NEW_UI_TYPE,
} from '@octopus/libs/forms';

import { TFieldRenderProps, TFieldSelectRenderProps } from './types';

export function parseFieldSelectWithAutoComplete(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldMetadata: FieldMetadata<any>,
  fieldDefinition:
    | IFieldCollectionValueWithOptionsDefinition
    | IFieldSingleValueWithOptionsDefinition,
  fieldsetName = '',
): TFieldRenderProps & TFieldSelectRenderProps {
  const selectProps = getSelectProps(fieldMetadata);

  const fieldRenderProps: TFieldRenderProps = {
    uiType: NEW_UI_TYPE.SELECT_AUTOCOMPLETE,
    fieldsetName,
    errors: fieldDefinition.errors,
    props: {
      key: selectProps.id,
      sx: fieldDefinition.sx,
    },
    label: {
      textContent: fieldDefinition.label,
      props: {
        htmlFor: selectProps.id,
      },
    },
    select: {
      props: {
        ...selectProps,
        placeholder: fieldDefinition.placeholder,
        info: fieldDefinition.info,
      },
      options: (fieldDefinition.options || [])
        .map((option) => ({
          props: {
            value: option.label as string,
            key: option.value as string,
          },
          defaultSelected: option.selected,
          textContent: option.label,
        })),
    },
  };

  return fieldRenderProps;
}

import { CreateScheduleRule } from '@octopus/vacations-types';

const scheduleErrorMessages: {
  [key in CreateScheduleRule]: {
    summary: string;
    description?: string;
  };
} = {
  daysAvailable: {
    summary: 'Dias insuficientes',
    description:
      'A quantidade de dias requisitados deve ser menor ou igual a de dias disponíveis',
  },
  daysBeforeDSR: {
    summary: 'Dias antes de DSR',
    description: `As preferencias de sua empresa não permitem começar as férias com essa quantidade de dias antes de um DSR`,
  },
  daysBeforeStartAllowedToRequestVacations: {
    summary: 'Pedido de férias com antecedencia menor que permitida',
    description:
      'As preferencias de sua empresa não permitem pedir as férias com essa antecedência',
  },
  vacationsMustNotStartInDSR: {
    summary: 'Férias não devem iniciar em dia de descanso',
  },
  mayOnlySellAThirdOfVacations: {
    summary: 'Só pode vender um terço das férias',
  },
  mayOnlySellVacationsOnce: {
    summary: 'Só pode vender as férias uma vez',
  },
  art134AtLeast14Days: {
    summary: 'Uma das férias tem que ter 14 dias',
    description:
      'Art. 134 da CLT: as férias podem ser fracionadas em até 3 períodos. Um deve ter no mínimo 14 dias de duração',
  },
  art134AtLeast5Days: {
    summary: 'Duração mínima de férias é 5 dias',
    description:
      'Art. 134 da CLT: as férias podem ser fracionadas em até 3 períodos. Nenhum período pode ser menor que 5 dias.',
  },
  contractMustBeActive: {
    summary: 'Contrato tem que ser ativo',
  },
  thirteenthAdvance: {
    summary: 'Não pode solicitar adiantamento do 13º salário',
    description:
      'Só pode solicitar o adiantamento do 13º se não foi solicitado no ano de início das férias',
  },
  mayNotOverlapVacationsDays: {
    summary: 'Não pode ter duas férias ativas ao mesmo tempo.',
  },
};

export default scheduleErrorMessages;

import { RefObject, useEffect, useRef } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { Box, Divider, Skeleton } from '@mui/material';

import { ContractEntry, useGetContract } from '@octopus/api';

import { BackButton } from '../../../modules/components/BackButton';
import { PageAlert } from '../../../modules/components/PageAlert';
import { QueryResult } from '../../../modules/types';
import { useFFlags } from '../../fflags';

import { CltContractDetails } from './CltContractDetails';
import { PersonSidePanel } from './PersonSidePanel';
import { PjContractDetails } from './PjContractDetails';

export type PersonProps = {
  organizationId: string | undefined;
};

export type PageRefs = {
  pageRef: RefObject<Element>;
  personalDetailsRef: RefObject<Element>;
  contractDetailsRef: RefObject<Element>;
  paymentDetailsRef: RefObject<Element>;
};

export default function Person({ organizationId }: PersonProps) {
  const { contractId } = useParams<{
    contractId: string;
  }>();
  const refs: PageRefs = {
    pageRef: useRef<Element>(null),
    personalDetailsRef: useRef<Element>(null),
    contractDetailsRef: useRef<Element>(null),
    paymentDetailsRef: useRef<Element>(null),
  };
  useEffect(() => {
    if (refs.pageRef.current) {
      refs.pageRef.current.scrollIntoView();
    }
  }, [refs.pageRef]);

  const contractQuery = useGetContract(
    {
      pathParams: {
        organizationId: organizationId ?? '',
        contractId: contractId ?? '',
      },
    },
    {
      enabled: !!organizationId && !!contractId,
    },
  );

  if (!contractId) {
    return <Navigate to="/people" replace />;
  }

  if (contractQuery.isError) {
    console.error(
      `Failed to load contract: ${JSON.stringify(contractQuery.error)}`,
    );
    return (
      <>
        <BackButton destination="/people" />
        <PageAlert
          message="Erro ao carregar dados do contrato"
          severity="error"
          showRetryMessage={true}
        />
      </>
    );
  }

  return (
    <Box display="flex">
      <BackButton destination="/people" />
      <PersonSidePanel refs={refs} contractQuery={contractQuery} />
      <Page refs={refs} contractQuery={contractQuery} />
    </Box>
  );
}

function Page({
  refs,
  contractQuery,
}: {
  refs: PageRefs;
  contractQuery: QueryResult<ContractEntry>;
}) {
  const { pageRef } = refs;
  return (
    <Box
      ref={pageRef}
      display="flex"
      flexDirection="column"
      flexGrow={1}
      alignItems="center"
      width="100%"
      py={15}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth="660px"
        minWidth="400px"
        gap={2}
      >
        <ContractDetailsContent refs={refs} contractQuery={contractQuery} />
      </Box>
    </Box>
  );
}

function ContractDetailsContent({
  refs,
  contractQuery,
}: {
  refs: PageRefs;
  contractQuery: QueryResult<ContractEntry>;
}) {
  const { FFlags } = useFFlags();
  if (contractQuery.isLoading) {
    return <ContractDetailsContentSkeleton />;
  }
  switch (contractQuery.data?.contractType) {
    case 'br:clt':
      return (
        <>
          <FFlags canAccessEmployeeEdit>
            <CltContractDetails
              refs={refs}
              contractQuery={contractQuery}
              isEditable={true}
              historyEnabled={true}
            />
          </FFlags>
          <FFlags canAccessEmployeeEdit={false}>
            <CltContractDetails
              refs={refs}
              contractQuery={contractQuery}
              isEditable={false}
              historyEnabled={false}
            />
          </FFlags>
        </>
      );
    case 'br:pj':
      return (
        <>
          <FFlags canAccessContractorEdit>
            <PjContractDetails
              refs={refs}
              contractQuery={contractQuery}
              isEditable={true}
              historyEnabled={true}
            />
          </FFlags>
          <FFlags canAccessContractorEdit={false}>
            <PjContractDetails
              refs={refs}
              contractQuery={contractQuery}
              isEditable={false}
              historyEnabled={false}
            />
          </FFlags>
        </>
      );
    default:
      return (
        <PageAlert
          message="Tipo de contrato não suportado"
          severity="error"
          showRetryMessage={false}
          retryMessage="Se o problema persistir, entre em contato com o suporte da Tako."
        />
      );
  }
}

function ContractDetailsContentSkeleton() {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Skeleton variant="rounded" height="48px" />
      <Divider />
      <Skeleton variant="rounded" height="40vh" />
      <Skeleton variant="rounded" height="40vh" />
      <Skeleton variant="rounded" height="40vh" />
    </Box>
  );
}

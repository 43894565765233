export type ApiFetcherExtraProps = {
  /**
   * You can add some extra props to your generated fetchers.
   *
   * Note: You need to re-gen after adding the first property to
   * have the `ApiFetcherExtraProps` injected in `ApiComponents.ts`
   **/
};

let refreshPromise: Promise<boolean> | undefined = undefined;
let authToken: string | undefined = undefined;

export const setAuthToken = (token: string) => {
  authToken = token;
};

export const getAuthToken = () => {
  return authToken;
};

export type ErrorWrapper<TError> =
  | TError
  | { status: number | 'unknown'; payload: string };

export type ApiError = Error & {
  statusCode: number | 'unknown';
};

export type ApiFetcherOptions<TBody, THeaders, TQueryParams, TPathParams> = {
  url: string;
  method: string;
  body?: TBody;
  headers?: THeaders;
  queryParams?: TQueryParams;
  pathParams?: TPathParams;
  signal?: AbortSignal;
} & ApiFetcherExtraProps;

export async function apiFetch<
  TData,
  TError,
  TBody extends {} | FormData | undefined | null,
  THeaders extends {},
  TQueryParams extends {},
  TPathParams extends {},
>({
  url,
  method,
  body,
  headers,
  pathParams,
  queryParams,
  signal,
}: ApiFetcherOptions<
  TBody,
  THeaders,
  TQueryParams,
  TPathParams
>): Promise<TData> {
  if (!authToken) {
    if (!(await refreshAuthToken())) {
      window.location.href = '/login';
    }
  }
  try {
    const requestHeaders: HeadersInit = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
      ...headers,
    };

    /**
     * As the fetch API is being used, when multipart/form-data is specified
     * the Content-Type header must be deleted so that the browser can set
     * the correct boundary.
     * https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects#sending_files_using_a_formdata_object
     */
    if (
      requestHeaders['Content-Type']!.toLowerCase().includes(
        'multipart/form-data',
      )
    ) {
      delete requestHeaders['Content-Type'];
    }

    const response = await window.fetch(
      `${resolveUrl(url, queryParams, pathParams)}`,
      {
        signal,
        method: method.toUpperCase(),
        body: body
          ? body instanceof FormData
            ? body as FormData
            : JSON.stringify(body)
          : undefined,
        headers: requestHeaders,
      },
    );
    if (!response.ok) {
      if (response.status === 401) {
        if (await refreshAuthToken()) {
          return apiFetch({
            url,
            method,
            body,
            headers,
            pathParams,
            queryParams,
            signal,
          });
        }
        window.location.href = '/login';
      }
      let error: ErrorWrapper<TError>;
      try {
        const parsed = await response.json();
        error = {
          status: response.status,
          ...parsed,
        };
      } catch (e) {
        error = {
          status: response.status,
          payload:
            e instanceof Error
              ? `Unexpected error (${e.message})`
              : 'Unexpected error',
        };
      }

      throw error;
    }

    if (response.status === 204) return undefined as TData;

    if (response.headers.get('content-type')?.includes('json')) {
      return await response.json();
    } else {
      // if it is not a json response, assume it is a blob and cast it to TData
      return (await response.blob()) as unknown as TData;
    }
  } catch (e: any) {
    const errorObject: ApiError = {
      statusCode: e?.status ?? 'unknown',
      name: 'unknown' as const,
      message: e.message,
      stack: e as string,
    };
    throw errorObject;
  }
}

const resolveUrl = (
  url: string,
  queryParams: Record<string, string> = {},
  pathParams: Record<string, string> = {},
) => {
  let query = new URLSearchParams(queryParams).toString();
  if (query) query = `?${query}`;
  return (
    url.replace(/\{\w*\}/g, (key) => pathParams[key.slice(1, -1)]!) + query
  );
};

async function refreshAuthToken(): Promise<boolean> {
  if (refreshPromise) {
    return refreshPromise;
  }
  refreshPromise = window
    .fetch('/api/user-auth/login/token', {
      credentials: 'include',
    })
    .then((data) => {
      if (data.ok) {
        return data.json().then(({ token }) => {
          setAuthToken(token);
          return true;
        });
      }
      return false;
    });
  const refresh = await refreshPromise;
  refreshPromise = undefined;
  return refresh;
}

import { useState } from 'react';

import { Drawer } from '@mui/material';

import { ContractEntry } from '@octopus/api';

import { QueryResult } from '../../../../modules/types';
import { ContractDetailsProps } from '../types';

import { ContractEventsHistory } from './ContractEventsHistory';
import { ContractEventsHistoryButton } from './ContractEventsHistoryButton';

type ContractEventsHistoryArgs = {
  contractQuery: QueryResult<ContractEntry>;
  historyEnabled: boolean;
  projectionComponent: React.FC<ContractDetailsProps>;
};

type UseContractEventsHistory = {
  ShowHistoryButton: JSX.Element;
  HistoryDrawer: JSX.Element;
};

export function useContractEventsHistory({
  contractQuery,
  historyEnabled,
  projectionComponent,
}: ContractEventsHistoryArgs): UseContractEventsHistory {
  const [showingHistory, setShowingHistory] = useState<boolean>(false);

  const showHistory = () => setShowingHistory(true);
  const closeHistory = () => setShowingHistory(false);

  const { isError, isLoading, data } = contractQuery;

  if (!historyEnabled || isError || isLoading) {
    return {
      ShowHistoryButton: null,
      HistoryDrawer: null,
    };
  }

  const { contractId, organizationId } = data;
  const personName = data.br?.pessoa?.nmSoc ?? data.br?.pessoa?.nmTrab;

  const showHistoryButton = (
    <ContractEventsHistoryButton onHistorySelected={showHistory} />
  );

  const historyDrawer = (
    <Drawer
      anchor="right"
      open={showingHistory}
      onClose={closeHistory}
      elevation={2}
    >
      <ContractEventsHistory
        projectionComponent={projectionComponent}
        contractId={contractId}
        organizationId={organizationId}
        personName={personName}
        onDismiss={closeHistory}
      />
    </Drawer>
  );

  return {
    ShowHistoryButton: showHistoryButton,
    HistoryDrawer: historyDrawer,
  };
}

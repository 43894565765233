import { Fragment, useState } from 'react';

import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid';

import { SelectionProps, ToggleAllCheckbox } from './selection';

export function DataGridHead({
  columns,
  field,
  order,
  setField,
  setOrder,
  extraColumns = 0,
  selectionProps = undefined,
  getHeadSx = () => ({}),
}: {
  columns: GridColDef[];
  field: string | undefined;
  order: 'asc' | 'desc';
  setField: (field: string) => void;
  setOrder: (order: 'asc' | 'desc') => void;
  extraColumns: number;
  selectionProps?: SelectionProps;
  getHeadSx: () => object;
}) {
  const toggleOrder = (order: string) => {
    return order === 'asc' ? 'desc' : 'asc';
  };
  const createSortHandler = (property: string) => () => {
    if (property === field) {
      setOrder(toggleOrder(order));
    } else {
      setField(property);
      setOrder('asc');
    }
  };

  const valueToPx = (
    value: number | string | undefined,
  ): `${string}px` | undefined => {
    if (typeof value === 'undefined') {
      return undefined;
    }

    if (typeof value === 'number') {
      return `${value}px`;
    }

    if (value.endsWith('px')) {
      return value as `${string}px`;
    }

    return `${value}px`;
  };

  return (
    <TableHead
      sx={{
        ...getHeadSx(),
      }}
    >
      <TableRow>
        {selectionProps && (
          <TableCell
            padding="checkbox"
            key="checkbox"
            sx={{
              paddingX: '0',
            }}
          >
            <ToggleAllCheckbox selectionProps={selectionProps} />
          </TableCell>
        )}
        {columns.map((column) => {
          const isSortableColumn = column.sortable !== false;
          const isCurrentSortedColumn = field === column.field;
          const TableLabel = isSortableColumn ? TableSortLabel : Fragment;

          const headerClassName =
            typeof column.headerClassName === 'string'
              ? column.headerClassName
              : '';

          return (
            <TableCell
              key={column.field}
              {...(isSortableColumn &&
                isCurrentSortedColumn && {
                  sortDirection: order,
                })}
              sx={{
                width: valueToPx(column.width),
                maxWidth: valueToPx(column.maxWidth),
                minWidth: valueToPx(column.minWidth),
              }}
              className={headerClassName}
            >
              <TableLabel
                {...(isSortableColumn && {
                  active: isCurrentSortedColumn,
                  direction: isCurrentSortedColumn ? order : 'asc',
                  onClick: createSortHandler(column.field),
                })}
              >
                <Typography
                  variant="caption"
                  color="text.primary"
                  fontWeight="700"
                >
                  {column.renderHeader
                    ? column.renderHeader({
                        field: column.headerName,
                        colDef: column,
                      } as GridColumnHeaderParams<any, any, any>) // eslint-disable-line @typescript-eslint/no-explicit-any
                    : column.headerName}
                </Typography>
              </TableLabel>
            </TableCell>
          );
        })}
        {new Array(extraColumns).fill(null).map((_, i) => (
          <TableCell key={`extra-column-${i}`} sx={{ width: '60px' }} />
        ))}
      </TableRow>
    </TableHead>
  );
}

export type SortingProps = {
  field: string | undefined;
  order: 'asc' | 'desc';
  setField: (field: string) => void;
  setOrder: (order: 'asc' | 'desc') => void;
};

export function useSorting(): SortingProps {
  const [field, setField] = useState<string | undefined>(undefined);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  return { field, order, setField, setOrder };
}

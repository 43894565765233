import { useSearchParams } from 'react-router-dom';

import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import { SxProps, TextField } from '@mui/material';

export type SearchProps = {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
};

export function useSearch(): SearchProps {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchTerm = searchParams.get('query') || '';
  const setSearchTerm = (term: string) => {
    setSearchParams({ query: term }, { replace: true });
  };
  return {
    searchTerm,
    setSearchTerm,
  };
}

export function SearchBar({
  placeholder = 'Pesquisar...',
  searchProps,
  sx,
}: {
  placeholder?: string;
  searchProps: SearchProps;
  sx?: SxProps;
}) {
  const { searchTerm, setSearchTerm } = searchProps;

  return (
    <TextField
      sx={{
        width: '300px',
        ...sx,
      }}
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <SearchIcon sx={{ fontSize: '16px', color: 'text.secondary' }} />
        ),
        endAdornment: searchTerm ? (
          <CancelIcon
            sx={{
              fontSize: '16px',
              cursor: 'pointer',
              color: 'strokes.heavy',
            }}
            onClick={() => setSearchTerm('')}
          />
        ) : undefined,
      }}
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      data-testid="table-search"
    />
  );
}

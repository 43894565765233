import { DateTimeFormatter, LocalDate } from '@js-joda/core';
import { z } from 'zod';

export const dateYearMonthSchema = z
  .string()
  .regex(/^\d{4}-\d{2}-\d{2}$/)
  .refine((val) => {
    try {
      LocalDate.parse(val, DateTimeFormatter.ofPattern('yyyy-MM-dd'));
      return true;
    } catch (_) {
      return false;
    }
  }, 'Invalid date');

import { StatusConfig } from './statusIndex';

export type PayrollStatus = keyof typeof payrollStatuses;

export const payrollStatuses = {
  calculating: 'calculating',
  calculationError: 'calculationError',
  open: 'open',
  approving: 'approving',
  approved: 'approved',
  reconcilingError: 'reconcilingError',
  closingError: 'closingError',
  reconciled: 'reconciled',
  closed: 'closed',
  archived: 'archived',
} as const;

export const payrollStatusIndex: { [key in PayrollStatus]: number } = {
  calculating: 0,
  calculationError: 0,
  open: 5,
  approving: 10,
  approved: 15,
  reconcilingError: 20,
  reconciled: 25,
  closingError: 25,
  closed: 9999,
  archived: 10000,
} as const;

export const payrollStatusConfig: Record<PayrollStatus, StatusConfig> = {
  archived: {
    index: payrollStatusIndex.archived,
  },
  open: {
    index: payrollStatusIndex.open,
  },
  calculating: {
    index: payrollStatusIndex.calculating,
  },
  calculationError: {
    index: payrollStatusIndex.calculationError,
  },
  approving: {
    index: payrollStatusIndex.approving,
  },
  reconcilingError: {
    index: payrollStatusIndex.reconcilingError,
    notifyOnMinutes: 5,
  },
  approved: {
    index: payrollStatusIndex.approved,
    notifyOnMinutes: 240,
  },
  reconciled: {
    index: payrollStatusIndex.reconciled,
  },
  closed: {
    index: payrollStatusIndex.closed,
  },
  closingError: {
    index: payrollStatusIndex.closingError,
    notifyOnMinutes: 5,
  },
};

export type PayrollType = keyof typeof payrollTypes;

export const payrollTypes = {
  monthly: 'monthly',
  thirteenthFirst: 'thirteenthFirst',
  thirteenthSecond: 'thirteenthSecond',
  vacations: 'vacations',
  termination: 'termination',
  advance: 'advance',
  complementary: 'complementary',

  provisions: 'provisions',
} as const;

export type TerminationType = keyof typeof TerminationTypes;
export type TerminationStartedBy = keyof typeof terminationStartedBy;
export type IndemnityPayer = keyof typeof indemnityPayers;
export type IndemnityType = keyof typeof indemnityType;

export const TerminationTypes = {
  resignation: 'resignation',
  justified: 'justified',
  unjustified: 'unjustified',
  mutualAgreement: 'mutualAgreement',
  death: 'death',
  endOfExpPeriod: 'endOfExpPeriod',
  earlyTerminationOfExpPeriodByEmployer:
    'earlyTerminationOfExpPeriodByEmployer',
  earlyTerminationOfExpPeriodByEmployee:
    'earlyTerminationOfExpPeriodByEmployee',
} as const;

export const indemnityPayers = {
  employer: 'employer',
  employee: 'employee',
  none: 'none',
} as const;

export const indemnityType = {
  indemnified: 'indemnified',
  deducted: 'deducted',
  exempt: 'exempt',
  worked: 'worked',
  other: 'other',
} as const;

export const terminationStartedBy = {
  employer: 'employer',
  employee: 'employee',
  both: 'both',
  other: 'other',
} as const;

import Big from 'big.js';

import {
  ArrowBackOutlined,
  CloseOutlined,
  HelpOutlineOutlined,
} from '@mui/icons-material';
import { Box, Divider, IconButton, Tooltip, Typography } from '@mui/material';

import { PayrollElement, PayrollElementMetaBody } from '@octopus/api';
import { formatMoney } from '@octopus/formatters';

import { CalculationBox } from './CalculationBox';

export function CalculationExplanation({
  element,
  canGoBack,
  goBack,
  onClickElement,
  onExit,
}: {
  element: PayrollElement;
  canGoBack: boolean;
  goBack: () => void;
  onClickElement: (element: PayrollElement) => void;
  onExit: () => void;
}) {
  if (!element) {
    return null;
  }

  const showBasisExplanation =
    !!element.meta.explanation?.basis.elements.length;
  const basisExplanation = (
    <CalculationBox>
      <>
        <Typography
          variant="body1"
          fontWeight="700"
          sx={{ pb: 1 }}
          display={'flex'}
          alignItems="start"
          gap="8px"
        >
          Base de cálculo
          <Tooltip title="Base de cálculo é a soma de todos os proventos e deduções que contam para o cálculo da rubrica.">
            <HelpOutlineOutlined
              fontSize="small"
              sx={(theme) => ({ color: theme.palette.text.secondary })}
            />
          </Tooltip>
        </Typography>

        <Divider sx={{ my: 1 }} />

        <table
          width="100%"
          style={{
            tableLayout: 'fixed',
            borderCollapse: 'collapse',
          }}
        >
          <tbody>
            {element.meta.explanation?.basis.elements.map((element) => (
              <Box
                component="tr"
                onClick={() => onClickElement(element)}
                key={element.id}
                sx={(theme) => ({
                  cursor: 'pointer',
                  transition: 'all 0.2s',
                  [theme.breakpoints.up('md')]: {
                    ':hover': {
                      backgroundColor: 'strokes.light',
                    },
                    '> td': {
                      padding: 0,
                    },
                  },
                  [theme.breakpoints.down('md')]: {
                    '> td': {
                      py: 0.5,
                    },
                  },
                })}
              >
                <td width="65%">
                  <Typography variant="body2" sx={{ py: 0.5 }}>
                    {element.name}
                  </Typography>
                </td>
                <td width="5%" />
                <td align="left" width="30%">
                  <Typography variant="body2">
                    <span
                      style={{
                        // make plus (+) and (-) the same width
                        fontFamily: 'monospace',
                        paddingRight: '8px',
                      }}
                    >
                      {element.type === 'earnings' ? '+' : '-'}
                    </span>
                    {formatMoney(element.amount)}
                  </Typography>
                </td>
              </Box>
            ))}
            <tr>
              <td colSpan={3}>
                <Divider sx={{ my: 1 }} />
              </td>
            </tr>
            <tr>
              <td>
                <Typography fontWeight="700" variant="body1">
                  Total
                </Typography>
              </td>
              <td />
              <td align="left">
                <Typography fontWeight="700" variant="body1">
                  {formatMoney(element.meta.explanation?.basis.total || '')}
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    </CalculationBox>
  );

  const showBodyExplanation = !!element.meta.explanation?.body;
  const showBodyCompensationExplanation =
    !!element.meta.explanation?.body?.compensation;
  const bodyExplanation = (
    <CalculationBox>
      <>
        <Typography variant="body1" fontWeight="700" sx={{ pb: 1 }}>
          Cálculo
        </Typography>

        <CalculationBody body={element.meta.explanation?.body} />

        {showBodyCompensationExplanation && (
          <>
            <Box sx={{ pb: 1, pt: 3 }}>
              <Typography variant="caption" fontWeight="700">
                Retenções anteriores
              </Typography>
            </Box>
            <CalculationBodyCompensation
              body={element.meta.explanation?.body}
            />
          </>
        )}
        <Box pt={1}></Box>
      </>
    </CalculationBox>
  );

  const showMessage = !!element?.meta?.explanation?.message;
  const explanationMessage = showMessage && (
    <CalculationBox>
      <>
        <Typography
          variant="body1"
          fontWeight="700"
          sx={{ pb: 1 }}
          display={'flex'}
          alignItems="start"
          gap="8px"
          whiteSpace="pre-wrap"
        >
          {element.meta.explanation.message.title}
        </Typography>
        <Typography
          variant="body2"
          sx={{ pb: 1 }}
          display={'flex'}
          alignItems="start"
          gap="8px"
          whiteSpace="pre-wrap"
        >
          {element.meta.explanation.message.description}
        </Typography>
      </>
    </CalculationBox>
  );

  return (
    <Box
      p={4}
      sx={{
        maxWidth: '600px',
        transition: 'all 0.2s',
        backgroundColor: 'background.default',
        overflowY: 'overlay',
      }}
      display="flex"
      flexDirection="column"
      data-testid="payroll-details-calculation-explation"
      gap="32px"
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
        }}
      >
        <Box display="flex" gap={1.5} alignItems="start">
          {canGoBack && (
            <IconButton
              onClick={goBack}
              sx={{ mt: 0.5, width: '24px', height: '24px' }}
            >
              <ArrowBackOutlined
                sx={{ color: 'text.primary', width: '24px', height: '24px' }}
              />
            </IconButton>
          )}
          <Typography variant="h2">{element.name}</Typography>
        </Box>

        <IconButton
          onClick={() => {
            onExit();
          }}
          sx={{ mt: 0.5, width: '24px', height: '24px' }}
        >
          <CloseOutlined
            sx={{ color: 'text.primary', width: '24px', height: '24px' }}
          />
        </IconButton>
      </Box>
      {showMessage && explanationMessage}
      {showBasisExplanation && basisExplanation}
      {showBodyExplanation && bodyExplanation}

      <CalculationBox transparent={true}>
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Typography variant="body1">Total</Typography>
          <Typography variant="h3" fontWeight="700">
            {formatMoney(element.amount)}
          </Typography>
        </Box>
      </CalculationBox>
    </Box>
  );
}

function CalculationBody({ body }: { body: PayrollElementMetaBody }) {
  if (!body) {
    return null;
  }

  if (body.type === 'progressive' || body.type === 'flatTable') {
    return (
      <table
        width="100%"
        style={{
          tableLayout: 'fixed',
          borderCollapse: 'collapse',
        }}
      >
        <thead>
          <tr>
            <th align="left" style={{ width: '50%' }}>
              <Typography variant="caption" fontWeight="700">
                Faixa
              </Typography>
            </th>
            <th align="left" style={{ width: '20%' }}>
              <Typography variant="caption" fontWeight="700">
                Alíquota
              </Typography>
            </th>
            <th align="left" style={{ width: '30%' }}>
              <Typography variant="caption" fontWeight="700">
                Valor por faixa
              </Typography>
            </th>
          </tr>
        </thead>
        <tr>
          <td colSpan={3}>
            <Divider sx={{ my: 1 }} />
          </td>
        </tr>
        <tbody>
          {body.brackets.map((bracket) => (
            <Box
              component="tr"
              key={bracket.rate}
              sx={{
                '> td': {
                  padding: 0.5,
                },
              }}
            >
              <td width="50%">
                <Typography variant="body2">
                  {bracket.upperBound === 'Infinity'
                    ? `Acima de ${formatMoney(bracket.lowerBound)}`
                    : `${formatMoney(bracket.lowerBound)} até ${formatMoney(
                        bracket.upperBound,
                      )}`}
                </Typography>
              </td>
              <td width="20%">
                <Typography variant="body2" color="text.secondary">
                  {Big(bracket.rate).mul(100).toFixed(2)}%
                </Typography>
              </td>
              <td width="30%">
                <Tooltip placement="left" title={bracket.calculationHint}>
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{
                      borderBottom: '1px dotted transparent',
                      transition: 'all 0.3s ease',
                      ':hover': {
                        borderBottom: '1px dotted',
                      },
                    }}
                  >
                    {bracket.totalInBracket === '-'
                      ? bracket.totalInBracket
                      : formatMoney(bracket.totalInBracket)}
                  </Typography>
                </Tooltip>
              </td>
            </Box>
          ))}
          <tr>
            <td colSpan={3}>
              <Divider sx={{ my: 1 }} />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <Typography fontWeight="700" variant="body2">
                Soma das faixas
              </Typography>
            </td>
            <td align="left">
              <Typography fontWeight="700" variant="body2">
                {formatMoney(body.total || '')}
              </Typography>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  let descriptions = [];
  let expanded = '';
  if (body.type === 'progressiveTableWithDeduction') {
    const bracket = body.brackets.find((bracket) => bracket.isRateEffective);
    descriptions = [
      {
        label: 'Alíquota',
        value: bracket.rate,
        valueType: 'percentage' as const,
      },
      {
        label: 'Parcela a deduzir',
        value: bracket.deduction,
        valueType: 'money' as const,
      },
    ];
    expanded = bracket.calculationHint;
  } else {
    descriptions = body.descriptions;
    expanded = body.expanded;
  }

  return (
    <>
      <table
        width="100%"
        style={{ tableLayout: 'fixed', borderCollapse: 'collapse' }}
      >
        <tbody>
          {descriptions.map((description) => (
            <Box
              component="tr"
              sx={{
                '> td': {
                  padding: 0,
                },
              }}
              key={description.label}
            >
              <td width="60%">
                <Typography variant="body2" sx={{ py: 0.5 }}>
                  {description.label}
                </Typography>
              </td>
              <td width="10%" />
              <td width="30%">
                <Typography variant="body2" sx={{ py: 0.5 }}>
                  {(() => {
                    const { value, valueType } = description;
                    if (valueType === 'money') {
                      return formatMoney(value);
                    }
                    if (valueType === 'percentage') {
                      return `${Big(value).mul(100).toFixed(2)}%`;
                    }
                    if (valueType === 'days') {
                      return `${value} dias`;
                    }

                    if (valueType === 'hours') {
                      return `${value}h`;
                    }

                    if (valueType === 'raw') {
                      return formatRaw(value);
                    }

                    valueType satisfies never;

                    return value;
                  })()}
                </Typography>
              </td>
            </Box>
          ))}
        </tbody>
      </table>
      <Box
        borderRadius={1}
        mt={2}
        p={2}
        sx={(theme) => ({
          border: `1px solid ${theme.palette.strokes.default}`,
        })}
        textAlign="center"
      >
        <Typography variant="body1">{expanded}</Typography>
      </Box>
    </>
  );
}

function CalculationBodyCompensation({
  body,
}: {
  body: PayrollElementMetaBody;
}) {
  if (!body.compensation) {
    return null;
  }

  return (
    <table
      width="100%"
      style={{ tableLayout: 'fixed', borderCollapse: 'collapse' }}
    >
      <thead>
        <tr>
          <th align="left" style={{ width: '70%' }}></th>
          <th align="left" style={{ width: '30%' }}></th>
        </tr>
      </thead>
      <Box mb={0.5} />
      <tbody>
        {body.compensation.items.map((compensationItem, index) => (
          <Box
            component="tr"
            key={index}
            sx={{
              '> td': {
                padding: 0,
              },
            }}
          >
            <td width="70%">
              <Typography variant="body2">{compensationItem.label}</Typography>
            </td>
            <td width="30%">
              <Typography variant="body2">
                {formatMoney(compensationItem.value)}
              </Typography>
            </td>
          </Box>
        ))}
        <tr>
          <td colSpan={3}>
            <Divider sx={{ my: 1 }} />
          </td>
        </tr>
        <tr>
          <td>
            <Typography fontWeight="700" variant="body2">
              Retenções totais
            </Typography>
          </td>
          <td align="left">
            <Typography variant="body2" fontWeight="700">
              {formatMoney(body.compensation.total || '')}
            </Typography>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

const NUMBER_PATTERN = /^(\d+)\.(\d{2})\d+$/;

function formatRaw(value: string) {
  const result = NUMBER_PATTERN.exec(value);
  if (result) {
    return `${result[1]},${result[2]}`;
  }
  return value;
}

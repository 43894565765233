import React, { useState } from 'react';

import dayjs from 'dayjs';
import { isFunction } from 'lodash';

import { ChevronLeft } from '@mui/icons-material';
import Close from '@mui/icons-material/Close';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import { Box, Button, Chip, IconButton, Typography } from '@mui/material';

import {
  EventSourcingEvent,
  GetContractEventsResponse,
  useGetContractEvents,
  useGetUserById,
} from '@octopus/api';
import { formatDateBR } from '@octopus/formatters';

import { ContractDetailsProps } from '../types';

import { ContractEventHistoryProjection } from './ContractEventsHistoryProjection';
import { Loading } from './Loading';
import { Utils } from './utils';

type EventSelection = {
  event: EventSourcingEvent;
  index: number;
};
type OnEventSelected = (selection: EventSelection) => void;

type ContractEventsHistoryProps = {
  organizationId: string;
  contractId: string;
  personName: string;
  onDismiss: () => void;
  projectionComponent: React.FC<ContractDetailsProps>;
};

export function ContractEventsHistory({
  organizationId,
  contractId,
  personName,
  onDismiss,
  projectionComponent,
}: ContractEventsHistoryProps) {
  const { isLoading, data } = useGetContractEvents({
    pathParams: { contractId, organizationId },
  });
  const events =
    isLoading || !data ? [] : Utils.Events.sortByEffectiveDateDesc(data);

  const [selectedEvent, setSelectedEvent] = useState<EventSelection>({
    event: undefined,
    index: undefined,
  });
  const [projectionIsOpen, setProjectionIsOpen] = useState<boolean>(false);

  const projectEvent = (selection: EventSelection) => {
    setProjectionIsOpen(true);
    setSelectedEvent(selection);
  };

  const onPrevious = () => {
    const currentIndex = selectedEvent.index;
    setSelectedEvent({
      event: events[currentIndex - 1],
      index: currentIndex - 1,
    });
  };

  const onNext = () => {
    const currentIndex = selectedEvent.index;
    setSelectedEvent({
      event: events[currentIndex + 1],
      index: currentIndex + 1,
    });
  };

  const dismissProjectionPanel = () => {
    setProjectionIsOpen(false);
    setSelectedEvent({ event: undefined, index: undefined });
  };

  return (
    <Box display="flex" alignContent="stretch" height="100%">
      <SecondaryDrawer
        isOpen={projectionIsOpen && !!selectedEvent.event}
        width="736px"
      >
        <ContractEventHistoryProjection
          projectionComponent={projectionComponent}
          event={selectedEvent.event}
          organizationId={organizationId}
          contractId={contractId}
          controls={{
            onDismiss: dismissProjectionPanel,
            hasPrevious: selectedEvent.index > 0,
            hasNext: selectedEvent.index < events.length - 1,
            onPrevious,
            onNext,
          }}
        />
      </SecondaryDrawer>
      <ContractEventsTimelinePanel
        projectedEventIndex={selectedEvent.index}
        events={events}
        eventsLoading={isLoading}
        onDismiss={onDismiss}
        personName={personName}
        onEventSelected={projectEvent}
      />
    </Box>
  );
}

function SecondaryDrawer({
  children,
  isOpen,
  width,
}: {
  children: React.ReactNode;
  isOpen: boolean;
  width: string;
}) {
  return (
    <Box
      height="100%"
      zIndex={1}
      sx={{
        opacity: isOpen ? 1 : 0,
        width: isOpen ? width : '0px',
        transition: 'all 0.2s',
        backgroundColor: 'background.default',
        overflowY: 'overlay',
        scrollbarWidth: 'thin',
      }}
    >
      {children}
    </Box>
  );
}

function ContractEventsTimelinePanel({
  events,
  eventsLoading,
  personName,
  onDismiss,
  onEventSelected,
  projectedEventIndex,
}: {
  events: GetContractEventsResponse;
  eventsLoading: boolean;
  personName: string;
  onDismiss: () => void;
  onEventSelected: OnEventSelected;
  projectedEventIndex?: number;
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '560px',
        overflowY: 'overlay',
        scrollbarWidth: 'thin',
      }}
    >
      <ContractHistoryPanelHeader
        personName={personName}
        onDismiss={onDismiss}
      />
      <Box sx={{ pt: 1, px: 7 }}>
        {eventsLoading ? (
          <Loading skeletons={3} />
        ) : (
          <ContractHistoryEventsTimeline
            events={events}
            onEventSelected={onEventSelected}
            projectedEventIndex={projectedEventIndex}
          />
        )}
      </Box>
    </Box>
  );
}

function ContractHistoryEventsTimeline({
  events,
  onEventSelected,
  projectedEventIndex,
}: {
  events: GetContractEventsResponse;
  onEventSelected: OnEventSelected;
  projectedEventIndex?: number;
}) {
  const now = dayjs();
  const currentEventIndex = events.findIndex(
    (event) =>
      dayjs(event.effectiveDate).isSame(now, 'day') ||
      dayjs(event.effectiveDate).isBefore(now, 'day'),
  );

  return (
    <Box display="flex" flexDirection="row" textAlign="left">
      <Timeline
        position="right"
        sx={{
          p: 0,
          mt: 0,
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {events.map((event, i) => (
          <ContractEventItem
            isProjected={projectedEventIndex === i}
            key={event.sequenceId}
            eventIndex={i}
            event={event}
            isCurrent={i === currentEventIndex}
            nextEvent={events[i + 1]}
            previousEvent={events[i - 1]}
            onEventSelected={onEventSelected}
          />
        ))}
      </Timeline>
    </Box>
  );
}

function ContractEventItem({
  event,
  eventIndex,
  nextEvent,
  previousEvent,
  isCurrent,
  onEventSelected,
  isProjected,
}: {
  isProjected: boolean;
  isCurrent: boolean;
  event: EventSourcingEvent;
  eventIndex: number;
  previousEvent?: EventSourcingEvent;
  nextEvent?: EventSourcingEvent;
  onEventSelected: OnEventSelected;
}) {
  return (
    <ContractEventItemWithAuthor
      event={event}
      eventIndex={eventIndex}
      nextEvent={nextEvent}
      previousEvent={previousEvent}
      isCurrent={isCurrent}
      authorName={
        event.author.startsWith('arn:')
          ? Utils.Events.getAuthorName()
          : undefined
      }
      onEventSelected={onEventSelected}
      isProjected={isProjected}
    />
  );
}

// TODO: use this component to resolve the author's name once authorization requirements are met
// https://linear.app/wgmi-octopus/issue/OCT-3631/endpoint-name-by-userid
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function ContractEventItemResolveAuthor({
  event,
  eventIndex,
  nextEvent,
  previousEvent,
  isCurrent,
  onEventSelected,
  isProjected,
}: {
  event: EventSourcingEvent;
  eventIndex: number;
  nextEvent?: EventSourcingEvent;
  previousEvent?: EventSourcingEvent;
  isCurrent: boolean;
  onEventSelected: OnEventSelected;
  isProjected: boolean;
}) {
  const userId = event.author;
  const { isLoading, isError, data } = useGetUserById({
    pathParams: { userId },
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ContractEventItemWithAuthor
      event={event}
      eventIndex={eventIndex}
      nextEvent={nextEvent}
      previousEvent={previousEvent}
      isCurrent={isCurrent}
      authorName={isError ? undefined : data.userData.name}
      onEventSelected={onEventSelected}
      isProjected={isProjected}
    />
  );
}

function ContractEventItemWithAuthor({
  event,
  eventIndex,
  nextEvent,
  previousEvent,
  isCurrent,
  authorName,
  onEventSelected,
  isProjected,
}: {
  event: EventSourcingEvent;
  eventIndex: number;
  nextEvent?: EventSourcingEvent;
  previousEvent?: EventSourcingEvent;
  isCurrent: boolean;
  authorName?: string;
  onEventSelected: OnEventSelected;
  isProjected: boolean;
}) {
  const { isHovered, hoveredStyle, hoverEventHandlers } =
    Utils.Hooks.useHoverBackground();

  const isSelected = isHovered || isProjected;

  return (
    <TimelineItem
      sx={(theme) => ({
        ...hoveredStyle(theme),
        backgroundColor: isSelected
          ? Utils.Styles.getHoveredBackgoundColor(theme)
          : undefined,
        cursor: isHovered ? 'pointer' : undefined,
        borderRadius: '12px',
        pr: 1,
      })}
      onClick={() => {
        if (!isHovered) return;
        onEventSelected({
          event,
          index: eventIndex,
        });
      }}
    >
      {isSelected ? (
        <IconButton size="small" sx={{ pl: 0.5, pr: 0, mx: 0 }}>
          <ChevronLeft sx={{ height: '12px', width: '12px', px: 0, mx: 0 }} />
        </IconButton>
      ) : null}

      <TimelineSeparator
        sx={{
          pr: 2,
          pl: isSelected ? 0 : 2,
        }}
      >
        <ContractEventTimelineNode
          event={event}
          previousEvent={previousEvent}
          nextEvent={nextEvent}
          isCurrent={isCurrent}
        />
      </TimelineSeparator>
      <TimelineContent
        {...hoverEventHandlers}
        sx={{
          px: 0,
          pt: 2,
          pb: 2.5,
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignContent="top"
          gap={2}
        >
          <Box display="flex" flexDirection="column">
            <Typography variant="body2">
              <strong>{Utils.Events.getEventActionName(event.type)}</strong> -{' '}
              {formatDateBR(event.effectiveDate)}
            </Typography>
            {authorName ? (
              <Typography variant="caption" color="textSecondary">
                Por <strong>{authorName}</strong> em{' '}
                {formatDateBR(event.recordDate)}
              </Typography>
            ) : (
              <Typography variant="caption" color="textSecondary">
                Em {formatDateBR(event.recordDate)}
              </Typography>
            )}
          </Box>
          <ContractEventTitleChip isCurrent={isCurrent} event={event} />
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
}

function ContractEventTitleChip({
  isCurrent,
  event,
}: {
  isCurrent: boolean;
  event: EventSourcingEvent;
}) {
  const isScheduled = Utils.Events.eventIsScheduled(event);

  if (!isScheduled && !isCurrent) return null;

  let color = 'textSecondary';
  let bgColor = undefined;
  let title = 'Agendada';

  if (isCurrent) {
    color = 'primary';
    bgColor = Utils.Styles.getPrimaryLighter;
    title = 'Atual';
  }

  return (
    <Chip
      sx={(theme) => ({
        px: 1,
        py: 0,
        borderRadius: '4px',
        backgroundColor: bgColor ? bgColor(theme) : bgColor,
      })}
      label={
        <Typography variant="caption" color={color} sx={{ p: 0 }}>
          {title}
        </Typography>
      }
    />
  );
}

function ContractEventTimelineNode({
  isCurrent,
  event,
  nextEvent,
  previousEvent,
}: {
  isCurrent: boolean;
  event: EventSourcingEvent;
  nextEvent?: EventSourcingEvent;
  previousEvent?: EventSourcingEvent;
}) {
  const isScheduled = Utils.Events.eventIsScheduled(event);
  const isFirst = !previousEvent;
  const isLast = !nextEvent;

  const baseTimelineDotStyle = {
    width: '2px',
    height: '2px',
    padding: 0,
    mb: 0,
    mt: 0,
  };

  let dotMuiColor = undefined;
  let dotVariant = 'outlined';
  let dotBorderColor = Utils.Styles.getPrimaryLighter;
  let separatorColor = Utils.Styles.getPrimaryLighter;

  if (isScheduled) {
    dotBorderColor = undefined;
    separatorColor = undefined;
  } else if (isCurrent) {
    dotMuiColor = 'primary';
    dotVariant = 'filled';
    dotBorderColor = undefined;
  }

  const EventTimelineDot = (
    <TimelineDot
      color={dotMuiColor as 'primary' | undefined}
      variant={dotVariant as 'filled' | 'outlined'}
      sx={(theme) => ({
        ...baseTimelineDotStyle,
        borderColor: dotBorderColor ? dotBorderColor(theme) : dotBorderColor,
      })}
    />
  );

  const EventTimelineConnectorBottom = isLast ? null : (
    <TimelineConnector
      sx={(theme) => ({
        height: isFirst ? undefined : 25,
        backgroundColor: separatorColor
          ? separatorColor(theme)
          : separatorColor,
      })}
    />
  );

  const topConnectorColor = isFirst
    ? undefined
    : previousEvent && Utils.Events.eventIsScheduled(previousEvent)
      ? Utils.Styles.getGrayscaleLighter
      : Utils.Styles.getPrimaryLighter;

  const EventTimelineConnectorTop = (
    <Box
      sx={(theme) => ({
        height: '24px',
        width: '2px',
        backgroundColor: isFunction(topConnectorColor)
          ? topConnectorColor(theme)
          : topConnectorColor,
      })}
    ></Box>
  );

  return (
    <>
      {EventTimelineConnectorTop}
      {EventTimelineDot}
      {EventTimelineConnectorBottom}
    </>
  );
}

function ContractHistoryPanelHeader({
  personName,
  onDismiss,
}: {
  personName: string;
  onDismiss: () => void;
}) {
  return (
    <Box
      gap={2}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        pt: 5,
        pb: 2,
        pr: '50px',
        pl: '50px',
      }}
    >
      <Box display="flex" flexDirection="column" textAlign="left">
        <Typography variant="body1" color="textSecondary">
          {personName}
        </Typography>
        <Typography variant="h2" gap={1}>
          Histórico de alterações
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" textAlign="right">
        <Button
          color="secondary"
          size="small"
          onClick={() => {
            onDismiss();
          }}
          sx={{
            borderColor: 'transparent',
            minWidth: '32px',
            minHeight: '32px',
            height: '32px',
            width: '32px',
          }}
        >
          <Close sx={{ fontSize: '24px', padding: 0.5 }} />
        </Button>
      </Box>
    </Box>
  );
}

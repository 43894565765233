import { useState } from 'react';

import { CheckCircle } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  Typography,
} from '@mui/material';

import { VacationsScheduleEntry } from '@octopus/api';
import { OctopusLoading } from '@octopus/ui/design-system';

import { SnackbarType } from '../../hooks/snackbarContext';
import { useSnackbar } from '../../hooks/useSnackbar';

const errorSnackbar: SnackbarType = {
  isOpen: true,
  variant: 'error',
  Message: 'Ocorreu um erro, por favor tente novamente.',
  hasCloseAction: true,
};

const getSuccessSnackbar = (message: string): SnackbarType => ({
  isOpen: true,
  variant: 'default',
  Message: message,
  StartAdornment: <CheckCircle />,
  autoHideDuration: 5000,
  hasCloseAction: false,
});

export function CancelConfirmationDialog({
  open,
  setOpen,
  action,
  onConfirmation,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  action: () => Promise<VacationsScheduleEntry>;
  onConfirmation: () => void;
}) {
  const { showSnackbar } = useSnackbar();

  const handleError = (error: Error) => {
    showSnackbar(errorSnackbar);
    setOpen(false);
    setLoading(false);
    console.error(error);
  };
  const [loading, setLoading] = useState(false);

  const content = (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'flex-start'}
      borderRadius={2}
      p={1}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        gap={2}
      >
        <Typography fontWeight={700} variant={'h1'}>
          Cancelar solicitação
        </Typography>
        <Typography variant={'body1'} fontWeight={440}>
          Tem certeza de que deseja cancelar sua solicitação de férias? Essa
          ação não pode ser desfeita.
        </Typography>
      </Box>
    </Box>
  );

  const actions = (
    <>
      <Button
        color={'error'}
        variant="contained"
        size="large"
        onClick={() => {
          setLoading(true);
          action()
            .then(() => {
              onConfirmation();
              showSnackbar(getSuccessSnackbar('Solicitação cancelada'));
            })
            .catch(handleError)
            .finally(() => {
              setLoading(false);
              setOpen(false);
            });
        }}
      >
        Cancelar solicitação
      </Button>
      <Button
        variant="contained"
        sx={{
          backgroundColor: 'background.paper',
          color: 'text.primary',
          px: 4,
          ':hover': {
            backgroundColor: 'strokes.light',
          },
        }}
        size="large"
        onClick={() => {
          setOpen(false);
        }}
        data-testid="cancel-archive-admission-button"
      >
        Manter solicitação
      </Button>
    </>
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: { display: 'none' },
        })}
        PaperProps={{
          sx: { minWidth: '400px', maxWidth: '800px', minHeight: '280px' },
        }}
      >
        {loading ? (
          <LoadingScene message={'Cancelando...'} />
        ) : (
          <>
            <DialogContent>{content}</DialogContent>
            <DialogActions
              sx={{
                px: 3,
                flexDirection: 'row-reverse',
                gap: 1,
                justifyContent: 'flex-start',
              }}
            >
              {actions}
            </DialogActions>
          </>
        )}
      </Dialog>
      <MobileDrawer
        open={open}
        setOpen={setOpen}
        actions={actions}
        content={content}
        loading={loading}
        setLoading={setLoading}
      />
    </>
  );
}

export function MobileDrawer({
  content,
  actions,
  setOpen,
  setLoading,
  loading,
  open,
}: {
  open: boolean;
  content: React.ReactNode;
  actions?: React.ReactNode;
  setOpen?: (open: boolean) => void;
  setLoading?: (open: boolean) => void;
  loading: boolean;
}) {
  return (
    <Drawer
      anchor={'bottom'}
      open={open}
      onClose={() => {
        if (setOpen) {
          setOpen(false);
          setLoading && setLoading(false);
        }
      }}
      sx={(theme) => ({
        display: 'flex',
        [theme.breakpoints.up('sm')]: { display: 'none' },
      })}
      transitionDuration={500}
      elevation={2}
      PaperProps={{ sx: { borderRadius: '16px 16px 0 0 ', height: 'auto' } }}
    >
      {loading && <LoadingScene message={'Cancelando'} />}
      {!loading && (
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
          p={2}
        >
          <Box height={'70%'}>{content}</Box>
          {actions && (
            <Box
              height={'30%'}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'flex-end'}
              mt={2}
              mb={1}
              gap={2}
            >
              {actions}
            </Box>
          )}
        </Box>
      )}
    </Drawer>
  );
}

function LoadingScene({ message }: { message: string }) {
  return (
    <Box
      sx={(theme) => {
        return {
          [theme.breakpoints.down('md')]: {
            height: '30%',
            mt: '15%',
          },
          [theme.breakpoints.up('sm')]: {
            minWidth: '500px',
            minHeight: '280px',
          },
        };
      }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box width="100px">
        <OctopusLoading />
      </Box>
      <Box pt={2} width={'100%'}>
        <Typography variant="h4" textAlign="center">
          {message}
        </Typography>
      </Box>
    </Box>
  );
}

import { useState } from 'react';

import { Box, Divider, SxProps, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { UI_TYPE } from '@octopus/libs/forms';

import { TFieldRadioRenderProps, TFieldRenderProps } from '../parseField/types';

type FieldRadioProps = {
  field: TFieldRenderProps & TFieldRadioRenderProps;
  sx?: SxProps<Theme>;
};

export function FieldRadio(props: FieldRadioProps) {
  const { field } = props;
  const [checkedKey, setCheckedKey] = useState(
    field.fields.find((f) => f.input.props.defaultChecked)?.props.key,
  );

  const onLabelKeyDown = (event: React.KeyboardEvent<HTMLLabelElement>) => {
    if (event.key !== ' ' && event.key !== 'Enter') return;
    const fieldElement = event.currentTarget;
    fieldElement.click();
    event.preventDefault();
  };

  return (
    <Box
      {...field.props}
      style={{ display: 'inline-block', width: '100%', cursor: 'pointer' }}
    >
      <Typography
        component="label"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          fontWeight: 'bold',
          width: '100%',
          marginBottom: 1,
          gap: 2,
        }}
        {...field.label.props}
      >
        {field.label.textContent}
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: 2,
        }}
      >
        {field.fields.map((f) => (
          <Box
            {...f.props}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              borderRadius: '12px',
              border: '2px solid',
              cursor: 'pointer',
              borderColor: (theme) =>
                checkedKey === f.props.key
                  ? theme.palette.primary.light
                  : theme.palette.strokes.light,
              '&:hover': {
                boxShadow:
                  '0px 4px 16px rgba(0, 0, 0, 0.03), 0px 2px 4px rgba(0, 0, 0, 0.04)',
              },
              flexGrow: 1,
              p: 2,
            }}
          >
            <Box
              component="input"
              {...f.input.props}
              sx={(theme) => ({
                accentColor: theme.palette.primary.light,
                mt: 0.5,
              })}
              onClick={() => setCheckedKey(f.props.key)}
            />
            <Box
              key={f.input.props.id}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                cursor: 'pointer',

                flexGrow: 1,
                px: 1,
              }}
            >
              <Typography
                component="label"
                sx={{
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
                onKeyDown={onLabelKeyDown}
                tabIndex={0}
                htmlFor={f.label.props.htmlFor}
              >
                {f.label.textContent}
              </Typography>

              {f.label.subtextContent && (
                <Typography
                  component="label"
                  sx={{
                    cursor: 'pointer',
                  }}
                  onKeyDown={onLabelKeyDown}
                  htmlFor={f.label.props.htmlFor}
                >
                  {f.label.subtextContent}
                </Typography>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

FieldRadio.uiType = UI_TYPE.RADIO;
FieldRadio.canRender = (
  field: TFieldRenderProps,
): field is FieldRadioProps['field'] => {
  if (!('fields' in field)) {
    return false;
  }

  const hasEnoughOptions = field.fields.length > 1;
  if (!hasEnoughOptions) return false;

  const isRadio = field.fields.reduce((isRadio, fieldOption) => {
    return (
      isRadio &&
      'input' in fieldOption &&
      fieldOption.input.props.type === 'radio'
    );
  }, true);

  return isRadio;
};

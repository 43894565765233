import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { Box, useTheme } from '@mui/material';

import {
  VacationsAccrualPeriodEntry,
  fetchGetContractAccrualPeriods,
} from '@octopus/api';

import RequestVacationContainer from './RequestVacationContainer';
import { LoadingScene } from './utils/RequestVacationLoadingScene';
import { RequestVacationFormSteps, requestVacationObject } from './utils/types';

export type VacationsProps = {
  organizationId: string | undefined;
  companyId: string | undefined;
};

function VacationRequestPage({
  organizationId,
}: {
  organizationId: string | undefined;
}) {
  const [requestVacation, setRequestVacation] = useState<requestVacationObject>(
    {},
  );
  const theme = useTheme();
  const { contractId } = useParams();
  const [searchParams] = useSearchParams();
  const [formSteps, setFormSteps] = useState<RequestVacationFormSteps>({
    initialStep: requestVacation,
    abonoPecuniario: requestVacation,
    thirteenthAdvance: requestVacation,
    dateAndDuration: requestVacation,
    reviewAndSubmit: requestVacation,
    result: requestVacation,
  });
  const [accrualPeriod, setAccrualPeriod] = useState<
    VacationsAccrualPeriodEntry & { nextSequence: number }
  >(null);
  const startDate = searchParams.get('startDate');

  const accrualPeriodsQuery = useQuery({
    queryKey: [organizationId, contractId],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryFn: () => {
      return fetchGetContractAccrualPeriods({
        pathParams: {
          organizationId: organizationId ?? '',
          contractId: contractId ?? '',
        },
      });
    },
    enabled: !!organizationId,
  });

  useEffect(() => {
    if (accrualPeriodsQuery.data) {
      setAccrualPeriod({
        ...accrualPeriodsQuery.data.accrualPeriods.find(
          (accrualPeriod) => accrualPeriod.startDate === startDate,
        ),
        nextSequence: accrualPeriodsQuery.data.nextSequence,
      });
    }
  }, [accrualPeriodsQuery.data, startDate]);

  useEffect(() => {
    if (accrualPeriod) {
      if (accrualPeriod.daysAvailable < 15 || accrualPeriod.daysSold > 0) {
        const { abonoPecuniario: _, ...newFormStep } = formSteps;
        setFormSteps(newFormStep);
      }
      if (accrualPeriod.thirteenthAdvanceByYear.includes(`${dayjs().year()}`)) {
        const { thirteenthAdvance: _, ...newFormStep } = formSteps;
        setFormSteps(newFormStep);
      }
    }
  }, [accrualPeriod, formSteps]);

  useEffect(() => {
    setFormSteps({
      initialStep: requestVacation,
      abonoPecuniario: requestVacation,
      thirteenthAdvance: requestVacation,
      dateAndDuration: requestVacation,
      reviewAndSubmit: requestVacation,
      result: requestVacation,
    });
  }, [requestVacation]);

  if (accrualPeriod === null) {
    return <LoadingScene title={`Iniciando pedido de férias`} />;
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.secondary,
      }}
      height={'100%'}
    >
      {accrualPeriod ? (
        <RequestVacationContainer
          accrualPeriod={accrualPeriod}
          formSteps={formSteps}
          setRequestVacation={setRequestVacation}
          organizationId={organizationId}
          contractId={contractId}
        />
      ) : null}
    </Box>
  );
}

export default VacationRequestPage;

import { ContractBRCltEntrySituacao, ContractStatus } from '@octopus/api';
import { contractTypes } from '@octopus/contract-types';
import { MotivoAfastamento } from '@octopus/esocial/mapper';
import { formatDateBR } from '@octopus/formatters';

import { StatusBadge } from '../../../../people/person/components/StatusBadge';
import { Record, RecordEntry } from '../../../Record';

export type SituacaoRecordProps = {
  status: ContractStatus;
  situacao: ContractBRCltEntrySituacao;
};

export function SituacaoRecord({ status, situacao }: SituacaoRecordProps) {
  if (!situacao) {
    return null;
  }
  return (
    <Record title="Situação">
      <RecordEntry label={'Situação'}>
        <StatusBadge status={status} contractType={contractTypes.brClt} />
      </RecordEntry>
      <RecordEntry
        label={'Data do desligamento'}
        hide={situacao.tipo !== 'desligado'}
      >
        {formatDateBR(
          (
            situacao as Extract<
              ContractBRCltEntrySituacao,
              { tipo: 'desligado' }
            >
          ).dtDeslig,
        )}
      </RecordEntry>
      <RecordEntry
        label={'Início do afastamento'}
        hide={situacao.tipo !== 'afastado'}
      >
        {formatDateBR(
          (
            situacao as Extract<
              ContractBRCltEntrySituacao,
              { tipo: 'afastado' }
            >
          ).dtIniAfast,
        )}
      </RecordEntry>
      <RecordEntry
        label={'Motivo do afastamento'}
        hide={situacao.tipo !== 'afastado'}
      >
        {MotivoAfastamento.getByCode(
          (
            situacao as Extract<
              ContractBRCltEntrySituacao,
              { tipo: 'afastado' }
            >
          ).codMotAfast,
        )}
      </RecordEntry>
    </Record>
  );
}

import React, { useEffect, useImperativeHandle } from 'react';

import {
  FormProvider,
  Submission,
  getFormProps,
  useForm,
} from '@conform-to/react';
import { getZodConstraint, parseWithZod } from '@conform-to/zod';
import { z } from 'zod';

import { Box, Typography } from '@mui/material';

import {
  IFormDefinition,
  createMetaFormSchema,
  createValidationSchema,
} from '@octopus/libs/forms';
import { Button } from '@octopus/ui/design-system';

import { FormFields } from './FormFields';
import { FormFromLayout } from './FormFromLayout';
import { getFieldsRenderProps } from './getFieldsRenderProps';
import { TFieldSelectRenderProps } from './parseField/types';
import { useFormFromDefinitionState } from './useFormFromDefinitionState';
import { useMetaFormAutocompleteOptionsState } from './useMetaFormAutocompleteOptionsState';

type IFormProps = React.FormHTMLAttributes<HTMLFormElement>;

// const log = (...args: unknown[]): void =>
//   console.log('[FormFromDefinition]', ...args);

function BaseFormFromDefinition(
  {
    definition,
    submitLabel,
    onOptionsSearch,
    isLoading,
    ...formArgProps
  }: Omit<IFormProps, 'onSubmit'> & {
    definition: IFormDefinition;
    submitLabel?: string;
    isLoading?: boolean;
    onOptionsSearch?: (
      fieldName: string,
      queryFieldName: string,
      queryFieldValue: string,
    ) =>
      | TFieldSelectRenderProps['select']['options']
      | undefined
      | Promise<TFieldSelectRenderProps['select']['options'] | undefined>;
    onSubmit: (
      event: React.FormEvent<HTMLFormElement>,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      formData: Submission<any, string[], any>,
    ) => void;
  },
  ref: React.Ref<unknown>,
) {
  const validationSchema = createValidationSchema(definition);
  const metaFormSchema = createMetaFormSchema(definition);

  const { onSubmit: onSubmitCallbackArg, id: formIdArg } = formArgProps;

  const { metaFormState, payloadFormState } =
    useFormFromDefinitionState(formIdArg);

  const [metaForm, metaFields] = useForm({
    id: metaFormState.id,
    defaultValue: metaFormState.lastResult?.initialValue,
    constraint: getZodConstraint(metaFormSchema),
    onSubmit(event, context) {
      event.preventDefault();
      const submission = parseWithZod(context.formData, {
        schema: metaFormSchema,
      });

      const submissionResult = submission.reply();

      metaFormState.set({
        submission,
        submissionResult,
      });

      // log('===META SUBMISSION=', submission);
      // log('===META CTX =', context);
    },
    onValidate({ formData }) {
      const submission = parseWithZod(formData, { schema: metaFormSchema });
      metaFormState.set({
        submission,
      });
      return submission;
    },
  });

  const defaultValue = getDefaults(validationSchema as z.AnyZodObject);

  const [payloadForm, payloadFields] = useForm({
    id: payloadFormState.id,
    defaultValue: payloadFormState.lastResult?.initialValue || defaultValue,
    constraint: getZodConstraint(validationSchema),
    onSubmit(event, context) {
      event.preventDefault();
      const submission = parseWithZod(context.formData, {
        schema: validationSchema,
      });

      const submissionResult = submission.reply();

      payloadFormState.set({
        submission,
        submissionResult,
      });

      // log('===SUBMISSION=', submission);
      // log('===CTX =', context);
      onSubmitCallbackArg(event, submission);
    },
    onValidate({ formData }) {
      const submission = parseWithZod(formData, { schema: validationSchema });
      payloadFormState.set({
        submission,
      });
      return submission;
    },
  });

  //const payloadformValue = payloadForm.value;
  //const payloadFormInitialValue = payloadForm.initialValue;
  const payloadFormErrors = payloadForm.allErrors;
  // useEffect(() => {
  //   log('===FORM VALUE=', payloadFormState.id, payloadformValue);
  //   // log('===FORM INITIAL VALUE=', payloadFormSetup.id, payloadFormInitialValue);
  //   if (Object.keys(payloadFormErrors).length)
  //     log('===FORM ERRORS=', payloadFormState.id, payloadFormErrors);
  // }, [
  //   payloadFormState.id,
  //   payloadformValue,
  //   payloadFormErrors,
  //   payloadFormInitialValue,
  // ]);

  // const metaFormValue = metaForm.value;
  // const metaFormInitialValue = metaForm.initialValue;
  // const metaFormErrors = metaForm.allErrors;

  // useEffect(() => {
  //   log('===META FORM VALUE=', metaFormState.id, metaFormValue);
  //   // log('===META FORM INITIAL VALUE=', metaFormSetup.id, metaFormInitialValue);
  //   if (Object.keys(metaFormErrors).length)
  //     log('===META FORM ERRORS=', metaFormState.id, metaFormErrors);
  // }, [metaFormState.id, metaFormValue, metaFormErrors, metaFormInitialValue]);

  const fieldsAutocomplete = useMetaFormAutocompleteOptionsState(metaForm);

  useEffect(() => {
    if (!onOptionsSearch) return;
    const fieldAutocompleteEntries = Object.entries(fieldsAutocomplete);

    for (const [
      autocompleteFieldName,
      autocompleteState,
    ] of fieldAutocompleteEntries) {
      console.log(
        '===Field Options Search',
        autocompleteFieldName,
        autocompleteState.queryFieldValue,
      );
      const searchResult = onOptionsSearch(
        autocompleteState.targetFieldName,
        autocompleteState.queryFieldName,
        autocompleteState.queryFieldValue,
      );

      autocompleteState.resolveQuery(searchResult);
    }
  }, [fieldsAutocomplete, onOptionsSearch]);

  const fieldsRenderPropsList = getFieldsRenderProps({
    definition,
    payloadFields,
    metaFields,
    metaForm,
    payloadFormErrors,
  });
  // log('===fieldsRenderPropsList=', fieldsRenderPropsList, payloadFormErrors);

  useImperativeHandle(
    ref,
    () => {
      return {
        submission: {
          payload: payloadForm.value,
        },
      };
    },
    [payloadForm.value],
  );

  return (
    <FormProvider context={payloadForm.context}>
      <FormProvider context={metaForm.context}>
        <div id={payloadForm.errorId}>{payloadForm.errors}</div>
        <form {...getFormProps(metaForm)} />
        <form {...getFormProps(payloadForm)} />
        <FormFromLayout
          fields={FormFields({ fields: fieldsRenderPropsList })}
        />

        {submitLabel && (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              paddingTop: 4,
            }}
          >
            <Button
              isLoading={isLoading}
              sx={{
                paddingBlock: 1,
                paddingInline: 3,
                marginLeft: 'auto',
              }}
              variantSemantic="primary"
              type="submit"
              form={payloadForm.id}
            >
              <Typography
                variant="body2"
                sx={{
                  lineHeight: '20px',
                  color: (theme) => theme.palette.primaryAlt.contrastText,
                }}
                data-testid="form-submit-button"
              >
                {submitLabel}
              </Typography>
            </Button>
          </Box>
        )}
      </FormProvider>
    </FormProvider>
  );
}

function getDefaults<Schema extends z.AnyZodObject>(schema: Schema) {
  return Object.fromEntries(
    Object.entries(schema.shape).map(([key, value]) => {
      if (value instanceof z.ZodDefault)
        return [key, value._def.defaultValue()];
      return [key, undefined];
    }),
  );
}

export const FormFromDefinition = React.forwardRef(BaseFormFromDefinition);

import {
  AdmissionDraftAttachmentType,
  AdmissionDraftInputFormSectionEnum,
  AdmissionDraftInputFormStepEnum,
  AdmissionDraftStatus,
  MembershipTypes,
} from '@octopus/api';

export const admissionDraftStatuses: {
  [key in AdmissionDraftStatus]: AdmissionDraftStatus;
} = {
  none: 'none',
  draft_created: 'draft_created',
  invite_sent: 'invite_sent',
  user_created: 'user_created',
  admission_partially_filled_admin: 'admission_partially_filled_admin',
  admission_partially_filled_user: 'admission_partially_filled_user',
  admission_submitted: 'admission_submitted',
  admission_approved: 'admission_approved',
  contract_created: 'contract_created',
  archived: 'archived',
} as const;

export const finalStatuses = new Set([
  admissionDraftStatuses.admission_approved,
  admissionDraftStatuses.contract_created,
  admissionDraftStatuses.archived,
]);

export const adminOnlyStatuses = new Set([
  admissionDraftStatuses.none,
  admissionDraftStatuses.draft_created,
  admissionDraftStatuses.admission_partially_filled_admin,
  admissionDraftStatuses.admission_submitted,
]);

export const workerOnlyStatuses = new Set([
  admissionDraftStatuses.invite_sent,
  admissionDraftStatuses.user_created,
  admissionDraftStatuses.admission_partially_filled_user,
]);

export const admissionDraftFormSections: {
  [key in AdmissionDraftInputFormSectionEnum]: AdmissionDraftInputFormSectionEnum;
} = {
  payroll_data: 'payroll_data',
  personal_data: 'personal_data',
};

export const admissionDraftFormSteps: {
  [key in AdmissionDraftInputFormStepEnum]: AdmissionDraftInputFormStepEnum;
} = {
  modalidade_contrato: 'modalidade_contrato',
  criacao_usuario: 'criacao_usuario',
  profissional: 'profissional',
  regime_trabalho: 'regime_trabalho',
  jornada: 'jornada',
  duracao: 'duracao',
  sindicato: 'sindicato',
  remuneracao: 'remuneracao',
  envio_convite: 'envio_convite',
  dados_pessoais: 'dados_pessoais',
  formacao_academica: 'formacao_academica',
  endereco_e_contatos: 'endereco_e_contatos',
  deficiencias: 'deficiencias',
  dependentes: 'dependentes',
  dados_bancarios: 'dados_bancarios',
  documentos: 'documentos',
  finaliza_admissao: 'finaliza_admissao',
} as const;

export const initialSteps = new Set([
  admissionDraftFormSteps.modalidade_contrato,
  admissionDraftFormSteps.criacao_usuario,
  admissionDraftFormSteps.profissional,
]);

export const customSteps = new Set([
  admissionDraftFormSteps.envio_convite,
  admissionDraftFormSteps.finaliza_admissao,
]);

export const adminSteps = new Set([
  admissionDraftFormSteps.modalidade_contrato,
  admissionDraftFormSteps.criacao_usuario,
  admissionDraftFormSteps.profissional,
  admissionDraftFormSteps.regime_trabalho,
  admissionDraftFormSteps.duracao,
  admissionDraftFormSteps.jornada,
  admissionDraftFormSteps.sindicato,
  admissionDraftFormSteps.remuneracao,
  admissionDraftFormSteps.envio_convite,
  admissionDraftFormSteps.finaliza_admissao,
]);

export const workerSteps = new Set([
  admissionDraftFormSteps.dados_pessoais,
  admissionDraftFormSteps.formacao_academica,
  admissionDraftFormSteps.endereco_e_contatos,
  admissionDraftFormSteps.deficiencias,
  admissionDraftFormSteps.dependentes,
  admissionDraftFormSteps.dados_bancarios,
  admissionDraftFormSteps.documentos,
  admissionDraftFormSteps.finaliza_admissao,
]);

export const membershipTypes: {
  [key in MembershipTypes]: MembershipTypes;
} = {
  external: 'external',
  internal: 'internal',
  owner: 'owner',
  'tako:support': 'tako:support',
} as const;

export const admissionDraftAttachmentTypes: {
  [key in AdmissionDraftAttachmentType]: string;
} = {
  rg: 'RG',
  cnh: 'CNH',
  cpf: 'CPF',
  titulo_eleitor: 'Título de eleitor',
  certidao_nascimento: 'Certidão de nascimento',
  certidao_casamento: 'Certidão de casamento',
  certificado_formacao: 'Certificado de Formação',
  passaporte: 'Passaporte',
  comprovante_residencia: 'Comprovante de resideência',
  outro: '',
} as const;

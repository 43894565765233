import { ReactElement } from 'react';

import { SvgIconComponent } from '@mui/icons-material';
import { Box, Divider, Typography } from '@mui/material';

import { PersonInfoCardProps, PersonInfoCardSkeleton } from './PersonInfoCard';

export type SidePanelProps = {
  isLoading?: boolean;
  name?: string;
  InfoCard: ReactElement<PersonInfoCardProps>;
  Menu?: ReactElement<SidePanelMenuProps>;
};

export function SidePanel({ isLoading, name, InfoCard, Menu }: SidePanelProps) {
  if (isLoading || !name) {
    return <SidePanelSkeleton Menu={Menu} />;
  }
  return (
    <Box ml="340px">
      <Box
        position="fixed"
        top="0px"
        left="56px"
        display="flex"
        flexDirection="column"
        width="340px"
        height="100vh"
        borderRight="1px solid #EDEDED"
      >
        <Box display="flex" flexDirection="column" zIndex={100}>
          {InfoCard}
          <Box pr={5} pl={4.25}>
            <Box py={4}>
              <Divider />
            </Box>
            {Menu}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export type SidePanelMenuProps = {
  children:
    | ReactElement<SidePanelMenuItemProps>
    | ReactElement<SidePanelMenuItemProps>[];
};

export function SidePanelMenu({ children }: SidePanelMenuProps) {
  return <Box>{children}</Box>;
}

export type SidePanelMenuItemProps = {
  Icon: SvgIconComponent;
  title: string;
  onClick: () => void;
  children?:
    | ReactElement<SidePanelMenuSubItemProps>
    | ReactElement<SidePanelMenuSubItemProps>[];
  testId?: string;
};

export function SidePanelMenuItem({
  Icon,
  title,
  onClick,
  children,
  testId,
}: SidePanelMenuItemProps) {
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box
        display="flex"
        alignItems="center"
        p={1}
        onClick={onClick}
        data-testid={testId}
        borderRadius={1}
        bgcolor="background.default"
      >
        <Typography
          variant="body2"
          fontWeight="700"
          sx={{
            display: 'flex',
            gap: 1,
            cursor: 'pointer',
          }}
        >
          <Icon
            sx={{
              width: '16px',
              height: '16px',
            }}
          />
          {title}
        </Typography>
      </Box>
      {children ? (
        <Box display="flex" flexDirection="column">
          {children}
        </Box>
      ) : undefined}
    </Box>
  );
}

export type SidePanelMenuSubItemProps = {
  title: string;
  onClick: () => void;
  testId?: string;
  hide?: boolean;
};

export function SidePanelMenuSubItem({
  title,
  onClick,
  testId,
  hide,
}: SidePanelMenuSubItemProps) {
  if (hide) {
    return null;
  }
  return (
    <Box
      display="flex"
      alignItems="center"
      pl={5}
      pr={1}
      py={1}
      onClick={onClick}
      data-testid={testId}
    >
      <Typography
        variant="body2"
        color="text.secondary"
        fontWeight="500"
        boxSizing="border-box"
        sx={(theme) => ({
          cursor: 'pointer',
          '&:hover': {
            color: theme.palette.text.primary,
          },
        })}
      >
        {title}
      </Typography>
    </Box>
  );
}

export type SidePanelSkeletonProps = {
  Menu?: ReactElement<SidePanelMenuProps>;
  hideBackButton?: boolean;
};

function SidePanelSkeleton({ Menu }: SidePanelSkeletonProps) {
  return (
    <Box width="340px" height="100%" borderRight="1px solid #EDEDED">
      <PersonInfoCardSkeleton />
      <Box
        display="flex"
        flexDirection="column"
        pr={5}
        pl={4.25}
        position="relative"
      >
        <Box py={4}>
          <Divider />
        </Box>
        {Menu}
      </Box>
    </Box>
  );
}

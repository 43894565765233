import {
  ContractBRCltEntryNascimento,
  ContractBRCltEntryPessoa,
} from '@octopus/api';
import {
  CondicaoIngresso,
  EstadoCivil,
  GrauInstrucao,
  Nacionalidades,
  Paises,
  RacaCor,
  Sexo,
  TempoResidencia,
} from '@octopus/esocial/mapper';
import { formatCPF, formatDateBR } from '@octopus/formatters';

import { Record, RecordEntry, mapperToOptions } from '../../../Record';
import { BaseRecordProps } from '../../common';
import { useRecordEdit } from '../../useRecordEdit';

type InformacoesPessoaisRecordData = {
  pessoa: ContractBRCltEntryPessoa;
  nascimento: ContractBRCltEntryNascimento;
};

export type InformacoesPessoaisRecordProps =
  BaseRecordProps<InformacoesPessoaisRecordData>;

export function InformacoesPessoaisRecord(
  props: InformacoesPessoaisRecordProps,
) {
  const {
    data: { pessoa, nascimento },
  } = props;

  const { editing, formData, updateData, editRecordProps, hasError } =
    useRecordEdit<InformacoesPessoaisRecordData>(props);

  const prepareOnChange = (
    group: 'pessoa' | 'nascimento',
    key: string,
    formatter: (val: string) => string | number = (val) => val,
  ) => {
    return (value: string) =>
      updateData((data) => ({
        ...data,
        [group]: { ...data[group], [key]: formatter(value) },
      }));
  };

  return (
    <Record title="Informações pessoais" edit={editRecordProps}>
      <RecordEntry
        label="Nome completo"
        edit={{
          type: 'text',
          editing,
          onChange: prepareOnChange('pessoa', 'nmTrab'),
          value: formData.pessoa.nmTrab,
          hasError: hasError('br/pessoa/nmTrab'),
        }}
      >
        {pessoa?.nmTrab}
      </RecordEntry>
      <RecordEntry
        label="Nome social"
        edit={{
          type: 'text',
          editing,
          onChange: prepareOnChange('pessoa', 'nmSoc'),
          value: formData.pessoa.nmSoc,
          hasError: hasError('br/pessoa/nmSoc'),
        }}
      >
        {pessoa?.nmSoc}
      </RecordEntry>
      <RecordEntry
        label="CPF"
        edit={{
          type: 'text',
          editing,
          onChange: prepareOnChange('pessoa', 'cpfTrab'),
          value: formatCPF(formData.pessoa.cpfTrab),
          disabled: true,
          hasError: hasError('br/pessoa/cpfTrab'),
        }}
      >
        {formatCPF(pessoa?.cpfTrab)}
      </RecordEntry>
      <RecordEntry
        label="Sexo"
        edit={{
          type: 'options',
          editing,
          onChange: prepareOnChange('pessoa', 'sexo'),
          value: formData.pessoa.sexo,
          options: mapperToOptions({ mapper: Sexo }),
          hasError: hasError('br/pessoa/sexo'),
        }}
      >
        {Sexo.getByCode(pessoa?.sexo)}
      </RecordEntry>
      <RecordEntry
        label="Raça e cor"
        edit={{
          type: 'options',
          editing,
          onChange: prepareOnChange('pessoa', 'racaCor', parseInt),
          value: formData.pessoa.racaCor,
          options: mapperToOptions({ mapper: RacaCor }),
          hasError: hasError('br/pessoa/racaCor'),
        }}
      >
        {RacaCor.getByCode(pessoa?.racaCor)}
      </RecordEntry>
      <RecordEntry
        label="Estado civil"
        edit={{
          type: 'options',
          editing,
          onChange: prepareOnChange('pessoa', 'estCiv', parseInt),
          value: formData.pessoa.estCiv,
          options: mapperToOptions({ mapper: EstadoCivil }),
          hasError: hasError('br/pessoa/estCiv'),
        }}
      >
        {EstadoCivil.getByCode(pessoa?.estCiv)}
      </RecordEntry>
      <RecordEntry
        label="Grau de instrução"
        edit={{
          type: 'options',
          editing,
          onChange: prepareOnChange('pessoa', 'grauInstr', parseInt),
          value: formData.pessoa.grauInstr,
          options: mapperToOptions({ mapper: GrauInstrucao }),
          hasError: hasError('br/pessoa/grauInstr'),
        }}
      >
        {GrauInstrucao.getByCode(pessoa?.grauInstr)}
      </RecordEntry>
      <RecordEntry
        label="Data de nascimento"
        edit={{
          type: 'date',
          editing,
          valueFormat: 'YYYY-MM-DD',
          value: nascimento.dtNascto,
          onChange: prepareOnChange('nascimento', 'dtNascto'),
          hasError: hasError('br/nascimento/dtNascto'),
        }}
      >
        {formatDateBR(nascimento?.dtNascto)}
      </RecordEntry>
      <RecordEntry
        label="País de nascimento"
        edit={{
          type: 'options',
          editing,
          onChange: prepareOnChange('nascimento', 'paisNascto', parseInt),
          value: formData.nascimento.paisNascto,
          options: mapperToOptions({ mapper: Paises, sortBy: 'label' }),
          hasError: hasError('br/nascimento/paisNascto'),
        }}
      >
        {Paises.getByCode(nascimento?.paisNascto)}
      </RecordEntry>
      <RecordEntry
        label="Nacionalidade"
        edit={{
          type: 'options',
          editing,
          onChange: prepareOnChange('nascimento', 'paisNac', parseInt),
          value: formData.nascimento.paisNac,
          options: mapperToOptions({
            mapper: Paises,
            sortBy: 'label',
            getLabel: (code) => Nacionalidades.getByCode(code as number),
          }),
          hasError: hasError('br/nascimento/paisNac'),
        }}
      >
        {Nacionalidades.getByCode(nascimento?.paisNac)}
      </RecordEntry>
      <RecordEntry
        label="Tempo de residência"
        hide={
          !nascimento?.tmpResid &&
          nascimento?.paisNac === 105 &&
          formData.nascimento?.paisNac === 105
        }
        edit={{
          type: 'options',
          editing,
          onChange: prepareOnChange('nascimento', 'tmpResid', parseInt),
          value: formData.nascimento.tmpResid,
          options: mapperToOptions({ mapper: TempoResidencia }),
          hasError: hasError('br/nascimento/tmpResid'),
        }}
      >
        {TempoResidencia.getByCode(nascimento?.tmpResid)}
      </RecordEntry>
      <RecordEntry
        label="Condição de ingresso no país"
        hide={
          !nascimento?.condIng &&
          nascimento?.paisNac === 105 &&
          formData.nascimento?.paisNac === 105
        }
        edit={{
          type: 'options',
          editing,
          onChange: prepareOnChange('nascimento', 'condIng', parseInt),
          value: formData.nascimento.condIng,
          options: mapperToOptions({ mapper: CondicaoIngresso }),
          hasError: hasError('br/nascimento/condIng'),
        }}
      >
        {CondicaoIngresso.getByCode(nascimento?.condIng)}
      </RecordEntry>
    </Record>
  );
}

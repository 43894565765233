import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CheckCircle } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  TextField,
  Typography,
} from '@mui/material';

import { AdmissionDraftEntry } from '@octopus/api';
import { OctopusLoading } from '@octopus/ui/design-system';

import { SnackbarType } from '../../hooks/snackbarContext';
import { useSnackbar } from '../../hooks/useSnackbar';

const errorSnackbar: SnackbarType = {
  isOpen: true,
  variant: 'error',
  Message: 'Ocorreu um erro, por favor tente novamente.',
  hasCloseAction: true,
};

const getSuccessSnackbar = (message: string): SnackbarType => ({
  isOpen: true,
  variant: 'default',
  Message: message,
  StartAdornment: <CheckCircle />,
  autoHideDuration: 5000,
  hasCloseAction: false,
});

export function DeleteConfirmationDialog({
  open,
  setOpen,
  action,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  action: () => Promise<void>;
}) {
  const { showSnackbar } = useSnackbar();

  const handleError = (error: Error) => {
    showSnackbar(errorSnackbar);
    setOpen(false);
    setLoading(false);
    console.error(error);
  };
  const [loading, setLoading] = useState(false);

  const content = (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'flex-start'}
      gap={4}
      borderRadius={2}
      p={1.5}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        gap={3}
      >
        <Typography fontWeight={700} variant={'h1'}>
          Excluir Candidato
        </Typography>
        <Typography variant={'body1'}>
          Ao excluir o candidato do processo, os acessos a admissão da
          organização serão revogados.
          <Typography display={'inline'} color={'#BC1A41'} fontWeight={700}>
            {' '}
            Essa ação não pode ser revertida.
          </Typography>
        </Typography>
      </Box>
    </Box>
  );

  const actions = (
    <>
      <Button
        variant="contained"
        sx={{
          backgroundColor: 'background.paper',
          color: 'text.primary',
          px: 4,
          ':hover': {
            backgroundColor: 'strokes.light',
          },
        }}
        size="large"
        onClick={() => {
          setOpen(false);
        }}
        data-testid="cancel-archive-admission-button"
      >
        Cancelar
      </Button>
      <Button
        color={'error'}
        variant="contained"
        size="large"
        onClick={() => {
          setLoading(true);
          action()
            .then(() => {
              showSnackbar(
                getSuccessSnackbar('O candidato foi excluído do processo'),
              );
            })
            .catch(handleError)
            .finally(() => {
              setLoading(false);
              setOpen(false);
            });
        }}
      >
        Excluir Candidato
      </Button>
    </>
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: { display: 'none' },
        })}
        PaperProps={{
          sx: { minWidth: '600px', maxWidth: '600px', minHeight: '300px' },
        }}
      >
        {loading && <LoadingScene message={'Carregando'} />}
        {!loading && (
          <>
            <DialogContent>{content}</DialogContent>
            <DialogActions>{actions}</DialogActions>
          </>
        )}
      </Dialog>
      <MobileDrawer
        open={open}
        setOpen={setOpen}
        actions={actions}
        content={content}
        loading={loading}
        setLoading={setLoading}
      />
    </>
  );
}

export function AdminTakeOverConfirmationDialog({
  open,
  setOpen,
  action,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  action: () => Promise<AdmissionDraftEntry>;
}) {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const handleError = (error: Error) => {
    showSnackbar(errorSnackbar);
    setOpen(false);
    setLoading(false);
    console.error(error);
  };

  const [loading, setLoading] = useState(false);

  const content = (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'flex-start'}
      gap={4}
      borderRadius={2}
      p={1.5}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        gap={3}
      >
        <Typography fontWeight={700} variant={'h1'}>
          Preencher para o candidato
        </Typography>
        <Typography variant={'body1'}>
          Ao assumir o preenchimento pelo candidato, o acesso dele ao aplicativo
          de admissão será interrompido. Certifique-se de que o candidato foi
          comunicado sobre esta decisão antes de prosseguir.
        </Typography>
      </Box>
    </Box>
  );

  const actions = (
    <>
      <LoadingButton
        disabled={loading}
        variant="contained"
        sx={{
          backgroundColor: 'background.paper',
          color: 'text.primary',
          px: 4,
          ':hover': {
            backgroundColor: 'strokes.light',
          },
        }}
        size="large"
        onClick={() => {
          setOpen(false);
        }}
        data-testid="cancel-archive-admission-button"
      >
        Cancelar
      </LoadingButton>
      <LoadingButton
        loading={loading}
        color="primaryAlt"
        variant="contained"
        size="large"
        onClick={() => {
          setLoading(true);
          action()
            .then((draft) => {
              showSnackbar(
                getSuccessSnackbar(
                  'Mudança realizada com sucesso! Redirecionando...',
                ),
              );
              setTimeout(() => navigate(`new/${draft.draftId}`), 2000);
            })
            .catch(handleError)
            .finally(() => {
              setLoading(false);
              setOpen(false);
            });
        }}
      >
        Preencher
      </LoadingButton>
    </>
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: { display: 'none' },
        })}
        PaperProps={{
          sx: { minWidth: '600px', maxWidth: '600px', minHeight: '300px' },
        }}
      >
        <DialogContent>{content}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
      <MobileDrawer
        open={open}
        setOpen={setOpen}
        actions={actions}
        content={content}
        loading={loading}
        setLoading={setLoading}
      />
    </>
  );
}

export function ResendInviteConfirmationDialog({
  open,
  setOpen,
  action,
  user_email,
  user_name,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  action: ({
    email,
    name,
  }: {
    email: string;
    name: string;
  }) => Promise<AdmissionDraftEntry>;
  user_email: string;
  user_name: string;
}) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(user_email);
  const [name, setName] = useState(user_name);
  const [formError, setFormError] = useState<{ name?: string; email?: string }>(
    {},
  );

  const { showSnackbar } = useSnackbar();

  const handleError = (error: Error) => {
    showSnackbar(errorSnackbar);
    setLoading(false);
    console.error(error);
  };

  const closeDialog = () => {
    setOpen(false);
    setName(null);
    setEmail(null);
    setFormError({});
  };

  const content = (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'stretch'}
      gap={4}
      borderRadius={2}
      p={1.5}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        gap={3}
      >
        <Typography fontWeight={700} variant={'h1'}>
          Reenviar email
        </Typography>
        <Typography variant={'body1'}>
          Confirme as informações de email para fazer o reenvio:
        </Typography>

        <Box display="flex" flexDirection={'column'} width={'100%'} gap={1}>
          <Typography variant="caption" color="text.secondary">
            Nome do Candidato
          </Typography>
          <TextField
            disabled
            error={formError.name != null}
            fullWidth={true}
            value={name}
            required={true}
          />
          <Typography variant="caption" color="error">
            {formError.name}
          </Typography>
        </Box>
        <Box display="flex" flexDirection={'column'} width={'100%'} gap={1}>
          <Typography variant="caption" color="text.secondary">
            Email
          </Typography>
          <TextField
            disabled
            error={formError.email != null}
            fullWidth={true}
            value={email}
            required={true}
          />
          <Typography variant="caption" color="error">
            {formError.email}
          </Typography>
        </Box>

        <Typography variant={'caption'}>
          Se o email estiver incorreto, favor solicitar a alteração no suporte
          da Tako.
        </Typography>
      </Box>
    </Box>
  );

  const actions = (
    <>
      <LoadingButton
        disabled={loading}
        variant="contained"
        sx={{
          backgroundColor: 'background.paper',
          color: 'text.primary',
          px: 4,
          ':hover': {
            backgroundColor: 'strokes.light',
          },
        }}
        size="large"
        onClick={() => {
          closeDialog();
        }}
        data-testid="cancel-archive-admission-button"
      >
        Cancelar
      </LoadingButton>
      <LoadingButton
        loading={loading}
        color="primaryAlt"
        variant="contained"
        size="large"
        onClick={() => {
          if (!name) {
            setFormError({ name: 'Nome é obrigatório' });
            return;
          }
          if (!email) {
            setFormError({ email: 'Email é obrigatório' });
            return;
          }

          setLoading(true);
          action({ email, name })
            .then(() => {
              closeDialog();
              showSnackbar(getSuccessSnackbar('O candidato foi convidado'));
            })
            .catch(handleError)
            .finally(() => {
              setLoading(false);
            });
        }}
      >
        Reenviar email
      </LoadingButton>
    </>
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={closeDialog}
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: { display: 'none' },
        })}
        PaperProps={{
          sx: { minWidth: '600px', maxWidth: '600px', minHeight: '300px' },
        }}
      >
        <DialogContent>{content}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
      <MobileDrawer
        open={open}
        setOpen={setOpen}
        actions={actions}
        content={content}
        loading={loading}
        setLoading={setLoading}
      />
    </>
  );
}

export function MobileDrawer({
  content,
  actions,
  setOpen,
  setLoading,
  loading,
  open,
}: {
  open: boolean;
  content: React.ReactNode;
  actions?: React.ReactNode;
  setOpen?: (open: boolean) => void;
  setLoading?: (open: boolean) => void;
  loading: boolean;
}) {
  return (
    <Drawer
      anchor={'bottom'}
      open={open}
      onClose={() => {
        if (setOpen) {
          setOpen(false);
          setLoading && setLoading(false);
        }
      }}
      sx={(theme) => ({
        [theme.breakpoints.up('sm')]: { display: 'none' },
        zIndex: 99999,
      })}
      transitionDuration={500}
      elevation={2}
      PaperProps={{ sx: { borderRadius: '16px 16px 0 0 ' } }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        p={2}
      >
        <>
          {loading && <LoadingScene message={'Carregando'} />}
          {!loading && (
            <>
              <Box height={'70%'}>{content}</Box>
              {actions && (
                <Box
                  height={'30%'}
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'flex-end'}
                  mt={5}
                  mb={1}
                  gap={2}
                >
                  {actions}
                </Box>
              )}
            </>
          )}
        </>
      </Box>
    </Drawer>
  );
}

function LoadingScene({ message }: { message: string }) {
  return (
    <Box
      sx={(theme) => {
        return {
          [theme.breakpoints.down('md')]: {
            height: '30%',
            mt: '15%',
            // mb: 1,
            // gap: 2,
          },
          [theme.breakpoints.up('sm')]: {
            height: '30%',
            mt: '15%',
            // mt: 10,
            // mb: 1,
            // gap: 2,
          },
        };
      }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box width="100px">
        <OctopusLoading />
      </Box>
      <Box pt={2} width={'100%'}>
        <Typography variant="h4" textAlign="center">
          {message}
        </Typography>
      </Box>
    </Box>
  );
}

import { RefObject } from 'react';

import { Box, Typography } from '@mui/material';

import {
  ContractBRCltEntry,
  ContractEntry,
  ContractStatus,
  useGetCompanyEntry,
  useGetJobTitleEntry,
  useGetLegalEntity,
} from '@octopus/api';
import {
  contractTypes,
  getWorkerId,
  isBRCltContract,
} from '@octopus/contract-types';
import {
  Categorias,
  ContratoRegimeParcial,
  IndicativoAdmissao,
  MotivoAfastamento,
  NaturezaAtividade,
  NaturezaEstagio,
  NivelEstagio,
  TipoAdmissao,
  TipoContrato,
  TipoJornada,
  TipoRegimeJornada,
  TipoRegimePrevidenciario,
  TipoRegimeTrabalho,
  UnidadeSalarioFixo,
} from '@octopus/esocial/mapper';
import {
  formatBooleanBR,
  formatCNPJ,
  formatCPF,
  formatDateBR,
  formatMoney,
} from '@octopus/formatters';

import {
  Record,
  RecordEntry,
  RecordEntryGroup,
  RecordGroup,
} from '../../modules/components/Record';
import { parsePixKey } from '../../modules/payrolls/parser';
import { PixChip } from '../../modules/people/person/components/PixChip';
import { StatusBadge } from '../../modules/people/person/components/StatusBadge';
import { QueryResult } from '../../modules/types';

export type ContractDetailsProps = {
  hasContracts: boolean;
  contractQuery: QueryResult<ContractEntry>;
  showExpandIcon: boolean;
  contractDetailsRef?: RefObject<Element>;
};

export function CltContractDetails({
  contractDetailsRef,
  contractQuery,
  showExpandIcon,
  hasContracts,
}: ContractDetailsProps) {
  if (!hasContracts) {
    return null;
  }
  const { isLoading, data: contractInfo } = contractQuery;
  if (!isLoading && (!contractInfo || !contractInfo.br)) {
    return null;
  }

  if (!isBRCltContract(contractInfo)) {
    return null;
  }

  const { organizationId, companyId, legalEntityId, br } = contractInfo ?? {};
  const {
    situacao,
    trabalho,
    gestao,
    estagio,
    vinculo,
    regime,
    jornada,
    remuneracao,
    duracao,
    sucessao,
    pagamento,
    observacao,
  } = (br as ContractBRCltEntry) ?? {};
  return (
    <Box ref={contractDetailsRef} data-testid="clt-contract-details">
      <RecordGroup showExpandIcon={showExpandIcon} title="Dados Contratuais">
        <CltSituacao
          status={contractInfo.status}
          situacao={situacao}
          isLoading={isLoading}
        />
        <Trabalho
          organizationId={organizationId}
          trabalho={trabalho}
          gestao={gestao}
          isLoading={isLoading}
        />
        <Estagio estagio={estagio} isLoading={isLoading} />
        <Vinculo
          vinculo={vinculo}
          workerId={getWorkerId(contractInfo)}
          isLoading={isLoading}
        />
        <Regime regime={regime} isLoading={isLoading} />
        <Jornada jornada={jornada} isLoading={isLoading} />
        <Remuneracao remuneracao={remuneracao} isLoading={isLoading} />
        <Pagamento pagamento={pagamento} isLoading={isLoading} />
        <Duracao duracao={duracao} isLoading={isLoading} />
        <Local
          organizationId={organizationId}
          companyId={companyId}
          legalEntityId={legalEntityId}
          isLoading={isLoading}
        />
        <Sucessao sucessao={sucessao} isLoading={isLoading} />

        <Observacao observacao={observacao} isLoading={isLoading} />
      </RecordGroup>
    </Box>
  );
}

function CltSituacao({
  status,
  situacao,
  isLoading,
}: {
  status: ContractStatus;
  situacao?: ContractBRCltEntry['situacao'];
  isLoading: boolean;
}) {
  return (
    <Record title="Situação">
      <RecordEntry label={'Situação'} isLoading={isLoading}>
        <StatusBadge status={status} contractType={contractTypes.brClt} />
      </RecordEntry>
      {situacao?.tipo === 'desligado' ? (
        <RecordEntry label={'Data início do desligamento'}>
          {formatDateBR(situacao?.dtDeslig)}
        </RecordEntry>
      ) : situacao?.tipo === 'afastado' ? (
        <>
          <RecordEntry label={'Data início do afastamento'}>
            {formatDateBR(situacao.dtIniAfast)}
          </RecordEntry>
          <RecordEntry label={'Código do motivo'}>
            {MotivoAfastamento.getByCode(situacao.codMotAfast)}
          </RecordEntry>
        </>
      ) : (
        <div></div>
      )}
    </Record>
  );
}

function Trabalho({
  organizationId,
  trabalho,
  gestao,
  isLoading,
}: {
  organizationId?: string;
  trabalho?: ContractBRCltEntry['trabalho'];
  gestao?: ContractBRCltEntry['gestao'];
  isLoading: boolean;
}) {
  const { data, isLoading: loadingJotTitle } = useGetJobTitleEntry(
    {
      pathParams: {
        organizationId: organizationId ?? '',
        jobTitleId: trabalho?.jobTitleId ?? '',
      },
    },
    {
      enabled: !!organizationId && !!trabalho?.jobTitleId,
    },
  );
  return (
    <Record title="Contrato de trabalho">
      <RecordEntry label="Código da categoria" isLoading={isLoading}>
        {Categorias.getByCode(trabalho?.codCateg)}
      </RecordEntry>
      <RecordEntry label="Cargo" isLoading={loadingJotTitle}>
        {data?.name}
      </RecordEntry>
      <RecordEntry label="CBO do cargo" isLoading={loadingJotTitle}>
        {data?.occupationCode && `${data?.occupationCode}`}
      </RecordEntry>
      <RecordEntry label="Cargo CPTS" isLoading={loadingJotTitle}>
        {data?.name}
      </RecordEntry>
      <RecordEntry label="Departamento" isLoading={isLoading}>
        {trabalho?.departamento}
      </RecordEntry>
      <RecordEntry label="Centro de custos" isLoading={isLoading}>
        {gestao?.nomeCentroCusto}
      </RecordEntry>
      <RecordEntry label="Código do centro de custos" isLoading={isLoading}>
        {gestao?.codigoCentroCusto}
      </RecordEntry>
    </Record>
  );
}

function Estagio({
  estagio,
  isLoading,
}: {
  estagio?: ContractBRCltEntry['estagio'];
  isLoading: boolean;
}) {
  if (!isLoading && !estagio) {
    return null;
  }
  const {
    natEstagio,
    nivEstagio,
    areaAtuacao,
    dtPrevTerm,
    nrApol,
    instEnsino,
  } = estagio ?? {};
  return (
    <Record title="Estágio">
      <RecordEntryGroup>
        <RecordEntry label="Natureza do estágio" isLoading={isLoading}>
          {NaturezaEstagio.getByCode(natEstagio)}
        </RecordEntry>
        <RecordEntry label="Nível" isLoading={isLoading}>
          {NivelEstagio.getByCode(nivEstagio)}
        </RecordEntry>
        <RecordEntry label="Área de atuação">{areaAtuacao}</RecordEntry>
        <RecordEntry label="Data de termino" isLoading={isLoading}>
          {formatDateBR(dtPrevTerm)}
        </RecordEntry>
        <RecordEntry label="Número da apólice do seguro" isLoading={isLoading}>
          {nrApol ? nrApol.match(/.{5}/g)?.join(' ') : undefined}
        </RecordEntry>
      </RecordEntryGroup>
      <RecordEntryGroup title="Instituição de ensino">
        <RecordEntry label="CNPJ">
          {formatCNPJ(instEnsino?.cnpjInstEnsino)}
        </RecordEntry>
      </RecordEntryGroup>
    </Record>
  );
}

function Vinculo({
  workerId,
  vinculo,
  isLoading,
}: {
  workerId: string;
  vinculo?: ContractBRCltEntry['vinculo'];
  isLoading: boolean;
}) {
  const { matricula, tpRegTrab, tpRegPrev, nrProcJud, emailCorp } =
    vinculo ?? {};
  return (
    <Record title="Vínculo com a empresa">
      <RecordEntry label="Matrícula usada na empresa" isLoading={isLoading}>
        {workerId}
      </RecordEntry>
      <RecordEntry label="Matrícula usada no eSocial" isLoading={isLoading}>
        {matricula}
      </RecordEntry>
      <RecordEntry label="Tipo de regime trabalhista" isLoading={isLoading}>
        {TipoRegimeTrabalho.getByCode(tpRegTrab)}
      </RecordEntry>
      <RecordEntry label="Tipo de regime previdenciário" isLoading={isLoading}>
        {TipoRegimePrevidenciario.getByCode(tpRegPrev)}
      </RecordEntry>
      <RecordEntry
        label="Alvará judicial relativo à contratação de menor de idade"
        isLoading={isLoading}
      >
        {nrProcJud ? nrProcJud.match(/.{5}/g)?.join(' ') : undefined}
      </RecordEntry>
      <RecordEntry label="Email corporativo" isLoading={isLoading}>
        {emailCorp}
      </RecordEntry>
    </Record>
  );
}

function Regime({
  regime,
  isLoading,
}: {
  regime?: ContractBRCltEntry['regime'];
  isLoading: boolean;
}) {
  const {
    dtAdm,
    tpAdmissao,
    indAdmissao,
    nrProcTrab,
    tpRegJor,
    natAtividade,
    dtBase,
    cnpjSindCategProf,
    dtOpcFGTS,
  } = regime ?? {};
  return (
    <Record title="Admissão e regime de trabalho">
      <RecordEntry label="Data de admissão" isLoading={isLoading}>
        {formatDateBR(dtAdm)}
      </RecordEntry>
      <RecordEntry label="Tipo de admissão">
        {TipoAdmissao.getByCode(tpAdmissao)}
      </RecordEntry>
      <RecordEntry label="Indicativo de admissão" isLoading={isLoading}>
        {IndicativoAdmissao.getByCode(indAdmissao)}
      </RecordEntry>
      <RecordEntry label="Número do processo trabalhista" isLoading={isLoading}>
        {nrProcTrab ? nrProcTrab.match(/.{5}/g)?.join(' ') : undefined}
      </RecordEntry>
      <RecordEntry label="Tipo de jornada" isLoading={isLoading}>
        {TipoRegimeJornada.getByCode(tpRegJor)}
      </RecordEntry>
      <RecordEntry label="Natureza da atividade" isLoading={isLoading}>
        {NaturezaAtividade.getByCode(natAtividade)}
      </RecordEntry>
      <RecordEntry label="Mês base da categoria" isLoading={isLoading}>
        {dtBase && dtBase < 10 ? `0${dtBase}` : `${dtBase}`}
      </RecordEntry>

      <RecordEntry label="Sindicato" isLoading={isLoading}>
        {sindicato(cnpjSindCategProf!)}
      </RecordEntry>
      <RecordEntry label="Data de opção do FGTS" isLoading={isLoading}>
        {formatDateBR(dtOpcFGTS)}
      </RecordEntry>
    </Record>
  );
}

function sindicato(cnpj: string) {
  if (cnpj === '37115367003500') {
    return 'Não filiado';
  }

  return formatCNPJ(cnpj);
}

function Jornada({
  jornada,
  isLoading,
}: {
  jornada?: ContractBRCltEntry['jornada'];
  isLoading: boolean;
}) {
  const { qtdHrsSem, tpJornada, tmpParc, horNoturno, descJorn } = jornada ?? {};
  return (
    <Record title="Horário contratual">
      <RecordEntry
        label="Jornada em horas"
        isLoading={isLoading}
      >{`${qtdHrsSem} horas`}</RecordEntry>
      <RecordEntry label="Tipo de jornada" isLoading={isLoading}>
        {TipoJornada.getByCode(tpJornada)}
      </RecordEntry>
      <RecordEntry label="Tempo parcial" isLoading={isLoading}>
        {ContratoRegimeParcial.getByCode(tmpParc)}
      </RecordEntry>
      <RecordEntry label="Possui horário noturno?" isLoading={isLoading}>
        {formatBooleanBR(horNoturno)}
      </RecordEntry>
      <RecordEntry label="Descrição da jornada semanal" isLoading={isLoading}>
        {descJorn}
      </RecordEntry>
    </Record>
  );
}

function Remuneracao({
  remuneracao,
  isLoading,
}: {
  remuneracao?: ContractBRCltEntry['remuneracao'];
  isLoading: boolean;
}) {
  const { vrSalFx, undSalFixo, dscSalVar } = remuneracao ?? {};
  return (
    <Record title="Remuneração">
      <RecordEntry label="Salário base" isLoading={isLoading}>
        {formatMoney(`${vrSalFx}`)}
      </RecordEntry>
      <RecordEntry label="Unidade para base de cálculo" isLoading={isLoading}>
        {UnidadeSalarioFixo.getByCode(undSalFixo)}
      </RecordEntry>
      <RecordEntry
        label="Descrição da remuneração variável"
        isLoading={isLoading}
      >
        {dscSalVar}
      </RecordEntry>
    </Record>
  );
}

function Pagamento({
  pagamento,
  isLoading,
}: {
  pagamento?: ContractBRCltEntry['pagamento'];
  isLoading: boolean;
}) {
  const { codigoBanco, nomeBanco, agencia, conta, chavePix, tipoPix } =
    pagamento ?? {};
  return (
    <Record title="Pagamento" hide={!pagamento}>
      <RecordEntry label="Código do Banco" isLoading={isLoading}>
        {codigoBanco}
      </RecordEntry>
      <RecordEntry label="Nome do Banco" isLoading={isLoading}>
        {nomeBanco}
      </RecordEntry>
      <RecordEntry label="Agência" isLoading={isLoading}>
        {agencia}
      </RecordEntry>
      <RecordEntry label="Conta" isLoading={isLoading}>
        {conta}
      </RecordEntry>
      <RecordEntry label="Chave Pix" isLoading={isLoading}>
        <Typography variant="body2" color="text.primary" textAlign="right">
          {chavePix}
          <PixChip type={tipoPix ?? parsePixKey(chavePix)} />
        </Typography>
      </RecordEntry>
    </Record>
  );
}

function Duracao({
  duracao,
  isLoading,
}: {
  duracao?: ContractBRCltEntry['duracao'];
  isLoading: boolean;
}) {
  const { tpContr, dtTerm, clauAssec, objDet } = duracao ?? {};
  return (
    <Record title="Duração do contrato">
      <RecordEntry label="Tipo de contrato" isLoading={isLoading}>
        {TipoContrato.getByCode(tpContr)}
      </RecordEntry>
      <RecordEntry label="Data do termino" isLoading={isLoading}>
        {formatDateBR(dtTerm)}
      </RecordEntry>
      <RecordEntry label="Contém clausula assecuratória?" isLoading={isLoading}>
        {formatBooleanBR(clauAssec)}
      </RecordEntry>
      <RecordEntry
        label="Objeto razão da contratação temporária"
        isLoading={isLoading}
      >
        {objDet}
      </RecordEntry>
    </Record>
  );
}

function Local({
  organizationId,
  companyId,
  legalEntityId,
  isLoading,
}: {
  organizationId?: string;
  companyId?: string;
  legalEntityId?: string;
  isLoading: boolean;
}) {
  const companyResponse = useGetCompanyEntry(
    {
      pathParams: {
        organizationId: organizationId ?? '',
        companyId: companyId ?? '',
      },
    },
    {
      enabled: !!organizationId && !!companyId,
    },
  );
  const legalEntityResponse = useGetLegalEntity(
    {
      pathParams: {
        organizationId: organizationId ?? '',
        companyId: companyId ?? '',
        legalEntityId: legalEntityId ?? '',
      },
    },
    {
      enabled: !!organizationId && !!companyId && !!legalEntityId,
    },
  );
  if (isLoading) {
    return null;
  }
  return (
    <Record title="Empregador e local de trabalho">
      <RecordEntry label="Empregador" isLoading={companyResponse.isLoading}>
        {companyResponse?.data?.br ? (
          <Typography variant="body2" color="text.primary" textAlign="right">
            {companyResponse.data.br.razaoSocial}
            <br />
            {formatCNPJ(companyResponse.data.br.cnpj)}
          </Typography>
        ) : undefined}
      </RecordEntry>

      <RecordEntry
        label="Estabelecimento"
        isLoading={legalEntityResponse.isLoading}
        hide={legalEntityResponse.data?.br?.classificacao === 'matriz'}
      >
        {legalEntityResponse?.data?.br ? (
          <Typography variant="body2" color="text.primary" textAlign="right">
            {legalEntityResponse.data.br.nomeFantasia}
            <br />
            {formatCNPJ(legalEntityResponse.data.br.cnpj)}
          </Typography>
        ) : undefined}
      </RecordEntry>
    </Record>
  );
}

function Sucessao({
  sucessao,
  isLoading,
}: {
  sucessao?: ContractBRCltEntry['sucessao'];
  isLoading: boolean;
}) {
  if (!isLoading && !sucessao) {
    return null;
  }
  const { tpInsc, nrInsc, matricAnt, dtTransf } = sucessao ?? {};
  return (
    <Record title="Sucessão de vínculo trabalhista">
      <RecordEntry label="Empregador anterior" isLoading={isLoading}>
        {tpInsc === 1
          ? formatCNPJ(nrInsc)
          : tpInsc === 2
            ? formatCPF(nrInsc)
            : nrInsc}
      </RecordEntry>
      <RecordEntry label="Matrícula anterior" isLoading={isLoading}>
        {matricAnt}
      </RecordEntry>
      <RecordEntry label="Data de transferência" isLoading={isLoading}>
        {formatDateBR(dtTransf)}
      </RecordEntry>
    </Record>
  );
}

function Observacao({
  observacao,
  isLoading,
}: {
  observacao: ContractBRCltEntry['observacao'];
  isLoading: boolean;
}) {
  if (!isLoading && !observacao) {
    return null;
  }
  return (
    <Record title="Observações">
      <RecordEntry
        label="Observação do contrato de trabalho"
        isLoading={isLoading}
      >
        {observacao}
      </RecordEntry>
    </Record>
  );
}

import { FieldMetadata, getSelectProps } from '@conform-to/react';

import {
  AUTO_COMPLETE_OPTION_VALUE,
  IFieldCollectionValueWithOptionsDefinition,
  IFieldSingleValueWithOptionsDefinition,
  parseFieldUiInfo,
} from '@octopus/libs/forms';

import { TFieldRenderProps, TFieldSelectRenderProps } from './types';

export function parseFieldSelect(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldMetadata: FieldMetadata<any>,
  fieldDefinition:
    | IFieldCollectionValueWithOptionsDefinition
    | IFieldSingleValueWithOptionsDefinition,
  fieldsetName = '',
): TFieldRenderProps & TFieldSelectRenderProps {
  const selectProps = getSelectProps(fieldMetadata);

  const currentValue =
    fieldMetadata.value !== undefined
      ? typeof fieldMetadata.value !== 'string' &&
        typeof fieldMetadata.value !== 'number'
        ? JSON.stringify(fieldMetadata.value)
        : `${fieldMetadata.value}`
      : undefined;

  const defaultValue: string | number | readonly string[] =
    currentValue ??
    selectProps.defaultValue ??
    (selectProps.multiple ? [] : '');

  const fieldRenderProps: TFieldRenderProps = {
    uiType: parseFieldUiInfo(fieldDefinition),
    fieldsetName,
    errors: fieldDefinition.errors,
    props: {
      key: selectProps.id,
      sx: fieldDefinition.sx,
    },
    label: {
      textContent: fieldDefinition.label,
      props: {
        htmlFor: selectProps.id,
      },
    },
    select: {
      props: {
        ...selectProps,
        ...(defaultValue && { defaultValue }),
        disabled: fieldDefinition.disabled,
        placeholder: fieldDefinition.placeholder,
        info: fieldDefinition.info,
      },
      options: (fieldDefinition.options || [])
        .filter((option) => option.value !== AUTO_COMPLETE_OPTION_VALUE)
        .map((option) => ({
          props: {
            value:
              typeof option.value !== 'string' &&
              typeof option.value !== 'number'
                ? JSON.stringify(option.value)
                : `${option.value}`,
            key: `${option.value as string}`,
          },
          defaultSelected: option.selected,
          textContent: option.label,
        })),
    },
  };

  return fieldRenderProps;
}

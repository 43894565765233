import { useNavigate } from 'react-router-dom';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Button, Typography } from '@mui/material';

import { PayrollPeriodSummary, PayrollTypes } from '@octopus/api';
import { payrollStatusIndex } from '@octopus/payroll-types';

import { countPayrollStatus } from '../../../../utils/statusIndexUtils';

import { PeriodStatusTag } from './PeriodInfo';

export function PendingPayrollsModal({
  close,
  summaries,
}: {
  close: () => void;
  summaries: PayrollPeriodSummary[];
}) {
  const navigate = useNavigate();

  if (!summaries) {
    return null;
  }

  return (
    <Box
      display="flex"
      gap={3}
      flexDirection="column"
      data-testid="pending-payrolls-modal"
    >
      <Box display="flex" mx={4} mt={5} flexDirection="column" gap={3}>
        <Typography variant="h1" fontWeight={700}>
          Folhas em andamento
        </Typography>

        <Box display="flex" flexDirection="column">
          <Typography variant="body1" fontWeight={500}>
            Atenção, alguns colaboradores estão inclusos em prévias de eventos
            ou em folhas abertas e por isso a folha mensal não pode ser
            aprovada.
          </Typography>
          <Typography variant="body1" fontWeight={500}>
            Aprove ou arquive as folhas seguintes para poder aprovar a folha
            mensal.
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          alignSelf="stretch"
          gap={1.5}
        >
          {summaries.map((periodSummary) => {
            const pendingCount = countPayrollStatus(
              periodSummary.totalizers,
              (statusIndex) => statusIndex <= payrollStatusIndex.open,
            );

            const periodType = periodSummary.type as PayrollTypes;

            const navigateTo: string = (() => {
              switch (periodType) {
                case 'vacations':
                  return '/vacations';
                case 'termination':
                  return '/terminations';
                case 'thirteenthFirst':
                case 'thirteenthSecond':
                case 'advance':
                case 'complementary':
                  return `/payrolls/${periodSummary.period}/${periodType}`;
                default:
                  throw new Error('Invalid period type');
              }
            })();

            return (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                alignSelf="stretch"
                key={`${periodSummary.period}-${periodSummary.type}`}
                data-testid="pending-payrolls-entry"
              >
                <Box
                  display="flex"
                  gap={0.5}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="body2" fontWeight={500}>
                    {getTypeLabel(periodType)}
                  </Typography>
                  <OpenInNewIcon
                    sx={{
                      width: '12px',
                      height: '12px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      close();
                      navigate(navigateTo);
                    }}
                  />
                </Box>
                <Box display="flex" gap={2} alignItems="center">
                  <Typography variant="body2" fontWeight={500}>
                    {getPendingCountLabel(pendingCount)}
                  </Typography>
                  <Box minWidth="60px">
                    <PeriodStatusTag period={periodSummary} hasIcon={false} />
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          backgroundColor: 'strokes.light',
        }}
        height="40px"
        p={1.5}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'background.paper',
            color: 'text.primary',
            px: 4,
            ':hover': {
              backgroundColor: 'strokes.light',
            },
          }}
          onClick={() => {
            close();
          }}
        >
          Fechar
        </Button>
      </Box>
    </Box>
  );
}

function getTypeLabel(type: PayrollTypes): string {
  switch (type) {
    case 'vacations':
      return 'Folha de férias';
    case 'thirteenthFirst':
      return 'Folha de 13º salário - 1ª parcela';
    case 'thirteenthSecond':
      return 'Folha de 13º salário - 2ª parcela';
    case 'termination':
      return 'Folha de rescisão';
    case 'advance':
      return 'Folha de adiantamento';
    case 'complementary':
      return 'Folha complementar';
    default:
      return 'Erro';
  }
}

function getPendingCountLabel(pendingCount: number): string {
  return `${pendingCount} ${
    pendingCount === 1 ? 'colaborador' : 'colaboradores'
  }`;
}

import { AdmissionDraftStatus } from '@octopus/api';

export enum AdmissionTabs {
  draft_created = 'draft_created',
  in_progress_candidate = 'in_progress_candidate',
  in_progress_admin = 'in_progress_admin',
  in_review = 'in_review',
  admission_done = 'admission_done',
}

export const admissionTabsConfig: {
  [key in AdmissionTabs]: {
    key: string;
    draftStatus: AdmissionDraftStatus[];
    label: string;
    color: string;
  };
} = {
  [AdmissionTabs.draft_created]: {
    key: 'draft_created',
    draftStatus: ['draft_created'],
    label: 'Rascunhos',
    color: 'warning',
  },
  [AdmissionTabs.in_progress_admin]: {
    key: 'in_progress_admin',
    draftStatus: ['admission_partially_filled_admin'],
    label: 'Preenchimento empresa',
    color: 'primary',
  },
  [AdmissionTabs.in_progress_candidate]: {
    key: 'in_progress_candidate',
    draftStatus: [
      'invite_sent',
      'user_created',
      'admission_partially_filled_user',
    ],
    label: 'Preenchimento candidato(a)',
    color: 'primary',
  },
  [AdmissionTabs.in_review]: {
    key: 'in_review',
    draftStatus: ['admission_submitted'],
    label: 'Revisão',
    color: 'primary',
  },
  [AdmissionTabs.admission_done]: {
    key: 'admission_done',
    draftStatus: ['admission_approved', 'contract_created'],
    label: 'Concluídas',
    color: 'primary',
  },
};

import { z } from 'zod';

import { createValidationSchema } from '../../createValidationSchema';
import { IFormDefinition } from '../formFieldDefinitions';

export function getDefaults(
  formDefinition: IFormDefinition,
): Record<string, string | readonly string[] | number | undefined> {
  const validationSchema = createValidationSchema(formDefinition);

  if (validationSchema instanceof z.ZodIntersection) {
    return {
      ...getDefaults(validationSchema._def.left),
      ...getDefaults(validationSchema._def.right),
    };
  }

  if (validationSchema instanceof z.ZodObject) {
    return Object.fromEntries(
      Object.entries(validationSchema.shape).map(([key, value]) => {
        try {
          const parsedValue = (value as z.ZodTypeAny).parse(undefined);
          if (
            parsedValue !== undefined &&
            typeof parsedValue !== 'string' &&
            typeof parsedValue !== 'number'
          ) {
            return [key, JSON.stringify(parsedValue)];
          }
          return [key, parsedValue ?? undefined];
        } catch (error) {
          return [key, undefined];
        }
      }),
    ) satisfies Record<string, string | readonly string[] | number | undefined>;
  }

  return {};
}

import React, { useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import CloseRoundedIcon from '@mui/icons-material/CloseOutlined';
import { Box, IconButton, Skeleton, Typography } from '@mui/material';

import {
  ContractEntry,
  ContractSummary,
  fetchGetContract,
  fetchSearchAllContracts,
} from '@octopus/api';
import { ContractTypes, getWorkerId } from '@octopus/contract-types';
import { SearchBar, SearchProps, useSearch } from '@octopus/ui/data-grid';
import { Tag } from '@octopus/ui/design-system';

import { DataFetching, FetchResult } from '../../dataFetching';
import UserAvatar from '../UserAvatar';

export function ContractSelector({
  organizationId,
  companyId,
  activeContract,
  setActiveContract,
  contractTypes,
}: {
  organizationId: string;
  companyId: string;
  activeContract: ContractEntry | undefined;
  setActiveContract: (contract: ContractEntry) => void;
  contractTypes: ContractTypes[];
}) {
  const search = useSearch();

  const hasSearchedHook = useState(false);
  const [_, setHasSearched] = hasSearchedHook;

  const { setSearchTerm } = search;

  return (
    <Box pb={1.5} display={'flex'} flexDirection={'column'} px={0.75}>
      {activeContract && (
        <Box gap={3} display={'flex'} flexDirection={'column'}>
          <ActiveContract
            contract={activeContract}
            setActiveContract={setActiveContract}
            setSearchTerm={setSearchTerm}
            setHasSearched={setHasSearched}
          />
        </Box>
      )}
      {!activeContract && (
        <SearchContract
          search={search}
          companyId={companyId}
          organizationId={organizationId}
          setActiveContract={setActiveContract}
          hasSearchedHook={hasSearchedHook}
          contractTypes={contractTypes}
        />
      )}
    </Box>
  );
}

function SearchContract({
  organizationId,
  companyId,
  setActiveContract,
  search,
  hasSearchedHook,
  contractTypes,
}: {
  organizationId: string;
  companyId: string;
  search: SearchProps;
  setActiveContract: (contract: ContractEntry) => void;
  hasSearchedHook: [boolean, (hasSearched: boolean) => void];
  contractTypes: ContractTypes[];
}) {
  const { searchTerm } = search;

  const [hasSearched, setHasSearched] = hasSearchedHook;

  const useFetch = () => {
    return useQuery({
      queryKey: ['searchAllContracts', organizationId, companyId, searchTerm],
      queryFn: async () => {
        if (searchTerm.length < 2) {
          return [];
        }

        const contractors = await fetchSearchAllContracts({
          pathParams: {
            organizationId,
          },
          body: {
            query: searchTerm.length > 0 ? searchTerm : undefined,
            pagination: {
              size: 20,
              page: 0,
            },
            sorting: {
              field: 'name',
              order: 'asc',
            },
            filtering: {
              elements: {
                contractType: contractTypes,
                companyId: [companyId],
              },
            },
          },
        });
        setHasSearched(true);
        return contractors.data ?? [];
      },
    });
  };

  return (
    <>
      <SearchBar
        searchProps={search}
        sx={{
          width: '100%',
        }}
      />
      <ContractSearch
        hasSearched={hasSearched}
        useFetch={useFetch}
        setActiveContract={setActiveContract}
      />
    </>
  );
}

function ActiveContract({
  contract,
  setActiveContract,
  setSearchTerm,
  setHasSearched,
}: {
  contract: ContractEntry;
  setActiveContract: (contract: ContractEntry) => void;
  setSearchTerm: (term: string) => void;
  setHasSearched: (hasSearched: boolean) => void;
}) {
  const workerId = getWorkerId(contract);

  return (
    <Box
      py={1}
      display={'flex'}
      flexDirection={'row'}
      borderRadius={0.75}
      bgcolor={'#F7F7F8'}
      justifyContent={'space-between'}
      alignItems={'center'}
      gap={1}
      paddingRight={1}
      paddingLeft={1.25}
    >
      <Box
        width={'95%'}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        boxSizing={'border-box'}
      >
        <Box>
          <UserAvatar
            name={contract.name}
            showFullName={true}
            avatarTextProps={{
              fontSize: '14px',
              lineHeight: '20px',
              color: 'text.primary',
              fontWeight: '500',
            }}
          />
        </Box>

        {workerId !== '' && (
          <Box>
            <Tag color={'default'} emphasis={'high'}>
              {getWorkerId(contract)}
            </Tag>
          </Box>
        )}
      </Box>
      <Box
        onClick={() => {
          setSearchTerm('');
          setHasSearched(false);
          setActiveContract(undefined);
        }}
        width={'10%'}
        boxSizing={'border-box'}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        paddingRight={1}
      >
        <IconButton>
          <CloseRoundedIcon color={'action'} />
        </IconButton>
      </Box>
    </Box>
  );
}

function ContractSearch({
  useFetch,
  hasSearched,
  setActiveContract,
}: {
  useFetch: () => FetchResult<ContractSummary[]>;
  hasSearched: boolean;
  setActiveContract: (contract: ContractEntry) => void;
}) {
  return (
    <Box py={1} hidden={!hasSearched}>
      <DataFetching
        useHook={useFetch}
        Loading={() => (
          <Box display="flex" flexDirection="column" gap="8px" pt={1}>
            <Skeleton variant="rounded" height={300} width="100%" />
          </Box>
        )}
        Data={({ data: response }) => {
          return (
            <Box
              borderRadius={1}
              border={'1px solid #EDEDED'}
              boxShadow={
                '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)'
              }
            >
              {response &&
                response.map((contractor) => (
                  <Box
                    key={contractor.contractId}
                    display={'flex'}
                    flexDirection={'row'}
                    gap={1.5}
                    py={1.25}
                    px={1}
                    alignItems={'center'}
                    sx={(theme) => ({
                      '&:hover': {
                        backgroundColor: theme.palette.strokes.secondary,
                        cursor: 'pointer',
                      },
                    })}
                    onClick={async () => {
                      const contract = await fetchGetContract({
                        pathParams: {
                          organizationId: contractor.organizationId,
                          contractId: contractor.contractId,
                        },
                      });

                      setActiveContract(contract);
                    }}
                  >
                    <UserAvatar
                      name={contractor.name}
                      showFullName={true}
                      avatarTextProps={{
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: 'text.primary',
                        fontWeight: '500',
                      }}
                    />
                    <Typography
                      variant={'body2'}
                      lineHeight={'20px'}
                      color={'#616161'}
                      fontWeight={300}
                    >
                      {contractor.employeeId}
                    </Typography>
                  </Box>
                ))}
              {!response ||
                (hasSearched && response.length === 0 && (
                  <Box px={1} py={1}>
                    <Typography
                      variant={'caption'}
                      fontSize={'12px'}
                      color={'text.secondary'}
                      fontWeight={700}
                    >
                      Nenhum resultado encontrado.
                    </Typography>
                  </Box>
                ))}
            </Box>
          );
        }}
      />
    </Box>
  );
}

import { MenuItem, Select, SxProps, Tooltip, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { UI_TYPE } from '@octopus/libs/forms';

import {
  TFieldRenderProps,
  TFieldSelectRenderProps,
} from '../parseField/types';

import { FieldWrapper } from './commons/FieldWrapper';

type FieldSelectProps = {
  field: TFieldRenderProps & TFieldSelectRenderProps;
  sx?: SxProps<Theme>;
};

const DEFAULT_PLACEHOLDER = 'Selecione...';

function renderValue({
  selected,
  multiple,
  placeholder,
  allOptions,
}: {
  selected: string | number | readonly string[];
  multiple: boolean;
  placeholder: string;
  allOptions: { [key: string]: string };
}) {
  const placeHolderNode = (
    <Typography variant="inherit" color="strokes.heavy" noWrap>
      {placeholder}
    </Typography>
  );

  if (!selected) {
    return placeHolderNode;
  }

  if (Array.isArray(selected)) {
    if (!multiple) {
      console.error('expected multiple');
      return selected;
    }
    if (selected.length === 0) {
      return placeHolderNode;
    }

    return selected.map((s) => allOptions[s as string]).join(', ');
  }

  return allOptions[selected as string] || selected;
}

export function FieldSelect(props: FieldSelectProps) {
  const { field } = props;
  const { options } = field.select;

  const allOptions: { [key: string]: string } = {};
  options.forEach((option) => {
    allOptions[option.props.value] = option.textContent;
  });

  const defaultValue =
    field.select.props.defaultValue ??
    options.find((o) => o.defaultSelected)?.props?.value ??
    '';

  const { multiple, placeholder } = field.select.props;
  const placeHolderText = placeholder ?? DEFAULT_PLACEHOLDER;

  const hasError = field.errors?.length > 0;

  return (
    <FieldWrapper field={field} sx={field.props.sx}>
      <Select
        fullWidth
        displayEmpty
        defaultValue={defaultValue}
        slotProps={{
          input: field.select.props,
        }}
        renderValue={(selected) =>
          renderValue({
            selected,
            multiple,
            placeholder: placeHolderText,
            allOptions,
          })
        }
        inputProps={{ color: '#FEF7F1' }}
        error={hasError}
        MenuProps={{
          sx: { maxWidth: 200, maxHeight: 340 },
        }}
      >
        {placeholder && (
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem {...option.props} selected={option.defaultSelected}>
            <Tooltip
              title={option.textContent}
              enterDelay={300}
              placement="right"
            >
              <Typography variant="inherit" noWrap>
                {option.textContent}
              </Typography>
            </Tooltip>
          </MenuItem>
        ))}
      </Select>
    </FieldWrapper>
  );
}

FieldSelect.uiType = UI_TYPE.SELECT;
FieldSelect.canRender = (
  field: TFieldRenderProps,
): field is FieldSelectProps['field'] => {
  return 'select' in field;
};

import React, { useState } from 'react';

import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { Box, Container, Typography } from '@mui/material';

import { AdmissionTable } from './AdmissionTable';

export type AdmissionProps = {
  organizationId: string | undefined;
  companyId: string | undefined;
};

export default function Admission({ organizationId }: AdmissionProps) {
  const [showAdmitted, setShowAdmitted] = useState(true);

  return (
    <Box
      pt={9}
      height="100vh"
      sx={{
        backgroundColor: 'background.paper',
      }}
    >
      <Container maxWidth="lg">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          data-testid="admission-header"
          gap={1}
        >
          <PersonAddAltOutlinedIcon
            sx={{ height: '40px', width: '40px', marginRight: 1.5 }}
          />
          <Typography variant="h1">Admissão</Typography>
        </Box>

        {/* 
        This is disabled for now, but we'll probably enable it soon
        <AdmissionCount organizationId={organizationId} /> */}

        <Box py={3.2}>
          <AdmissionTable
            organizationId={organizationId}
            setShowAdmitted={setShowAdmitted}
            showAdmitted={showAdmitted}
          />
        </Box>
      </Container>
    </Box>
  );
}

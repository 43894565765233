import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { MoreVert, PersonRemoveOutlined } from '@mui/icons-material';
import {
  Box,
  Container,
  IconButton,
  MenuItem,
  Popover,
  Skeleton,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@mui/material';

import {
  PayrollSummary,
  fetchSearchAllPayrolls,
  useArchivePayroll,
} from '@octopus/api';
import { formatDateBR } from '@octopus/formatters';
import {
  DataGrid,
  DataGridToolbar,
  FilterOptions,
  FilteringProps,
  GridColDef,
  PaginationProps,
  SearchProps,
  SortingProps,
  makeDateRangeFilter,
  makeElementListFilter,
  useDataGrid,
} from '@octopus/ui/data-grid';

import CancelledIcon from '../../assets/cancelled.svg';
import { CustomSwitch } from '../../modules/components/CustomSwitch';
import TabLabel from '../../modules/components/TabLabel';
import UserAvatar from '../../modules/components/UserAvatar';
import { DataFetching } from '../../modules/dataFetching';
import {
  formatTerminationStartedBy,
  formatTerminationType,
} from '../../modules/formatters';
import { getActiveElementFilters, getActiveRangeFilters } from '../../utils';

import { ExplodedDate } from './local-components/ExplodedDate';

export type TerminationsProps = {
  organizationId: string | undefined;
  companyId: string | undefined;
};

const getTerminationColumns = ({
  currentTabName,
  onPayrollArchived,
}: {
  currentTabName: keyof typeof tabPropsByName;
  onPayrollArchived: (payrollId: string) => void;
}): GridColDef<PayrollSummary>[] => {
  const columns: GridColDef<PayrollSummary>[] = [
    {
      field: 'workerData.name',
      headerName: 'Colaborador(a)',
      valueGetter: (params) => params.row.workerData.name,
      renderCell: (params) => {
        return (
          <UserAvatar
            name={params.formattedValue}
            avatarProps={{
              ml: 0,
              mr: 2.25,
            }}
            sx={{
              '--UserAvatar-name-max-width': '12.5em',
            }}
          />
        );
      },
    },
    {
      field: 'termination.startedBy',
      headerName: 'Iniciado por',
      sortable: false,
      valueGetter: (params) =>
        formatTerminationStartedBy(params.row.termination?.startedBy),
    },
    {
      field: 'termination.noticeDate',
      headerName: 'Data de aviso',
      valueGetter: (params) => formatDateBR(params.row.termination?.noticeDate),
      renderCell: (params) => {
        return <ExplodedDate ddmmyyyy={params.formattedValue} />;
      },
    },
    {
      field: 'termination.noticeIndemnityType',
      headerName: 'Tipo',
      sortable: false,
      valueGetter: (params) =>
        formatTerminationType(params.row.termination?.noticeIndemnityType),
    },
    {
      field: 'termination.terminationDate',
      headerName: 'Data de desligamento',
      valueGetter: (params) =>
        formatDateBR(params.row.termination?.terminationDate),
      renderCell: (params) => {
        return <ExplodedDate ddmmyyyy={params.formattedValue} />;
      },
    },
    {
      field: 'paymentDate',
      headerName: 'Aprovar até',
      sortable: false,
      valueGetter: () => '08/10 às 23:59', //TODO review auto archive
    },
    currentTabName === 'open' && {
      field: 'reviewCallToAction',
      sortable: false,
      renderCell: () => (
        <Typography color="primary.main" variant="body2" fontWeight={700}>
          Revisar
        </Typography>
      ),
    },
    currentTabName !== 'archived' && {
      field: 'actions',
      headerName: '',
      sortable: false,
      renderCell: (params) => (
        <PayrollRowActionMenu
          payrollId={params.row.payrollId}
          organizationId={params.row.organizationId}
          companyId={params.row.companyId}
          onPayrollArchived={onPayrollArchived}
        />
      ),
      width: 48,
    },
  ];

  return columns.filter(Boolean) as GridColDef<PayrollSummary>[];
};

function getStatusFilter(tabName: keyof typeof tabPropsByName) {
  switch (tabName) {
    case 'open':
      return ['open'];
    case 'approved':
      return ['approved'];
    case 'closed':
      return ['closed', 'reconciled'];
    case 'archived':
      return ['archived'];
  }
}

function getFetchHook(
  refetchCount: number,
  organizationId: string | undefined,
  companyId: string | undefined,
  tabName: keyof typeof tabPropsByName,
  searchProps: SearchProps,
  filteringProps: FilteringProps,
  paginationProps: PaginationProps,
  sortingProps: SortingProps,
) {
  return () => {
    const elementFilters = getActiveElementFilters(filteringProps);
    const rangeFilters = getActiveRangeFilters(filteringProps);

    return useQuery({
      queryKey: [
        refetchCount,
        organizationId,
        companyId,
        paginationProps,
        sortingProps,
        tabName,
        searchProps,
        rangeFilters,
        elementFilters,
        tabName,
      ],
      queryFn: () =>
        fetchSearchAllPayrolls({
          pathParams: {
            organizationId: organizationId!,
            companyId: companyId!,
          },
          body: {
            query: searchProps.searchTerm,
            pagination: {
              size: paginationProps.rowsPerPage,
              page: paginationProps.page,
            },
            ...(sortingProps.field
              ? {
                  sorting: {
                    field: sortingProps.field,
                    order: sortingProps.order,
                  },
                }
              : {}),
            filtering: {
              elements: {
                ...elementFilters,
                status: getStatusFilter(tabName),
                type: ['termination'],
              },
              ...(rangeFilters && { ranges: rangeFilters }),
            },
            counting: {
              filtered: {
                byProp: {
                  status: ['open', 'approved', 'closed', 'archived'],
                },
              },
            },
          },
        }),
      enabled: !!organizationId && !!companyId,
    });
  };
}

const tabPropsByName = {
  open: {
    color: 'warning',
    label: 'Prévias',
  },
  approved: {
    color: 'primary',
    label: 'Aprovadas',
  },
  closed: {
    color: 'success',
    label: 'Fechadas',
  },
  archived: {
    color: 'error',
    label: 'Excluídas',
  },
};

function TerminationsListPage({
  organizationId,
  companyId,
}: TerminationsProps) {
  const navigate = useNavigate();

  const [currentTabName, setCurrentTabName] =
    useState<keyof typeof tabPropsByName>('open');

  const filters = useFilters();
  const { filteringProps, searchProps, sortingProps, paginationProps } =
    useDataGrid({
      filters,
    });

  const [isArchivedItemsVisible, setIsArchivedItemsVisible] = useState(false);
  const [rerenderCount, setRerenderCount] = useState(0);
  const archivedPayrollsRef = useRef(new Set());

  const fetcher = getFetchHook(
    rerenderCount,
    organizationId,
    companyId,
    currentTabName,
    searchProps,
    filteringProps,
    paginationProps,
    sortingProps,
  );

  const onPayrollArchived = (payrollId: string) => {
    setRerenderCount((count) => count + 1);
    archivedPayrollsRef.current.add(payrollId);
  };

  const onChangeArchivedItemsToggle = () => {
    setIsArchivedItemsVisible((isVisible) => !isVisible);
    if (!isArchivedItemsVisible) {
      setCurrentTabName('archived');
    } else {
      setCurrentTabName('open');
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        height: '100%',
      }}
    >
      <Toolbar />

      <Container>
        <Box py={5}>
          <Box
            display="flex"
            flexDirection="row"
            pb={2}
            mb={5}
            data-testid="termination-header"
          >
            <PersonRemoveOutlined
              sx={{ height: '40px', width: 'auto', mr: 2 }}
            />
            <Typography variant="h1">Rescisão</Typography>
          </Box>

          <DataGridToolbar
            filters={filters}
            searchProps={searchProps}
            filteringProps={filteringProps}
            searchPlaceholder="Procurar..."
          />
          <Box
            my={2}
            sx={(theme) => ({
              boxShadow: `0 -1px 0 ${theme.palette.strokes.light} inset`,
            })}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Tabs
              value={currentTabName}
              onChange={(_, newTab) => setCurrentTabName(newTab)}
              textColor="inherit"
              TabIndicatorProps={{
                sx: {
                  backgroundColor: `${tabPropsByName[currentTabName].color}.main`,
                },
              }}
            >
              {Object.entries(tabPropsByName)
                .filter(
                  ([tabName]) =>
                    tabName !== 'archived' || isArchivedItemsVisible,
                )
                .map(([tabName, tabProps]) => (
                  <Tab
                    key={tabName}
                    value={tabName}
                    label={
                      <TabLabel
                        isSelected={tabName === currentTabName}
                        {...tabProps}
                      />
                    }
                  />
                ))}
            </Tabs>
            <PageActionMenu
              isArchivedItemsVisible={isArchivedItemsVisible}
              onChangeArchivedItemsToggle={onChangeArchivedItemsToggle}
            />
          </Box>

          <DataFetching
            useHook={fetcher}
            Loading={() => (
              <Skeleton variant="rounded" height={400} width="100%" />
            )}
            Data={({ data: list }) => {
              if (!list) {
                return null;
              }
              const rows = Object.values(list.data || []);
              const visibleRows = rows.filter(
                (row) => !archivedPayrollsRef.current.has(row.payrollId),
              );

              const totalRowCount =
                list.total - (rows.length - visibleRows.length);

              return (
                <DataGrid
                  sortingProps={sortingProps}
                  paginationProps={paginationProps}
                  totalRowCount={totalRowCount}
                  rows={visibleRows}
                  getRowId={(row) => row.payrollId}
                  getRowSx={() => getRowSxForStatus(currentTabName)}
                  getHeadSx={() => getHeadSxForStatus(currentTabName)}
                  onRowClick={(params) => {
                    const payrollSummary = params.row as PayrollSummary;
                    navigate(`/terminations/${payrollSummary.payrollId}`);
                  }}
                  columns={getTerminationColumns({
                    currentTabName,
                    onPayrollArchived,
                  })}
                />
              );
            }}
          />
        </Box>
      </Container>
    </Box>
  );
}

function getRowSxForStatus(status: keyof typeof tabPropsByName) {
  switch (status) {
    case 'approved':
    case 'open':
      return {
        '--PayrollRowActionMenu-opacity': '0',
        'td:nth-last-of-type(1), th:nth-last-of-type(1)': {
          pl: 0,
          pr: 1,
          width: '1em',
        },
        ':hover': {
          '--PayrollRowActionMenu-opacity': '1',
        },
      };
    default:
      return {};
  }
}

function getHeadSxForStatus(status: keyof typeof tabPropsByName) {
  switch (status) {
    case 'approved':
    case 'open':
      return {
        'th:nth-last-of-type(1)': {
          pl: 0,
          pr: 1,
        },
      };
    default:
      return {};
  }
}

function useFilters(): FilterOptions {
  return [
    makeElementListFilter({
      label: 'Iniciado por',
      propertyToFilter: 'termination.startedBy',
      elements: ['employee', 'employer', 'both', 'other'],
      labels: {
        employee: 'Colaborador',
        employer: 'Empresa',
        both: 'Ambos',
        other: 'Outro',
      },
      sortElements: false,
      disableSearch: true,
    }),
    makeDateRangeFilter({
      label: 'Data de aviso',
      propertyToFilter: 'termination.noticeDate',
    }),
    makeElementListFilter({
      label: 'Tipo',
      propertyToFilter: 'termination.noticeIndemnityType',
      elements: ['indemnified', 'deducted', 'exempt', 'worked', 'other'],
      labels: {
        indemnified: 'Indenizado',
        deducted: 'Deduzido',
        exempt: 'Dispensado',
        worked: 'Trabalhado',
        other: 'Outro',
      },
      sortElements: false,
      disableSearch: true,
    }),
    makeDateRangeFilter({
      label: 'Data de desligamento',
      propertyToFilter: 'termination.terminationDate',
    }),
  ];
}

function PageActionMenu({
  isArchivedItemsVisible,
  onChangeArchivedItemsToggle,
}: {
  isArchivedItemsVisible: boolean;
  onChangeArchivedItemsToggle: () => void;
}) {
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);

  return (
    <Box display="flex" justifyContent="flex-end">
      <IconButton
        size="small"
        onClick={(event) => {
          setOpen(true);
          event.stopPropagation();
        }}
        ref={menuRef}
        sx={{
          borderRadius: '8px',
          padding: '4px',
        }}
      >
        <MoreVert
          fontSize="inherit"
          sx={{
            width: '24px',
            height: '24px',
          }}
        />
      </IconButton>
      <Popover
        open={open}
        anchorEl={menuRef.current}
        onClick={(event) => event.stopPropagation()}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        elevation={1}
        sx={{
          fontSize: '14px',
          transform: 'translateY(-5px) translateX(5px)',
          '.MuiPaper-root': {
            padding: '4px',
            minWidth: '9.9215em',
          },
        }}
      >
        <MenuItem
          sx={{
            display: 'flex',
            alignItems: 'center',
            py: 1,
            pl: 1.5,
            pr: 1.5,
            borderRadius: 0.5,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              display: 'flex',
              alignItems: 'center',
              mr: 4,
            }}
            component={'label'}
            htmlFor="toggleArchived"
          >
            <Box
              component={'img'}
              src={CancelledIcon}
              sx={{
                mr: 1.5,
                mt: '-.0125em', //artificially sets icon baseline
                alignSelf: 'baseline',
                height: '1.25em',
              }}
            />
            Mostrar excluídas
          </Typography>
          <CustomSwitch
            inputProps={{
              id: 'toggleArchived',
              'aria-label': 'Mostrar excluídos',
            }}
            checked={isArchivedItemsVisible}
            onChange={onChangeArchivedItemsToggle}
          />
        </MenuItem>
      </Popover>
    </Box>
  );
}

function PayrollRowActionMenu({
  payrollId,
  organizationId,
  companyId,
  onPayrollArchived,
}: {
  payrollId: string;
  organizationId: string;
  companyId: string;
  onPayrollArchived: (payrollId: string) => unknown;
}) {
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);

  const { mutate, isLoading, isSuccess } = useArchivePayroll();

  const onClickArchiveButton = () => {
    mutate({
      pathParams: {
        organizationId,
        companyId,
        payrollId,
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      onPayrollArchived(payrollId);
    }
  }, [payrollId, isSuccess, onPayrollArchived]);

  return (
    <Box display="flex" justifyContent="flex-end">
      <IconButton
        size="small"
        onClick={(event) => {
          setOpen(true);
          event.stopPropagation();
        }}
        ref={menuRef}
        sx={{
          borderRadius: '8px',
          padding: '4px',
          opacity: open ? 1 : 'var(--PayrollRowActionMenu-opacity)',
        }}
      >
        <MoreVert
          fontSize="inherit"
          sx={{
            width: '24px',
            height: '24px',
          }}
        />
      </IconButton>
      <Popover
        open={open}
        anchorEl={menuRef.current}
        onClick={(event) => event.stopPropagation()}
        onClose={() => !isLoading && setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        elevation={1}
        sx={{
          fontSize: '14px',
          transform: 'translateY(-5px) translateX(5px)',
          '.MuiPaper-root': {
            padding: '4px',
            minWidth: '9.9215em',
          },
        }}
      >
        <MenuItem
          color="secondary"
          onClick={onClickArchiveButton}
          disabled={isLoading}
          sx={{
            p: 0,
            borderRadius: '4px',
          }}
        >
          <Typography
            variant="body2"
            // fontWeight={500}
            sx={{
              my: '8px',
              ml: '12px',
              mr: '32px',
            }}
          >
            Excluir
          </Typography>
        </MenuItem>
      </Popover>
    </Box>
  );
}

export default TerminationsListPage;

import dayjs from 'dayjs';

import { BeachAccessOutlined } from '@mui/icons-material';
import { Box, Card, Typography, useTheme } from '@mui/material';

import { VacationsAccrualPeriodEntry } from '@octopus/api';
import { Button } from '@octopus/ui/design-system';

export default function InitialStepView({
  accrualPeriod,
  requestVacationMSV,
}: {
  accrualPeriod: VacationsAccrualPeriodEntry;
  requestVacationMSV: any;
}) {
  const theme = useTheme();
  return (
    <Box
      test-id={'step-box'}
      sx={{
        px: 2,
        pt: 8,
        pb: 2,
        flexDirection: 'column',
        display: 'inline-flex',
        justifyContent: 'stretch',
        bgcolor: theme.palette.background.secondary,
        gap: 4,
        height: '100%',
      }}
    >
      <Box>
        <Box>
          <Typography
            variant="body1"
            fontSize={16}
            fontWeight={500}
            display={'inline'}
          >
            {
              'Vamos iniciar o agendamento das suas férias. Lembre-se que você deve agendá-las com um '
            }
          </Typography>
          <Typography
            fontSize={16}
            fontWeight={700}
            variant="body1"
            display={'inline'}
          >
            {'mínimo de 45 dias de antecedência.'}
          </Typography>
        </Box>
        {renderCard(accrualPeriod)}
      </Box>
      <Box sx={{ flexGrow: 1, alignContent: 'flex-end' }}>
        <Button
          variantSemantic={'primary'}
          onClick={() => requestVacationMSV.goForward()}
          sx={{ width: '100%' }}
        >
          Vamos lá
        </Button>
      </Box>
    </Box>
  );
}

function renderCard(accrualPeriod: VacationsAccrualPeriodEntry) {
  return (
    <Card
      variant="elevation"
      sx={{
        padding: '20px',
        border: '12px',
        mt: '10px',
      }}
    >
      <Box
        component={BeachAccessOutlined}
        sx={{ height: '32px', width: 'auto', mr: 0, color: '#BABABF' }}
      />
      <Typography fontWeight={700} fontSize={20} lineHeight={'28px'}>
        {accrualPeriod.daysAvailable} dias disponíveis
      </Typography>
      <Typography
        fontWeight={500}
        fontSize={14}
        variant={'subtitle1'}
        lineHeight={'20px'}
      >
        Agendar até{' '}
        {dayjs(accrualPeriod.concessionPeriod.limitDateToStartVacations).format(
          'DD/MM/YYYY',
        )}
      </Typography>
    </Card>
  );
}

import { useEffect, useState } from 'react';

import { Submission } from '@conform-to/react';

import { Box } from '@mui/material';

import { Button } from '@octopus/ui/design-system';

import { Form } from '../../../../modules/form/NewForm';
import { useFormFromDefinition } from '../../../../modules/form/useFormFromDefinition';
import { AdmissionFormState, FormStepDefinition } from '../form/types';

import { Dependent, getDefinition } from './formStepInputs';

type Props = {
  disabled: boolean;
  isLoading: boolean;
  formState: AdmissionFormState;
  stepDefinition: FormStepDefinition;
  currentDependente: Dependent;
  submitLabel: string;
  onSubmitCallback: (
    event: React.FormEvent<HTMLFormElement>,
    formData: Submission<any, string[], any>,
  ) => void;
};

export const NewAdmissionDependentForm = ({
  disabled,
  isLoading,
  formState,
  stepDefinition,
  currentDependente,
  submitLabel,
  onSubmitCallback,
}: Props) => {
  const onDependenteSubmit = (
    event: React.FormEvent<HTMLFormElement>,
    formData: Submission<any, string[], any>,
  ) => {
    onSubmitCallback(event, formData);
    setDependente(null);
  };

  const { options } = stepDefinition;
  const [dependente, setDependente] = useState<Dependent>(currentDependente);
  const definition = getDefinition(currentDependente);

  const form = useFormFromDefinition(definition, {
    id: options?.id,
    onSubmit: onDependenteSubmit,
    persistLocal: false,
  });

  useEffect(() => {
    if (dependente?.nome !== currentDependente?.nome) {
      setDependente(currentDependente);
      form.loadState(currentDependente);
    }
  }, [form, dependente, currentDependente]);

  if (!formState || !stepDefinition) {
    return null;
  }
  return (
    <Form payloadForm={form.payloadForm} metaForm={form.metaForm}>
      <Form.Layout>
        {Object.entries(form.fields).map(([key, field]) => (
          <Form.Field for={field} key={key} />
        ))}
      </Form.Layout>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          mt: 2,
        }}
      >
        <Button
          isLoading={isLoading}
          disabled={isLoading || disabled}
          sx={{
            marginLeft: 'auto',
            display: 'inline-flex',
            alignItems: 'center',
            width: '100%',
          }}
          variantSemantic="secondary"
          variantLayout="small"
          type="submit"
          form={form.id}
        >
          {submitLabel}
        </Button>
      </Box>
    </Form>
  );
};

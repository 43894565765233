import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, MenuItem, Popover, Skeleton, Typography } from '@mui/material';

import {
  PayrollCalculationTotals,
  PayrollPeriodSummaryStatus,
  PayrollSummary,
  PayrollType,
  SearchFilteringRange,
  fetchSearchAllPayrolls,
  useGetLegalEntities,
} from '@octopus/api';
import { capitalize, formatMoney } from '@octopus/formatters';
import { summaryElements } from '@octopus/payroll-engine/public-types/core';
import { payrollStatuses } from '@octopus/payroll-types';
import {
  DataGrid,
  DataGridToolbar,
  FilterOptions,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
  makeElementListFilter,
  makeMoneyRangeFilter,
  useDataGrid,
} from '@octopus/ui/data-grid';

import { ExpandableTypography } from '../../../../modules/components/ExpandableTypography';
import { DisplayDiff } from '../../../../modules/components/payrolls/DisplayDiff';
import { useArchivePayrollModal } from '../../../../modules/components/payrolls/useArchivePayroll';
import { PayslipActionsMenu } from '../../../../modules/components/payslips/ActionsMenu';
import { DataFetching } from '../../../../modules/dataFetching';
import { FLAGS } from '../../../../modules/flags';
import { PeriodFormat } from '../../../../modules/types';
import { getActiveElementFilters } from '../../../../utils';

type LegalEntityEntry = { id: string; name: string };

const ARCHIVABLE_PAYROLL_TYPES = ['advance', 'complementary'];

export function PayrollsTable({
  organizationId,
  companyId,
  period,
  type,
  compareTo,
  onWorkerClick,
  rubricaElements,
  status,
}: {
  organizationId: string;
  companyId: string;
  period: PeriodFormat;
  type: PayrollType;
  compareTo: PeriodFormat | undefined;
  onWorkerClick: (
    payrollId: string,
    contractId: string,
    rows: Array<PayrollSummary>,
  ) => void;
  rubricaElements: { [key: string]: string } | null;
  status: PayrollPeriodSummaryStatus;
}) {
  const [legalEntities, setLegalEntities] = useState<LegalEntityEntry[]>([]);

  const filters = useFilters({ rubricaElements, legalEntities });
  const { sortingProps, filteringProps, searchProps, paginationProps } =
    useDataGrid({
      filters,
    });

  const legalEntitiesQuery = useGetLegalEntities(
    {
      pathParams: {
        organizationId,
        companyId,
      },
    },
    {
      enabled: !!organizationId && !!companyId,
    },
  );

  useEffect(() => {
    if (!legalEntitiesQuery.isError && legalEntitiesQuery.data) {
      setLegalEntities(
        legalEntitiesQuery.data.data.map((entity) => ({
          id: entity.legalEntityId,
          name: entity.br.nomeFantasia,
        })),
      );
    }
  }, [legalEntitiesQuery.isError, legalEntitiesQuery.data]);

  const useFetch = () => {
    const rangeFilters = new Set(
      Object.values(summaryElements).map(
        (element) => `calculationTotals.${element}`,
      ) as string[],
    );
    const rangeFiltersValue = Object.fromEntries(
      Object.entries(filteringProps.filtersState.rangeFilters)
        .filter(([k]) => filteringProps.activeFilters.has(k))
        .map(([k, v]) => [`calculationTotals.${k}`, v] as const)
        .filter(([k]) => rangeFilters.has(k)),
    ) as Record<string, SearchFilteringRange>;

    const [query] = (() => {
      const { searchTerm } = searchProps;

      if (!searchTerm.startsWith('rubrica:')) {
        return [searchTerm, ''];
      }

      const [, element] = searchTerm.split(':');
      return ['', element];
    })();

    const elementFilters = {
      period: [period],
      type: [type],
      status: [{ not: 'archived' }],
    };

    const arrayFilters = getActiveElementFilters(filteringProps);
    const payrollBody = {
      query: query.length > 0 ? query : undefined,
      pagination: {
        size: paginationProps.rowsPerPage,
        page: paginationProps.page,
      },
      ...(sortingProps.field
        ? {
            sorting: {
              field: prepareSortField(sortingProps.field),
              order: sortingProps.order,
            },
          }
        : {}),
      filtering: {
        ranges: rangeFiltersValue,
        elements: elementFilters,
        arrays: arrayFilters,
      },
    };
    return useQuery({
      queryKey: [
        'searchAllPayrolls',
        organizationId,
        companyId,
        query,
        paginationProps,
        sortingProps,
        rangeFiltersValue,
        elementFilters,
        arrayFilters,
        compareTo,
      ],
      queryFn: async () => {
        const payrolls = await fetchSearchAllPayrolls({
          pathParams: {
            organizationId,
            companyId,
          },
          body: payrollBody,
        });

        const comparisons: Record<string, PayrollCalculationTotals> = {};
        if (compareTo) {
          const fetchComparisonPayrollsResult = await fetchSearchAllPayrolls({
            pathParams: {
              organizationId,
              companyId,
            },
            body: {
              ...payrollBody,
              filtering: {
                elements: {
                  ...elementFilters,
                  period: [compareTo],
                  contractId: payrolls.data.map(
                    (payroll) => payroll.contractId,
                  ),
                },
                arrays: arrayFilters,
              },
            },
          });
          for (const payroll of fetchComparisonPayrollsResult.data) {
            if (payroll.calculationTotals) {
              comparisons[payroll.contractId] = payroll.calculationTotals;
            }
          }
        }

        return {
          ...payrolls,
          data: payrolls.data.map((payroll) => {
            return {
              ...payroll,
              ...(compareTo && {
                comparison: comparisons[payroll.contractId],
              }),
            };
          }),
        };
      },
    });
  };

  const navigate = useNavigate();
  const {
    archivePayrollProps,
    isLoadingArchivePayroll,
    archivePayroll,
    ArchivePayrollModal,
  } = useArchivePayrollModal(organizationId, companyId);

  return (
    <>
      <DataGridToolbar
        filters={filters}
        searchProps={searchProps}
        filteringProps={filteringProps}
      >
        {payrollStatuses.approved === status && (
          <Box display="flex" width="100%" flexDirection="column">
            <PayslipActionsMenu
              payroll={{
                organizationId: organizationId,
                companyId: companyId,
                periodId: period,
                payrollType: type,
              }}
            />
          </Box>
        )}
      </DataGridToolbar>
      <DataFetching
        useHook={useFetch}
        Loading={() => (
          <Box display="flex" flexDirection="column" gap="8px" pt={1}>
            <Skeleton variant="rounded" height={300} width="100%" />
          </Box>
        )}
        Data={({ data: response }) => {
          return (
            <Box>
              <Box mt={1}>
                {response ? (
                  <DataGrid<PayrollSummary>
                    sortingProps={sortingProps}
                    paginationProps={paginationProps}
                    totalRowCount={response.total}
                    getRowId={(row) => row.contractId}
                    rows={response.data}
                    columns={columns}
                    onRowClick={(params) =>
                      onRowClick({
                        onWorkerClick,
                        row: params.row,
                        rows: response.data,
                      })
                    }
                    getRowSx={() => ({
                      'td:nth-last-child(1)': {
                        opacity: 0,
                      },
                      '&:hover': {
                        'td:nth-last-child(1)': {
                          opacity: 1,
                        },
                      },
                    })}
                    rowCallToAction={(row) => {
                      if (
                        row.status === 'open' &&
                        ARCHIVABLE_PAYROLL_TYPES.includes(type)
                      ) {
                        return (
                          <WorkerActionsMenu>
                            <MenuItem
                              onClick={() =>
                                onRowClick({
                                  onWorkerClick,
                                  row: row,
                                  rows: response.data,
                                })
                              }
                            >
                              <Typography variant="body2" m={1}>
                                Ver detalhamento
                              </Typography>
                            </MenuItem>
                            <MenuItem
                              disabled={isLoadingArchivePayroll}
                              onClick={() =>
                                archivePayroll(
                                  row.payrollId,
                                  row.workerData.name,
                                )
                              }
                            >
                              <Typography variant="body2" m={1}>
                                Remover colaborador(a)
                              </Typography>
                            </MenuItem>
                          </WorkerActionsMenu>
                        );
                      }

                      if (
                        ![
                          'approving',
                          'approved',
                          'reconciling',
                          'reconciled',
                          'reconcilingError',
                          'closed',
                        ].includes(row.status)
                      ) {
                        return null;
                      }

                      return (
                        <WorkerActionsMenu>
                          <MenuItem
                            onClick={() =>
                              navigate(`/payrolls/${row.payrollId}/payslips`)
                            }
                            data-testid="access-worker-payslips-button"
                          >
                            <Typography variant="body2" m={1}>
                              Ver Holerite
                            </Typography>
                          </MenuItem>
                          <MenuItem>
                            <Typography variant="body2" m={1}>
                              Ver Pagamento
                            </Typography>
                          </MenuItem>
                        </WorkerActionsMenu>
                      );
                    }}
                  />
                ) : null}
              </Box>
            </Box>
          );
        }}
      />
      <ArchivePayrollModal {...archivePayrollProps} />
    </>
  );
}

function onRowClick({
  onWorkerClick,
  row,
  rows,
}: {
  row: PayrollSummary;
  onWorkerClick: (
    payrollId: string,
    contractId: string,
    rows: Array<PayrollSummary>,
  ) => void;
  rows: Array<PayrollSummary>;
}) {
  if (!row) {
    return;
  }

  onWorkerClick(row.payrollId, row.contractId, rows);
}

function useFilters({
  legalEntities,
  rubricaElements,
}: {
  legalEntities: LegalEntityEntry[];
  rubricaElements: { [key: string]: string } | null;
}): FilterOptions {
  return [
    rubricaElements &&
      makeElementListFilter({
        label: 'Rubricas',
        propertyToFilter: 'calculationTotals.elementIds',
        elements: Object.keys(rubricaElements),
        labels: rubricaElements,
      }),
    makeMoneyRangeFilter({
      label: 'Proventos',
      propertyToFilter: summaryElements.workerEarningsTotal,
      getRangeMin: () => 0,
      getRangeMax: () => 150_000,
    }),
    makeMoneyRangeFilter({
      label: 'Deduções',
      propertyToFilter: summaryElements.workerDeductionsTotal,
      getRangeMin: () => 0,
      getRangeMax: () => 150_000,
    }),
    makeMoneyRangeFilter({
      label: 'Líquido',
      propertyToFilter: summaryElements.netPay,
      getRangeMin: () => 0,
      getRangeMax: () => 150_000,
    }),
    FLAGS.ENABLE_PAYROLL_TOTAL_COST &&
      FLAGS.ENABLE_PAYROLL_TOTAL_COST_PROVISIONS &&
      makeMoneyRangeFilter({
        label: 'Custo Total',
        propertyToFilter: summaryElements.totalCost,
        getRangeMin: () => 0,
        getRangeMax: () => 150_000,
      }),
    ...(legalEntities.length > 1
      ? [
          makeElementListFilter({
            label: 'Empregador',
            propertyToFilter: 'legalEntityId.keyword',
            elements: legalEntities.map(({ id }) => id),
            labels: legalEntities.reduce(
              (acc, { id, name }) => {
                acc[id] = name;
                return acc;
              },
              {} as Record<string, string>,
            ),
          }),
        ]
      : []),
  ].filter(Boolean);
}

function WorkerActionsMenu({
  children,
}: {
  children:
    | React.ReactElement<typeof MenuItem>
    | React.ReactElement<typeof MenuItem>[];
}) {
  const [open, setOpen] = useState(false);
  const menuRef = useRef();
  return (
    <Box display="flex" justifyContent="flex-end">
      <Box
        onClick={(event) => {
          setOpen(true);
          event.stopPropagation();
        }}
        ref={menuRef}
        p={0.5}
        sx={(theme) => ({
          '&:hover': {
            backgroundColor: theme.palette.strokes.light,
          },
          '&:active': {
            backgroundColor: theme.palette.strokes.heavy,
          },
          borderRadius: '8px',
          width: '24px',
          height: '24px',
        })}
        data-testid="worker-actions-menu"
      >
        <MoreVertIcon
          sx={{
            width: '24px',
            height: '24px',
          }}
        />
      </Box>
      <Popover
        open={open}
        anchorEl={menuRef.current}
        onClick={(event) => event.stopPropagation()}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
        sx={{
          m: 1,
        }}
        data-testid="filters-popover"
      >
        {children}
      </Popover>
    </Box>
  );
}

const columns: GridColDef<PayrollSummary>[] = [
  {
    field: 'name',
    headerName: 'Colaboradores',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.workerData.name;
    },
    renderCell: (params: GridRenderCellParams) => {
      const name = params.row.workerData.name;
      const salary = params.row.workerData.salary;

      return (
        <Box>
          <ExpandableTypography>{capitalize(name)}</ExpandableTypography>
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ lineHeight: 2.5 }}
          >
            {formatMoney(salary)}/mês
          </Typography>
        </Box>
      );
    },
  },
  ...(
    [
      [summaryElements.workerEarningsTotal, 'Proventos'],
      [summaryElements.workerDeductionsTotal, 'Deduções'],
      [summaryElements.netPay, 'Valor líquido'],
      FLAGS.ENABLE_PAYROLL_TOTAL_COST &&
        FLAGS.ENABLE_PAYROLL_TOTAL_COST_PROVISIONS && [
          summaryElements.totalCost,
          'Prévia de custo',
        ],
    ] as const
  )
    .filter(Boolean)
    .map(
      ([field, headerName]) =>
        ({
          field,
          headerName,
          flex: 0.5,
          valueGetter: (params) =>
            params.row?.calculationTotals?.[field] ?? '0',
          valueFormatter: (params) => formatMoney(params.value),
          renderCell: (params) => (
            <CellWithComparison
              params={params}
              comparison={
                'comparison' in params.row && params.row.comparison?.[field]
              }
            />
          ),
        }) as GridColDef,
    ),
  FLAGS.ENABLE_PAYROLL_TOTAL_COST &&
    !FLAGS.ENABLE_PAYROLL_TOTAL_COST_PROVISIONS &&
    null &&
    ({
      field: summaryElements.totalCost,
      headerName: 'Prévia de custo',
      flex: 0.5,
      valueGetter: (params) => params.row?.calculationTotals.totalCost ?? '0',
      valueFormatter: (params) =>
        params.value !== '...loading...'
          ? formatMoney(params.value)
          : params.value,
      renderCell: (params) => {
        const isLoading = params.value === '...loading...';

        return isLoading ? (
          <Skeleton variant="rounded" width="5em" />
        ) : (
          <CellWithComparison
            params={params}
            comparison={
              'comparison' in params.row && params.row.comparison?.totalCost
            }
          />
        );
      },
    } as GridColDef),
].filter(Boolean);

function prepareSortField(orderBy?: string) {
  if (orderBy === 'name') {
    return 'workerData.name';
  }
  return `calculationTotals.${orderBy}`;
}

function CellWithComparison({
  params,
  comparison,
}: {
  params: GridRenderCellParams;
  comparison: string | undefined | false;
}) {
  return (
    <Box>
      <Typography variant="body2">{params.formattedValue}</Typography>
      {comparison !== false && (
        <DisplayDiff
          variant="caption"
          current={params.value}
          compare={comparison}
          withSign={false}
          withCurrency={false}
        />
      )}
    </Box>
  );
}

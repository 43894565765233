const recordTypes = {
  employeeInformation: 'employeeInformation',
  informacoesDeFolha: 'informacoesDeFolha',
  eventosHistoricos: 'eventosHistoricos',
  tabelasDeRubricas: 'tabelasDeRubricas',
  desoneracaoDeFolha: 'desoneracaoDeFolha',

  provisoes: 'provisoes',
} as const;

const eventTypes = {
  periodoAquisitivoFerias: 'periodoAquisitivoFerias',
  periodosAquisitivosFerias: 'periodosAquisitivosFerias',
  periodoAquisitivoAtual: 'periodoAquisitivoAtual',
  horasTrabalhadasEvent: 'horasTrabalhadasEvent',
  faltasInjustificadas: 'faltasInjustificadas',
  ferias: 'ferias',
  feriasColetivas: 'feriasColetivas',
  decimoTerceiro: 'decimoTerceiro',
  rescisao: 'rescisao',
  adiantamentoSalarial: 'adiantamentoSalarial',
  faltasJustificadas: 'faltasJustificadas',
  afastamento: 'afastamento',
  afastamentoPrevidenciario: 'afastamentoPrevidenciario',
  licencaMaternidade: 'licencaMaternidade',
  abonoAnual: 'abonoAnual',
  pensaoAlimenticia: 'pensaoAlimenticia',
  adicionalDeInsalubridade: 'adicionalDeInsalubridade',
} as const;

const tiposDeFolha = {
  regularMensal: 'regularMensal',
  ferias: 'ferias',
  decimoTerceiro: 'decimoTerceiro',
  rescisao: 'rescisao',
  adiantamento: 'adiantamento',
  complementar: 'complementar',

  provisoes: 'provisoes',
} as const;

type TiposDeFolha = keyof typeof tiposDeFolha;

const horasTrabalhadasTypes = {
  normal: 'normal',
  noturna: 'noturna',
  faltas: 'faltas',
  faltasBancoDeHoras: 'faltasBancoDeHoras',
  faltasJustificadas: 'faltasJustificadas',

  extra: 'extra',
  extraNoturnas: 'extraNoturnas',
  extraBancoDeHoras: 'extraBancoDeHoras',

  interjornada: 'interjornada',
  intrajornada: 'intrajornada',

  sobreaviso: 'sobreaviso',
} as const;

const tiposDeFuncionario = {
  clt: 'CLT',
  jovemAprendiz: 'JovemAprendiz',
  diretorSemFgts: 'DiretorSemFgts',
  diretorComFgts: 'DiretorComFgts',
  estagio: 'Estagio',
} as const;

type TipoDeFuncionario =
  (typeof tiposDeFuncionario)[keyof typeof tiposDeFuncionario];

const codCategParaTipoDeFuncionario = {
  101: tiposDeFuncionario.clt,
  103: tiposDeFuncionario.jovemAprendiz,
  721: tiposDeFuncionario.diretorComFgts,
  722: tiposDeFuncionario.diretorSemFgts,
  723: tiposDeFuncionario.diretorSemFgts,
  901: tiposDeFuncionario.estagio,
};

export type { TiposDeFolha, TipoDeFuncionario };
export {
  recordTypes,
  eventTypes,
  tiposDeFolha,
  horasTrabalhadasTypes,
  tiposDeFuncionario,
  codCategParaTipoDeFuncionario,
};

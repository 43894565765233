import { times } from 'lodash';

import { Box, Skeleton } from '@mui/material';

type LoadingProps = {
  skeletons?: number;
};

export function Loading({ skeletons = 1 }: LoadingProps) {
  return (
    <Box>
      {times(skeletons, () => (
        <Skeleton
          variant="rounded"
          width="100%"
          sx={{ height: '60px', mt: 1 }}
        />
      ))}
    </Box>
  );
}

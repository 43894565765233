import React from 'react';

import {
  Box,
  Button,
  Container,
  Radio,
  Typography,
  useTheme,
} from '@mui/material';

import { requestVacationObject } from '../utils/types';

export default function AdvanceThirteenthStepView({
  requestVacationMSV,
  request,
  setRequest,
}: {
  requestVacationMSV: any;
  request: requestVacationObject;
  setRequest: React.Dispatch<React.SetStateAction<requestVacationObject>>;
}) {
  const theme = useTheme();
  const choiceSx = {
    justifyContent: 'space-between',
    padding: '20px',
    paddingLeft: '24px',
    paddingRight: '24px',
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.strokes.light,
  };
  const selectedSx = {
    ...choiceSx,
    backgroundColor: theme.palette.background.info,
    borderColor: theme.palette.strokes.light,
  };
  return (
    <Container
      test-id={'container'}
      sx={{
        paddingTop: 8,
        paddingBottom: 8,
        flexDirection: 'column',
        display: 'inline-flex',
        justifyContent: 'center',
        alignContent: 'center',
        bgcolor: theme.palette.background.secondary,
        gap: '8px',
      }}
    >
      <Box>
        <Box>
          <Typography variant="body1" fontSize={14} fontWeight={500}>
            {
              'Você quer receber o adiantamento da parcela do 13º referente às suas férias?'
            }
          </Typography>
        </Box>
      </Box>

      <Button
        id="advanceThirteenth-yes"
        variant="outlined"
        sx={request.advanceThirteenth === true ? selectedSx : choiceSx}
        onClick={() => {
          setRequest({ ...request, advanceThirteenth: true });
          setTimeout(() => requestVacationMSV.goForward(), 600);
        }}
      >
        <Typography fontWeight={700} fontSize={16}>
          Sim
        </Typography>
        <Radio checked={request.advanceThirteenth === true} />
      </Button>
      <Button
        id="advanceThirteenth-no"
        variant={'outlined'}
        onClick={() => {
          setRequest({ ...request, advanceThirteenth: false });
          setTimeout(() => requestVacationMSV.goForward(), 600);
        }}
        sx={request.advanceThirteenth === false ? selectedSx : choiceSx}
      >
        <Typography fontSize={16} fontWeight={700}>
          Não
        </Typography>
        <Radio checked={request.advanceThirteenth === false} />
      </Button>
    </Container>
  );
}

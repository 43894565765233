import { useEffect, useMemo, useRef } from 'react';

import { useInputControl } from '@conform-to/react';

import { Autocomplete, SxProps, TextField } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { Theme } from '@mui/material/styles';

import {
  TFieldRenderProps,
  TFieldSelectRenderProps,
} from '../parseField/types';

import { FieldWrapper } from './commons/FieldWrapper';

type FieldSelectProps = {
  field: TFieldRenderProps & TFieldSelectRenderProps;
  sx?: SxProps<Theme>;
  formId: string;
};

type TOption = {
  textContent: string;
  defaultSelected: boolean;
  props: {
    key: string;
  };
};

export function FieldSelectWithAutoComplete(props: FieldSelectProps) {
  const setDefaultOnce = useRef(false);
  const startedTyping = useRef(false);

  const { field } = props;
  const options = useMemo(
    () =>
      field.select.options.map((opt) => ({
        textContent: opt.textContent,
        defaultSelected: opt.defaultSelected,
        props: {
          key: opt.props.key,
        },
      })),
    [field.select.options],
  );

  const optionDict: Record<string, string> = useMemo(
    () =>
      options.reduce(
        (acc, opt) => ({
          ...acc,
          [opt.props.key]: opt.textContent,
        }),
        {},
      ),
    [options],
  );

  const defaultOption = options.find((opt: TOption) => opt.defaultSelected);
  const option = useInputControl({
    key: field.select.props.key,
    name: field.select.props.name,
    formId: props.formId,
  });

  const defaultKey = defaultOption?.props.key;

  useEffect(
    () => {
      if (setDefaultOnce.current) return;
      if (!option.value && defaultKey) {
        setDefaultOnce.current = true;
        option.change(defaultKey);
      }
    },
    // we don't want every "option" change to trigger this effect, only the "option.value" changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultKey, option.value, setDefaultOnce],
  );

  const filterOptions = createFilterOptions({
    limit: 5,
    ignoreCase: true,
  });

  const hasError = field.errors?.length > 0;
  const { defaultValue: _, ...selectProps } = field.select.props;
  return (
    <FieldWrapper field={field} sx={field.props.sx}>
      <Autocomplete
        forcePopupIcon
        disablePortal
        fullWidth
        freeSolo
        clearOnBlur={false}
        noOptionsText="Nenhuma opção encontrada."
        filterOptions={filterOptions}
        value={option.value}
        onFocus={option.focus}
        onBlur={option.blur}
        onChange={(_event, newValue) => {
          const newKey = (newValue as TOption)?.props?.key;
          if (newKey) {
            option.change(newKey);
          } else {
            option.change(null);
          }
        }}
        onKeyDown={() => {
          if (!startedTyping.current) {
            startedTyping.current = true;
          }
        }}
        options={options}
        renderInput={(params) => (
          <TextField
            error={hasError}
            fullWidth
            {...params}
            {...field.label.props}
            {...selectProps}
            InputProps={{
              ...params.InputProps,
              ...selectProps,
              inputProps: {
                ...params.inputProps,
                autoComplete: 'off',
                defaultValue: null,
                value:
                  (params.inputProps.value === '' &&
                    option.value === defaultKey &&
                    !startedTyping.current) ||
                  params.inputProps.value === optionDict[option.value]
                    ? optionDict[option.value]
                    : params.inputProps.value,
              },
            }}
          />
        )}
        isOptionEqualToValue={(option, value) => {
          return (
            (option as TOption).props.key === (value as TOption)?.props?.key ||
            (option as TOption).props.key === (value as string)
          );
        }}
        getOptionKey={(option) => (option as TOption).props?.key}
        getOptionLabel={(option) =>
          options.find(
            (opt) =>
              (opt as TOption).props.key === (option as TOption)?.props?.key ||
              (opt as TOption).props.key === (option as string),
          )?.textContent
        }
        slotProps={{
          popper: {
            ...selectProps,
            disablePortal: true,
          },
        }}
        ListboxProps={{ ...field.props }}
      />
    </FieldWrapper>
  );
}

import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';

import {
  VacationsAccrualPeriodEntry,
  VacationsScheduleInput,
  fetchPostVacationsSchedule,
} from '@octopus/api';

import { BackButton } from '../../../../modules/components/BackButton';
import { MultiStepView } from '../../../../modules/navigation/multiStep/MultiStepView';
import { useMultiStepView } from '../../../../modules/navigation/multiStep/useMultiStepView';

import AdvanceThirteenthStepView from './steps/advanceThirteenthStep';
import DurationAndDatePickerStep from './steps/durationAndDatePickerStep';
import InitialStepView from './steps/initialStep';
import ResultStepView from './steps/result';
import ReviewAndSubmitStepView from './steps/reviewAndSubmit';
import SellVacationsStepView from './steps/sellVacationsStep';
import {
  RequestVacationFormSteps,
  inputFormSteps,
  requestVacationObject,
} from './utils/types';

type Props = {
  accrualPeriod: VacationsAccrualPeriodEntry & { nextSequence: number };
  formSteps: RequestVacationFormSteps;
  setRequestVacation: Dispatch<SetStateAction<requestVacationObject>>;
  organizationId: string | undefined;
  contractId: string | undefined;
};

function RequestVacationContainer({
  accrualPeriod,
  formSteps,
  setRequestVacation,
  organizationId,
  contractId,
}: Props) {
  const [currStep, setCurrStep] = useState<number>(0);
  const [totalSteps, setTotalSteps] = useState<number>(0);
  const [title, setTitle] = useState<string>(undefined);
  const requestVacationMSV = useMultiStepView<string>(formSteps);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    setCurrStep(requestVacationMSV.currentStep.idx);
    setTotalSteps(requestVacationMSV.steps.length - 3);
    setTitle(
      requestVacationMSV.currentStep.name === 'reviewAndSubmit'
        ? 'Revisar solicitação'
        : 'Solicitação de férias',
    );
  }, [requestVacationMSV]);

  const submitVacationsScheduler = (request: requestVacationObject) => {
    const body: VacationsScheduleInput = {
      startDate: request.date.format('YYYY-MM-DD'),
      endDate: request.date
        .add(request.duration - 1, 'day')
        .format('YYYY-MM-DD'),
      daysSold: request.sellVacation ? 10 : 0,
      thirteenthAdvance: request.advanceThirteenth ?? false,
      sequence: accrualPeriod.nextSequence,
      accrualPeriod: {
        startDate: accrualPeriod.startDate,
        endDate: accrualPeriod.endDate,
      },
    };
    return fetchPostVacationsSchedule({
      pathParams: {
        organizationId: organizationId,
        contractId: contractId,
      },
      body,
    });
  };

  return isLoading ? (
    renderLoading()
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        height: '100%',
      }}
    >
      <BackButton
        destination={
          requestVacationMSV.currentStep.isFirst
            ? `/vacations/${contractId}/scheduler`
            : undefined
        }
        position="static"
      />
      <Box
        data-testid={`vacation-request-type-header`}
        sx={{
          marginBottom: 5,
          marginLeft: 2,
          paddingLeft: '10px',
        }}
      >
        <Typography variant="h1" fontWeight={700} fontSize={24}>
          {title}
        </Typography>
        {inputFormSteps.includes(requestVacationMSV.currentStep.name) ? (
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: 14,
              fontWeight: 500,
              lineHeight: '20px',
              color: '#616161',
            }}
          >
            {`Passo ${currStep} de ${totalSteps}`}
          </Typography>
        ) : null}
      </Box>
      <MultiStepView multiStepView={requestVacationMSV}>
        {Object.entries(formSteps).map(([step, stepValue]) => {
          switch (step) {
            case 'initialStep':
              return (
                <MultiStepView.Step name={step} key={step}>
                  <InitialStepView
                    accrualPeriod={accrualPeriod}
                    requestVacationMSV={requestVacationMSV}
                  />
                </MultiStepView.Step>
              );
            case 'abonoPecuniario':
              return (
                <MultiStepView.Step name={step} key={step}>
                  <SellVacationsStepView
                    requestVacationMSV={requestVacationMSV}
                    request={stepValue}
                    setRequest={setRequestVacation}
                  />
                </MultiStepView.Step>
              );
            case 'thirteenthAdvance':
              return (
                <MultiStepView.Step name={step} key={step}>
                  <AdvanceThirteenthStepView
                    requestVacationMSV={requestVacationMSV}
                    request={stepValue}
                    setRequest={setRequestVacation}
                  />
                </MultiStepView.Step>
              );
            case 'dateAndDuration':
              return (
                <MultiStepView.Step name={step} key={step}>
                  <DurationAndDatePickerStep
                    accrualPeriod={accrualPeriod}
                    requestVacationMSV={requestVacationMSV}
                    request={stepValue}
                    setRequest={setRequestVacation}
                  />
                </MultiStepView.Step>
              );
            case 'reviewAndSubmit':
              return (
                <MultiStepView.Step name={step} key={step}>
                  <ReviewAndSubmitStepView
                    accrualPeriod={accrualPeriod}
                    requestVacationMSV={requestVacationMSV}
                    request={stepValue}
                    setRequest={setRequestVacation}
                    setIsLoading={setIsLoading}
                    submitVacationsScheduler={submitVacationsScheduler}
                  />
                </MultiStepView.Step>
              );
            case 'result':
              return (
                <MultiStepView.Step name={step} key={step}>
                  <ResultStepView
                    request={stepValue}
                    requestVacationMSV={requestVacationMSV}
                  />
                </MultiStepView.Step>
              );
          }
          return null;
        })}
      </MultiStepView>
    </Box>
  );
}

function renderLoading() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <svg width="51" height="51" viewBox="0 0 51 51" fill="none">
        <circle
          cx="25.5"
          cy="25.5"
          r="23"
          stroke="#2D2D2D"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="1 11 1 11"
        />
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="360 0 0"
          to="0 0 0"
          dur="4s"
          repeatCount="indefinite"
        />
      </svg>
    </Box>
  );
}

export default RequestVacationContainer;

import { cpf } from 'cpf-cnpj-validator';
import { z } from 'zod';

export const cpfSchema = z
  .string()
  .regex(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/)
  .refine((val) => {
    try {
      return cpf.isValid(val);
    } catch (_) {
      return false;
    }
  }, 'Invalid CPF');

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';

import {
  useGetAdmissionDraftByUser,
  useGetOrganizationEntry,
} from '@octopus/api';

import TakoLogo from '../../../../assets/takoLogo.svg';
import { LoadingScene } from '../../../admission/edit/AdmissionDraftEditLoadingScene';

type Props = {
  organizationId: string;
  userId: string;
};

export function SignupSuccess({ organizationId, userId }: Props) {
  const [draftId, setDraftId] = useState<string>(null);
  const [companyName, setCompanyName] = useState<string>();

  const admissionDraftQuery = useGetAdmissionDraftByUser(
    {
      pathParams: {
        organizationId,
        userId,
      },
    },
    {
      enabled: organizationId != null && userId != null && draftId == null,
    },
  );

  const {
    data: draft,
    status: draftStatus,
    isLoading,
    isFetching,
  } = admissionDraftQuery;

  useEffect(() => {
    if (draftStatus === 'error') {
      throw new Error(
        'Erro ao carregar dados da admissão. Tente novamente ou entre em contato com o departamento pessoal.',
      );
    }
    if (draft?.draftId != null && draftId == null) {
      setDraftId(draft.draftId);
    }
  }, [draft, draftId, draftStatus]);

  const organizationQuery = useGetOrganizationEntry(
    {
      pathParams: {
        organizationId,
      },
    },
    { enabled: organizationId != null && companyName == null, retry: 2 },
  );

  const { data: organization, status: organizationError } = organizationQuery;
  useEffect(() => {
    if (organizationError === 'error') {
      throw new Error(
        'Erro ao carregar dados da admissão. Tente novamente ou entre em contato com o departamento pessoal.',
      );
    }
    if (organization?.name != null && companyName == null) {
      setCompanyName(organization.name);
    }
  }, [organization, companyName, organizationError]);

  if (!draftId || !companyName || isLoading || isFetching) {
    return <LoadingScene />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        textAlign: 'center',
        padding: 2,
        height: '80%',
        justifyContent: 'stretch',
        margin: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          flexGrow: 2,
          justifyContent: 'flex-end',
        }}
      >
        <img src={TakoLogo} alt="Tako" height={34} />
        <Typography variant="body2">
          Seu cadastro foi efetivado na Tako, clique no botão abaixo para
          iniciar a sua admissão em {companyName}
        </Typography>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        <Button
          fullWidth
          color="primaryAlt"
          component={Link}
          to={`/admissions/new/${draftId}`}
          sx={{ p: 1.5 }}
          data-testid="back"
        >
          <Typography variant="body1" color="secondary" fontWeight="700">
            Começar minha admissão
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}

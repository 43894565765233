import React from 'react';

import { maskitoTransform } from '@maskito/core';
import { maskitoNumberOptionsGenerator } from '@maskito/kit';
import { useMaskito } from '@maskito/react';

import { InputAdornment, SxProps, TextField, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { UI_TYPE } from '@octopus/libs/forms';

import { TFieldInputRenderProps, TFieldRenderProps } from '../parseField/types';

import { FieldWrapper } from './commons/FieldWrapper';

type FieldTextMoneyProps = {
  field: TFieldRenderProps & TFieldInputRenderProps;
  sx?: SxProps<Theme>;
};
export function FieldTextMoney(props: FieldTextMoneyProps) {
  const { field } = props;

  const options = maskitoNumberOptionsGenerator({
    decimalZeroPadding: false,
    precision: 2,
    decimalSeparator: ',',
    thousandSeparator: '',
  });

  const maskito = useMaskito({
    options,
  });

  const hasError = field.errors?.length > 0;

  const defaultValue = maskitoTransform(
    field.input.props.defaultValue ?? '0',
    options,
  );

  return (
    <FieldWrapper field={field} sx={field.props.sx}>
      <TextField
        fullWidth
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        variant="outlined"
        inputProps={{
          ...field.input.props,
          defaultValue,
          inputMode: 'decimal',
        }}
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position={'start'}>
              <Typography color={'text.primary'}>R$</Typography>
            </InputAdornment>
          ),
        }}
        ref={maskito}
        error={hasError}
      />
    </FieldWrapper>
  );
}

FieldTextMoney.uiType = UI_TYPE.TEXT_MONEY;
FieldTextMoney.canRender = (
  field: TFieldRenderProps,
): field is FieldTextMoneyProps['field'] => {
  return (
    'input' in field &&
    field.input.props.type !== 'checkbox' &&
    field.input.props.type !== 'radio'
  );
};

import { Box, Typography } from '@mui/material';

import { PayrollOutputsOutputs } from '@octopus/api';
import { formatMoney, formatPercentage } from '@octopus/formatters';
import {
  UNSAFE_INTERNALS_PLEASE_FIXME_ASAP,
  elementsIdsPublicos,
} from '@octopus/payroll-engine/public-types/brazil';

import {
  getAmountFromElement,
  getBasisFromElement,
  getRateFromElement,
} from '../../../utils';

export type CalculationSummaryProps = {
  calculationOutputs: PayrollOutputsOutputs;
};

export function CalculationSummary({
  calculationOutputs,
}: CalculationSummaryProps) {
  return (
    <Box
      display="flex"
      px={1.5}
      pt={3}
      pb={7}
      sx={(theme) => ({
        [theme.breakpoints.up('md')]: {
          justifyContent: 'space-between',
          gap: 5,
          px: 1.5,
          pt: 3,
          pb: 7,
        },
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
          gap: 1,
          p: 3,
        },
      })}
      data-testid="payslip-calculation-summary"
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        justifyContent="space-between"
        sx={(theme) => ({
          [theme.breakpoints.up('md')]: {
            gap: 0.75,
          },
          [theme.breakpoints.down('md')]: {
            gap: 1,
          },
        })}
      >
        <SummaryEntry
          label="Salário Base"
          value={formatMoney(
            getAmountFromElement(
              UNSAFE_INTERNALS_PLEASE_FIXME_ASAP.elementsIds.intermediarios
                .salarioBase,
              calculationOutputs,
            ),
          )}
        />
        <SummaryEntry
          label="Salario de Contribuição p/ INSS"
          value={formatMoney(
            getAmountFromElement(
              elementsIdsPublicos.finais.inss.salarioContribuicao,
              calculationOutputs,
            ),
          )}
        />
        <SummaryEntry
          label="Base FGTS"
          value={formatMoney(
            getBasisFromElement(
              elementsIdsPublicos.finais.fgts,
              calculationOutputs,
            ),
          )}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        justifyContent="space-between"
        sx={(theme) => ({
          [theme.breakpoints.up('md')]: {
            gap: 0.75,
          },
          [theme.breakpoints.down('md')]: {
            gap: 1,
          },
        })}
      >
        <SummaryEntry
          label="FGTS do mês"
          value={formatMoney(
            getAmountFromElement(
              elementsIdsPublicos.finais.fgts,
              calculationOutputs,
            ),
          )}
        />
        <SummaryEntry
          label="Base IRRF"
          value={formatMoney(
            getBasisFromElement(
              elementsIdsPublicos.finais.irrf.remuneracaoMensal,
              calculationOutputs,
            ),
          )}
        />
        <SummaryEntry
          label="Faixa no IRRF"
          value={formatPercentage(
            getRateFromElement(
              elementsIdsPublicos.finais.irrf.remuneracaoMensal,
              calculationOutputs,
            ),
          )}
        />
      </Box>
    </Box>
  );
}

function SummaryEntry({
  label,
  value,
}: {
  label: string;
  value: string | undefined;
}) {
  if (!value) {
    return null;
  }
  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        })}
      >
        <Typography variant="caption" color="text.secondary" fontWeight="500">
          {label}
        </Typography>
        <Typography variant="caption" color="text.secondary" fontWeight="500">
          {value}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={(theme) => ({
          [theme.breakpoints.up('md')]: {
            display: 'none',
          },
        })}
      >
        <Typography variant="body2" color="text.secondary" fontWeight="500">
          {label}
        </Typography>
        <Typography variant="body2" color="text.secondary" fontWeight="500">
          {value}
        </Typography>
      </Box>
    </Box>
  );
}

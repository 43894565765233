import { ContractTypes } from '@octopus/contract-types';
import { Tag } from '@octopus/ui/design-system';

const configByStatus: {
  [key: string]: {
    color: 'info' | 'default';
    label: string;
  };
} = {
  'br:clt': {
    color: 'info',
    label: 'Colaborador',
  },
  'br:pj': {
    color: 'default',
    label: 'Prestador',
  },
};

export default function ContractTypeTag({
  contractType,
}: {
  contractType: ContractTypes;
}) {
  return (
    <Tag color={configByStatus[contractType]?.color ?? 'default'}>
      {configByStatus[contractType]?.label ?? '---'}
    </Tag>
  );
}

import { useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';

import { ErrorOutline, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from '@mui/material';

import { CreateScheduleRule } from '@octopus/vacations-types';

import sendSuccess from '../../../../../assets/sendSuccess.svg';
import scheduleErrorMessages from '../utils/scheduleErrorMessages';
import { requestVacationObject } from '../utils/types';

export default function ResultStepView({
  request,
  requestVacationMSV,
}: {
  request: requestVacationObject;
  requestVacationMSV: any;
}) {
  const navigate = useNavigate();
  const { contractId } = useParams();
  const [expanded, setExpanded] = useState<string | false>('');

  if (request?.result?.ok) {
    return successPage(navigate, contractId);
  } else {
    return errorPage({ request, expanded, setExpanded, requestVacationMSV });
  }
}

function errorPage({
  request,
  expanded,
  setExpanded,
  requestVacationMSV,
}: {
  request: requestVacationObject;
  expanded: string | false;
  setExpanded: React.Dispatch<React.SetStateAction<string | false>>;
  requestVacationMSV: any;
}) {
  return (
    <Box
      test-id={'step-box'}
      sx={(theme) => ({
        px: 2,
        pt: 2,
        pb: 2,
        flexDirection: 'column',
        display: 'inline-flex',
        bgcolor: theme.palette.background.secondary,
        gap: 2,
        height: '100%',
      })}
    >
      <Box
        sx={{
          flexGrow: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
          height: '100%',
          gap: 2,
        }}
      >
        <Box
          component={ErrorOutline}
          sx={{ height: '64px', width: 'auto', mr: 0, color: '#BABABF' }}
        />
        <Typography fontSize={22} fontWeight={600} lineHeight={2}>
          Solicitação com problemas
        </Typography>
        <Typography variant={'subtitle1'} fontSize={14}>
          {`Sua solicitação não foi enviada pois não é válida. Veja os detalhes para saber mais`}
        </Typography>
        <Box sx={{ width: '100%' }}>
          <Button
            color={'primaryAlt'}
            onClick={() => {
              const firstStepName = requestVacationMSV.steps[0].name;
              requestVacationMSV.goTo(firstStepName);
            }}
            sx={{ width: '100%', py: 1, px: 2.5, height: '48px' }}
          >
            Ok, entendi
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            flexGrow: 1,
            textAlign: 'center',
          }}
        >
          {request?.result?.ok === false ? (
            <Accordion
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                alignContent: 'center',
                backgroundColor: theme.palette.background.error,
              })}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                sx={(theme) => ({
                  padding: 1,
                  width: '100%',
                  alignContent: 'center',
                  height: '48px',
                  backgroundColor: theme.palette.background.error,
                })}
              >
                <Typography variant="body1">Detalhes</Typography>
              </AccordionSummary>

              <AccordionDetails sx={{ padding: 0 }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                    justifyContent: 'flex-start',
                    flexGrow: 1,
                    textAlign: 'left',
                    overflowY: 'hidden',
                    maxHeight: '60%',
                  }}
                >
                  {request?.result?.errorRuleCodes.map((id: string) => {
                    id = id.split('cvs/')[1];
                    return (
                      <Accordion
                        expanded={expanded === id}
                        onChange={(_, newExpanded: boolean) => {
                          setExpanded(newExpanded ? id : false);
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            scheduleErrorMessages[id as CreateScheduleRule]
                              .description ? (
                              <ExpandMore />
                            ) : null
                          }
                          sx={{
                            display: 'flex',
                            padding: 1,
                            width: '100%',
                            alignContent: 'flex-start',
                            aligntText: 'left',
                            pointerEvents: scheduleErrorMessages[
                              id as CreateScheduleRule
                            ].description
                              ? 'visiblePainted'
                              : 'none',
                          }}
                        >
                          {
                            scheduleErrorMessages[id as CreateScheduleRule]
                              .summary
                          }
                        </AccordionSummary>
                        {scheduleErrorMessages[id as CreateScheduleRule]
                          .description ? (
                          <AccordionDetails
                            sx={(theme) => ({
                              padding: 1,
                              backgroundColor: theme.palette.background.paper,
                            })}
                          >
                            {
                              scheduleErrorMessages[id as CreateScheduleRule]
                                .description
                            }
                          </AccordionDetails>
                        ) : null}
                      </Accordion>
                    );
                  })}
                </Box>
              </AccordionDetails>
            </Accordion>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}

function successPage(navigate: NavigateFunction, contractId: string) {
  return (
    <Box
      test-id={'step-box'}
      sx={(theme) => ({
        px: 2,
        pt: 8,
        pb: 2,
        flexDirection: 'column',
        display: 'inline-flex',
        bgcolor: theme.palette.background.secondary,
        gap: 4,
        height: '100%',
      })}
    >
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            flexGrow: 1,
            textAlign: 'center',
          }}
        >
          <img src={sendSuccess} alt="Enviado com sucesso" />
          <Typography fontSize={24} fontWeight={700} lineHeight={2}>
            Solicitação enviada
          </Typography>
          <Typography variant={'body1'} fontWeight={450}>
            Sua solicitação foi enviada e vamos te notificar assim que todas as
            aprovações forem finalizadas.
          </Typography>
        </Box>
      </Box>
      <Box sx={{ width: '100%', flexGrow: 1, alignContent: 'flex-end' }}>
        <Button
          color={'primaryAlt'}
          onClick={() => {
            navigate(`/vacations/${contractId}/scheduler`);
          }}
          sx={{ width: '100%', py: 1, px: 2.5, height: '48px', gap: '8px' }}
        >
          Ok, entendi
        </Button>
      </Box>
    </Box>
  );
}

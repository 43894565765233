import { groupBy } from 'lodash';

import { Mapper } from './mapper';
import { MUNICIPIOS } from './municipios';
import { UFs } from './uf';

/**
 * Map of `Municipio` mappers.
 *
 * You first index into it using a `Estado`'s (state)
 * code to get a handle to a mapper
 * that maps it's `Municipios` (cities).
 *
 * @example
 *   const state = Estados.codes()[0];
 *   const mapper = MunicipiosByEstado[state];
 *   const allOfTheStatesCities = mapper.codes().map(
 *     (code) => mapper.getByCode(code)
 *   );
 *
 */
const MunicipiosByEstado: Record<string, Mapper> = {};

const munipiosByCode: Record<string, [string, string][]> = groupBy(
  Object.entries(MUNICIPIOS),
  ([code, _value]) => code.slice(0, 2),
);

for (const ufCode of UFs.codes()) {
  const uf = UFs.getByCode(ufCode) as string;
  const matchingMunicipios = munipiosByCode[ufCode] as [string, string][];
  MunicipiosByEstado[uf] = Mapper.from(Object.fromEntries(matchingMunicipios));
}

export { MunicipiosByEstado };

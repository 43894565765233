import { v4 } from 'uuid';

import {
  AdmissionDraftInput,
  AdmissionDraftInputFormInfo,
  AdmissionDraftInputFormSectionEnum,
  AdmissionDraftInputFormStepEnum,
  AdmissionDraftInputFormSteps,
  AdmissionDraftUpdateInput,
  AdmissionType,
} from '@octopus/api';

import { getEffectiveDate } from './inputUtils';
import {
  getCreateInputFromFormState,
  getUpdateInputFromFormState,
} from './mappers';
import {
  AdmissionFormState,
  AdmissionFormSteps,
  FormStepOptions,
} from './types';

const getInputStepsFromFormSteps = ({
  previousFormSteps,
  formSteps,
  section,
  currentStepName,
}: {
  previousFormSteps: AdmissionDraftInputFormSteps;
  formSteps: AdmissionFormSteps;
  section: AdmissionDraftInputFormSectionEnum;
  currentStepName: AdmissionDraftInputFormStepEnum;
}): AdmissionDraftInputFormSteps => {
  const data: AdmissionDraftInputFormSteps = { ...previousFormSteps };

  Object.keys(formSteps).forEach((key) => {
    data[key] = {
      section,
      step: key as AdmissionDraftInputFormStepEnum,
      started: false,
      completed:
        key === currentStepName || previousFormSteps[key]?.completed || false,
    };
  });
  return data;
};

const getStartingStep = (
  steps?: {
    name: string;
    isFirst: boolean;
    data: any;
  }[],
): string => {
  if (!steps) return null;

  const nextStep =
    steps.find((step) => {
      const stepOptions = step?.data?.options as FormStepOptions;
      return !step.isFirst && !stepOptions?.completed;
    }) ?? steps[steps.length - 1];
  return nextStep?.name;
};

const getFormInfo = ({
  previousFormInfo,
  adminFormSteps,
  workerFormSteps,
  currentStepName,
}: {
  previousFormInfo: AdmissionDraftInputFormInfo;
  adminFormSteps: AdmissionFormSteps;
  workerFormSteps: AdmissionFormSteps;
  currentStepName: AdmissionDraftInputFormStepEnum;
}): AdmissionDraftInputFormInfo => {
  const payroll_data = getInputStepsFromFormSteps({
    previousFormSteps: previousFormInfo?.formSections?.['payroll_data'] || {},
    formSteps: adminFormSteps,
    section: 'payroll_data',
    currentStepName,
  });

  const personal_data = getInputStepsFromFormSteps({
    previousFormSteps: previousFormInfo?.formSections?.['personal_data'] || {},
    formSteps: workerFormSteps,
    section: 'personal_data',
    currentStepName,
  });

  return {
    formSections: {
      payroll_data,
      personal_data,
    },
  };
};

const getCreateBody = ({
  previousFormInfo,
  companyId,
  formState,
  adminFormSteps,
  workerFormSteps,
  currentStepName,
}: {
  previousFormInfo: AdmissionDraftInputFormInfo;
  companyId: string;
  formState: AdmissionFormState;
  adminFormSteps: AdmissionFormSteps;
  workerFormSteps: AdmissionFormSteps;
  currentStepName: AdmissionDraftInputFormStepEnum;
}): AdmissionDraftInput => {
  const formInfo = getFormInfo({
    previousFormInfo: previousFormInfo,
    adminFormSteps,
    workerFormSteps,
    currentStepName,
  });

  const createInput = getCreateInputFromFormState({ formState });
  return {
    companyId,
    legalEntityId: formState.legalEntity,
    contractType: formState.contractType,
    admissionType: formState.admissionType as AdmissionType,
    effectiveStartDate: getEffectiveDate(),
    formInfo,
    ...createInput,
  };
};

const getUpdateBody = ({
  previousFormInfo,
  formState,
  adminFormSteps,
  workerFormSteps,
  currentStepName,
}: {
  previousFormInfo: AdmissionDraftInputFormInfo;
  formState: AdmissionFormState;
  adminFormSteps: AdmissionFormSteps;
  workerFormSteps: AdmissionFormSteps;
  currentStepName: AdmissionDraftInputFormStepEnum;
}): AdmissionDraftUpdateInput => {
  const formInfo = getFormInfo({
    previousFormInfo,
    adminFormSteps,
    workerFormSteps,
    currentStepName,
  });
  const updateInput = getUpdateInputFromFormState({
    formState,
    currentStepName,
  });

  return {
    contractType: formState.contractType,
    effectiveStartDate: getEffectiveDate(),
    formInfo,
    ...updateInput,
  };
};

const getHeaders = () => {
  const idempotencyKey = v4();
  return {
    'x-idempotency-key': idempotencyKey,
  };
};

export { getCreateBody, getStartingStep, getUpdateBody, getHeaders };

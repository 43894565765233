import { DateTimeFormatter, YearMonth } from '@js-joda/core';
import { z } from 'zod';

export const yearMonthSchema = z
  .string()
  .regex(/^\d{4}-\d{2}$/)
  .refine((val) => {
    try {
      YearMonth.parse(val, DateTimeFormatter.ofPattern('yyyy-MM'));
      return true;
    } catch (_) {
      return false;
    }
  }, 'Invalid date');

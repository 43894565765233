import { Box, DialogContent, Typography } from '@mui/material';

import { OctopusLoading } from '@octopus/ui/design-system';

export function LoadingScene({ title }: { title: string }) {
  return (
    <DialogContent>
      <Box
        alignItems="center"
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          [theme.breakpoints.up('md')]: {
            py: 10,
            px: 16,
          },
          [theme.breakpoints.down('md')]: {
            py: 8,
            px: 4,
          },
        })}
      >
        <Box width="100px">
          <OctopusLoading />
        </Box>
        <Box pt={2}>
          <Typography variant="h4" textAlign="center">
            {title}
          </Typography>
        </Box>
      </Box>
    </DialogContent>
  );
}

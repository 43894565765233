import {
  CostCenterSummary,
  JobTitleSummary,
  LegalEntitySummary,
  fetchGetAllJobTitles,
  fetchGetLegalEntities,
  fetchSearchAllCostCenters,
} from '@octopus/api';

import { BaseContext, CompanyContext, CostCenterContext } from '../types';

type CompanyFetcher<T> = {
  type: 'GET' | 'POST';
  fetch: (params: any) => Promise<{
    data?: T[];
    page: number;
    size: number;
    total: number;
  }>;
  pathParams: {
    organizationId: string;
    companyId?: string;
  };
  parseItem: (item: T) => BaseContext<T>;
};

const fetchCompanyContext = async ({
  organizationId,
  companyId,
}: {
  organizationId: string;
  companyId: string;
}): Promise<CompanyContext> => {
  const legalEntities: CompanyFetcher<LegalEntitySummary> = {
    fetch: fetchGetLegalEntities,
    type: 'GET',
    pathParams: {
      organizationId,
      companyId,
    },
    parseItem: (item: LegalEntitySummary) => ({
      id: item.legalEntityId,
      name: item.br.nomeFantasia,
      summary: item,
    }),
  };

  const jobTitles: CompanyFetcher<JobTitleSummary> = {
    fetch: fetchGetAllJobTitles,
    type: 'GET',
    pathParams: {
      organizationId,
    },
    parseItem: (item: JobTitleSummary) => ({
      id: item.jobTitleId,
      name: `${item.name} - ${item.occupationCode ?? ''}`,
      summary: item,
    }),
  };

  const costCenters: CompanyFetcher<CostCenterSummary> = {
    fetch: fetchSearchAllCostCenters,
    type: 'POST',
    pathParams: {
      organizationId,
    },
    parseItem: (item: CostCenterSummary): CostCenterContext => ({
      id: item.costCenterId,
      name: `${item.name} - ${item.code ?? ''}`,
      summary: item,
    }),
  };

  const companyFetchers: {
    [key in keyof CompanyContext]: CompanyFetcher<any>;
  } = {
    legalEntities,
    jobTitles,
    costCenters,
  };

  const companyContext: CompanyContext = {};
  await Promise.all(
    Object.entries(companyFetchers).map(([key, fetcher]) => {
      return fetchAllPages({ fetcher }).then((result) => {
        if (result.data) {
          const items = result.data
            .map(fetcher.parseItem)
            .sort((a, b) =>
              a.name
                .toLocaleLowerCase()
                .localeCompare(b.name.toLocaleLowerCase()),
            );
          companyContext[key as keyof CompanyContext] = items;
        }
      });
    }),
  );

  return companyContext;
};

async function fetchAllPages<T>({
  size = 100,
  fetcher,
}: {
  size?: number;
  fetcher: CompanyFetcher<T>;
}) {
  const { fetch, pathParams } = fetcher;

  const requestParams = (page: number, type: string) => ({
    pathParams,
    ...(type === 'GET' && {
      queryParams: { page: page.toString(), size: size.toString() },
    }),
    ...(type === 'POST' && { body: { pagination: { page, size } } }),
  });

  const initialState = {
    page: 0,
    data: [] as T[],
    size: 0,
    total: 0,
  };

  const fetchUntilEnd = async (
    page = 0,
    acc = initialState,
  ): Promise<{
    page: number;
    data: T[];
    size: number;
    total: number;
  }> => {
    const params = requestParams(page, fetcher.type);
    const response = await fetch({
      ...params,
    });
    if (!response.data || response.data.length === 0) {
      return acc;
    }

    const allVisited = acc.size + size;
    const nextResult = {
      ...acc,
      data: acc.data.concat(response.data),
      size: allVisited,
    };

    const hasNextPage = response.total > allVisited;
    if (!hasNextPage) {
      return nextResult;
    }

    return fetchUntilEnd(page + 1, nextResult);
  };

  return fetchUntilEnd(0, initialState);
}

export { fetchCompanyContext };

import { useNavigate } from 'react-router-dom';

import { WarningRounded } from '@mui/icons-material';
import { Alert, Box, Button, Card, Typography } from '@mui/material';

export type nextVacationsCardSaldoProps = {
  enabled: boolean;
  due: 'unacquired' | 'acquired' | 'closeToDue' | 'overdue';
  days: number;
  dueDate: string;
  requestLink: string;
};

function closeToDueAlert() {
  return (
    <Alert
      severity="warning"
      icon={<WarningRounded fontSize="large" />}
      sx={{ mt: 1, alignItems: 'center', justifyContent: 'center' }}
    >
      Atenção, a data limite para solicitação está próxima, faça a sua
      solicitação.
    </Alert>
  );
}

function dueAlert() {
  return (
    <Alert
      severity="error"
      icon={<WarningRounded fontSize="large" />}
      sx={{ mt: 1, alignItems: 'center', justifyContent: 'center' }}
    >
      A data limite para solicitação venceu, entre em contato com seu
      departamento pessoal.
    </Alert>
  );
}

export default function NextVacationsCardSaldo({
  props,
}: {
  props: nextVacationsCardSaldoProps;
}) {
  const navigate = useNavigate();
  const content = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography fontWeight={700} fontSize={'20px'}>
          {props.days} dias
        </Typography>
        <Typography fontWeight={500} fontSize={'14px'} variant={'subtitle1'}>
          {!props.enabled
            ? `Disponíveis a partir de ${props.dueDate}`
            : `Solicitar até ${props.dueDate}`}
        </Typography>
      </Box>
      {['closeToDue', 'acquired'].includes(props.due) ? (
        <Button variant="text" href={props.requestLink}>
          Solicitar
        </Button>
      ) : null}
    </Box>
  );
  const disabledProps = {
    backgroundColor: '#F7F7F8',
    border: '1px solid #EDEDED',
    boxShadow: 'none',
  };

  return (
    <Card
      variant={'outlined'}
      onClick={() => navigate(props.requestLink)}
      sx={{
        boxSizing: 'border-box',
        py: '20px',
        px: '20px',
        borderRadius: 2,
        my: 0.3,
        ...(['unacquired', 'overdue'].includes(props.due) ? disabledProps : {}),
      }}
    >
      <>
        {content}
        {props.due === 'closeToDue' ? closeToDueAlert() : null}
        {props.due === 'overdue' ? dueAlert() : null}
      </>
    </Card>
  );
}

import { BiMap } from '@rimbu/core';

import {
  ContractBRCltCodigoMotivoAfastamentoDoencaOuAcidente,
  ContractBRCltCodigoMotivoAfastamentoFeriasOuRecesso,
  ContractBRCltCodigoMotivoAfastamentoMaternidade,
  ContractBRCltCodigoMotivoAfastamentoOutrosMotivos,
  ContractBRCltCodigoMotivoAfastamentoProrrogacaoMaternidade,
  ContractBRCltGrauExpComInsalubridade,
  ContractBRCltGrauExpSemInsalubridade,
  ContractEntry,
} from '@octopus/api';

const tsvTableBuilder = BiMap.builder<string, string>();
tsvTableBuilder.addEntries([
  ['201', 'Trabalhador Avulso Portuário'],
  ['202', 'Trabalhador Avulso Não Portuário'],
  [
    '304',
    'Servidor público exercente de mandato eletivo, inclusive com exercício de cargo em comisão',
  ],
  [
    '305',
    'Servidor Público indicado para conselho ou órgão deliberativo, na condição de representante do governo, órgão ou entidade da administração pública.',
  ],
  ['308', 'Conscritos'],
  ['401', 'Dirigente Sindical - informação prestada pelo Sindicato'],
  [
    '410',
    'Trabalhador cedido/exercício em outro órgão/juiz auxiliar - Informação prestada pelo cessionário/destino',
  ],
  ['721', 'Contribuinte individual - Diretor não empregado, com FGTS'],
  ['722', 'Contribuinte individual - Diretor não empregado, sem FGTS'],
  [
    '723',
    'Contribuinte individual - Empresários, sócios e membro de conselho de administração ou fiscal',
  ],
  [
    '731',
    'Contribuinte individual - Cooperado que presta serviços por intermédio de Cooperativa de Trabalho',
  ],
  [
    '734',
    'Contribuinte individual - Transportador Cooperado que presta serviços por intermédio de cooperativa de trabalho',
  ],
  [
    '738',
    'Contribuinte individual - Cooperado filiado a Cooperativa de Produção',
  ],
  [
    '761',
    'Contribuinte individual - Associado eleito para direção de Cooperativa, associação ou entidade de classe de qualquer natureza ou finalidade, bem como o síndico ou administrador eleito para exercer atividade de direção condominial, desde que recebam remuneração',
  ],
  [
    '771',
    'Contribuinte individual - Membro de conselho tutelar, nos termos da Lei nº 8.069, de 13 de julho de 1990',
  ],
  ['901', 'Estagiário'],
  ['902', 'Médico Residente'],
]);

export const tsvTable = tsvTableBuilder.build();

export function isTrabalhadorSemVinculo(entry: ContractEntry): boolean {
  if (entry.br === undefined) {
    return false;
  }

  if (entry.contractType !== 'br:clt') {
    return true;
  }

  return tsvTable.hasKey(`${entry.br.trabalho.codCateg}`);
}

export const BRCltContractLeavesDiseaseOrAccidentCodMotAfast: ContractBRCltCodigoMotivoAfastamentoDoencaOuAcidente[] =
  [1, 3];

export const BRCltContractLeavesMaternityCodMotAfast: ContractBRCltCodigoMotivoAfastamentoMaternidade[] =
  [17, 18, 19, 20, 35];

export const BRCltContractLeavesMaternityExtensionCodMotAfast: ContractBRCltCodigoMotivoAfastamentoProrrogacaoMaternidade[] =
  [18, 35];

export const BRCltContractLeavesVacationCodMotAfast: ContractBRCltCodigoMotivoAfastamentoFeriasOuRecesso[] =
  [15];

export const BRCltContractLeavesOtherReasonsCodMotAfast: ContractBRCltCodigoMotivoAfastamentoOutrosMotivos[] =
  [
    5, 6, 7, 8, 10, 11, 12, 13, 14, 16, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
    31, 33, 34, 36, 37, 38, 39, 40, 41, 42,
  ];

export const BRCltContractGrauExpSemInsalubridade: ContractBRCltGrauExpSemInsalubridade[] =
  [1];
export const BRCltContractGrauExpComInsalubridade: ContractBRCltGrauExpComInsalubridade[] =
  [2, 3, 4];

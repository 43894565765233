import { useEffect, useState } from 'react';

import dayjs, { Dayjs } from 'dayjs';

import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import {
  CompanyList,
  PayrollConfigurationEntry,
  fetchGetCompanyPayrollConfiguration,
  fetchPostCompanyPayrollConfiguration,
  fetchPutCompanyPayrollConfiguration,
} from '@octopus/api';
import { formatCNPJ } from '@octopus/formatters';
import { preferences as enginePreferences } from '@octopus/payroll-engine/public-types/core';
import { DateField } from '@octopus/ui/data-grid';

type MultipleChoicePreferencePayload = {
  type: 'multipleChoice';
  values: {
    [key: string]: string;
  };
  defaultValue: string;
  labels: {
    [key: string]: string;
  };
};
type NumberPreferencePayload = {
  type: 'number';
  defaultValue: number;
};
type Preference = {
  id: string;
  title: string;
  parent: 'brPreferences' | 'paymentConfig';
} & (MultipleChoicePreferencePayload | NumberPreferencePayload);

export const preferences = {
  quantosDiasTemNoMes: {
    type: 'multipleChoice',
    ...enginePreferences.quantosDiasTemNoMes,
    title: 'Quantos dias tem no mês',
    labels: {
      sempre30Dias: 'Sempre 30 dias',
      diasReaisDoMes: 'Usar dias reais do mês',
    },
    parent: 'brPreferences',
  },
  usarSempreDiasReaisDoMes: {
    type: 'multipleChoice',
    ...enginePreferences.usarSempreDiasReaisDoMes,
    title: 'Quando sobreescrever sempre 30 dias',
    labels: {
      nunca: 'Nunca',
      admissao: 'Admissão',
    },
    parent: 'brPreferences',
  },
  proporcionalidadeDeDecimoTerceiroAteDezembroParaAdmitidosNoAno: {
    type: 'multipleChoice',
    ...enginePreferences.proporcionalidadeDeDecimoTerceiroAteDezembroParaAdmitidosNoAno,
    title:
      'Preferência de proporcionalidade para a primeira parcela do 13º salário',
    labels: {
      avos: 'Avos adquiridos',
      ano: 'Ano todo, incluindo dezembro',
    },
    parent: 'brPreferences',
  },
  proporcionalidadeAdiantamento: {
    type: 'multipleChoice',
    ...enginePreferences.proporcionalidadeAdiantamento,
    title: 'Proporcionalidade adiantamento',
    labels: {
      proporcional: 'Proporcional',
      integral: 'Integral',
    },
    parent: 'brPreferences',
  },
  pagarMediasNaPrimeiraParcela13o: {
    type: 'multipleChoice',
    ...enginePreferences.pagarMediasNaPrimeiraParcela13o,
    title: 'Pagar médias na primeira parcela do 13o salário',
    labels: {
      sim: 'Sim',
      nao: 'Não',
    },
    parent: 'brPreferences',
  },
  pagarMediasEmFaltasJustificadas: {
    type: 'multipleChoice',
    ...enginePreferences.pagarMediasEmFaltasJustificadas,
    title: 'Pagar médias em faltas justificadas',
    labels: {
      sim: 'Sim',
      nao: 'Não',
    },
    parent: 'brPreferences',
  },
  subtrairFeriasDosMesesTrabalhadosParaMedias: {
    type: 'multipleChoice',
    ...enginePreferences.subtrairFeriasDosMesesTrabalhadosParaMedias,
    title: 'Subtrair férias dos meses trabalhados para médias',
    labels: {
      sim: 'Sim',
      nao: 'Não',
    },
    parent: 'brPreferences',
  },
  subtrairAfastamentosDosMesesTrabalhadosParaMedias: {
    type: 'multipleChoice',
    ...enginePreferences.subtrairAfastamentosDosMesesTrabalhadosParaMedias,
    title: 'Subtrair afastamentos dos meses trabalhados para médias',
    labels: {
      sim: 'Sim',
      nao: 'Não',
    },
    parent: 'brPreferences',
  },
  descontarAjusteNegativoDe13o: {
    type: 'multipleChoice',
    ...enginePreferences.descontarAjusteNegativoDe13o,
    title: 'Descontar ajuste negativo de 13o',
    labels: {
      sim: 'Sim',
      nao: 'Não',
    },
    parent: 'brPreferences',
  },
  considerarMesDaRescisaoParaMedias: {
    type: 'multipleChoice',
    ...enginePreferences.considerarMesDaRescisaoParaMedias,
    title: 'Considerar mês da rescisão para médias',
    labels: {
      quandoAdquirirAvo: 'Quando adquirir avo',
      sempre: 'Sempre',
      nunca: 'Nunca',
    },
    parent: 'brPreferences',
  },
  incluirMediasNaMultaArtigo479: {
    type: 'multipleChoice',
    ...enginePreferences.incluirMediasNaMultaArtigo479,
    title: 'Incluir médias na multa do artigo 479',
    labels: {
      sim: 'Sim',
      nao: 'Não',
    },
    parent: 'brPreferences',
  },
  incluirMediasNaMultaArtigo480: {
    type: 'multipleChoice',
    ...enginePreferences.incluirMediasNaMultaArtigo480,
    title: 'Incluir médias na multa do artigo 480',
    labels: {
      sim: 'Sim',
      nao: 'Não',
    },
    parent: 'brPreferences',
  },
  percentualAdicionalNoturno: {
    type: 'number',
    defaultValue: 0.2,
    id: 'percentualAdicionalNoturno',
    title: 'Percentual de adicional noturno',
    parent: 'brPreferences',
  },

  baseMonth: {
    type: 'multipleChoice',
    id: 'baseMonth',
    values: {
      current: 'current',
      next: 'next',
    },
    defaultValue: 'next',

    title: 'Mês de pagamento',
    labels: {
      current: 'Mês atual da competência',
      next: 'Mês seguinte a competência',
    },
    parent: 'paymentConfig',
  },
  baseDay: {
    type: 'number',
    defaultValue: 1,
    id: 'baseDay',
    title: 'Dia de pagamento',
    parent: 'paymentConfig',
  },
} as const satisfies Record<string, Preference>;

type PreferenceKey = keyof typeof preferences;
const preferencesKeys = Object.keys(preferences) as PreferenceKey[];
type PreferenceValues<Key> = Key extends PreferenceKey
  ? (typeof preferences)[Key] extends MultipleChoicePreferencePayload
    ? keyof (typeof preferences)[Key]['values']
    : (typeof preferences)[Key] extends NumberPreferencePayload
      ? number
      : never
  : never;

export function Preferences({
  organizationId,
  companies,
}: {
  organizationId: string;
  companies: CompanyList;
}) {
  const [response, setResponse] = useState('');
  const [error, setError] = useState('');
  const [warning, setWarning] = useState('');
  const [effectiveDate, setEffectiveDate] = useState<Dayjs>(dayjs());
  const [disabled, setDisabled] = useState(true);
  const [company, setCompany] = useState<string>('');

  const [preferenceOptions, setPreferenceOptions] = useState(
    Object.keys(preferences).reduce(
      (acc, key) => ({
        ...acc,
        [key]: preferences[key as PreferenceKey].defaultValue,
      }),
      {} as { [key in PreferenceKey]: PreferenceValues<key> },
    ),
  );

  const setPreferenceOption = <Key extends PreferenceKey>(
    key: Key,
    value: PreferenceValues<Key>,
  ) => {
    setPreferenceOptions({
      ...preferenceOptions,
      [key]: value,
    });
  };
  useEffect(() => {
    loadCompanyConfig({
      organizationId,
      companyId: company,
      effectiveDate,
      setResponse,
      setWarning,
      setError,
      setDisabled,
      setPreferenceOptions,
    });
  }, [company, effectiveDate, organizationId]);

  const handleSubmit = async () => {
    if (!company || !effectiveDate) {
      return;
    }

    await submitPreferences({
      organizationId,
      companyId: company,

      preferenceOptions,

      effectiveDate,
      setResponse,
      setWarning,
      setError,
    });
  };

  return (
    <Paper elevation={1} sx={{ backgroundColor: 'white', mt: 2 }}>
      <Box py={4} px={4}>
        <Typography variant="h3">Configurações de Companhia</Typography>
        <Divider sx={{ my: 2 }} />

        {error && (
          <Box pb={2}>
            <Alert severity="error" onClose={() => setError('')}>
              {error}
            </Alert>
          </Box>
        )}

        {response && (
          <Box pb={2}>
            <Alert onClose={() => setResponse('')}>{response}</Alert>
          </Box>
        )}

        {warning && (
          <Box pb={2}>
            <Alert severity="warning" onClose={() => setWarning('')}>
              {warning}
            </Alert>
          </Box>
        )}

        <Box display="flex" flexDirection="column" gap={1.5}>
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="body1">
              Escolha uma Companhia e uma Data de Mudança alterar configurações.
            </Typography>
          </Box>

          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="body2">Companhia</Typography>
            <Select
              fullWidth
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            >
              {companies.data &&
                companies.data.map((company) => (
                  <MenuItem key={company.companyId} value={company.companyId}>
                    {company.br?.razaoSocial} ({formatCNPJ(company.br?.cnpj)})
                  </MenuItem>
                ))}
            </Select>
          </Box>

          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="body2">Data da Mudança</Typography>
            <DateField
              dateFormat="DD / MM / YYYY"
              value={effectiveDate}
              onChange={(date) => setEffectiveDate(date || dayjs())}
              sx={{ width: '100%' }}
            ></DateField>
          </Box>

          <Divider sx={{ my: 2 }} />

          {Object.values(preferences).map((preference) => {
            const form = (() => {
              if (preference.type === 'multipleChoice') {
                return (
                  <RadioGroup
                    defaultValue={preference.defaultValue}
                    name={preference.id}
                    onChange={(e) =>
                      setPreferenceOption(
                        preference.id,
                        e.target.value as PreferenceValues<
                          typeof preference.id
                        >,
                      )
                    }
                    value={preferenceOptions[preference.id]}
                  >
                    {Object.entries(preference.labels).map(([value, label]) => (
                      <FormControlLabel
                        key={value}
                        value={value}
                        control={<Radio />}
                        label={label}
                        disabled={disabled}
                      />
                    ))}
                  </RadioGroup>
                );
              }

              if (preference.type === 'number') {
                return (
                  <TextField
                    disabled={disabled}
                    value={preferenceOptions[preference.id] || null}
                    placeholder={preference.title}
                    onChange={(e) =>
                      setPreferenceOption(preference.id, Number(e.target.value))
                    }
                  ></TextField>
                );
              }

              return null;
            })();

            return (
              <Box
                display="flex"
                flexDirection="column"
                gap={0}
                key={preference.id}
              >
                <Typography variant="body2">{preference.title}</Typography>
                <Box display="flex" gap={1}>
                  <FormControl size={undefined} sx={{ height: '100%' }}>
                    {form}
                  </FormControl>
                </Box>
              </Box>
            );
          })}
        </Box>

        <Divider sx={{ my: 2 }} />

        <Box display="flex" justifyContent="flex-end">
          <Button onClick={handleSubmit}>Salvar configurações</Button>
        </Box>
      </Box>
    </Paper>
  );
}

async function loadCompanyConfig({
  organizationId,
  companyId,
  effectiveDate,
  setResponse,
  setWarning,
  setError,
  setDisabled,

  setPreferenceOptions,
}: {
  organizationId: string;
  companyId: string;
  effectiveDate: Dayjs;
  setResponse: (response: string) => void;
  setWarning: (warning: string) => void;
  setError: (error: string) => void;
  setDisabled: (disabled: boolean) => void;

  setPreferenceOptions: (options: {
    [key in PreferenceKey]: PreferenceValues<key>;
  }) => void;
}) {
  const resetAlerts = () => {
    setResponse('');
    setWarning('');
    setError('');
  };

  const setWithDefaultValues = () => {
    setPreferenceOptions(
      Object.keys(preferences).reduce(
        (acc, key) => ({
          ...acc,
          [key]: preferences[key as PreferenceKey].defaultValue,
        }),
        {} as { [key in PreferenceKey]: PreferenceValues<key> },
      ),
    );
  };

  const resetScreen = () => {
    resetAlerts();
    setWithDefaultValues();
    setDisabled(true);
  };

  const fetchMostRecentPayrollConfiguration = async (
    organizationId: string,
    companyId: string,
    effectiveDateStr: string,
  ): Promise<PayrollConfigurationEntry> => {
    return fetchGetCompanyPayrollConfiguration({
      pathParams: {
        organizationId: organizationId,
        companyId: companyId,
        effectiveDate: effectiveDateStr,
      },
    });
  };

  const processRetrievedInformation = (
    payrollConfiguration: PayrollConfigurationEntry,
    effectiveDateStr: string,
  ) => {
    if (payrollConfiguration.effectiveDate !== effectiveDateStr) {
      setUpForNewPayrollConfiguration();
      return;
    }

    setPreferenceOptions(
      (Object.keys(preferences) as PreferenceKey[]).reduce(
        (acc, key) => {
          const parent = payrollConfiguration[preferences[key].parent];

          if (!parent) {
            return {
              ...acc,
              [key]: preferences[key].defaultValue,
            };
          }

          return {
            ...acc,
            [key]:
              key in parent
                ? parent[key as keyof typeof parent]
                : preferences[key].defaultValue,
          };
        },
        {} as { [key in PreferenceKey]: PreferenceValues<key> },
      ),
    );

    setDisabled(false);

    setWarning('Você está alterando uma configuração existente.');
  };

  const setUpForNewPayrollConfiguration = () => {
    setWithDefaultValues();
    setDisabled(false);
    setWarning('Você está criando uma nova configuração.');
  };

  resetScreen();

  if (!companyId || !effectiveDate) {
    return;
  }

  const effectiveDateStr = effectiveDate?.toISOString().substring(0, 10);

  try {
    const payrollConfiguration: PayrollConfigurationEntry =
      await fetchMostRecentPayrollConfiguration(
        organizationId,
        companyId,
        effectiveDateStr,
      );
    processRetrievedInformation(payrollConfiguration, effectiveDateStr);
  } catch (error) {
    if (error.statusCode === 404) {
      setUpForNewPayrollConfiguration();
      return;
    }
    console.log(error);
    setError('Não foi possível carregar configuração da Companhia.');
  }
}

async function submitPreferences({
  organizationId,
  companyId,
  preferenceOptions,

  effectiveDate,
  setResponse,
  setWarning,
  setError,
}: {
  organizationId: string;
  companyId: string;
  preferenceOptions: {
    [key in PreferenceKey]: PreferenceValues<key>;
  };
  effectiveDate: Dayjs;
  setResponse: (response: string) => void;
  setWarning: (warning: string) => void;
  setError: (error: string) => void;
}) {
  const getConfiguration = async (
    organizationId: string,
    companyId: string,
    effectiveDate: Dayjs,
  ): Promise<PayrollConfigurationEntry | void> => {
    let payrollConfiguration: PayrollConfigurationEntry | undefined;
    try {
      payrollConfiguration = await fetchGetCompanyPayrollConfiguration({
        pathParams: {
          organizationId,
          companyId,
          effectiveDate: effectiveDate.toISOString().substring(0, 10),
        },
      });
    } catch (error) {
      console.log(error);
    }

    if (
      payrollConfiguration?.effectiveDate ===
      effectiveDate.toISOString().substring(0, 10)
    ) {
      return payrollConfiguration;
    }
  };

  const newConfigBody = preferencesKeys.reduce(
    (acc, key) => {
      if (preferences[key].parent === 'brPreferences') {
        return {
          ...acc,
          brPreferences: {
            ...acc.brPreferences,
            [key]: preferenceOptions[key],
          },
        };
      }

      if (preferences[key].parent === 'paymentConfig') {
        return {
          ...acc,
          paymentConfig: {
            ...acc.paymentConfig,
            [key]: preferenceOptions[key],
          },
        };
      }

      return acc;
    },
    {
      brPreferences: {} as { [key in PreferenceKey]: PreferenceValues<key> },
      paymentConfig: {} as { [key in PreferenceKey]: PreferenceValues<key> },
    },
  );

  const createNewPayrollConfiguration = () => {
    return fetchPostCompanyPayrollConfiguration({
      pathParams: {
        organizationId,
        companyId,
      },
      body: {
        effectiveDate: effectiveDate?.toISOString().substring(0, 10),
        modules: ['brazil'],
        ...newConfigBody,
      },
    });
  };

  const updatePayrollConfiguration = (version: number) => {
    return fetchPutCompanyPayrollConfiguration({
      pathParams: {
        organizationId,
        companyId,
        effectiveDate: effectiveDate?.toISOString().substring(0, 10),
      },
      body: {
        ...newConfigBody,
        version,
      },
    });
  };

  setResponse('');
  setWarning('');
  setError('');

  const configuration = await getConfiguration(
    organizationId,
    companyId,
    effectiveDate,
  );

  (configuration
    ? updatePayrollConfiguration(configuration.version)
    : createNewPayrollConfiguration()
  )
    .then(() => {
      setResponse('Preferências salvas com sucesso!');
    })
    .catch((err) => {
      setError(
        `${
          err.stack.message ?? err.message ?? 'Erro inesperado'
        }\n${JSON.stringify(err)}`,
      );
    });
}

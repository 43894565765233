import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import { useContractsPerson } from '@octopus/api';

import { MissingPersonIdAlert } from '../../modules/components/MissingPersonIdAlert';
import { PageAlert } from '../../modules/components/PageAlert';

import { ReceiptsCards } from './ReceiptsCards';
import { ReceiptsTable } from './ReceiptsTable';

export type ReceiptsProps = {
  organizationId: string;
  personId: string;
};

export function Receipts({ organizationId, personId }: ReceiptsProps) {
  const { data, isLoading, isError } = useContractsPerson(
    {
      pathParams: {
        organizationId,
        personId,
      },
      queryParams: {
        size: '1',
      },
    },
    {
      enabled: !!organizationId && !!personId,
    },
  );
  if (!personId) {
    return <MissingPersonIdAlert />;
  }
  if (isError) {
    return (
      <PageAlert
        message="Não foi possivel recuperar dados de contrato vinculados ao seu perfil."
        severity="error"
        showRetryMessage={false}
      />
    );
  }
  if (!isLoading && !data?.data?.length) {
    return (
      <PageAlert
        message="Seu perfil de acesso não possui contratos vinculados a essa organização."
        severity="warning"
        showRetryMessage={false}
      />
    );
  }
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor="background.paper"
      sx={(theme) => ({
        [theme.breakpoints.up('sm')]: {
          mx: 8,
        },
        [theme.breakpoints.up('md')]: {
          my: 7,
        },
        [theme.breakpoints.down('md')]: {
          paddingBottom: 12,
        },
      })}
    >
      <Box
        display="flex"
        flexDirection="column"
        maxWidth="800px"
        width="100%"
        gap={1.5}
        px={2.5}
      >
        <ReceiptsTitle />
        <ReceiptsContent
          organizationId={organizationId}
          contractId={data?.data?.[0]?.contractId}
        />
      </Box>
    </Box>
  );
}

function ReceiptsTitle() {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap={1.5}
      sx={(theme) => ({
        [theme.breakpoints.up('md')]: {
          py: 2,
        },
        [theme.breakpoints.down('md')]: {
          pb: 4,
          pt: 5,
        },
      })}
    >
      <ReceiptLongOutlinedIcon
        sx={(theme) => ({
          width: '40px',
          height: '40px',
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        })}
      />
      <Typography variant="h1" data-testid="receipts-title">
        Holerites e recibos
      </Typography>
    </Box>
  );
}

function ReceiptsContent({
  organizationId,
  contractId,
}: {
  organizationId: string;
  contractId: string | undefined;
}) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  if (isSmall) {
    return (
      <ReceiptsCards organizationId={organizationId} contractId={contractId} />
    );
  }
  return (
    <ReceiptsTable organizationId={organizationId} contractId={contractId} />
  );
}

import { useEffect } from 'react';

import { useLottie } from 'lottie-react';

import { Box } from '@mui/material';

import octopusLoadingJson from './octopusLoading.json';

export const OctopusLoading = () => {
  const options = {
    animationData: octopusLoadingJson,
    loop: true,
  };

  const { View, setSpeed } = useLottie(options);

  useEffect(() => {
    setSpeed(0.75);
  }, []);

  return (
    <Box
      sx={{
        filter: 'hue-rotate(216deg) saturate(100%)',
      }}
    >
      {View}
    </Box>
  );
};

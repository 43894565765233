import { Avatar, Box, Typography } from '@mui/material';

export type OrganizationAvaterProps = {
  name: string;
  logo?: string;
  size?: string;
};

export function OrganizationAvatar({
  name,
  logo,
  size = '32px',
}: OrganizationAvaterProps) {
  const orgLetter = name ? name.charAt(0).toUpperCase() : 'O';
  if (logo) {
    return (
      <Box
        minWidth={size}
        maxWidth={size}
        height={size}
        border="1px solid rgba(237, 237, 237, 1)"
        overflow="hidden"
        borderRadius={1}
      >
        <img src={logo} width="100%" height="100%" alt={orgLetter} />
      </Box>
    );
  }
  return (
    <Avatar
      sx={{
        width: size,
        height: size,
        border: '1px solid rgba(237, 237, 237, 1)',
      }}
      variant="rounded"
    >
      <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
        {orgLetter}
      </Typography>
    </Avatar>
  );
}

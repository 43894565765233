import React from 'react';

import { CheckCircle } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';

import { MobileDrawer } from '../../../../modules/components/onboarding/dialogs';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export function MissingFieldsDialog({ open, setOpen }: Props) {
  return (
    <MobileDrawer
      open={open}
      loading={false}
      // setOpen={setOpen}
      actions={
        <Button
          variant="outlined"
          color="primaryAlt"
          sx={{ padding: 1.25 }}
          onClick={() => setOpen(false)}
        >
          Fechar Alerta
        </Button>
      }
      content={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            padding: 1,
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Typography variant="h2">Preencha os dados</Typography>
          <Typography variant="body1">
            Atenção, você não preencheu os dados obrigatórios, verifique os
            itens ainda não marcados com icone <CheckCircle color="success" />.
          </Typography>
        </Box>
      }
    ></MobileDrawer>
  );
}

import { cnpj } from 'cpf-cnpj-validator';
import { z } from 'zod';

export const cnpjSchema = z
  .string()
  .regex(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/)
  .refine((val) => {
    try {
      return cnpj.isValid(val);
    } catch (_) {
      return false;
    }
  }, 'Invalid CNPJ');

import React, {
  ComponentProps,
  MouseEventHandler,
  ReactElement,
  useMemo,
} from 'react';
import { Link, LinkProps } from 'react-router-dom';

import {
  Box,
  CircularProgress,
  SxProps,
  Typography,
  TypographyVariant,
} from '@mui/material';

type SizeVariant = 'tiny' | 'small' | 'medium';
type SemanticVariant = 'highlight' | 'primary' | 'secondary' | 'tertiary';

type BaseButtonChild =
  | React.ReactNode
  | ReactElement<
      ComponentProps<typeof Button.Adornment>,
      typeof Button.Adornment
    >;

type BaseButtonProps = {
  children: BaseButtonChild | BaseButtonChild[];
  variantLayout?: SizeVariant;
  variantSemantic?: SemanticVariant;
  disabled?: boolean;
  isLoading?: boolean;
  sx?: SxProps;
  'data-testid'?: string;
};

type LinkButtonProps = BaseButtonProps & LinkProps;
type DefaultButtonProps = BaseButtonProps & React.HTMLProps<HTMLButtonElement>;
type ButtonProps = DefaultButtonProps | LinkButtonProps;

export function Button(props: ButtonProps) {
  const baseComponentProps:
    | {
        component: 'button';
        onClick?: MouseEventHandler<unknown>;
        'data-testid'?: string;
      }
    | {
        component: typeof Link;
        to: LinkProps['to'];
        'data-testid'?: string;
      } = !('to' in props)
    ? {
        component: 'button',
        onClick: props.onClick,
        'data-testid': props['data-testid'],
      }
    : {
        component: Link,
        to: props.to,
        'data-testid': props['data-testid'],
      };

  const sizeVariant = props.variantLayout || 'medium';
  const semanticVariant = props.variantSemantic || 'primary';

  const typographyVariant: TypographyVariant =
    sizeVariant === 'tiny'
      ? 'caption'
      : sizeVariant === 'small'
        ? 'body2'
        : sizeVariant === 'medium'
          ? 'body1'
          : 'body1';

  const commonStyleProps: SxProps = {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '0 0 auto',
    textDecoration: 'none',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    [`
      &:disabled,
      &[disabled]
    `]: {
      userSelect: 'none',
      pointerEvents: 'none',
      cursor: 'default',
    },
  };

  const variantStyleProps: SxProps = {
    ...(sizeVariant === 'tiny' && {
      padding: '8px 12px',
    }),
    ...(sizeVariant === 'small' && {
      padding: '8px 16px',
    }),
    ...(sizeVariant === 'medium' && {
      padding: '8px 20px',
    }),
    ...(semanticVariant === 'highlight' && {
      backgroundColor: '#0058DB',
      color: '#FFF',
      '&:hover': {
        opacity: 0.8,
      },
      '&:active': {
        opacity: 0.8,
        backgroundImage: 'linear-gradient(rgba(0,0,0,.2), rgba(0,0,0,.2))',
      },
      [`
        &:disabled,
        &[disabled]
      `]: {
        opacity: 0.5,
      },
    }),
    ...(semanticVariant === 'primary' && {
      backgroundColor: '#25252D',
      color: '#FFF',
      '&:hover': {
        opacity: 0.8,
      },
      '&:active': {
        opacity: 0.8,
        backgroundImage: 'linear-gradient(rgba(0,0,0,.2), rgba(0,0,0,.2))',
      },
      [`
        &:disabled,
        &[disabled]
      `]: {
        opacity: 0.5,
      },
    }),
    ...(semanticVariant === 'secondary' && {
      backgroundColor: '#FFF',
      border: '1px solid #EDEDED',
      color: '#616161',
      '&:hover': {
        opacity: 0.6,
      },
      '&:active': {
        opacity: 0.8,
      },
      [`
        &:disabled,
        &[disabled]
      `]: {
        opacity: 0.5,
      },
    }),
    ...(semanticVariant === 'tertiary' && {
      backgroundColor: 'transparent',
      color: '#0058DB',
      [`
        &:disabled,
        &[disabled]
      `]: {
        opacity: 0.5,
      },
    }),
  };

  const buttonStyleProps: SxProps = {
    ...commonStyleProps,
    ...variantStyleProps,
    ...props.sx,
  };

  const typographyStyleProps: SxProps = useMemo(
    () => ({
      display: 'inline-flex',
      alignItems: 'center',
      color: 'currentColor',
      ...(sizeVariant === 'tiny' && {
        marginLeft: '4px',
        marginRight: '4px',
      }),
      ...(sizeVariant === 'small' && {
        marginLeft: '8px',
        marginRight: '8px',
        fontWeight: 'normal',
      }),
      ...(sizeVariant === 'medium' && {
        marginLeft: '8px',
        marginRight: '8px',
      }),
    }),
    [sizeVariant],
  );

  const adornmentStyleProps: SxProps = useMemo(
    () => ({
      display: 'inline-flex',
      alignItems: 'center',
      color: 'currentColor',
      '& > *': {
        flex: '1 1 auto',
        maxHeight: '100%',
        maxWidth: '100%',
      },
      ...(sizeVariant === 'tiny' && {
        fontSize: '16px',
        width: '16px',
        height: '16px',
      }),
      ...(sizeVariant === 'small' && {
        fontSize: '16px',
        width: '16px',
        height: '16px',
      }),
      ...(sizeVariant === 'medium' && {
        fontSize: '24px',
        width: '24px',
        height: '24px',
      }),
    }),
    [sizeVariant],
  );

  const loadingSize =
    sizeVariant === 'tiny' ? 8 : sizeVariant === 'small' ? 16 : 24;

  const childByType = useMemo(
    () =>
      React.Children.toArray(props.children).reduce(
        (childByType, child) => {
          const isAddornment =
            child !== null &&
            typeof child === 'object' &&
            'type' in child &&
            child.type === Button.Adornment;

          if (isAddornment && childByType.contents.length === 0) {
            childByType.startAdornments.push(child);
          }

          if (isAddornment && childByType.contents.length > 0) {
            childByType.endAdornments.push(child);
          }

          if (!isAddornment) {
            childByType.contents.push(child);
          }

          return childByType;
        },
        {
          startAdornments: [] as React.PropsWithChildren['children'][],
          contents: [] as React.PropsWithChildren['children'][],
          endAdornments: [] as React.PropsWithChildren['children'][],
        },
      ),
    [props.children],
  );

  const content = (
    <>
      {childByType.startAdornments.length > 0 && (
        <Box component="span" sx={adornmentStyleProps}>
          {childByType.startAdornments}
        </Box>
      )}
      <Typography variant={typographyVariant} sx={typographyStyleProps}>
        {childByType.contents}
      </Typography>
      {childByType.endAdornments.length > 0 && (
        <Box component="span" sx={adornmentStyleProps}>
          {childByType.endAdornments}
        </Box>
      )}
    </>
  );

  const isDisabled = props.disabled || props.isLoading;

  return (
    <Box
      {...baseComponentProps}
      sx={buttonStyleProps}
      {...(isDisabled && { disabled: isDisabled })}
      {...('form' in props && { form: props.form })}
    >
      {props.isLoading ? (
        <CircularProgress size={loadingSize} sx={{ color: 'currentColor' }} />
      ) : (
        content
      )}
    </Box>
  );
}

type IButtonAdornmentProps = React.PropsWithChildren;
Button.Adornment = function ButtonAdornment(props: IButtonAdornmentProps) {
  return props.children;
};

import React, { useState } from 'react';

import { BeachAccessOutlined } from '@mui/icons-material';
import { Box, Container, Tab, Tabs, Typography } from '@mui/material';

import TabLabel from '../../../../modules/components/TabLabel';

import NextVacationsTab from './nextVacationsTab';
import {
  VacationSchedulerTabs,
  vacationScheduleTabsConfig,
} from './utils/types';
import VacationsHistoryTab from './vacationsHistoryTab';

export type VacationsProps = {
  organizationId: string | undefined;
  companyId: string | undefined;
};

type PageRenderProps = {
  TitleIcon: React.ElementType;
  Title: React.ElementType;
};

function VacationSchedulerPage({
  organizationId,
}: {
  organizationId: string | undefined;
}) {
  const [tab, setTab] = useState<keyof typeof vacationScheduleTabsConfig>(
    VacationSchedulerTabs.next,
  );

  const switchTab = (tab: string) => {
    setTab(tab as keyof typeof vacationScheduleTabsConfig);
  };

  const pageRenderProps: PageRenderProps = {
    Title: () => <> Férias </>,
    TitleIcon: BeachAccessOutlined,
  };

  return (
    <Box
      pt={9}
      height="100vh"
      sx={{
        backgroundColor: 'background.paper',
      }}
    >
      <Container maxWidth="lg">
        <Box
          data-testid={`payroll-type-header`}
          sx={{
            marginBottom: 5,
            display: 'flex',
            flexDirection: 'row',
            marginBotton: '100px',
          }}
        >
          <Box
            component={pageRenderProps.TitleIcon}
            sx={{ height: '40px', width: 'auto', mr: 1 }}
          />

          <Typography variant="h1">
            <pageRenderProps.Title />
          </Typography>
        </Box>
        <Container>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            data-testid="vacation-scheduler-header"
          >
            <Tabs
              value={tab}
              onChange={(_, newTab) => switchTab(newTab)}
              textColor="inherit"
              data-testid="vacation-scheduler-tabs"
              TabIndicatorProps={{
                sx: {
                  bgcolor: `primary`,
                },
              }}
              sx={{
                height: 'hug',
                width: '744px',
                pb: '24px',
              }}
            >
              {Object.entries(vacationScheduleTabsConfig).map(
                ([key, tabConfig]) => (
                  <Tab
                    key={key}
                    value={key}
                    icon={
                      <TabLabel
                        isSelected={key === tab}
                        color={'primary'}
                        label={tabConfig.label}
                      />
                    }
                    sx={{
                      ':hover': {
                        opacity: 0.75,
                      },
                    }}
                    data-testid={`vacation-sheduler-tab-${key}`}
                  />
                ),
              )}
            </Tabs>
          </Box>
          {tab === VacationSchedulerTabs.next ? (
            <NextVacationsTab organizationId={organizationId} />
          ) : null}
          {tab === VacationSchedulerTabs.history ? (
            <VacationsHistoryTab organizationId={organizationId} />
          ) : null}
        </Container>
      </Container>
    </Box>
  );
}

export default VacationSchedulerPage;

import React, { useEffect, useRef, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { PersonRemoveOutlined } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  IconButton,
  Popover,
  Skeleton,
  Switch,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';

import {
  SearchInput,
  fetchSearchAllContracts,
  useGetLegalEntities,
} from '@octopus/api';
import { contractTypes } from '@octopus/contract-types';
import {
  DataGrid,
  DataGridToolbar,
  FilterOptions,
  GridColDef,
  GridValueGetterParams,
  makeDateRangeFilter,
  makeElementListFilter,
  useDataGrid,
} from '@octopus/ui/data-grid';

import { ExpandableTypography } from '../../modules/components/ExpandableTypography';
import UserAvatar from '../../modules/components/UserAvatar';
import { DataFetching } from '../../modules/dataFetching';
import { StatusBadge } from '../../modules/people/person/components/StatusBadge';
import { prepareDataGridSearchInput } from '../../utils';

import { addTerminationExclusionToFilter } from './terminationFilter';

type PeopleTableState = {
  totals:
    | {
        totalCount: number;
        current: number;
      }
    | undefined;
  filteredCounters: {
    [key: string]: number;
  };
  filters: {
    titles: {
      id: string;
      name: string;
      count: number;
    }[];
    departments: {
      name: string;
      count: number;
    }[];
    contractTypes: {
      name: string;
      count: number;
    }[];
    legalEntities: {
      id: string;
      name: string;
    }[];
    companyId: {
      id: string;
      name: string;
    }[];
  };
};

const peopleGroupsConfig = {
  all: {
    key: 'all',
    label: 'Todos',
  },

  [contractTypes.brClt]: {
    key: contractTypes.brClt,
    label: 'Colaboradores',
  },

  [contractTypes.brPj]: {
    key: contractTypes.brPj,
    label: 'Prestadores de serviço',
  },
};

function addContractTypeFilter(
  searchInput: SearchInput,
  tab: 'br:clt' | 'br:pj',
) {
  if (!searchInput.filtering) {
    searchInput.filtering = {};
  }

  if (!searchInput.filtering.elements) {
    searchInput.filtering.elements = {};
  }

  searchInput.filtering.elements['contractType'] = [tab];
}

function addCompanyFilter(searchInput: SearchInput, companyId: string) {
  if (!searchInput.filtering.elements) {
    searchInput.filtering.elements = {};
  }
  if (companyId) {
    searchInput.filtering.elements['companyId'] = [companyId];
  }
}

export function PeopleTable({
  organizationId,
  companyId,
  onContractClick,
  showTerminated,
  setShowTerminated,
}: {
  organizationId: string | undefined;
  companyId: string | undefined;
  onContractClick: (contractId: string) => void;
  showTerminated: boolean;
  setShowTerminated: (value: boolean) => void;
}) {
  const initialState: PeopleTableState = {
    totals: undefined,
    filteredCounters: {},
    filters: {
      titles: [],
      departments: [],
      contractTypes: [],
      legalEntities: [],
      companyId: [],
    },
  };

  const [state, setState] = React.useState<PeopleTableState>(initialState);
  const filters = useFilters(state.filters);
  const dataGridProps = useDataGrid({
    filters,
  });

  const [tab, setTab] = useState<keyof typeof peopleGroupsConfig>('all');

  const { sortingProps, searchProps, paginationProps, filteringProps } =
    dataGridProps;

  const searchInput = prepareDataGridSearchInput(dataGridProps);
  addCompanyFilter(searchInput, companyId);

  if (tab !== 'all') {
    addContractTypeFilter(searchInput, tab);
  }

  const returnedContractTypes = Object.values(state.filters.contractTypes);

  if (!showTerminated) {
    addTerminationExclusionToFilter(searchInput);
  }

  searchInput.counting = {
    filtered: {
      byProp: {
        contractType: Object.values(contractTypes),
      },
    },
    unfiltered: {
      byProp: {
        contractType: Object.values(contractTypes),
      },
    },
  };

  const contractQuery = useQuery({
    queryKey: [organizationId, searchInput],
    refetchOnWindowFocus: false,
    queryFn: () => {
      return fetchSearchAllContracts({
        pathParams: {
          organizationId: organizationId ?? '',
        },
        body: searchInput,
      });
    },
    enabled: !!organizationId && !!companyId,
  });

  useEffect(() => {
    if (!contractQuery.isError && contractQuery.data) {
      // sums value for each contract type to get the Total tab
      // we can't just use current total as when there is a filtered contract type
      // current will be the value of that tab considering the filter (it is part of the query)
      // and unfilteredTotal won't be right
      const totalCount =
        Object.values(
          contractQuery.data.metadata?.filtered?.counters?.byProp[
            'contractType'
          ],
        )?.reduce((previousValue, currentValue) => {
          return previousValue + currentValue;
        }, 0) ?? 0;

      setState((state) => ({
        totals: {
          current: contractQuery.data.total,
          totalCount: totalCount,
        },
        filteredCounters: {
          all: totalCount,
          ...(contractQuery.data.metadata?.filtered?.counters?.byProp[
            'contractType'
          ] ?? {}),
        },
        filters: {
          ...state.filters,
          ...contractQuery.data.filters,
          showTerminated,
        },
      }));
    }
  }, [contractQuery.isError, contractQuery.data, showTerminated]);

  const legalEntitiesQuery = useGetLegalEntities(
    {
      pathParams: {
        organizationId: organizationId ?? '',
        companyId: companyId ?? '',
      },
    },
    {
      enabled: !!organizationId && !!companyId,
    },
  );

  useEffect(() => {
    if (!legalEntitiesQuery.isError && legalEntitiesQuery.data) {
      setState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          legalEntities:
            legalEntitiesQuery?.data?.data?.map((legalEntity) => ({
              id: legalEntity.legalEntityId,
              name: legalEntity.br.nomeFantasia,
            })) ?? [],
        },
      }));
    }
  }, [legalEntitiesQuery.isError, legalEntitiesQuery.data]);

  const switchTab = (tab: string) => {
    setState(initialState);
    setTab(tab as keyof typeof peopleGroupsConfig);
  };

  const totals = (() => {
    if (contractQuery.isFetching || contractQuery.isLoading) {
      return undefined;
    }

    const current = state?.totals?.current ?? 0;
    const total = state?.totals?.totalCount ?? 0;

    return {
      current,
      all: total,
    };
  })();

  const showCountOnTabs = () => {
    return totals?.all !== totals?.current;
  };

  return (
    <>
      <Box
        paddingBottom={1.5}
        display={'flex'}
        justifyContent={'space-between'}
      >
        <Box alignSelf="stretch" width={'100%'}>
          <DataGridToolbar
            filters={filters}
            searchProps={searchProps}
            filteringProps={filteringProps}
            totals={totals}
            typeOfResultLabel={'resultados'}
          />
        </Box>
      </Box>
      {returnedContractTypes.length > 1 && (
        <Box
          my={2}
          sx={(theme) => ({
            boxShadow: `0 -1px 0 ${theme.palette.strokes.light} inset`,
          })}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Tabs
            value={tab}
            onChange={(_, newTab) => switchTab(newTab)}
            textColor="inherit"
            TabIndicatorProps={{}}
            data-testid="people-contractType-tabs"
          >
            {Object.entries(peopleGroupsConfig)
              // remove empty contractTypes
              .filter(([config]) => {
                return (
                  returnedContractTypes.find(
                    (value) => value.name === config,
                  ) || config === 'all'
                );
              })
              .map(([key, value]) => (
                <Tab
                  key={key}
                  value={key}
                  icon={
                    <PeopleTabLabel
                      isSelected={key === tab}
                      config={value}
                      count={
                        key === 'all'
                          ? state.filteredCounters['all']
                          : state.filteredCounters[key] ?? 0
                      }
                      showCount={showCountOnTabs()}
                    />
                  }
                  data-testid={`people-contractType-tab-${key}`}
                />
              ))}
          </Tabs>
          <ActionMenu
            showTerminated={showTerminated}
            setShowTerminated={setShowTerminated}
          />
        </Box>
      )}
      <DataFetching
        fetchResult={contractQuery}
        Loading={() => {
          return (
            <Box display="flex" flexDirection="column" gap="8px" pt={1}>
              <Skeleton variant="rounded" height={300} width="100%" />
            </Box>
          );
        }}
        Data={({ data }) => {
          const response = data;

          return (
            <Box mt={2}>
              {response ? (
                <DataGrid
                  sortingProps={sortingProps}
                  paginationProps={paginationProps}
                  totalRowCount={response.total || 0}
                  getRowId={(row) => row.contractId}
                  rows={response.data}
                  columns={columnsByTab[tab]}
                  onRowClick={(params) =>
                    onContractClick(params.row.contractId)
                  }
                />
              ) : null}
            </Box>
          );
        }}
      />
    </>
  );
}

function useFilters({
  titles,
  departments,
  legalEntities,
}: PeopleTableState['filters']): FilterOptions {
  return [
    makeElementListFilter({
      label: 'Cargo',
      propertyToFilter: 'title',
      elements: titles.map(({ id }) => id),
      labels: titles.reduce(
        (acc, { id, name }) => {
          acc[id] = name;
          return acc;
        },
        {} as Record<string, string>,
      ),
    }),
    makeElementListFilter({
      label: 'Departamento',
      propertyToFilter: 'department',
      elements: departments.map(({ name }) => name),
    }),
    makeDateRangeFilter({
      label: 'Data de admissão',
      propertyToFilter: 'admissionDate',
    }),
    makeElementListFilter({
      label: 'Situação',
      propertyToFilter: 'status',
      elements: ['active', 'onLeave', 'terminated'],
      labels: {
        active: 'Ativo',
        onLeave: 'Afastado',
        terminated: 'Desligado / Inativo',
      },
      disableSearch: true,
      disableSelectAll: true,
      sortElements: false,
    }),
    ...(legalEntities.length > 1
      ? [
          makeElementListFilter({
            label: 'Empregador',
            propertyToFilter: 'legalEntityId.keyword',
            elements: legalEntities.map(({ id }) => id),
            labels: legalEntities.reduce(
              (acc, { id, name }) => {
                acc[id] = name;
                return acc;
              },
              {} as Record<string, string>,
            ),
          }),
        ]
      : []),
  ];
}

type PeopleTabLabelProps = {
  isSelected: boolean;
  config: (typeof peopleGroupsConfig)[keyof typeof peopleGroupsConfig];
  count: number;
  showCount: boolean;
};

function PeopleTabLabel({
  isSelected,
  config: { label },
  count,
  showCount,
}: PeopleTabLabelProps) {
  const fontWeight = isSelected ? 700 : 500;
  const color = isSelected ? 'primary.main' : 'text.secondary';

  return (
    <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
      <Typography variant="body1" fontWeight={fontWeight} color={color}>
        {label}
      </Typography>
      {showCount && count > 0 && (
        <Box
          paddingY={0.25}
          paddingX={1}
          alignItems={'center'}
          borderRadius={2}
          bgcolor={'strokes.secondary'}
          height={'20px'}
        >
          <Typography variant="caption" fontWeight={fontWeight} color={color}>
            {count}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

const nameColumn: GridColDef = {
  field: 'name',
  headerName: 'Nome',
  renderHeader: (params) => {
    return <Box ml={2}>{params.field}</Box>;
  },
  flex: 1,
  valueGetter: (params: GridValueGetterParams) => {
    return params.row.name;
  },
  renderCell: ({ value }) => {
    return (
      <UserAvatar
        name={value}
        expandNameOnHover={true}
        sx={{
          '--UserAvatar-name-max-width': '12.5em',
        }}
      />
    );
  },
};

const cargoColumn: GridColDef = {
  field: 'title',
  headerName: 'Cargo',
  flex: 1,
  valueGetter: (params: GridValueGetterParams) => {
    return params.row.titleName;
  },
  renderCell: ({ value }) => {
    return <ExpandableTypography>{value}</ExpandableTypography>;
  },
};

const departmentColumn: GridColDef = {
  field: 'department',
  headerName: 'Departamento',
  flex: 1,
  valueGetter: (params: GridValueGetterParams) => {
    return params.row.department;
  },
  renderCell: ({ value }) => {
    return <ExpandableTypography>{value}</ExpandableTypography>;
  },
};

const admissionDateColumn: GridColDef = {
  field: 'admissionDate',
  headerName: 'Admissão / Início',
  flex: 1,
  valueGetter: (params: GridValueGetterParams) => {
    return new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
      Date.parse(params.row.admissionDate),
    );
  },
};

const statusColumn: GridColDef = {
  field: 'status',
  headerName: 'Situação',
  valueGetter: (params: GridValueGetterParams) => {
    return {
      status: params.row.status,
      contractType: params.row.contractType,
    };
  },
  renderCell: ({ value }) => {
    const { status, contractType } = value;
    return <StatusBadge status={status} contractType={contractType} />;
  },
};

const contractTypeColumn: GridColDef = {
  field: 'contractType',
  headerName: 'Atuação',
  valueGetter: (params: GridValueGetterParams) => {
    return params.row.contractType === contractTypes.brClt
      ? 'Colaborador'
      : 'Prestador de serviço';
  },
  renderCell: ({ value }) => {
    return <ExpandableTypography>{value}</ExpandableTypography>;
  },
};

const columnsByTab: Record<keyof typeof peopleGroupsConfig, GridColDef[]> = {
  all: [
    nameColumn,
    cargoColumn,
    departmentColumn,
    admissionDateColumn,
    contractTypeColumn,
    statusColumn,
  ],
  'br:clt': [
    nameColumn,
    cargoColumn,
    departmentColumn,
    {
      ...admissionDateColumn,
      headerName: 'Data de admissão',
    },
    statusColumn,
  ],
  'br:pj': [
    nameColumn,
    cargoColumn,
    departmentColumn,
    {
      ...admissionDateColumn,
      headerName: 'Início de contrato',
    },
    statusColumn,
  ],
};

function ActionMenu({
  showTerminated,
  setShowTerminated,
}: {
  showTerminated: boolean;
  setShowTerminated: (value: boolean) => void;
}) {
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);
  return (
    <Box display={'flex'} justifyContent="flex-end" alignItems={'flex-start'}>
      <IconButton
        size="small"
        onClick={(event) => {
          setOpen(true);
          event.stopPropagation();
        }}
        ref={menuRef}
        sx={{
          borderRadius: '8px',
          padding: '4px',
        }}
      >
        <MoreVertIcon
          fontSize="inherit"
          sx={{
            width: '24px',
            height: '24px',
          }}
        />
      </IconButton>
      <Popover
        open={open}
        anchorEl={menuRef.current}
        onClick={(event) => event.stopPropagation()}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
      >
        <Box p={0.5} display={'flex'} alignItems={'center'}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            py={1}
            px={1.5}
            gap={2}
            alignItems={'center'}
          >
            <Box width={'16px'} height={'16px'} color={'text.secondary'}>
              <PersonRemoveOutlined />
            </Box>

            <Typography fontSize={'14px'} py={1} variant={'body1'}>
              Mostrar desligados e inativos
            </Typography>
          </Box>
          <Box px={1}>
            <Switch
              checked={showTerminated}
              onChange={() => {
                setShowTerminated(!showTerminated);
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}

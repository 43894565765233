import { useEffect, useState } from 'react';

import {
  fetchContractsPerson,
  fetchGetAdmissionDraftByUser,
  fetchGetAllCompanies,
  fetchGetUserIdentity,
  fetchGetUserMembership,
} from '@octopus/api';
import { admissionDraftStatuses } from '@octopus/onboarding-types';

import { membershipEquals } from '../../utils';
import { fetchMembershipOptions } from '../memberships/fetchMembershipOptions';
import {
  AdmissionContext,
  ChangeMembership,
  CompanyContext,
  ContractContext,
  IAppContext,
  IGetDataStateResult,
  MembershipContext,
  MembershipOption,
  SetAdmissionContext,
  SetCompanyContext,
  SetContractContext,
  UserContext,
} from '../types';

import { fetchCompanyContext } from './companyContextHelper';

export type AppContextProps = {
  onAuthFailure: () => void;
};

type AppContextState = {
  isLoading: boolean;
  isError: boolean;
  error?: string;
};

export type AppContextResult = AppContextState & {
  appContext: IAppContext | undefined;
  membershipOptions: MembershipOption[] | undefined;
  changeMembership: ChangeMembership;
};

export function useAppContext({
  onAuthFailure,
}: AppContextProps): AppContextResult {
  const [state, setState] = useState<AppContextState>({
    isLoading: true,
    isError: false,
  });
  const [userContext, setUserContext] = useState<UserContext | undefined>(
    undefined,
  );
  const [membershipContext, setMembershipContext] = useState<
    MembershipContext | undefined
  >(undefined);

  const [contractContext, setContractContext] = useState<
    ContractContext | undefined
  >(undefined);

  const [admissionContext, setAdmissionContext] = useState<
    AdmissionContext | undefined
  >(undefined);

  const [companyContext, setCompanyContext] = useState<
    CompanyContext | undefined
  >(undefined);

  const [membershipOptions, setMembershipOptions] = useState<
    MembershipOption[] | undefined
  >(undefined);

  useEffect(() => {
    initializeAppContext(
      setUserContext,
      setMembershipContext,
      setContractContext,
      setAdmissionContext,
      setCompanyContext,
      setMembershipOptions,
      setState,
    ).catch(() => {
      onAuthFailure();
    });
  }, []);

  useEffect(() => {
    if (userContext && membershipContext) {
      const { personId } = userContext;
      const { membershipType, organizationId, companyId } = membershipContext;

      setContextOnLocalStorage(
        userContext.userId,
        membershipContext,
        contractContext,
        admissionContext,
        companyContext,
      );

      if (membershipType === 'internal' || membershipType === 'owner') {
        fetchGetAdmissionDraftByUser({
          pathParams: {
            organizationId: organizationId,
            userId: userContext.userId,
          },
        })
          .then((draft) => {
            const draftId = draft.draftId;
            const newAdmissionContext =
              draft.draftStatus !== admissionDraftStatuses.archived
                ? { draftId }
                : null;
            console.log({ draft, newAdmissionContext });
            setAdmissionContext(newAdmissionContext);
            setContextOnLocalStorage(
              userContext.userId,
              membershipContext,
              contractContext,
              newAdmissionContext,
              companyContext,
            );
          })
          .catch((err) => {
            // it is expected to get 404 if no draft exists for that user
            console.log(err);
            setAdmissionContext(undefined);
            return null;
          });

        if (personId) {
          fetchContractsPerson({
            pathParams: {
              organizationId,
              personId,
            },
            queryParams: {
              size: '1',
            },
          }).then((result) => {
            if (result.size > 0) {
              setContractContext({
                contractId: result.data[0].contractId,
                contractType: result.data[0].contractType,
              });
            } else {
              setContractContext(undefined);
            }

            setContextOnLocalStorage(
              userContext.userId,
              membershipContext,
              contractContext,
              admissionContext,
              companyContext,
            );
          });
        } else {
          setContractContext(undefined);

          setContextOnLocalStorage(
            userContext.userId,
            membershipContext,
            contractContext,
            admissionContext,
            companyContext,
          );
        }
      }

      if (membershipType === 'tako:support' || membershipType === 'owner') {
        fetchCompanyContext({ organizationId, companyId }).then(
          (newCompanyContext) => {
            setCompanyContext(newCompanyContext);
            setContextOnLocalStorage(
              userContext.userId,
              membershipContext,
              contractContext,
              admissionContext,
              newCompanyContext,
            );
          },
        );
      }
    }
  }, [userContext, membershipContext]);

  return {
    ...state,
    appContext: userContext
      ? {
          user: userContext,
          membership: membershipContext,
          admission: admissionContext,
          contract: contractContext,
          company: companyContext,
        }
      : undefined,
    membershipOptions,
    changeMembership: (membership: MembershipContext) =>
      setMembershipContext({
        organizationId: membership?.organizationId,
        companyId: membership?.companyId,
        membershipType: membership?.membershipType,
      }),
  };
}

async function initializeAppContext(
  setUserContext: (user: UserContext) => void,
  setMembershipContext: ChangeMembership,
  setContractContext: SetContractContext,
  setAdmissionContext: SetAdmissionContext,
  setCompanyContext: SetCompanyContext,
  setMembershipOptions: (options: MembershipOption[]) => void,
  setState: (state: AppContextState) => void,
) {
  const user = await fetchGetUserIdentity({});
  setUserContext({
    userId: user.userId,
    email: user.email,
    name: user.name,
    personId: user.personId,
  });

  const membershipOptions = await fetchMembershipOptions(
    user.userId,
    user.personId,
  );
  setMembershipOptions(membershipOptions);
  const localStorageContext = getContextFromLocalStorage();
  if (
    !localStorageContext ||
    localStorageContext.userId !== user.userId ||
    !membershipOptions.some((option) =>
      membershipEquals(option, localStorageContext.membership),
    )
  ) {
    setMembershipContext(membershipOptions?.[0] ?? {});
    setState({
      isLoading: false,
      isError: false,
    });
  } else {
    setMembershipContext(localStorageContext.membership);
    setContractContext(localStorageContext.contract);
    setAdmissionContext(localStorageContext.admission);
    setCompanyContext(localStorageContext.company);
    setState({
      isLoading: false,
      isError: false,
    });
  }
}

async function getUserContext(): Promise<IGetDataStateResult<UserContext>> {
  try {
    const user = await fetchGetUserIdentity({});
    const userContext = {
      userId: user.userId,
      email: user.email,
      name: user.name,
      personId: user.personId,
    };

    return {
      state: {
        isError: false,
      },
      data: userContext,
    };
  } catch (error) {
    return {
      state: {
        isError: true,
        error,
      },
    };
  }
}

async function getMembershipContext(
  userId: string,
): Promise<IGetDataStateResult<MembershipContext>> {
  try {
    const membershipList = await fetchGetUserMembership({
      pathParams: { userId },
    });
    if (!membershipList.data.length) {
      return {
        state: {
          isError: true,
          error: 'User has no memberships',
        },
      };
    }
    const { organizationId, type: membershipType } = membershipList.data[0];
    const { data } = await fetchGetAllCompanies({
      pathParams: {
        organizationId: organizationId,
      },
      queryParams: {
        size: '1',
      },
    });
    const companyId = data?.[0]?.companyId;

    return {
      state: {
        isError: false,
      },
      data: {
        organizationId,
        companyId,
        membershipType,
      },
    };
  } catch (err) {
    return {
      state: {
        isError: true,
        error: err.message,
      },
    };
  }
}

export async function getAppContext(): Promise<
  IGetDataStateResult<{
    user: UserContext;
    membership: MembershipContext;
  }>
> {
  const userContext = await getUserContext();
  if (userContext.state.isError) {
    return {
      state: userContext.state,
    };
  } //TODO handle retry logic and UI feedback

  const membershipContext = await getMembershipContext(userContext.data.userId);
  if (membershipContext.state.isError) {
    return {
      state: membershipContext.state,
    };
  } //TODO handle retry logic and UI feedback

  return {
    state: {
      isError: false,
    },
    data: {
      user: userContext.data,
      membership: membershipContext.data,
    },
  };
}

const CONTEXT_ITEM_KEY = 'AppContext';

type LocalStorageContext = {
  userId: string;
  membership: MembershipContext;
  contract: ContractContext;
  admission: AdmissionContext;
  company: CompanyContext;
};

function getContextFromLocalStorage(): LocalStorageContext | undefined {
  const context = localStorage.getItem(CONTEXT_ITEM_KEY);
  if (!context) {
    return undefined;
  }
  try {
    return JSON.parse(context) as LocalStorageContext;
  } catch (err) {
    return undefined;
  }
}

function setContextOnLocalStorage(
  userId: string,
  membership: MembershipContext,
  contract?: ContractContext,
  admission?: AdmissionContext,
  company?: CompanyContext,
) {
  localStorage.setItem(
    CONTEXT_ITEM_KEY,
    JSON.stringify({
      userId,
      membership,
      contract,
      admission,
      company,
    }),
  );
}

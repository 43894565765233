import React, { ReactNode } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';

import {
  SearchInput,
  VacationsScheduleSummary,
  fetchSearchAllScheduledVacations,
} from '@octopus/api';

import VacationsRequestedCard from '../../../../modules/components/vacation-scheduler/vacationsRequestedCard';
import { DataFetching } from '../../../../modules/dataFetching';

function VacationsHistoryTab({
  organizationId,
}: {
  organizationId: string | undefined;
}): ReactNode {
  const { contractId } = useParams();
  const searchFilter: SearchInput = {
    filtering: {
      elements: {
        contractId: [contractId],
      },
    },
  };

  const vacationsScheduledQuery = useQuery({
    queryKey: [organizationId, contractId, 'proximas', 'solicitadas'],
    refetchOnWindowFocus: true,
    refetchOnMount: false,
    queryFn: () => {
      return fetchSearchAllScheduledVacations({
        pathParams: {
          organizationId: organizationId,
        },
        body: searchFilter,
      });
    },
    enabled: !!organizationId,
  });

  const [expandButtonState, setExpandButtonState] = React.useState(false);

  const handleExpandButtonClick = () => {
    setExpandButtonState(!expandButtonState);
  };

  return (
    <Box
      display={'flex'}
      sx={{
        width: '100%',
        flexDirection: 'column',
        py: 0,
      }}
    >
      <DataFetching
        fetchResult={vacationsScheduledQuery}
        Loading={() => {
          return (
            <Box display="flex" flexDirection="column" gap="8px" pt={1}>
              <Skeleton variant="rounded" height={300} width="100%" />
            </Box>
          );
        }}
        Data={({ data }) => {
          const response = data;
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {response.data.filter(hasVacationSucceeded).length > 0 ? (
                response.data.map(
                  (scheduleSummary: VacationsScheduleSummary) => {
                    return hasVacationSucceeded(scheduleSummary) ? (
                      <VacationsRequestedCard
                        scheduleSummary={scheduleSummary}
                        detailsMode={false}
                        historyMode={true}
                        detailsLink={`/vacations/${contractId}/requestDetails/${scheduleSummary.startDate}/${scheduleSummary.sequence}`}
                      />
                    ) : null;
                  },
                )
              ) : (
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: '450',
                    fontStyle: 'italic',
                  }}
                >
                  Você não possui férias passadas ainda.
                </Typography>
              )}
              {response.data.filter(shouldShowRequestAsCanceled).length > 0 ? (
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                  <ListItemButton
                    onClick={handleExpandButtonClick}
                    sx={{ padding: '0px' }}
                  >
                    <ListItemText sx={{ p: 0, pt: 1 }}>
                      <Typography
                        variant="body1"
                        sx={{
                          color: '#616161',
                          fontSize: '14px',
                          fontWeight: 500,
                          pb: 1,
                        }}
                      >
                        Recusadas e canceladas
                      </Typography>
                    </ListItemText>
                    {expandButtonState ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={expandButtonState} timeout="auto" unmountOnExit>
                    {response.data.map(
                      (scheduleSummary: VacationsScheduleSummary) => {
                        return shouldShowRequestAsCanceled(scheduleSummary) ? (
                          <VacationsRequestedCard
                            scheduleSummary={scheduleSummary}
                            detailsMode={false}
                            historyMode={true}
                            detailsLink={`/vacations/${contractId}/requestDetails/${scheduleSummary.startDate}/${scheduleSummary.sequence}`}
                          />
                        ) : null;
                      },
                    )}
                  </Collapse>
                </List>
              ) : null}
            </Box>
          );
        }}
      />
    </Box>
  );
}

function hasVacationSucceeded(scheduleSummary: VacationsScheduleSummary) {
  return (
    scheduleSummary.status === 'payrollApproved' &&
    dayjs(scheduleSummary.endDate).isAfter(dayjs())
  );
}
function shouldShowRequestAsCanceled(
  scheduleSummary: VacationsScheduleSummary,
): boolean {
  return ['rejected', 'canceled'].includes(scheduleSummary.status);
}
export default VacationsHistoryTab;

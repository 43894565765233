import { ReactElement } from 'react';

import { Alert, Box, Typography } from '@mui/material';

export type PageAlertProps = {
  message: string | ReactElement;
  severity: 'error' | 'warning' | 'info' | 'success';
  showRetryMessage: boolean;
  retryMessage?: string | undefined;
};

export function PageAlert({
  message,
  severity,
  showRetryMessage,
  retryMessage,
}: PageAlertProps) {
  const extraMessage =
    retryMessage ||
    'Por favor, tente novamente em alguns instantes. Se o problema persistir, entre em contato com o suporte da Tako.';
  return (
    <Box
      position="fixed"
      top="50%"
      left="50%"
      sx={{
        transform: 'translate(-50%, -50%)',
      }}
      minWidth="280px"
    >
      <Box pt={2} pb={4} data-testid="page-alert">
        <Alert severity={severity}>
          <Box>
            <Typography variant="body1" fontWeight="700">
              {message}
            </Typography>
            {showRetryMessage && (
              <Typography variant="body2" fontWeight="400">
                {extraMessage}
              </Typography>
            )}
          </Box>
        </Alert>
      </Box>
    </Box>
  );
}

import { useEffect, useMemo, useRef, useState } from 'react';

import { LocalDate } from '@js-joda/core';
import { z } from 'zod';

import {
  ArrowForward,
  BeachAccessOutlined,
  ChevronRight,
  PersonOutlineRounded,
  SettingsOutlined,
  Warning,
} from '@mui/icons-material';
import { Box, Container, Typography } from '@mui/material';

import { fetchSearchAllContracts } from '@octopus/api';
import { contractTypes } from '@octopus/contract-types';
import { AUTO_COMPLETE_OPTION_VALUE, UI_TYPE } from '@octopus/libs/forms';
import { Button, OctopusLoading } from '@octopus/ui/design-system';

import { BackButton } from '../../../modules/components/BackButton';
import { ErrorLabel } from '../../../modules/form/Field/commons/ErrorLabel';
import { Form } from '../../../modules/form/Form';
import { useFormFromDefinition } from '../../../modules/form/useFormFromDefinition';
import { MultiStepView } from '../../../modules/navigation/multiStep/MultiStepView';
import { useMultiStepView } from '../../../modules/navigation/multiStep/useMultiStepView';
import { findNearestBankingDate } from '../../../utils/dateUtils';
import VacationReceiptPage from '../[id]/page';

import { submitNewVacation } from './actions';
import { IVacationFormInputs, IVacationFormOutputs } from './form/types';
import { useQueryVacationFormOutputs } from './form/useQueryVacationFormOutputs';
import { useVacationFormEffects } from './form/useVacationFormEffects';
import { useVacationFormOverview } from './form/useVacationFormOverview';
import { VacationFormOverview } from './form/VacationFormOverview';
import { ActionBar } from './PageActionsBar';

type Props = {
  organizationId: string;
  companyId: string;
};

export function VacationInputPage(props: Props) {
  const newVacationMultiStepView = useMultiStepView({
    basicInfo: {
      title: 'Informações básicas',
    },
    iniciarCalculo: {
      title: 'Iniciar cálculo de férias',
    },
    preview: {
      errors: undefined,
      payrollId: undefined,
      isLoading: undefined,
    },
  });

  const isSubmissionFinishedRef = useRef(false);
  const [isSubmissionFinished, setIsSubmissionFinished] = useState(false);

  const onFirstStepSubmit = () => {
    newVacationMultiStepView.goTo('iniciarCalculo');
  };

  const onSecondStepSubmit = () => {
    setIsSubmissionFinished((isSubmissionFinishedRef.current = true));
  };

  const onOptionsSearch = async (
    fieldName: string,
    queryFieldName: string,
    queryFieldValue: string,
  ) => {
    if (fieldName !== 'contractId') return undefined;

    const contractors = await fetchSearchAllContracts({
      pathParams: {
        organizationId: props.organizationId,
      },
      body: {
        query: queryFieldValue.length > 0 ? queryFieldValue : undefined,
        pagination: {
          size: 20,
          page: 0,
        },
        sorting: {
          field: 'name',
          order: 'asc',
        },
        filtering: {
          elements: {
            companyId: [props.companyId],
            contractType: [contractTypes.brClt],
            status: ['active'],
          },
        },
      },
    });

    return (
      contractors.data?.map((contractor) => {
        return {
          textContent: `${contractor.name}`,
          content: {
            employeeName: `${contractor.name}`,
            employeeId: `${contractor.employeeId}`,
          },
          props: {
            key: contractor.contractId,
            value: contractor.contractId,
          },
        };
      }) || []
    );
  };

  const [formOutputs, setFormOutputs] = useState<IVacationFormOutputs>();
  const [formInputs, setFormInputs] = useState<IVacationFormInputs>();

  const formEfffects = useVacationFormEffects({
    outputs: formOutputs,
    inputs: formInputs,
  });

  const stepOneForm = useFormFromDefinition(
    [
      {
        label: 'Contrato',
        type: z.string(),
        name: 'contractId',
        uiType: UI_TYPE.SELECT,
        options: [
          {
            label: 'Nome do colaborador',
            name: 'employeeName',
            value: AUTO_COMPLETE_OPTION_VALUE,
          },
        ],
      },
      {
        label: 'Data de início',
        type: z.string(),
        name: 'startDate',
        uiType: UI_TYPE.TEXT_DATE_PICKER,
      },
    ],
    {
      id: 'newvacation1',
      persistLocal: false,
      onSubmit: onFirstStepSubmit,
      onOptionsSearch: onOptionsSearch,
    },
  );

  const stepTwoForm = useFormFromDefinition(
    [
      {
        label: 'Dias de férias',
        type: z
          .number()
          .max(
            formOutputs?.vacationInputsPreview?.br
              ?.feriasPorPeriodoAquisitivo[0]?.feriasMaximo
              ?.qtDiasTotalDisponiveis ?? Infinity,
          ),
        name: 'diasDeFerias',
        uiType: UI_TYPE.TEXT_NUMBER,
      },
      {
        label: 'Dias de abono',
        type: z.number().max(10),
        name: 'diasDeAbono',
        uiType: UI_TYPE.TEXT_NUMBER,
        value: 0,
      },
      {
        label: 'Pagar adiantamento do 13º',
        type: z.boolean().default(false),
        name: 'anticipate13th',
        uiType: UI_TYPE.SELECT_RADIO_BOOLEAN,
        options: [
          {
            value: true,
            label: 'Sim',
          },
          {
            value: false,
            label: 'Não',
            selected: true,
          },
        ],
      },
      {
        label: 'Descontar Faltas',
        type: z.boolean().default(false),
        name: 'discountLeaves',
        uiType: UI_TYPE.SELECT_RADIO_BOOLEAN,
        options: [
          {
            value: true,
            label: 'Sim',
          },
          {
            value: false,
            label: 'Não',
            selected: true,
          },
        ],
      },
      {
        label: 'Data de pagamento',
        type: z.string(),
        name: 'paymentDate',
        uiType: UI_TYPE.TEXT_DATE_PICKER,

        value: formOutputs?.vacationInputsPreview?.br?.inputs?.dataDeInicio
          ? findNearestBankingDate(
              LocalDate.parse(
                formOutputs.vacationInputsPreview.br.inputs.dataDeInicio,
              ).minusDays(2),
            )
          : '',
      },
    ],
    {
      id: 'newvacation2',
      persistLocal: false,
      onSubmit: onSecondStepSubmit,
    },
  );

  useEffect(
    function onAllSubmissionsComplete() {
      if (
        !isSubmissionFinished ||
        !isSubmissionFinishedRef.current ||
        !stepOneForm.submissionResult ||
        stepOneForm.submissionResult.status === 'error' ||
        !stepTwoForm.submissionResult ||
        stepTwoForm.submissionResult.status === 'error'
      ) {
        return;
      }

      setIsSubmissionFinished((isSubmissionFinishedRef.current = false));
      (async () => {
        newVacationMultiStepView.goTo('preview', {
          isLoading: true,
        });

        const createVacationActionResult = await submitNewVacation({
          companyId: props.companyId,
          organizationId: props.organizationId,
          ...stepOneForm.submission.payload,
          ...stepTwoForm.submission.payload,
        });

        const hasRequestError = 'error' in createVacationActionResult;
        const hasCalculationError =
          !hasRequestError &&
          Boolean(createVacationActionResult.data.errors?.length);
        const errors = hasRequestError
          ? [createVacationActionResult.error]
          : hasCalculationError
            ? createVacationActionResult.data.errors
            : null;

        if (hasRequestError || hasCalculationError) {
          // newVacationMultiStepView.goBack()
          return newVacationMultiStepView.goTo('iniciarCalculo', {
            errors: errors.map((error) =>
              typeof error === 'object' && 'message' in error
                ? error.message.toString()
                : error.toString(),
            ),
          });
        }

        const payrollId = createVacationActionResult.data.payrollId;

        if (payrollId) {
          newVacationMultiStepView.replaceCurrent('preview', {
            payrollId,
            isLoading: false,
          });
        }
      })();
    },
    [
      isSubmissionFinished,
      newVacationMultiStepView,
      props.companyId,
      props.organizationId,
      stepOneForm,
      stepTwoForm,
    ],
  );

  const inputs = useMemo(
    () => ({
      organizationId: props.organizationId,
      companyId: props.companyId,
      contractId: stepOneForm.payloadValue?.contractId?.toString(),
      employeeName:
        stepOneForm.metaValue.contractId_autocomplete_input?.toString(),
      startDate: stepOneForm.payloadValue?.startDate?.toString(),
      diasDeFerias: Number(stepTwoForm.payloadValue?.diasDeFerias ?? 0),
      diasDeAbono: Number(stepTwoForm.payloadValue?.diasDeAbono ?? 0),
      anticipate13th: Boolean(stepTwoForm.payloadValue?.anticipate13th),
      discountLeaves: Boolean(stepTwoForm.payloadValue?.discountLeaves),
      paymentDate: stepTwoForm.payloadValue?.paymentDate?.toString(),
    }),
    [
      props.organizationId,
      props.companyId,
      stepOneForm.payloadValue?.contractId,
      stepOneForm.payloadValue?.startDate,
      stepOneForm.metaValue.contractId_autocomplete_input,
      stepTwoForm.payloadValue?.diasDeFerias,
      stepTwoForm.payloadValue?.diasDeAbono,
      stepTwoForm.payloadValue?.anticipate13th,
      stepTwoForm.payloadValue?.discountLeaves,
      stepTwoForm.payloadValue?.paymentDate,
    ],
  );

  const outputsQueryResult = useQueryVacationFormOutputs({
    inputs,
  });

  useEffect(() => {
    if (!outputsQueryResult.data) return;
    setFormOutputs(outputsQueryResult.data);
  }, [outputsQueryResult.data]);

  useEffect(() => {
    setFormInputs(inputs);
  }, [inputs]);

  const vacationFormOverview = useVacationFormOverview({
    inputs: inputs,
    outputsQueryResult,
  });

  const currentStepData = newVacationMultiStepView.currentStep.data;
  const { isLoading } = currentStepData;
  const firstStep = newVacationMultiStepView.currentStep.isFirst;
  const backButtonLabel = firstStep ? 'Cancelar' : 'Voltar';

  const onBackClick = (reset: boolean) => {
    if (reset) {
      stepOneForm.reset();
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
      }}
    >
      {!isLoading && (
        <BackButton
          label={backButtonLabel}
          beforeClick={() => onBackClick(firstStep)}
        />
      )}
      <Container
        sx={{
          display: 'flex',
          minHeight: '100svh',
        }}
      >
        <MultiStepView multiStepView={newVacationMultiStepView}>
          <MultiStepView.Step name="basicInfo">
            <Box
              sx={{
                boxSizing: 'border-box',
                paddingBlock: 9,
                width: '100%',
                maxWidth: '522px',
                margin: 'auto auto',
              }}
            >
              <Box
                sx={{
                  marginBottom: 2,
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Box
                  component={BeachAccessOutlined}
                  sx={{ height: '30px', width: 'auto', mr: 1 }}
                />

                <Typography variant="h2">Férias</Typography>
              </Box>
              <VacationFormStepLabel
                totalSteps={newVacationMultiStepView.steps.length}
                currentStep={newVacationMultiStepView.currentStep.idx + 1}
                label={currentStepData.title}
              />

              <Form for={stepOneForm}>
                <Form.Layout>
                  <Form.Field for={stepOneForm.fields.contractId} />
                  <Form.Field for={stepOneForm.fields.startDate} />
                </Form.Layout>
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    paddingTop: 4,
                  }}
                >
                  <Button
                    sx={{
                      marginLeft: 'auto',
                      display: 'inline-flex',
                      alignItems: 'center',
                    }}
                    variantSemantic="primary"
                    variantLayout="small"
                    type="submit"
                    form={stepOneForm.id}
                  >
                    Avançar
                    <Button.Adornment>
                      <ArrowForward />
                    </Button.Adornment>
                  </Button>
                </Box>
              </Form>
            </Box>
          </MultiStepView.Step>
          <MultiStepView.Step name="iniciarCalculo">
            <Box
              sx={{
                boxSizing: 'border-box',
                paddingBlock: 9,
                width: '100%',
                padding: '96px 108px',
                margin: 'auto auto',
              }}
            >
              <Box
                sx={{
                  marginBottom: 2,
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Box
                  component={BeachAccessOutlined}
                  sx={{ height: '30px', width: 'auto', mr: 1 }}
                />

                <Typography variant="h2">Férias</Typography>
              </Box>
              <VacationFormStepLabel
                totalSteps={newVacationMultiStepView.steps.length}
                currentStep={newVacationMultiStepView.currentStep.idx + 1}
                label={currentStepData.title}
              />
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gap: '80px',
                }}
              >
                <Box>
                  <Typography
                    variant="body2"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '22px',
                    }}
                  >
                    <SettingsOutlined
                      sx={{ width: '16px', marginRight: '8px' }}
                    />
                    Parâmetros das férias
                  </Typography>
                  <Form for={stepTwoForm} />
                  <Form.Layout>
                    <Form.Field for={stepTwoForm.fields.diasDeFerias} />
                    <Form.Field for={stepTwoForm.fields.diasDeAbono} />
                    <Form.Field for={stepTwoForm.fields.anticipate13th} />
                    <Form.Field for={stepTwoForm.fields.discountLeaves} />
                    <Form.Field for={stepTwoForm.fields.paymentDate} />
                  </Form.Layout>
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '22px',
                    }}
                  >
                    <PersonOutlineRounded
                      sx={{ width: '16px', marginRight: '8px' }}
                    />
                    Informações do colaborador
                  </Typography>
                  <FormGlobalMessages effects={formEfffects} />
                  <VacationFormOverview {...vacationFormOverview} />
                </Box>

                {!currentStepData?.isLoading &&
                  currentStepData?.errors?.length &&
                  currentStepData?.errors?.map((error: string) => (
                    <ErrorLabel errorMessage={error} />
                  ))}
              </Box>
              <ActionBar>
                <ActionBar.Action variantSemantic="secondary" to="/vacations">
                  Cancelar
                </ActionBar.Action>
                <ActionBar.Action type="submit" form={stepTwoForm.id}>
                  Gerar prévia
                  <ActionBar.Action.Adornment>
                    <ArrowForward />
                  </ActionBar.Action.Adornment>
                </ActionBar.Action>
              </ActionBar>
            </Box>
          </MultiStepView.Step>
          <MultiStepView.Step name="preview">
            {currentStepData.isLoading && (
              <Box
                sx={{
                  margin: 'auto auto',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    svg: {
                      margin: '0 auto',
                      height: '156px!important',
                      width: '156px!important',
                    },
                  }}
                >
                  <OctopusLoading />
                </Box>
                <Typography
                  variant="h4"
                  sx={{
                    margin: '0 auto',
                    maxWidth: '340px',
                    textAlign: 'center',
                  }}
                >
                  Estamos fazendo os cálculos, aguarde alguns segundos...
                </Typography>
              </Box>
            )}
            {!currentStepData.isLoading && currentStepData.payrollId && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <VacationReceiptPage
                  organizationId={props.organizationId}
                  companyId={props.companyId}
                  payrollId={currentStepData.payrollId}
                />
              </Box>
            )}
          </MultiStepView.Step>
        </MultiStepView>
      </Container>
    </Box>
  );
}

function VacationFormStepLabel({
  label,
  currentStep,
  totalSteps,
}: {
  label: string;
  currentStep: number;
  totalSteps: number;
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
        marginBottom: '26px',
      }}
    >
      <Typography variant="body2">{label}</Typography>
      <ChevronRight
        sx={{
          width: '12px',
          fill: (theme) => theme.palette.strokes.heavy,
        }}
      />
      <Typography
        variant="body2"
        sx={{
          color: (theme) => theme.palette.strokes.heavy,
        }}
      >
        Passo {currentStep} de {totalSteps - 1}
      </Typography>
    </Box>
  );
}

function FormGlobalMessages({
  effects,
}: {
  effects: ReturnType<typeof useVacationFormEffects>;
}) {
  if (!effects?.global?.warn?.length) return null;

  const hasMoreThanOneWarning = effects?.global?.warn.length > 1;

  return (
    <Box
      sx={{
        background: '#FEF7F1',
        borderRadius: '8px',
        padding: '12px',
        marginBottom: '22px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '24px',
          alignItems: 'center',
        }}
      >
        <Warning
          sx={{
            paddingLeft: '12px',
            width: '16px',
            fill: '#93500B',
            flex: '0 0 auto',
            ...(hasMoreThanOneWarning && {
              alignSelf: 'flex-start',
            }),
            ...(!hasMoreThanOneWarning && {
              alignSelf: 'center',
            }),
          }}
        />
        <Box
          component="ul"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            padding: 0,
            paddingInlineStart: '1em',
            margin: 0,
            ...(!hasMoreThanOneWarning && {
              listStyle: 'none',
            }),
          }}
        >
          {effects?.global?.warn.map((warning) => (
            <Typography
              component="li"
              variant="caption"
              sx={{
                width: '100%',
                flex: '1 0 auto',
              }}
            >
              {warning.message}
            </Typography>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

import { useContext } from 'react';

import { Box, Divider, Typography } from '@mui/material';

import { formatDateBR } from '@octopus/formatters';
import { Button } from '@octopus/ui/design-system';

import ContractTypeTag from '../../../modules/components/contracts/ContractTypeTag';
import { CompanyContext } from '../../../modules/types';
import { AppContext } from '../../context';

import { AdmissionFormState } from './form/types';

type Props = {
  disabled: boolean;
  formState: AdmissionFormState;
  onSubmit: () => void;
  submitLabel: string;
  isLoading: boolean;
};

export const NewAdmissionReview = ({
  disabled,
  formState,
  onSubmit,
  submitLabel,
  isLoading,
}: Props) => {
  const { appContext } = useContext(AppContext);
  const { company: companyContext } = appContext;
  const tableItems = getTableItems({ formState, companyContext });

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 1.5,
        border: `1px solid ${theme.palette.strokes.light}`,
        paddingTop: 4,
        paddingBottom: 3,
        px: 4,
        minWidth: '480px',
      })}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Typography variant="h4">Resumo</Typography>
        <Divider sx={{ my: 2 }} />
      </Box>
      <Box
        component={'table'}
        width="100%"
        style={{
          borderSpacing: 12,
          marginLeft: '-8px',
        }}
      >
        <thead></thead>
        <tbody style={{ width: '100%' }}>
          {tableItems.map(({ label, value }) => (
            <tr key={label} style={{ width: '100%' }}>
              <td style={{ width: '50%' }}>
                <Typography
                  sx={{ color: 'text.secondary' }}
                  variant="body2"
                  fontWeight="normal"
                >
                  {label}
                </Typography>
              </td>
              <td style={{ width: '50%' }}>
                <Typography variant="body2">{value}</Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </Box>
      <Button
        onClick={onSubmit}
        variantSemantic="primary"
        sx={{ width: '100%', mt: 2 }}
        isLoading={isLoading}
        disabled={disabled || isLoading}
      >
        {submitLabel}
      </Button>
    </Box>
  );
};

const getTableItems = ({
  formState,
  companyContext,
}: {
  formState: AdmissionFormState;
  companyContext: CompanyContext;
}) => {
  return [
    {
      label: 'Nome completo',
      value: formState.user_name,
    },
    {
      label: 'Data de admissão',
      value: formatDateBR(formState.admissionDate),
    },
    {
      label: 'Tipo de regime trabalhista',
      value: <ContractTypeTag contractType={formState.contractType} />,
    },
    {
      label: 'Cargo',
      value:
        companyContext?.jobTitles?.find((j) => j.id === formState.cargo)
          ?.name ?? formState.cargo,
    },
    {
      label: 'Departamento',
      value: formState.departamento,
    },
    {
      label: 'Centro de custo',
      value:
        companyContext?.costCenters?.find(
          (cc) => cc.id === formState.centro_de_custo,
        )?.name ?? formState.centro_de_custo,
    },
    {
      label: 'Salário base',
      value: `R$ ${formState.salary}`,
    },
    {
      label: 'Email pessoal',
      value: formState.user_email,
    },
    {
      label: 'Empregador',
      value:
        companyContext?.legalEntities?.find(
          (le) => le.id === formState.legalEntity,
        )?.name ?? formState.legalEntity,
    },
    {
      label: 'Sindicato',
      value: formState.cnpjSindCategProf,
    },
  ];
};

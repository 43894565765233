const finais = {
  // eSocial1020
  comissoes: 'comissoes',

  // eSocial1000
  salario: 'salario',

  // eSocial5050
  atrasos: 'atrasos',

  // padrao9208a
  descontoDeBancoDeHoras: 'descontoDeBancoDeHoras',

  // eSocial1100
  horasExtras: 'horasExtras',

  // eSocial1101
  horasExtrasNoturnas: 'horasExtrasNoturnas',

  // eSocial1150
  horasInterjornada: 'horasInterjornada',

  // eSocial1140
  horasIntrajornada: 'horasIntrajornada',

  // eSocial1030
  horasSobreaviso: 'horasSobreaviso',

  // eSocial1120
  horasExtrasBancoDeHoras: 'horasExtrasBancoDeHoras',

  //padrao1050b
  horasFaltasJustificadas: 'horasFaltasJustificadas',

  // esocial1050
  faltasJustificadas: 'faltasJustificadas',

  faltasInjustificadas: {
    // eSocial5060
    sobreSalario: 'faltas.sobreSalario',
    // eSocial5070
    sobreDSR: 'faltas.sobreDSR',
  },

  diasAfastadosSemRemuneracao: {
    provento: 'diasAfastadosSemRemuneracao.provento',
    desconto: 'diasAfastadosSemRemuneracao.desconto',
  },

  // padrao1050a
  faltasJustificadasAntesDeAfastamentoPrevidenciario:
    'faltasJustificadasAntesDeAfastamentoPrevidenciario',

  salarioMaternidade: {
    // eSocial1700
    pagoPeloEmpregador: 'salarioMaternidade.pagoPeloEmpregador',

    // eSocial1701
    pagoPeloInss: 'salarioMaternidade.pagoPeloInss',

    // eSocial1710
    decimoTerceiro: 'salarioMaternidade.decimoTerceiro',

    // eSocial1711
    decimoTerceiroPagoPeloInss: 'salarioMaternidade.decimoTerceiroPagoPeloInss',
  },

  dsr: {
    // eSocial1213
    sobreComissoes: 'dsr.sobreComissoes',

    // eSocial1211
    sobreAdicionalNoturno: 'dsr.sobreAdicionalNoturno',

    // eSocial1210
    sobreHorasExtras: 'dsr.sobreHorasExtras',

    // padrao1002a
    sobreHorasInterjornada: 'dsr.sobreHorasInterjornada',

    // padrao1002b
    sobreHorasIntrajornada: 'dsr.sobreHorasIntrajornada',
  },

  // eSocial1130
  adicionalNoturno: 'adicionalNoturno',

  // eSocial1300
  adicionalDeInsalubridade: 'adicionalDeInsalubridade',

  // eSocial1320
  adicionalPorTempoDeServico: 'adicionalPorTempoDeServico',

  prolabore: {
    // eSocial2130
    socios: 'prolabore.socios',

    // eSocial2131
    diretoresNaoEmpregados: 'prolabore.diretoresNaoEmpregados',
  },

  estagio: {
    // eSocial2100
    bolsa: 'estagio.bolsa',

    // padrao1350a
    recessoRemunerado: 'estagio.recessoRemunerado',

    // eSocial5080
    faltasEAtrasos: 'estagio.faltasEAtrasos',
  },

  ferias: {
    // eSocial1900
    dias: 'ferias.dias',

    // eSocial1901
    tercoConstitucional: 'ferias.tercoConstitucional',

    // Abono Pecuniário = "venda de férias"
    // eSocial1930
    abonoPecuniario: 'ferias.abonoPecuniario',
    // eSocial1940
    tercoSobreAbono: 'ferias.tercoSobreAbono',

    // eSocial1950
    dobro: 'ferias.dobro',

    // Férias que são recalculadas durante a folha regular,
    // ao final do mês. Isso serve para fazer a compensação
    // de qualquer premissa que tenha mudado (como salário
    // ou média de comissões).
    // eSocial1910
    tiradasNoMes: 'ferias.tiradasNoMes',
    // eSocial1920
    tercoSobreTiradasNoMes: 'ferias.tercoSobreTiradasNoMes',

    // Soma de todos os valores que já foram pagos nos recibos
    // de férias que impactam aquela folha regular.
    // eSocial5030
    valorJaPago: 'ferias.valorJaPago',

    // 9205 Provisão de contribuição previdenciária
    //   Desconto efetuado em recibos de férias relativo
    //   a provisão de contribuição previdenciária
    // eSocial5182
    provisaoDeInss: 'ferias.provisaoDeInss',

    // natureza 1623	Ressarcimento de provisão
    //   Ressarcimento de desconto efetuado em
    //    recibos de férias relativo a provisão de
    //    contribuição previdenciária
    // eSocial1984 - mas o nome é ruim
    ressarcimentoDeProvisaoDeInss: 'ferias.ressarcimentoDeProvisaoDeInss',
  },

  decimoTerceiro: {
    parcelas: {
      // eSocial1800
      primeira: 'decimoTerceiro.parcelas.primeira',

      // eSocial1810
      segunda: 'decimoTerceiro.parcelas.segunda',

      // eSocial1820
      ajustePositivo: 'decimoTerceiro.parcelas.ajustePositivo',
      // eSocial5048
      ajusteNegativo: 'decimoTerceiro.parcelas.ajusteNegativo',

      // eSocial5040
      descontoDoAdiantamento: 'decimoTerceiro.parcelas.descontoDoAdiantamento',
    },

    // FGTS é sempre FGTS né, e não vai pro eSocial
    // FIXME: parece que nem existe esse element aqui
    fgts: 'decimoTerceiro.fgts',
  },

  irrf: {
    // eSocial5190
    remuneracaoMensal: 'irrf.remuneracaoMensal',

    // eSocial5191
    ferias: 'irrf.ferias',

    // eSocial5192
    decimoTerceiro: 'irrf.decimoTerceiro',
  },

  inss: {
    // eSocial5180
    mensal: 'inss.mensal',

    // eSocial5181
    decimoTerceiro: 'inss.decimoTerceiro',

    // não vai pro eSocial, só custo total
    patronal: 'inss.patronal',
    rat: 'inss.rat',
    outrasEntidades: 'inss.outrasEntidades',
    salarioContribuicao: 'inss.salarioContribuicao',
  },

  fgts: 'fgts',

  // eSocial1840
  insuficienciaDeSaldo: 'insuficienciaDeSaldo',
  // padrao2930a
  descontoDeSaldoDevedor: 'descontoDeSaldoDevedor',

  rescisao: {
    // eSocial3000
    saldoDeSalario: 'rescisao.saldoDeSalario',

    // eSocial3030
    avisoPrevioIndenizado: 'rescisao.avisoPrevioIndenizado',

    // eSocial5000
    avisoPrevioDescontado: 'rescisao.avisoPrevioDescontado',

    ferias: {
      // eSocial3050
      proporcionais: 'rescisao.ferias.proporcionais',
      // eSocial3065
      sobreAvisoPrevioIndenizado: 'rescisao.ferias.sobreAvisoPrevioIndenizado',

      // eSocial3060
      vencidas: 'rescisao.ferias.vencidas',

      // eSocial3040
      vencidasEmDobro: 'rescisao.ferias.vencidasEmDobro',

      // eSocial3070
      tercoConstitucional: 'rescisao.ferias.tercoConstitucional',
    },

    // não vai pro eSocial
    multaFgts: 'rescisao.multaFgts',
    fgts: 'rescisao.fgts',

    // eSocial3080
    indenizacaoDataBaseSindicato: 'indenizacaoDataBaseSindicato',

    decimoTerceiro: {
      // eSocial3020
      proporcional: 'rescisao.decimoTerceiro.proporcional',

      // eSocial3010
      sobreAvisoPrevioIndenizado:
        'rescisao.decimoTerceiro.sobreAvisoPrevioIndenizado',

      // eSocial5029
      adiantado: 'rescisao.decimoTerceiro.adiantado',
    },

    // eSocial3100
    indenizacaoArtigo479: 'rescisao.indenizacaoArtigo479',

    // eSocial5010
    indenizacaoArtigo480: 'rescisao.indenizacaoArtigo480',

    estagio: {
      // padrao1350a
      recessoRemunerado: 'rescisao.estagio.recessoRemunerado',
    },
  },

  adiantamentoSalarial: {
    // eSocial1040
    comIncidenciaDeIR: 'adiantamentoSalarial.comIncidenciaDeIR',

    // padrao5501a
    semIncidenciaDeIR: 'adiantamentoSalarial.semIncidenciaDeIR',

    // eSocial5020
    descontoComIncidenciaDeIR: 'adiantamentoSalarial.descontoComIncidenciaDeIR',

    // eSocial5098
    descontoSemIncidenciaDeIR: 'adiantamentoSalarial.descontoSemIncidenciaDeIR',
  },

  pensaoAlimenticia: {
    // eSocial5110
    mensal: 'pensaoAlimenticia.mensal',

    // eSocial5111
    decimoTerceiro: 'pensaoAlimenticia.decimoTerceiro',

    // eSocial5112
    ferias: 'pensaoAlimenticia.ferias',
  },

  // Nada disso vai pro eSocial
  provisoes: {
    provisoes: 'provisoes',
    direitosTrabalhistas: {
      feriasProporcionais: 'provisoes.direitosTrabalhistas.feriasProporcionais',
      tercoSobreFerias: 'provisoes.direitosTrabalhistas.tercoSobreFerias',
      decimoTerceiroProporcional:
        'provisoes.direitosTrabalhistas.decimoTerceiroProporcional',
    },
    fgts: {
      fgtsSobreDireitosTrabalhistas:
        'provisoes.fgts.fgtsSobreDireitosTrabalhistas',
      multaSobreRemuneracao: 'provisoes.fgts.multaSobreRemuneracao',
      multaSobreDireitosTrabalhistas:
        'provisoes.fgts.multaSobreDireitosTrabalhistas',
    },
    inssSobreDireitosTrabalhistas: {
      inssPatronal: 'provisoes.inssSobreDireitosTrabalhistas.inssPatronal',
      inssRat: 'provisoes.inssSobreDireitosTrabalhistas.inssRat',
      inssOutrasEntidades:
        'provisoes.inssSobreDireitosTrabalhistas.inssOutrasEntidades',
    },
  },
};

const informativas = {
  // esocial1740
  salarioBaseAfastamentoPrevidenciario: 'salarioBaseAfastamentoPrevidenciario',

  // esocial1745
  salarioBaseAfastamentoPrevidenciario13o:
    'salarioBaseAfastamentoPrevidenciario13o',

  irrfDeducaoDependentes: {
    mensal: 'irrfDeducaoDependentes.mensal',
    decimoTerceiro: 'irrfDeducaoDependentes.decimoTerceiro',
    ferias: 'irrfDeducaoDependentes.ferias',
  },

  irrfDeducaoInss: {
    mensal: 'irrfDeducaoInss.mensal',
    ferias: 'irrfDeducaoInss.ferias',
  },
};

export const elementsIdsPublicos = {
  finais,
  informativas,
} as const;

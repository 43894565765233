import React from 'react';

import { z } from 'zod';

import {
  AdmissionDraftInputFormSteps,
  AdmissionDraftStatus,
  AdmissionType,
} from '@octopus/api';
import { banksList } from '@octopus/contract-types';
import {
  CBOs,
  Categorias,
  ContratoRegimeParcial,
  EstadoCivil,
  Estados,
  GrauInstrucao,
  IndicativoAdmissao,
  Municipios,
  Nacionalidades,
  Paises,
  RacaCor,
  Sexo,
  TipoAdmissao,
  TipoContrato,
  TipoJornada,
  TipoLogradouro,
  TipoRegimeJornada,
  TipoRegimePrevidenciario,
  TipoRegimeTrabalho,
  UnidadeSalarioFixo,
} from '@octopus/esocial/mapper';
import {
  BrasilAmountType,
  IFormDefinition,
  NEW_UI_TYPE,
  UI_TYPE,
  cepSchema,
  cnpjSchema,
  cpfSchema,
  dateYearMonthSchema,
  phoneSchema,
} from '@octopus/libs/forms';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';
import { codCategParaTipoDeFuncionario } from '@octopus/payroll-engine/public-types/brazil';

import {
  PixKeyType,
  pixKeyLabels,
  pixKeyTypes,
} from '../../../../modules/payrolls/parser';
import { CompanyContext } from '../../../../modules/types';
import { AddNewDependent } from '../dependentes/NewAdmissionDependents';
import { NewAdmissionReview } from '../NewAdmissionReview';

import {
  getOptionsFromCompanyContext,
  getOptionsFromList,
  getOptionsFromMapper,
} from './inputUtils';
import {
  AdmissionFormState,
  AdmissionFormSteps,
  FormStepDefinition,
} from './types';

const ENABLED_CODIGO_CATEGORIA = new Set(
  Object.keys(codCategParaTipoDeFuncionario),
);

export const getAdminSteps = ({
  draftStatus,
  formState,
  companyContext,
  formSteps,
  isWorkerExperience,
}: {
  draftStatus: AdmissionDraftStatus;
  formState: AdmissionFormState;
  companyContext: CompanyContext;
  formSteps: AdmissionDraftInputFormSteps;
  isWorkerExperience: boolean;
}): AdmissionFormSteps => {
  if (
    !companyContext ||
    !companyContext.costCenters ||
    !companyContext.jobTitles ||
    !companyContext.legalEntities
  ) {
    return {};
  }

  const {
    contractType,
    legalEntity,
    admissionType,
    user_name,
    user_email,
    workerId,
    emailCorp,
    matricula,
    centro_de_custo,
    departamento,
    admissionDate,
    tpRegTrab,
    tpAdmissao,
    tpRegPrev,
    cargo,
    cargoConfianca,
    indAdmissao,
    nrProcTrab,
    tpRegJor,
    tpJornada,
    horNoturno,
    qtdHrsSem,
    tmpParc,
    codCateg,
    descJorn,
    tpContr,
    cnpjSindCategProf,
    salary,
    unidadeBaseCalculo,
  } = formState;

  const modalidade_contrato = {
    definition: [
      {
        label: 'Qual a modalidade de contratação',
        type: z.string(),
        name: 'contractType',
        uiType: UI_TYPE.SELECT,
        placeholder: 'Selecione a modalidade',
        options: [
          {
            value: 'br:clt',
            selected: !contractType || contractType === 'br:clt',
            label: 'Colaborador',
          },
        ],
        // options: getOptionsFromMapper({
        //   mapper: ContractTypeMap,
        //   currentValue: contractType,
        // }),
      },
      {
        label: 'Qual o local de serviço',
        type: z.string(),
        name: 'legalEntity',
        uiType: UI_TYPE.SELECT,
        placeholder: 'Selecione o local de serviço',
        options: getOptionsFromCompanyContext({
          baseContext: companyContext?.legalEntities,
          currentValue: legalEntity,
          selectIfOnlyOne: true,
        }),
      },
      {
        label: 'Tipo de admissão',
        type: z.string(),
        name: 'admissionType',
        disabled: admissionType != null,
        uiType: UI_TYPE.SELECT,
        placeholder: 'Selecione o tipo de admissão',
        options: [
          {
            value: 'admin_fills',
            selected: admissionType != null || admissionType === 'admin_fills',
            label: 'Preencher os dados',
          },
          {
            value: 'send_invite',
            selected: admissionType === 'send_invite',
            label: 'Envio de convite',
          },
        ],
      },
    ],
    options: {
      id: admissionDraftFormSteps.modalidade_contrato,
      title: 'Modalidade e local de serviço',
      completed:
        formSteps[admissionDraftFormSteps.modalidade_contrato]?.completed,

      hideLeftMenu: legalEntity == null,
    },
  };

  const criacao_usuario = {
    definition: [
      {
        label: 'Nome do candidato',
        type: z.string(),
        name: 'user_name',
        uiType: UI_TYPE.TEXT,
        placeholder: 'Ex: Maria de Lourdes',
        value: user_name || '',
      },
      {
        label: 'Email pessoal',
        type: z.string().email(),
        name: 'user_email',
        uiType: UI_TYPE.TEXT,
        placeholder: 'Ex: exemplo@exemplo.com.br',
        value: user_email || '',
      },
    ],
    options: {
      id: admissionDraftFormSteps.criacao_usuario,
      title: 'Dados do candidato',
      completed: formSteps[admissionDraftFormSteps.criacao_usuario]?.completed,
      disclaimer:
        admissionType === 'send_invite'
          ? 'O novo colaborador vai receber o e-mail de convite, com a senha temporária.'
          : 'O novo colaborador vai receber o e-mail com a senha temporária no dia da admissão.',
    },
  };

  const profissional = {
    definition: [
      {
        label: 'Matrícula',
        type: z.string(),
        name: 'workerId',
        uiType: UI_TYPE.TEXT,
        placeholder: '000000',
        value: workerId || '',
      },
      {
        label: 'Matrícula do eSocial',
        type: z.string(),
        name: 'matricula',
        uiType: UI_TYPE.TEXT,
        placeholder: '000000',
        value: matricula || '',
      },
      {
        label: 'Cargo',
        type: z.string(),
        name: 'cargo',
        uiType: NEW_UI_TYPE.SELECT_AUTOCOMPLETE,
        placeholder: 'Selecione um cargo',
        options: getOptionsFromCompanyContext({
          baseContext: companyContext?.jobTitles,
          currentValue: cargo,
        }),
      },
      {
        label: 'É cargo de confiança?',
        type: z.string(),
        name: 'cargoConfianca',
        uiType: UI_TYPE.SELECT,
        fieldsOptions: [
          {
            label: 'Não',
            selected:
              cargoConfianca?.enabled != null &&
              cargoConfianca?.enabled === 'false',
            value: 'false',
            fields: [
              {
                label: '-',
                hidden: true,
                type: z.string(),
                name: 'enabled',
                value: 'false',
                uiType: UI_TYPE.TEXT,
              },
            ],
          },
          {
            label: 'Sim',
            selected: cargoConfianca?.enabled === 'true',
            value: 'true',
            fields: [
              {
                label: 'CBO da Função',
                type: z.string(),
                name: 'CBOFuncao',
                uiType: UI_TYPE.SELECT,
                placeholder: 'Selecione uma função',
                options: getOptionsFromMapper({
                  mapper: CBOs,
                  currentValue: cargoConfianca?.CBOFuncao,
                  showKey: true,
                }),
              },
              {
                label: 'Nome da Função',
                type: z.string(),
                name: 'nmFuncao',
                uiType: UI_TYPE.TEXT,
                value: cargoConfianca?.nmFuncao || '',
              },
              {
                label: '-',
                hidden: true,
                type: z.string(),
                name: 'enabled',
                value: 'true',
                uiType: UI_TYPE.TEXT,
              },
            ],
          },
        ],
      },
      {
        label: 'Departamento',
        type: z.string(),
        name: 'departamento',
        uiType: UI_TYPE.TEXT,
        placeholder: 'Nome do departamento',
        value: departamento || '',
      },
      {
        label: 'Centro de custo',
        type: z.string(),
        name: 'centro_de_custo',
        uiType: UI_TYPE.SELECT,
        placeholder: 'Selecione um centro de custo',
        options: getOptionsFromCompanyContext({
          baseContext: companyContext?.costCenters,
          currentValue: centro_de_custo,
        }),
      },
      {
        label: 'Email corporativo',
        type: z.string().email().optional(),
        name: 'emailCorp',
        uiType: UI_TYPE.TEXT,
        placeholder: 'Ex: exemplo@empresa.com.br',
        value: emailCorp || '',
      },
    ],
    options: {
      id: admissionDraftFormSteps.profissional,
      title: 'Informações profissionais',
      completed: formSteps[admissionDraftFormSteps.profissional]?.completed,
    },
  };

  const indicativoAdmissaoFields: Record<string, IFormDefinition> = {
    '1': [],
    '2': [],
    '3': [
      {
        label: 'Número do processo trabalhista',
        type: z.string().length(20),
        name: 'nrProcTrab',
        uiType: UI_TYPE.TEXT,
        placeholder: 'Preencha somente se indicativo for Decisão Judicial',
        value: nrProcTrab || '',
      },
    ],
  };

  const regime_trabalho = {
    definition: [
      {
        label: 'Data de admissão',
        type: dateYearMonthSchema,
        name: 'admissionDate',
        uiType: UI_TYPE.DATE_PICKER,
        placeholder: '00/00/0000',
        value: admissionDate || '',
      },
      {
        label: 'Tipo de regime trabalhista',
        type: z.number().int(),
        name: 'tpRegTrab',
        uiType: UI_TYPE.SELECT,
        placeholder: 'Selecione o regime trabalhista',
        options: getOptionsFromMapper({
          mapper: TipoRegimeTrabalho,
          currentValue: tpRegTrab,
        }),
      },
      {
        label: 'Tipo de admissão',
        type: z.number().int(),
        name: 'tpAdmissao',
        uiType: UI_TYPE.SELECT,
        placeholder: 'Selecione o tipo de admissão',
        options: getOptionsFromMapper({
          mapper: TipoAdmissao,
          currentValue: tpAdmissao,
        }),
      },
      {
        label: 'Regime previdenciário',
        type: z.number().int(),
        name: 'tpRegPrev',
        uiType: UI_TYPE.SELECT,
        placeholder: 'Selecione o regime previdenciário',
        options: getOptionsFromMapper({
          mapper: TipoRegimePrevidenciario,
          currentValue: tpRegPrev,
        }),
      },
      {
        label: 'Codigo de categoria',
        type: z.string(),
        name: 'codCateg',
        uiType: UI_TYPE.SELECT,
        placeholder: 'Digite para buscar o código',
        value: codCateg,
        options: getOptionsFromMapper({
          mapper: Categorias,
          currentValue: codCateg,
          enabledKeys: ENABLED_CODIGO_CATEGORIA,
        }),
      },
      {
        label: 'Indicativo de admissão',
        type: z.number().int(),
        name: 'indAdmissao',
        uiType: UI_TYPE.SELECT,
        placeholder: 'Selecione a categoria',
        fieldsOptions: getOptionsFromMapper({
          mapper: IndicativoAdmissao,
          currentValue: indAdmissao?.defaultValue,
        }).map((option) => {
          return {
            ...option,
            fields: [
              {
                label: '',
                type: z.number().optional(),
                name: `indAdmissao_${option.value}`,
                value: parseInt(option.value),
                uiType: UI_TYPE.TEXT,
                hidden: true,
              },
              ...indicativoAdmissaoFields[option.value],
            ],
          };
        }),
      },
    ],
    options: {
      id: admissionDraftFormSteps.regime_trabalho,
      title: 'Admissão e regime de trabalho',
      completed: formSteps[admissionDraftFormSteps.regime_trabalho]?.completed,
    },
  };

  const jornada = {
    definition: [
      {
        label: 'Regime jornada',
        type: z.number().int(),
        name: 'tpRegJor',
        uiType: UI_TYPE.SELECT,
        placeholder: 'Selecione o regime jornada',
        options: getOptionsFromMapper({
          mapper: TipoRegimeJornada,
          currentValue: tpRegJor,
        }),
      },
      {
        label: 'Tipo de jornada',
        type: z.number().int(),
        name: 'tpJornada',
        uiType: UI_TYPE.SELECT,
        placeholder: 'Selecione o tipo de jornada',
        options: getOptionsFromMapper({
          mapper: TipoJornada,
          currentValue: tpJornada,
        }),
      },
      {
        label: 'Regime Parcial do Contrato',
        type: z.number().int(),
        name: 'tmpParc',
        uiType: UI_TYPE.SELECT,
        options: getOptionsFromMapper({
          mapper: ContratoRegimeParcial,
          currentValue: tmpParc,
        }),
      },
      {
        label: 'Jornada em horas semanais',
        type: z.number().max(44),
        name: 'qtdHrsSem',
        uiType: UI_TYPE.TEXT_NUMBER,
        placeholder: 'Ex: 44',
        value: qtdHrsSem,
      },
      {
        label: 'Descrição da Jornada',
        type: z.string(),
        name: 'descJorn',
        uiType: UI_TYPE.TEXT,
        placeholder: 'Ex: Jornada padrão da empresa',
        value: descJorn,
      },
      {
        label: 'Hora noturna',
        type: z.boolean().default(false),
        uiType: UI_TYPE.SELECT,
        name: 'horNoturno',
        options: [
          {
            label: 'Não',
            selected: !horNoturno,
            value: false,
          },
          {
            label: 'Sim',
            selected: horNoturno,
            value: true,
          },
        ],
      },
    ],
    options: {
      id: admissionDraftFormSteps.jornada,
      title: 'Jornada de trabalho',
      completed: formSteps[admissionDraftFormSteps.jornada]?.completed,
    },
  };

  const duracao = {
    definition: [
      {
        label: 'Tipo de contrato',
        type: z.number().int(),
        name: 'tpContr',
        uiType: UI_TYPE.SELECT,
        placeholder: 'Selecione o tipo de contrato',
        fieldsOptions: [
          {
            label: TipoContrato.getByCode(1),
            selected: tpContr?.type === 1,
            value: 1,
            fields: [
              {
                label: '-',
                hidden: true,
                type: z.number(),
                name: 'type',
                value: 1,
                uiType: UI_TYPE.TEXT,
              },
            ],
          },
          {
            label: TipoContrato.getByCode(2),
            selected: tpContr?.type === 2,
            value: 2,
            fields: [
              {
                label: 'Quantidade de dias do contrato',
                type: z
                  .number()
                  .int()
                  .min(1)
                  .max(2 * 365),
                name: 'duracaoContr',
                uiType: UI_TYPE.TEXT,
                placeholder: 'Ex: 15',
                value: tpContr?.duracaoContr || '',
              },
              {
                label: 'Cláusula assecuratória ',
                type: z.string(),
                name: 'clauAssec',
                uiType: UI_TYPE.SELECT,
                value: tpContr?.clauAssec ?? '',
                options: [
                  {
                    label: 'Não',
                    selected: tpContr?.clauAssec === 'false',
                    value: 'false',
                  },
                  {
                    label: 'Sim',
                    selected: tpContr?.clauAssec === 'true',
                    value: 'true',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    options: {
      id: admissionDraftFormSteps.duracao,
      title: 'Duração de contrato',
      completed: formSteps[admissionDraftFormSteps.duracao]?.completed,
    },
  };

  const sindicato = {
    definition: [
      {
        label: 'CNPJ do sindicato',
        type: cnpjSchema,
        name: 'cnpjSindCategProf',
        uiType: UI_TYPE.TEXT,
        placeholder: '00.000.000/0000-00',
        value: cnpjSindCategProf || '',
        mask: 'cnpj',
      },
    ],
    options: {
      id: admissionDraftFormSteps.sindicato,
      title: 'Sindicato',
      completed: formSteps[admissionDraftFormSteps.sindicato]?.completed,
    },
  };

  const remuneracao = {
    definition: [
      {
        label: 'Salário base',
        type: BrasilAmountType,
        name: 'salary',
        uiType: UI_TYPE.TEXT_MONEY,
        placeholder: '0,00',
        value: salary || '',
      },
      {
        label: 'Unidade para base de cálculo',
        type: z.number().int(),
        name: 'unidadeBaseCalculo',
        uiType: UI_TYPE.SELECT,
        placeholder: 'Selecione o tipo de salário',
        options: getOptionsFromMapper({
          mapper: UnidadeSalarioFixo,
          currentValue: unidadeBaseCalculo,
        }),
      },
    ],
    options: {
      id: admissionDraftFormSteps.remuneracao,
      title: 'Remuneração',
      completed: formSteps[admissionDraftFormSteps.remuneracao]?.completed,
    },
  };

  const envio_convite = getSummaryStep({
    draftStatus,
    admissionType,
    stepName: 'envio_convite',
    formSteps,
    isWorkerExperience,
  });

  return {
    modalidade_contrato,
    criacao_usuario,
    profissional,
    regime_trabalho,
    duracao,
    jornada,
    sindicato,
    remuneracao,
    ...(envio_convite && { envio_convite }),
  };
};

const getSummaryStep = ({
  draftStatus,
  admissionType,
  stepName,
  formSteps,
  isWorkerExperience,
}: {
  draftStatus: AdmissionDraftStatus;
  admissionType: AdmissionType;
  stepName: string;
  formSteps: AdmissionDraftInputFormSteps;
  isWorkerExperience: boolean;
}): FormStepDefinition => {
  if (
    (admissionType === 'admin_fills' && stepName !== 'finaliza_admissao') ||
    (admissionType === 'send_invite' && stepName !== 'envio_convite') ||
    isWorkerExperience
  ) {
    return null;
  }

  const cta =
    admissionType === 'admin_fills'
      ? draftStatus !== 'admission_submitted'
        ? 'Salvar candidato(a)'
        : 'Aprovar candidato(a)'
      : draftStatus !== 'draft_created'
        ? 'Reenviar convite'
        : 'Salvar candidato(a) e enviar convite';

  return {
    definition: [] as IFormDefinition,
    options: {
      id: stepName as keyof typeof admissionDraftFormSteps,
      title: 'Resumo e finalização',
      hideTitle: true,
      completed: formSteps[stepName]?.completed,

      getCustomComponent: ({
        updateDisabled,
        formState,
        stepDefinition,
        onSubmit,
        isLoading,
      }: {
        updateDisabled: boolean;
        formState: AdmissionFormState;
        stepDefinition: FormStepDefinition;
        onSubmit: () => void;
        isLoading: boolean;
      }): React.ReactElement => {
        if (!formState || !stepDefinition) {
          return null;
        }

        return (
          <NewAdmissionReview
            submitLabel={cta}
            formState={formState}
            onSubmit={onSubmit}
            isLoading={isLoading}
            disabled={updateDisabled}
          />
        );
      },
    },
  };
};

export const getWorkerSteps = ({
  draftStatus,
  formState,
  formSteps,
  isWorkerExperience,
}: {
  draftStatus: AdmissionDraftStatus;
  formState: AdmissionFormState;
  formSteps: AdmissionDraftInputFormSteps;
  isWorkerExperience: boolean;
}): AdmissionFormSteps => {
  const {
    admissionType,
    nome,
    nomeSocial,
    cpf,
    grauInstr,
    sexo,
    racaCor,
    estadoCivil,
    dtNascto,
    tpLograd,
    dscLograd,
    nrLograd,
    complemento,
    bairro,
    codMunic,
    countryCode,
    nacionalidade,
    paisNascto,
    uf,
    cep,
    fonePrinc,
    chavePix,
    tipoPix,
    codigoBanco,
    agencia,
    conta,
    pessoaComDeficiencia,
  } = formState;
  const dados_pessoais = {
    definition: [
      {
        label: 'Nome completo',
        type: z.string(),
        name: 'nome',
        uiType: UI_TYPE.TEXT,
        placeholder: 'Ex: Maria de Lourdes',
        value: nome || '',
      },
      {
        label: 'Nome social',
        type: z.string().optional(),
        name: 'nomeSocial',
        uiType: UI_TYPE.TEXT,
        placeholder: 'Ex: Maria de Lourdes',
        value: nomeSocial || '',
      },
      {
        label: 'CPF',
        type: cpfSchema,
        name: 'cpf',
        uiType: UI_TYPE.TEXT,
        placeholder: '000.000.000-00',
        value: cpf || '',
        mask: 'cpf',
      },
      {
        label: 'Sexo',
        type: z.string(),
        name: 'sexo',
        uiType: UI_TYPE.SELECT,
        info: 'Essa é uma informação requerida pelo eSocial.',
        options: getOptionsFromMapper({ mapper: Sexo, currentValue: sexo }),
      },
      {
        label: 'Identidade racial',
        type: z.number().int(),
        name: 'racaCor',
        uiType: UI_TYPE.SELECT,
        info: 'Essa é uma informação requerida pelo eSocial.',
        options: getOptionsFromMapper({
          mapper: RacaCor,
          currentValue: racaCor,
        }),
      },
      {
        label: 'Estado civil',
        type: z.number().int(),
        name: 'estadoCivil',
        uiType: UI_TYPE.SELECT,
        placeholder: 'Selecione',
        options: getOptionsFromMapper({
          mapper: EstadoCivil,
          currentValue: estadoCivil,
        }),
      },
      {
        label: 'Data de nascimento',
        type: dateYearMonthSchema,
        name: 'dtNascto',
        uiType: UI_TYPE.DATE_PICKER,
        placeholder: '00/00/0000',
        value: dtNascto || '',
      },
      {
        label: 'País de nascimento',
        type: z.string(),
        name: 'paisNascto',
        uiType: NEW_UI_TYPE.SELECT_AUTOCOMPLETE,
        placeholder: 'Digite para buscar o país',
        options: getOptionsFromMapper({
          mapper: Paises,
          currentValue: paisNascto,
        }),
      },
      {
        label: 'Nacionalidade',
        type: z.string(),
        name: 'nacionalidade',
        uiType: NEW_UI_TYPE.SELECT_AUTOCOMPLETE,
        placeholder: 'Digite para buscar a nacionalidade',
        options: getOptionsFromMapper({
          mapper: Nacionalidades,
          currentValue: nacionalidade,
        }),
      },
      // todo admission: use file uploader here
    ],
    options: {
      id: admissionDraftFormSteps.dados_pessoais,
      title: 'Dados pessoais',
      completed: formSteps[admissionDraftFormSteps.dados_pessoais]?.completed,
    },
  };

  const formacao_academica = {
    definition: [
      {
        label: 'Escolaridade',
        type: z.number().int(),
        name: 'grauInstr',
        uiType: UI_TYPE.SELECT,
        placeholder: 'Selecione',
        options: getOptionsFromMapper({
          mapper: GrauInstrucao,
          currentValue: grauInstr,
        }),
      },
      // {
      //   label: 'Instituição de ensino',
      //   type: z.string().optional(),
      //   name: 'instEnsino',
      //   uiType: UI_TYPE.TEXT,
      //   placeholder: 'Nome da instituição',
      // },
      // {
      //   label: 'Curso',
      //   type: z.string().optional(),
      //   name: 'curso',
      //   uiType: UI_TYPE.TEXT,
      //   placeholder: 'Descreva o curso',
      // },
      // todo admission: use file uploader here
      // obs: mostrar somente o tipo comprovante_formacao aqu, pré-selecionado
    ],
    options: {
      id: admissionDraftFormSteps.formacao_academica,
      title: 'Formação acadêmica',
      completed:
        formSteps[admissionDraftFormSteps.formacao_academica]?.completed,
    },
  };

  const temDeficiencia =
    pessoaComDeficiencia?.deficiencias?.length > 0 ||
    (pessoaComDeficiencia?.descDeficiencia != null &&
      pessoaComDeficiencia?.descDeficiencia !== '-');

  const pessoa_com_deficiencia = {
    definition: [
      {
        label: 'Você é portador(a) de alguma deficiência?',
        type: z.string(),
        uiType: UI_TYPE.SELECT,
        name: 'pessoaComDeficiencia',
        fieldsOptions: [
          {
            label: 'Não',
            selected: !temDeficiencia,
            fields: [
              {
                label: '-',
                hidden: true,
                type: z.string().optional(),
                name: 'deficiencia_hidden',
                uiType: UI_TYPE.TEXT,
              },
            ],
          },
          {
            label: 'Sim',
            selected: temDeficiencia,
            fields: [
              {
                label: 'Quais tipos?',
                type: z.array(z.string()),
                name: 'deficiencias',
                uiType: UI_TYPE.SELECT_CHECKBOX_LIST,
                options: [
                  {
                    value: 'defAuditiva',
                    label: 'Auditiva',
                    selected:
                      pessoaComDeficiencia?.deficiencias?.indexOf(
                        'defAuditiva',
                      ) >= 0,
                  },
                  {
                    value: 'defFisica',
                    label: 'Fisica',
                    selected:
                      pessoaComDeficiencia?.deficiencias?.indexOf(
                        'defFisica',
                      ) >= 0,
                  },
                  {
                    value: 'defIntelectual',
                    label: 'Intelectual',
                    selected:
                      pessoaComDeficiencia?.deficiencias?.indexOf(
                        'defIntelectual',
                      ) >= 0,
                  },
                  {
                    value: 'defMental',
                    label: 'Mental',
                    selected:
                      pessoaComDeficiencia?.deficiencias?.indexOf(
                        'defMental',
                      ) >= 0,
                  },
                  {
                    value: 'defVisual',
                    label: 'Visual',
                    selected:
                      pessoaComDeficiencia?.deficiencias?.indexOf(
                        'defVisual',
                      ) >= 0,
                  },
                ],
              },
              {
                label: 'Descrição',
                type: z.string().optional(),
                name: 'descDeficiencia',
                uiType: UI_TYPE.TEXT,
                value: pessoaComDeficiencia?.descDeficiencia,
                placeholder: 'Ex: Daltonismo tricromático',
                info: 'Informe uma descrição resumida, com virgula para multiplas, exemplo: Daltonismo, Dificultade motora',
              },
            ],
          },
        ],
      },
    ],
    options: {
      id: admissionDraftFormSteps.deficiencias,
      title: 'Pessoa com deficiência',
      completed: formSteps[admissionDraftFormSteps.deficiencias]?.completed,
    },
  };

  const pixField = {
    [pixKeyTypes.email]: {
      type: z.string().optional(),
      placeholder: 'Ex: maria.eduarda.carmo@gmail.com',
    },
    [pixKeyTypes.celular]: {
      type: phoneSchema.optional(),
      placeholder: '(00) 00000-0000',
      mask: 'phone',
    },
    [pixKeyTypes.cnpj]: {
      type: cnpjSchema.optional(),
      placeholder: '00.000.000/0000-00',
      mask: 'cnpj',
    },
    [pixKeyTypes.cpf]: {
      type: cpfSchema.optional(),
      placeholder: '000.000.000-00',
      mask: 'cpf',
    },
    [pixKeyTypes.aleatoria]: {
      type: z.string().optional(),
      placeholder: 'Ex: dcta478j-196l-03fm-t6gh-4298er7845m2',
    },
  };

  const dados_bancarios = {
    definition: [
      {
        label: 'Tipo Pix',
        type: z.string().optional(),
        name: 'tipoPix',
        uiType: UI_TYPE.SELECT,
        placeholder: 'Prefiro não informar',
        fieldsOptions: [
          {
            selected: !tipoPix,
            label: 'Prefiro não informar',
            fields: [
              {
                label: '-',
                hidden: true,
                type: z.string().optional(),
                name: 'chave_pix_none',
                uiType: UI_TYPE.TEXT,
              },
            ],
          },
          ...Object.keys(pixKeyTypes).map((pixKeyType) => ({
            value: pixKeyType ?? '',
            selected: tipoPix === pixKeyType,
            label: pixKeyLabels[pixKeyType as PixKeyType],
            fields: [
              {
                ...pixField[pixKeyType as PixKeyType],
                label: 'Chave Pix',
                name: pixKeyType,
                uiType: UI_TYPE.TEXT,
                value: chavePix ?? '',
              },
            ],
          })),
        ],
      },
      {
        label: 'Banco',
        type: z.string().optional(),
        name: 'codigoBanco',
        uiType: UI_TYPE.SELECT,
        placeholder: 'Selecione',
        options: getOptionsFromList({
          list: banksList,
          currentValue: codigoBanco,
        }),
      },
      {
        label: 'Agência',
        type: z.number().optional(),
        name: 'agencia',
        uiType: UI_TYPE.TEXT,
        placeholder: '0000',
        value: agencia || '',
      },
      {
        label: 'Conta (com dígito)',
        type: z.string().optional(),
        name: 'conta',
        uiType: UI_TYPE.TEXT,
        placeholder: '0000000-0',
        value: conta || '',
      },
    ],
    options: {
      id: admissionDraftFormSteps.dados_bancarios,
      title: 'Dados bancários',
      completed: formSteps[admissionDraftFormSteps.dados_bancarios]?.completed,
    },
  };

  // const documentos = {
  //   definition: [
  //     {
  //       label: 'Foto de perfil',
  //       subLabel:
  //         'Essa foto será exibida em seu perfil, e será visível para o departamento pessoal e os membros da organização que você participa.',
  //       type: z.string().optional(),
  //       name: 'fotos',
  //       uiType: UI_TYPE.FIELDSET,
  //     },
  //     // TODO: add file uploader
  //   ],
  //   options: {
  //     id: admissionDraftFormSteps.documentos,
  //     title: 'Documentos',
  //      completed: formSteps[admissionDraftFormSteps.documentos]?.completed,
  //   },
  // };

  const dependentes_section = {
    definition: [] as IFormDefinition,
    options: {
      getCustomComponent: ({
        updateDisabled,
        isLoading,
        formState,
        stepDefinition,
        onSubmitCallback,
        goToNextStep,
        goToPreviousStep,
      }: {
        updateDisabled: boolean;
        isLoading: boolean;
        formState: AdmissionFormState;
        stepDefinition: FormStepDefinition;
        goToNextStep: () => void;
        goToPreviousStep: () => void;
        onSubmitCallback: (newFormState: AdmissionFormState) => void;
      }): React.ReactElement => {
        if (!formState || !stepDefinition) {
          return null;
        }
        return (
          <AddNewDependent
            disabled={updateDisabled}
            isLoading={isLoading}
            formState={formState}
            stepDefinition={stepDefinition}
            onSubmitCallback={onSubmitCallback}
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
          />
        );
      },
      id: admissionDraftFormSteps.dependentes,
      title: 'Dependentes',
      completed: formSteps[admissionDraftFormSteps.dependentes]?.completed,
    },
  };

  const endereco_e_contatos = {
    definition: [
      {
        label: 'CEP',
        type: cepSchema,
        name: 'cep',
        uiType: UI_TYPE.TEXT,
        placeholder: '00000-000',
        value: cep || '',
        mask: 'cep',
      },
      {
        label: 'Logradouro',
        type: z.string(),
        name: 'tpLograd',
        uiType: NEW_UI_TYPE.SELECT_AUTOCOMPLETE,
        placeholder: 'Digite para buscar. Ex: Rua, Avenida...',
        options: getOptionsFromMapper({
          mapper: TipoLogradouro,
          currentValue: tpLograd,
        }),
      },
      {
        label: '',
        type: z.string(),
        name: 'dscLograd',
        uiType: UI_TYPE.TEXT,
        placeholder: 'Ex: dos bonifácios',
        value: dscLograd || '',
        sx: { marginTop: '-24px' },
      },
      {
        label: 'Número',
        type: z.string(),
        name: 'nrLograd',
        uiType: UI_TYPE.TEXT,
        placeholder: 'Ex: 123',
        value: nrLograd || '',
      },
      {
        label: 'Complemento',
        type: z.string().optional(),
        name: 'complemento',
        uiType: UI_TYPE.TEXT,
        placeholder: 'Ex: apto 31',
        value: complemento || '',
      },
      {
        label: 'Bairro',
        type: z.string(),
        name: 'bairro',
        uiType: UI_TYPE.TEXT,
        placeholder: 'Ex: Vila formosa',
        value: bairro || '',
      },
      {
        label: 'País',
        type: z.string(),
        name: 'countryCode',
        uiType: NEW_UI_TYPE.SELECT_AUTOCOMPLETE,
        placeholder: 'Digite para buscar o país',
        options: getOptionsFromMapper({
          mapper: Paises,
          currentValue: countryCode,
        }),
      },
      {
        label: 'Estado',
        type: z.string(),
        name: 'uf',
        uiType: NEW_UI_TYPE.SELECT_AUTOCOMPLETE,
        placeholder: 'Digite para buscar o estado',
        options: getOptionsFromMapper({
          mapper: Estados,
          currentValue: uf,
        }),
      },
      {
        label: 'Cidade / Município',
        type: z.string(),
        name: 'codMunic',
        uiType: NEW_UI_TYPE.SELECT_AUTOCOMPLETE,
        placeholder: 'Digite para buscar a cidade',
        options: getOptionsFromMapper({
          mapper: Municipios,
          currentValue: codMunic,
        }),
      },
      {
        label: 'Telefone',
        type: phoneSchema.optional(),
        name: 'fonePrinc',
        uiType: UI_TYPE.TEXT,
        placeholder: '(00) 00000-0000',
        value: fonePrinc || '',
        mask: 'phone',
      },
      // todo admission: use file uploader here
    ],
    options: {
      id: admissionDraftFormSteps.endereco_e_contatos,
      title: 'Endereço e contato',
      completed:
        formSteps[admissionDraftFormSteps.endereco_e_contatos]?.completed,
    },
  };

  const finaliza_admissao = getSummaryStep({
    draftStatus,
    admissionType,
    stepName: 'finaliza_admissao',
    formSteps,
    isWorkerExperience,
  });

  return {
    dados_pessoais,
    formacao_academica,
    endereco_e_contatos,
    deficiencias: pessoa_com_deficiencia,
    dependentes: dependentes_section,
    dados_bancarios,
    ...(finaliza_admissao && { finaliza_admissao }),
    // documentos,
  };
};

import { Box } from '@mui/material';

import { isBRPjContract } from '@octopus/contract-types';

import {
  ContatoDeEmergenciaRecord,
  EnderecoEContatoRecord,
  PagamentoRecord,
  pj,
} from '../../../modules/components/people/br';
import { RecordGroup } from '../../../modules/components/Record';

import { ContractDetailsTitle } from './ContractDetailsTitle';
import { useContractEventsHistory } from './history';
import { ContractDetailsProps } from './types';
import { useContractEdit } from './useContractEdit';

export function PjContractDetails({
  isEditable,
  refs,
  contractQuery,
  historyEnabled,
  showTitle = true,
}: ContractDetailsProps) {
  const { isLoading, data: contractInfo } = contractQuery;

  const contractEdit = useContractEdit({ contractQuery, isEditable });

  const { HistoryDrawer, ShowHistoryButton } = useContractEventsHistory({
    contractQuery,
    historyEnabled,
    projectionComponent: PjContractDetails,
  });

  if (
    (!isLoading && (!contractInfo || !contractInfo.br)) ||
    !isBRPjContract(contractInfo) ||
    !contractEdit
  ) {
    return null;
  }

  const { UpdateDialog, updateRecordProps, internalTransferRecordProps } =
    contractEdit;

  const { organizationId, companyId, legalEntityId, status, workerId, br } =
    contractInfo;

  const {
    pessoa,
    nascimento,
    endereco,
    contato,
    contatoDeEmergencia,
    dependentes,
    emailCorp,
    prestador,
    empresa,
    contrato,
    pagamento,
  } = br;
  return (
    <Box display="flex" flexDirection="column" gap={4}>
      {showTitle ? (
        <ContractDetailsTitle
          title="Dados do prestador(a) de serviço"
          right={ShowHistoryButton}
        />
      ) : null}
      <Box ref={refs?.personalDetailsRef} data-testid="person-details">
        <RecordGroup title="Dados Pessoais">
          <pj.InformacoesPessoaisRecord
            data={{
              pessoa,
              nascimento,
            }}
            edit={updateRecordProps}
          />
          <EnderecoEContatoRecord
            data={{ endereco, contato }}
            edit={updateRecordProps}
          />
          <ContatoDeEmergenciaRecord
            data={{
              contatoDeEmergencia,
            }}
            edit={updateRecordProps}
          />
          <pj.DependentesRecord
            data={{ dependentes }}
            edit={updateRecordProps}
          />
        </RecordGroup>
      </Box>
      <Box ref={refs?.contractDetailsRef} data-testid="contract-details">
        <RecordGroup title="Dados Contratuais">
          <pj.SituacaoRecord status={status} contrato={contrato} />
          <pj.PrestadorDeServicoRecord
            data={{
              organizationId,
              workerId,
              emailCorp,
              prestador,
            }}
            edit={{
              ...updateRecordProps,
              onSave: (data, onSuccess, onError) => {
                const {
                  update: { organizationId: _, workerId, ...changes },
                } = data;
                updateRecordProps.onSave(
                  { update: changes, workerId },
                  onSuccess,
                  onError,
                );
              },
            }}
          />
          <pj.DuracaoDoContratoRecord
            data={{ contrato }}
            edit={updateRecordProps}
          />
          <pj.ContratadaRecord data={{ empresa }} edit={updateRecordProps} />
          <pj.EnderecoEmpresaRecord
            data={{ empresa }}
            edit={updateRecordProps}
          />
          <pj.LocalDePrestacaoDeServico
            data={{
              organizationId,
              companyId,
              legalEntityId,
            }}
            edit={internalTransferRecordProps}
          />
        </RecordGroup>
      </Box>
      <Box ref={refs?.paymentDetailsRef} data-testid="payment-details">
        <RecordGroup title="Pagamento">
          <pj.PagamentoRecord data={{ pagamento }} edit={updateRecordProps} />
          <PagamentoRecord data={{ pagamento }} edit={updateRecordProps} />
        </RecordGroup>
      </Box>
      {UpdateDialog}
      {HistoryDrawer}
    </Box>
  );
}

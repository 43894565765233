import dayjs from 'dayjs';

export type RequestVacationFormStepsKeys =
  | 'initialStep'
  | 'abonoPecuniario'
  | 'thirteenthAdvance'
  | 'dateAndDuration'
  | 'reviewAndSubmit'
  | 'result';

export const inputFormSteps = [
  'abonoPecuniario',
  'thirteenthAdvance',
  'dateAndDuration',
];

export type requestVacationObject = {
  sellVacation?: boolean;
  advanceThirteenth?: boolean;
  date?: dayjs.Dayjs;
  duration?: number;
  result?: {
    ok: boolean;
    errorRuleCodes?: string[];
  };
};

export const canCancelRequestStatuses = [
  'waitingApproval',
  'approved',
  'payrollCreated',
];

export type RequestVacationFormSteps = Partial<
  Record<RequestVacationFormStepsKeys, requestVacationObject>
>;

import { ChangeEventHandler, MouseEvent, useState } from 'react';

import { TablePagination } from '@mui/material';

const ROWS_PER_PAGE_OPTIONS = [25, 50, 100];

export type PaginationProps = {
  page: number;
  rowsPerPage: number;
  handleChangePage: (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => void;
  handleChangeRowsPerPage: ChangeEventHandler<HTMLInputElement>;
};

export function usePagination(): PaginationProps {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_OPTIONS[0]);

  const handleChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage: ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  };
}

export function TablePaginationFooter({
  totalRowCount,
  paginationProps,
}: {
  totalRowCount: number;
  paginationProps: Pick<
    ReturnType<typeof usePagination>,
    'page' | 'rowsPerPage' | 'handleChangePage' | 'handleChangeRowsPerPage'
  >;
}) {
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    paginationProps;

  if (totalRowCount <= ROWS_PER_PAGE_OPTIONS[0]) {
    return null;
  }

  return (
    <TablePagination
      rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
      component="div"
      count={totalRowCount}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      sx={{
        '*': {
          color: 'text.secondary',
          fontWeight: '500',
        },
        borderTop: '1px solid',
        borderColor: 'strokes.default',
      }}
    />
  );
}

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Submission } from '@conform-to/react';

import { Cancel, CheckCircle } from '@mui/icons-material';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import {
  AdmissionDraftEntry,
  AdmissionDraftInputFormInfo,
  AdmissionDraftInputFormStepEnum,
  MembershipTypes,
  fetchAdmissionDraftPost,
  fetchApproveAdmissionDraftReview,
  fetchPostAdmissionInvite,
  fetchPutAdmissionDraft,
  fetchSubmitAdmissionDraftReview,
} from '@octopus/api';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { SnackbarType } from '../../../modules/hooks/snackbarContext';
import { useSnackbar } from '../../../modules/hooks/useSnackbar';
import { useMultiStepView } from '../../../modules/navigation/multiStep/useMultiStepView';
import {
  CompanyContext,
  MembershipContext,
  MembershipOption,
} from '../../../modules/types';
import { LoadingScene } from '../edit/AdmissionDraftEditLoadingScene';

import { MissingFieldsDialog } from './components/MissingFieldsDialog';
import {
  getCreateBody,
  getHeaders,
  getStartingStep,
  getUpdateBody,
} from './form/fetchUtils';
import { getAdminSteps, getWorkerSteps } from './form/formStepInputs';
import { getFormStateFromEntry } from './form/mappers';
import { AdmissionFormState, AdmissionFormSteps } from './form/types';
import { NewAdmissionContainer } from './NewAdmissionContainer';

type Props = {
  organizationId: string;
  companyId: string;
  companyContext: CompanyContext;
  admissionDraftEntry?: AdmissionDraftEntry;
  draftId?: string;
  membershipProps: {
    membershipType: MembershipTypes;
    membership: MembershipContext;
    membershipOptions: MembershipOption[];
  };
};

const SAVE_AND_CLOSE_BUTTON_ID = 'save_and_close_BUTTON_ID_button';

const error_snackbar: SnackbarType = {
  isOpen: true,
  variant: 'error',
  Message:
    'Ocorreu um erro. Por favor tente novamente ou contacte o suporte da Tako.',
  StartAdornment: <Cancel />,
  autoHideDuration: 5000,
  hasCloseAction: true,
};

const getSuccessSnackbar = (message: string): SnackbarType => ({
  isOpen: true,
  variant: 'default',
  Message: message,
  StartAdornment: <CheckCircle />,
  autoHideDuration: 5000,
  hasCloseAction: false,
});

export function AdmissionDraftInputPage({
  organizationId,
  companyId,
  companyContext,
  admissionDraftEntry,
  draftId,
  membershipProps,
}: Props) {
  const navigate = useNavigate();

  const { showSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formSteps, setFormSteps] = useState<AdmissionFormSteps>({});
  const [adminFormSteps, setAdminFormSteps] = useState<AdmissionFormSteps>({});
  const [workerFormSteps, setWorkerFormSteps] = useState<AdmissionFormSteps>(
    {},
  );
  const [formState, setFormState] = useState<AdmissionFormState>({});
  const [fieldStepsState, setFieldStepsState] =
    useState<AdmissionDraftInputFormInfo>(null);
  const [oneTimeRedirect, setOneTimeRedirect] = useState<boolean>(false);
  const [openMissingFieldsDrawer, setOpenMissingFieldsDrawer] =
    useState<boolean>(false);

  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));
  const isWorkerExperience = membershipProps.membershipType === 'internal';

  useEffect(() => {
    if (draftId != null && draftId === admissionDraftEntry?.draftId) {
      const newFormState = getFormStateFromEntry({ admissionDraftEntry });
      setFormState(newFormState);
      setFieldStepsState({ ...admissionDraftEntry?.formInfo });
    }
  }, [draftId, admissionDraftEntry]);

  useEffect(() => {
    if (draftId && Object.keys(formState).length === 0) {
      return;
    }

    const adminFills =
      formState.admissionType != null
        ? formState.admissionType === 'admin_fills'
        : true;

    const adminSteps: AdmissionFormSteps = !isWorkerExperience
      ? getAdminSteps({
          draftStatus: admissionDraftEntry?.draftStatus,
          formState,
          companyContext,
          formSteps: fieldStepsState?.formSections?.['payroll_data'] ?? {},
          isWorkerExperience,
        })
      : ({} as AdmissionFormSteps);
    setAdminFormSteps(adminSteps);

    const workerSteps: AdmissionFormSteps =
      adminFills || isWorkerExperience
        ? getWorkerSteps({
            draftStatus: admissionDraftEntry?.draftStatus,
            formState,
            formSteps: fieldStepsState?.formSections?.['personal_data'] ?? {},
            isWorkerExperience,
          })
        : ({} as AdmissionFormSteps);
    setWorkerFormSteps(workerSteps);

    const steps: AdmissionFormSteps = {
      ...adminSteps,
      ...workerSteps,
    };
    setFormSteps(steps);
  }, [
    companyContext,
    formState,
    draftId,
    fieldStepsState,
    admissionDraftEntry,
    isWorkerExperience,
  ]);

  const multiStepView = useMultiStepView<string>(formSteps);

  useEffect(() => {
    if (oneTimeRedirect || !draftId || Object.keys(formSteps).length <= 0) {
      return;
    }

    const startingStep = getStartingStep(multiStepView.steps);
    if (startingStep != null) {
      setOneTimeRedirect(true);
      if (multiStepView.currentStep?.isFirst && !isWorkerExperience) {
        multiStepView.goTo(startingStep);
      }
    }
  }, [formSteps, draftId, oneTimeRedirect, multiStepView, isWorkerExperience]);

  const getCurrentStepName = () =>
    multiStepView.currentStep?.name as AdmissionDraftInputFormStepEnum;

  const onStepSubmit = (
    event: React.FormEvent<HTMLFormElement>,
    formData: Submission<any, string[], any>,
  ) => {
    const shouldClose =
      (event.nativeEvent as SubmitEvent).submitter.id ===
      SAVE_AND_CLOSE_BUTTON_ID;
    const newFormState = { ...formState, ...formData.payload };

    const currentStepName = getCurrentStepName();
    switch (currentStepName) {
      // empty steps - we don't save the information on these initial steps
      case admissionDraftFormSteps.modalidade_contrato:
      case admissionDraftFormSteps.criacao_usuario:
        if (draftId) {
          updateAdmissionDraft({ currentStepName, newFormState, shouldClose });
        } else {
          forceCompleteCurrentStep();
          setFormState(newFormState);
        }
        shouldClose ? closeFormPage() : multiStepView.goForward();
        break;

      case admissionDraftFormSteps.profissional: {
        if (!draftId) {
          createAdmissionDraft({ currentStepName, newFormState });
        } else {
          updateAdmissionDraft({ currentStepName, newFormState, shouldClose });
        }
        break;
      }

      default: {
        updateAdmissionDraft({ currentStepName, newFormState, shouldClose });
        break;
      }
    }
  };

  const forceCompleteCurrentStep = () => {
    const currentStepName = getCurrentStepName();
    setFieldStepsState({
      formSections: {
        payroll_data: {
          ...fieldStepsState?.formSections['payroll_data'],
          [currentStepName]: {
            section: 'payroll_data',
            step: currentStepName,
            completed: true,
          },
        },
      },
    });
  };

  const onSubmitCustom = ({
    stepName,
  }: {
    stepName?: AdmissionDraftInputFormStepEnum;
  }) => {
    const currentStepName = stepName || getCurrentStepName();
    const newFormState = { ...formState };
    switch (currentStepName) {
      case admissionDraftFormSteps.envio_convite: {
        sendInvite({ currentStepName, newFormState });
        break;
      }
      case admissionDraftFormSteps.finaliza_admissao: {
        if (isWorkerExperience) {
          const allSteps = Object.keys(workerFormSteps).length;
          const completedSteps = Object.values(
            fieldStepsState.formSections.personal_data,
          ).filter((step) => step.completed).length;
          if (allSteps !== completedSteps) {
            return setOpenMissingFieldsDrawer(true);
          }
        }
        updateAdmissionDraft({
          currentStepName,
          newFormState,
          successCallback: (admissionDraftEntry) => {
            if (admissionDraftEntry?.draftStatus !== 'admission_submitted') {
              submitAdmissionToApproval();
            } else {
              approveAdmissionDraft();
            }
          },
        });

        break;
      }
      default: {
        throw new Error(
          `onSubmitCustomCallback not implemented for step ${currentStepName}`,
        );
      }
    }
  };

  const onSubmitCustomCallback = (newFormState: AdmissionFormState) => {
    const currentStepName = getCurrentStepName();

    switch (currentStepName) {
      case admissionDraftFormSteps.dependentes: {
        return updateAdmissionDraft({
          currentStepName,
          newFormState,
          disableMoveToNextStep: true,
        });
      }
      default: {
        throw new Error(
          `onSubmitCustomCallback not implemented for step ${currentStepName}`,
        );
      }
    }
  };

  const handleError = (error: Error) => {
    let errorMessage = error_snackbar.Message;
    if (
      error.message.match('Exclusive resource') &&
      error.message.match('workerId|matricula')
    ) {
      errorMessage = 'Matricula já utilizada, por favor escolha outro valor.';
    }
    setIsLoading(false);
    showSnackbar({ ...error_snackbar, Message: errorMessage });
    console.error(error);
  };

  const submitAdmissionToApproval = () => {
    setIsLoading(true);
    const body = {
      contractType: formState.contractType,
    };

    fetchSubmitAdmissionDraftReview({
      pathParams: {
        organizationId,
        draftId,
      },
      body,
    })
      .then((draft) => {
        const newState = getFormStateFromEntry({ admissionDraftEntry: draft });
        setFormState(newState);
        setIsLoading(false);
        showSnackbar(
          getSuccessSnackbar('Admissão submetida para revisão com sucesso!'),
        );
        closeFormPage();
      })
      .catch(handleError);
  };

  const approveAdmissionDraft = () => {
    setIsLoading(true);
    const body = {
      contractType: formState.contractType,
    };

    fetchApproveAdmissionDraftReview({
      pathParams: {
        organizationId,
        draftId,
      },
      body,
    })
      .then((draft) => {
        const newState = getFormStateFromEntry({ admissionDraftEntry: draft });
        setFormState(newState);
        setIsLoading(false);
        showSnackbar(getSuccessSnackbar('Admissão aprovada com sucesso!'));
        closeFormPage();
      })
      .catch(handleError);
  };

  const updateAdmissionDraft = ({
    currentStepName,
    newFormState,
    disableMoveToNextStep = false,
    shouldClose = false,
    successCallback,
  }: {
    currentStepName: AdmissionDraftInputFormStepEnum;
    newFormState: AdmissionFormState;
    disableMoveToNextStep?: boolean;
    shouldClose?: boolean;
    successCallback?: (admissionDraftEntry: AdmissionDraftEntry) => void;
  }) => {
    setIsLoading(true);
    const body = getUpdateBody({
      previousFormInfo: fieldStepsState,
      formState: newFormState,
      adminFormSteps,
      workerFormSteps,
      currentStepName,
    });
    const headers = getHeaders();

    fetchPutAdmissionDraft({
      pathParams: {
        organizationId,
        draftId,
      },
      body,
      headers,
    })
      .then((draft) => {
        const newState = getFormStateFromEntry({ admissionDraftEntry: draft });
        setFieldStepsState(draft?.formInfo);

        setFormState(newState);
        setIsLoading(false);
        if (shouldClose) {
          showSnackbar(getSuccessSnackbar('O candidato foi salvo'));
          closeFormPage();
        } else if (draftId === draft.draftId && !disableMoveToNextStep) {
          multiStepView.goForward();
        }

        successCallback && draft && successCallback(draft);
      })
      .catch(handleError);
  };

  const createAdmissionDraft = ({
    currentStepName,
    newFormState,
  }: {
    currentStepName: AdmissionDraftInputFormStepEnum;
    newFormState: AdmissionFormState;
  }) => {
    setIsLoading(true);
    const body = getCreateBody({
      previousFormInfo: fieldStepsState,
      companyId,
      formState: newFormState,
      adminFormSteps,
      workerFormSteps,
      currentStepName,
    });
    const headers = getHeaders();

    fetchAdmissionDraftPost({
      pathParams: {
        organizationId,
      },
      body,
      headers,
    })
      .then((draft) => {
        setIsLoading(false);
        const nextStep = multiStepView.currentStep.idx + 1;
        navigate(`/admissions/new/${draft.draftId}?msvnavpos=${nextStep}`);
      })
      .catch(handleError);
  };

  const sendInvite = ({
    currentStepName,
    newFormState,
  }: {
    currentStepName: AdmissionDraftInputFormStepEnum;
    newFormState: AdmissionFormState;
  }) => {
    setIsLoading(true);
    fetchPostAdmissionInvite({
      pathParams: {
        organizationId,
      },
      body: {
        companyId,
        contractType: formState.contractType,
        workerName: formState.user_name,
        workerEmail: formState.emailCorp || formState.user_email,
        draftId,
      },
    })
      .then(() => {
        updateAdmissionDraft({
          currentStepName,
          newFormState,
          shouldClose: true,
        });
        showSnackbar(getSuccessSnackbar('O candidato foi convidado'));
      })
      .catch(handleError);
  };

  const closeFormPage = () => {
    if (!isSmallDevice) {
      // todo: check user type
      navigate('/admissions');
    } else {
      // navigate where?
    }
  };

  if (
    draftId &&
    (Object.keys(formState).length === 0 || Object.keys(formSteps).length === 0)
  ) {
    return <LoadingScene />;
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
      }}
      display={'flex'}
      flexDirection={'column'}
    >
      <NewAdmissionContainer
        isWorkerExperience={isWorkerExperience}
        draftId={draftId}
        draftStatus={admissionDraftEntry?.draftStatus}
        saveAndCloseButtonId={SAVE_AND_CLOSE_BUTTON_ID}
        isSmallDevice={isSmallDevice}
        isLoading={isLoading}
        adminFormSteps={adminFormSteps}
        workerFormSteps={workerFormSteps}
        formSteps={formSteps}
        formState={formState}
        multiStepView={multiStepView}
        onStepSubmit={onStepSubmit}
        onSubmitCustom={onSubmitCustom}
        onSubmitCustomCallback={onSubmitCustomCallback}
        getCurrentStepName={getCurrentStepName}
        updateAdmissionDraft={updateAdmissionDraft}
        forceCompleteCurrentStep={forceCompleteCurrentStep}
        closeFormPage={closeFormPage}
        membershipProps={membershipProps}
      />

      <MissingFieldsDialog
        open={openMissingFieldsDrawer}
        setOpen={setOpenMissingFieldsDrawer}
      />
    </Box>
  );
}

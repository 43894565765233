import React, { ReactNode } from 'react';

import {
  AccessibleOutlined,
  AccountBalanceWalletOutlined,
  AttachFileOutlined,
  CheckCircle,
  ExpandMore as ExpandMoreIcon,
  KeyboardArrowRightOutlined,
  PermContactCalendarOutlined,
  PersonAddAltOutlined,
  PersonOutline,
  SchoolOutlined,
  SupervisorAccountOutlined,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { AdmissionDraftInputFormStepEnum } from '@octopus/api';

import TakoLogo from '../../../../assets/takoLogo.svg';

import { AdmissionFormSteps } from './types';

type Props = {
  isWorkerExperience: boolean;
  adminSteps: AdmissionFormSteps;
  workerSteps?: AdmissionFormSteps;
  moveToStepClick: (key: AdmissionDraftInputFormStepEnum) => void;
  currentStep: AdmissionDraftInputFormStepEnum;
  currentStepIndex: number;
};

const startIcons: Record<string, ReactNode> = {
  dados_pessoais: <PersonOutline fontSize="large" />,
  formacao_academica: <SchoolOutlined />,
  endereco_e_contatos: <PermContactCalendarOutlined />,
  deficiencias: <AccessibleOutlined />,
  dependentes: <SupervisorAccountOutlined />,
  dados_bancarios: <AccountBalanceWalletOutlined />,
  documentos: <AttachFileOutlined />,
};

export function LeftFormStepper({
  isWorkerExperience,
  adminSteps,
  workerSteps,
  moveToStepClick,
  currentStep,
  currentStepIndex,
}: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const adminButtons = getStepsLayout({
    steps: adminSteps,
    currentStep,
    moveToStepClick,
    showStartIcon: false,
    isMobile,
  });

  const workerButtons = workerSteps
    ? getStepsLayout({
        steps: workerSteps,
        currentStep,
        moveToStepClick,
        showStartIcon: isWorkerExperience,
        isMobile,
      })
    : null;

  const showWorkerSteps =
    workerSteps != null && Object.keys(workerSteps).length > 0;
  const showAdminSteps =
    !isWorkerExperience &&
    adminSteps != null &&
    Object.keys(adminSteps).length > 0;

  const currentStepIsAdmin =
    !showWorkerSteps || currentStepIndex < Object.keys(adminSteps).length;

  const showAccordion = showWorkerSteps && showAdminSteps;

  return (
    <Box
      sx={(theme) => ({
        minWidth: '320px',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
          width: '320px',
        },
        [theme.breakpoints.down('md')]: {
          width: '100%',
          gap: 0,
        },
      })}
    >
      {!isWorkerExperience && (
        <Box
          sx={{
            marginBottom: 2,
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Box
            component={PersonAddAltOutlined}
            sx={{ height: '32px', width: 'auto', mr: 2 }}
          />
          <Typography variant="h2">Nova admissão</Typography>
        </Box>
      )}
      {isWorkerExperience && (
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            [theme.breakpoints.up('md')]: {
              my: 2,
            },
            [theme.breakpoints.down('md')]: {
              mt: 2,
            },
          })}
        >
          <img src={TakoLogo} alt="Tako" height={34} />
          <Typography variant="body2" sx={{ pt: 2 }}>
            Tenha em mãos, RH, CPF e dados bancários. A cada sessão finalizada,
            o seu progresso é salvo automaticamente.
          </Typography>
        </Box>
      )}

      {showAccordion && showAdminSteps && (
        <Accordion className="borderless" defaultExpanded={currentStepIsAdmin}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
            sx={{ padding: 0 }}
          >
            <Typography variant="h4">Dados para inclusão na folha</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            {adminButtons}
          </AccordionDetails>
        </Accordion>
      )}
      {showAccordion && <Divider sx={{ my: 1 }} />}
      {showAccordion && showWorkerSteps && !isWorkerExperience && (
        <Accordion className="borderless" defaultExpanded={!currentStepIsAdmin}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
            sx={{ padding: 0, ':before': { display: 'none' } }}
          >
            <Typography variant="h4">Dados do candidato(a)</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            {workerButtons}
          </AccordionDetails>
        </Accordion>
      )}

      {!showAccordion && showAdminSteps && (
        <Box marginTop={2}>{adminButtons}</Box>
      )}
      {!showAccordion && showWorkerSteps && (
        <Box marginTop={2}>{workerButtons}</Box>
      )}
    </Box>
  );
}

function getStepsLayout({
  steps,
  currentStep,
  moveToStepClick,
  showStartIcon,
  isMobile,
}: {
  steps: AdmissionFormSteps;
  currentStep: AdmissionDraftInputFormStepEnum;
  moveToStepClick: (key: AdmissionDraftInputFormStepEnum) => void;
  showStartIcon: boolean;
  isMobile: boolean;
}) {
  const buttons = Object.entries(steps).map(([key, step]) => {
    const isCurrentStep = key === currentStep;
    const isCompleted = step.options.completed;
    const isDisabled = !isCurrentStep && !isCompleted;

    const color =
      !isCurrentStep || isMobile || (isCompleted && !isCurrentStep)
        ? 'inherit'
        : 'primary';

    const startIcon =
      showStartIcon && startIcons[key] ? startIcons[key] : <Box></Box>;

    const endIcon =
      isCurrentStep || isMobile ? (
        <KeyboardArrowRightOutlined
          sx={{ alignSelf: 'flex-end', marginLeft: 'auto' }}
        />
      ) : isCompleted ? (
        <Box
          component={CheckCircle}
          sx={{
            height: '16px',
            width: 'auto',
            color: 'success.main',
            alignSelf: 'flex-end',
            marginLeft: 'auto',
          }}
        />
      ) : (
        <Box></Box>
      );

    return (
      <Box
        sx={(theme) => ({
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          textAlign: 'left',
          [theme.breakpoints.up('md')]: {
            borderRadius: 0,
            borderColor: 'transparent',
          },
          [theme.breakpoints.down('md')]: {
            borderRadius: 1,
            marginBottom: 1.5,
            border: '1px solid #F2F2F2',
          },
        })}
      >
        <Button
          disabled={isDisabled}
          key={key}
          onClick={() =>
            moveToStepClick(key as AdmissionDraftInputFormStepEnum)
          }
          endIcon={endIcon}
          color={color}
          startIcon={startIcon}
          sx={(theme) => ({
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            fontWeight: 'normal',
            borderRadius: 0,
            textAlign: 'left',
            gap: 0.5,
            [theme.breakpoints.up('md')]: {
              px: 2,
              py: 1.5,
            },
            [theme.breakpoints.down('md')]: {
              px: 2,
              py: 2,
            },
          })}
        >
          <Box flexGrow={1}>{step.options.title}</Box>
        </Button>
      </Box>
    );
  });

  return (
    <ButtonGroup
      orientation="vertical"
      aria-label="Passos da admissão a serem preenchidos"
      variant="text"
      sx={{ alignItems: 'stretch', width: '100%', borderRadius: 0 }}
    >
      {buttons}
    </ButtonGroup>
  );
}

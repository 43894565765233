import React from 'react';

import { Box } from '@mui/material';

type FormFromLayoutProps = {
  fields: [fieldsetName: string, React.ReactElement][] | undefined;
  validationErrors?: Record<string, string[]>;
  fieldsetInline?: boolean;
};

export function FormFromLayout({
  fields,
  fieldsetInline = true,
}: FormFromLayoutProps) {
  const fieldsets = fields.reduce(
    (fieldsets, entry) => {
      const [name, field] = entry;

      if (name !== '') {
        const oldset = fieldsets.find(([fname]) => fname === name);
        let set = oldset;
        if (!set) {
          set = [name];
          fieldsets.push(set);
        }
        set.push(field);
        return fieldsets;
      } else {
        fieldsets.push([name, field]);
        return fieldsets;
      }
    },
    [] as unknown as [fieldsetName: string, ...fields: React.ReactElement[]][],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px 0',
      }}
    >
      {fieldsets.map(([_fieldsetName, ...Fields], idx) =>
        Fields.length > 1 ? (
          <Box
            key={idx}
            sx={{
              display: 'flex',
              flexDirection: fieldsetInline ? 'row' : 'column',
              alignItems: 'stretch',
              justifyItems: 'stretch',
              width: '100%',
              gap: fieldsetInline ? 1.5 : 2,
              '& > *:not(:first-of-type)': {
                width: fieldsetInline ? 0 : '100%',
                flex: '2 0 min-content',
              },
              '& > *:first-of-type': {
                width: fieldsetInline ? '12rem' : '100%',
                flex: '0 0 auto',
              },
            }}
          >
            {...Fields}
          </Box>
        ) : (
          <Box key={idx}>{...Fields}</Box>
        ),
      )}
    </Box>
  );
}

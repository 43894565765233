import { ReactNode } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { Box, Skeleton, Typography } from '@mui/material';

import {
  SearchInput,
  VacationsAccrualPeriodEntry,
  VacationsScheduleSummary,
  fetchGetContractAccrualPeriods,
  fetchSearchAllScheduledVacations,
} from '@octopus/api';

import NextVacationsCardSaldo, {
  nextVacationsCardSaldoProps,
} from '../../../../modules/components/vacation-scheduler/nextVacationsCardSaldo';
import VacationsRequestedCard from '../../../../modules/components/vacation-scheduler/vacationsRequestedCard';
import { DataFetching } from '../../../../modules/dataFetching';

function NextVacationsTab({
  organizationId,
}: {
  organizationId: string | undefined;
}): ReactNode {
  const { contractId } = useParams();
  const searchFilter: SearchInput = {
    filtering: {
      elements: {
        contractId: [contractId],
      },
    },
    sorting: { field: 'approvalDeadline', order: 'desc' },
  };

  const vacationsScheduledQuery = useQuery({
    queryKey: [organizationId, contractId, 'proximas', 'solicitadas'],
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    queryFn: () => {
      return fetchSearchAllScheduledVacations({
        pathParams: {
          organizationId: organizationId,
        },
        body: searchFilter,
      });
    },
    enabled: !!organizationId,
  });

  const accrualPeriodsQuery = useQuery({
    queryKey: [organizationId, contractId, 'saldo'],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryFn: () => {
      return fetchGetContractAccrualPeriods({
        pathParams: {
          organizationId: organizationId ?? '',
          contractId: contractId ?? '',
        },
      });
    },
    enabled: !!organizationId,
  });

  return (
    <Box
      display={'flex'}
      sx={{
        width: '100%',
        flexDirection: 'column',
        py: 0,
      }}
    >
      <DataFetching
        fetchResult={vacationsScheduledQuery}
        Loading={() => {
          return (
            <Box display="flex" flexDirection="column" gap="8px" pt={1}>
              <Skeleton variant="rounded" height={300} width="100%" />
            </Box>
          );
        }}
        Data={({ data }) => {
          const response = data;
          return response.data.filter((scheduleSummary) =>
            shouldShowVacationInRequestedSection(scheduleSummary),
          ).length > 0 ? (
            <Box>
              <Typography
                variant="body1"
                sx={{
                  color: '#616161',
                  fontSize: '14px',
                  fontWeight: 500,
                  pb: 1,
                }}
              >
                Solicitadas
              </Typography>
              <Box
                data-testid="box-saldo"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  pt: 0,
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {response.data.map(
                    (scheduleSummary: VacationsScheduleSummary) => {
                      return shouldShowVacationInRequestedSection(
                        scheduleSummary,
                      ) ? (
                        <VacationsRequestedCard
                          scheduleSummary={scheduleSummary}
                          detailsMode={false}
                          detailsLink={`/vacations/${contractId}/requestDetails/${scheduleSummary.startDate}/${scheduleSummary.sequence}`}
                        />
                      ) : null;
                    },
                  )}
                </Box>
              </Box>
            </Box>
          ) : null;
        }}
      />
      <Typography
        variant="body1"
        sx={{
          color: '#616161',
          fontSize: '14px',
          fontWeight: 500,
          py: 1,
          pt: 3,
        }}
      >
        Saldo
      </Typography>
      <DataFetching
        fetchResult={accrualPeriodsQuery}
        Loading={() => {
          return (
            <Box display="flex" flexDirection="column" gap="8px" pt={1}>
              <Skeleton variant="rounded" height={300} width="100%" />
            </Box>
          );
        }}
        Data={({ data }) => {
          const response = data;
          return (
            <Box
              data-testid="box-saldo"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                pt: 0,
              }}
            >
              {response ? (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {response.accrualPeriods
                    .filter((accrualPeriod) =>
                      dayjs(accrualPeriod.startDate)
                        .subtract(1, 'day')
                        .isBefore(dayjs()),
                    )
                    .map((accrualPeriod: VacationsAccrualPeriodEntry) => {
                      const props = getSaldoCardProps(
                        accrualPeriod,
                        contractId as string,
                      );
                      return <NextVacationsCardSaldo props={props} />;
                    })}
                </Box>
              ) : null}
            </Box>
          );
        }}
      />
    </Box>
  );
}

function isFutureVacation(scheduleSummary: VacationsScheduleSummary): boolean {
  return dayjs(scheduleSummary.endDate).isAfter(dayjs());
}

function shouldShowVacationInRequestedSection(
  scheduleSummary: VacationsScheduleSummary,
): boolean {
  return (
    isFutureVacation(scheduleSummary) &&
    !['rejected', 'canceled'].includes(scheduleSummary.status)
  );
}

function getSaldoCardProps(
  accrualPeriod: VacationsAccrualPeriodEntry,
  contractId: string,
): nextVacationsCardSaldoProps {
  const enabled: boolean = dayjs(
    accrualPeriod.concessionPeriod?.startDate,
  ).isBefore(dayjs());
  const due = getDueProp(accrualPeriod);
  const days = enabled
    ? accrualPeriod.daysAvailable
    : accrualPeriod.maximumAvailableDaysForWorker;
  const dueDate = enabled
    ? accrualPeriod.concessionPeriod?.limitDateToStartVacations
    : accrualPeriod.endDate;
  return {
    enabled: enabled,
    due: due,
    days: days,
    dueDate: dayjs(dueDate).format('DD/MM/YYYY'),
    requestLink: `/vacations/${contractId}/request?startDate=${accrualPeriod.startDate}`,
  };
}

function getDueProp(
  accrualPeriod: VacationsAccrualPeriodEntry,
): 'acquired' | 'unacquired' | 'overdue' | 'closeToDue' {
  if (
    accrualPeriod.concessionPeriod &&
    accrualPeriod.concessionPeriod.limitDateToStartVacations
  ) {
    if (
      dayjs(accrualPeriod.concessionPeriod.limitDateToStartVacations).isBefore(
        dayjs(),
      )
    ) {
      return 'overdue';
    } else if (
      dayjs(accrualPeriod.concessionPeriod.limitDateToStartVacations)
        .subtract(32, 'days')
        .isBefore(dayjs())
    ) {
      return 'closeToDue';
    }
  }
  return dayjs(accrualPeriod.concessionPeriod?.startDate).isBefore(dayjs()) &&
    accrualPeriod.daysAvailable > 0
    ? 'acquired'
    : 'unacquired';
}

export default NextVacationsTab;

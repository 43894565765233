import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { BeachAccessOutlined, DeleteOutline, Edit } from '@mui/icons-material';
import {
  Box,
  Container,
  Divider,
  Drawer,
  Skeleton,
  Typography,
} from '@mui/material';

import {
  PayrollElement,
  PayrollEntry,
  PayrollPayslipEntry,
  useArchivePayroll,
  useDeletePayroll,
  useGetPayroll,
  useGetPayrollPayslip,
} from '@octopus/api';
import { capitalize, formatDateBR, formatMoney } from '@octopus/formatters';
import {
  FeriasEvent,
  UNSAFE_INTERNALS_PLEASE_FIXME_ASAP,
} from '@octopus/payroll-engine/public-types/brazil';
import { Tag } from '@octopus/ui/design-system';

import { ActionMenu } from '../../../modules/components/ActionMenu';
import DoubleColumnListRow from '../../../modules/components/DoubleColumnListRow';
import { CalculationExplanation } from '../../../modules/components/payrolls/CalculationExplanation';
import { ElementsTable } from '../../../modules/components/payrolls/ElementsTable';
import { SendStatus } from '../../../modules/components/SendStatus';
import { DataFetching } from '../../../modules/dataFetching';
import { findEventByType } from '../../../modules/payrolls/parser';
import { downloadPayslipPdf } from '../../../modules/pdf/payslips';
import { ActionBar } from '../new/PageActionsBar';

import { ApprovalModal } from './ApprovalModal';

export type VacationsProps = {
  organizationId: string | undefined;
  companyId: string | undefined;
  payrollId?: string;
};

function VacationsDetails({ payroll }: { payroll: PayrollEntry }) {
  // FIXME: Isso aqui não deveria existir!
  //        O fix disso é o backend retornar esse dado.
  //        A Engine já expõe esse dado de uma forma mais segura através
  //        dos "dados relvantes".
  const periodoAquisitivoFeriasEvent: FeriasEvent['payload'] = findEventByType(
    payroll.outputs?.records?.events?.payload?.events || [],
    UNSAFE_INTERNALS_PLEASE_FIXME_ASAP.eventTypes.periodosAquisitivosFerias,
  ) as { dataDeInicio: string; dataDeFim: string };

  const diasAbonados = (() => {
    if (payroll.inputs?.vacations?.sellingVacations) {
      return '10 dias';
    }

    if (payroll.inputs?.vacations?.daysSold) {
      return `${payroll.inputs?.vacations?.daysSold} dias`;
    }

    return '0 dias';
  })();

  return (
    <Box key="vacation-details">
      <Typography variant="h3" color="text.primary">
        Detalhes das férias
      </Typography>
      {[
        [
          'Período de aquisição',
          `${formatDateBR(periodoAquisitivoFeriasEvent?.dataDeInicio)}
                  - ${formatDateBR(periodoAquisitivoFeriasEvent?.dataDeFim)}`,
        ],
        [
          'Dias a exercer',
          `${payroll.outputs?.vacations?.durationInDays} dias`,
        ],
        ['Dias abonados', diasAbonados],
        [
          'Período de gozo',
          `${formatDateBR(payroll.inputs?.vacations?.startDate, {
            includeDayName: true,
          })} - ${formatDateBR(payroll.inputs?.vacations?.endDate, {
            includeDayName: true,
          })}`,
        ],
      ].map(([label, value]) => (
        <>{DoubleColumnListRow(label, value)}</>
      ))}
      <Divider sx={{ my: 1.5 }} />
      <>
        {DoubleColumnListRow(
          'Valor do pagamento',
          formatMoney(payroll.outputs?.netPay.total),
          {
            value: {
              sx: {
                fontWeight: 700,
              },
            },
          },
        )}
      </>
      <>
        {DoubleColumnListRow(
          'Data de pagamento',
          formatDateBR(payroll.paymentDate),
        )}
      </>
    </Box>
  );
}

function ElementsTables({ payroll }: { payroll: PayrollEntry }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [elementStack, setElementStack] = useState<PayrollElement[]>([]);

  const onClickElement = (element: PayrollElement) => {
    setElementStack((state) => [
      ...state,
      payroll.outputs?.elements[element.id],
    ]);
    setIsDrawerOpen(true);
  };

  return (
    <>
      <Box
        borderRadius={1.5}
        borderColor="strokes.light"
        sx={{
          borderWidth: 1,
          borderStyle: 'solid',
        }}
      >
        <Box px={2}>
          <Typography
            variant="h4"
            color="text.primary"
            pl={2.3}
            pt={4}
            pb={1.5}
          >
            Proventos
          </Typography>
          <ElementsTable
            payroll={payroll}
            summary="workerEarningsTotal"
            onClickElement={onClickElement}
            selectedElementId={elementStack[elementStack.length - 1]?.id}
            selectorIconPosition="right"
          />

          <Typography
            variant="h4"
            color="text.primary"
            pl={2.3}
            pt={4}
            pb={1.5}
          >
            Descontos
          </Typography>
          <ElementsTable
            payroll={payroll}
            summary="workerDeductionsTotal"
            onClickElement={onClickElement}
            selectedElementId={elementStack[elementStack.length - 1]?.id}
            selectorIconPosition="right"
          />
        </Box>

        <Box px={4} pt={3}>
          <Divider />
        </Box>

        <Box px={2} py={3} display="flex" justifyContent="space-between">
          <table
            width="100%"
            style={{
              tableLayout: 'fixed',
              borderCollapse: 'collapse',
            }}
          >
            <tbody>
              <tr>
                <td style={{ width: '16px' }} role="presentation" />
                <td style={{ width: '70%' }}>
                  <Typography variant="h4" color="text.primary">
                    Valor líquido total
                  </Typography>
                </td>
                <td style={{ width: '30%' }}>
                  <Typography variant="h4" color="text.primary">
                    {formatMoney(payroll.outputs?.netPay?.total)}
                  </Typography>
                </td>
                <td style={{ width: '16px' }} role="presentation" />
              </tr>
            </tbody>
          </table>
        </Box>
      </Box>

      <Drawer
        anchor={'right'}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        elevation={2}
      >
        <Box
          width="520px"
          height="100%"
          sx={{
            opacity: elementStack.length > 0 ? 1 : 0,
            transition: 'all 0.2s',
            backgroundColor: 'background.default',
            overflowY: 'overlay',
          }}
        >
          <CalculationExplanation
            element={elementStack[elementStack.length - 1]}
            canGoBack={elementStack.length > 1}
            goBack={() => setElementStack((state) => state.slice(0, -1))}
            onClickElement={onClickElement}
            onExit={() => {
              setElementStack([]);
              setIsDrawerOpen(false);
            }}
          />
        </Box>
      </Drawer>
    </>
  );
}

function VacationsReceipt({
  organizationId,
  companyId,
  payroll,
  payslip,
}: {
  organizationId: string;
  companyId: string;
  payroll: PayrollEntry;
  payslip: PayrollPayslipEntry;
}) {
  const navigate = useNavigate();
  const archivePayrollMutation = useArchivePayroll();
  const onClickArchivePayroll = () => {
    archivePayrollMutation.mutate({
      pathParams: {
        companyId,
        organizationId,
        payrollId: payroll.payrollId,
      },
    });
  };

  useEffect(() => {
    if (archivePayrollMutation.isSuccess) {
      navigate(-1);
    }
  }, [navigate, archivePayrollMutation.isSuccess]);

  return (
    <Box pt={7} pb={14} data-testid="vacations-receipt">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <BeachAccessOutlined
            fontSize="inherit"
            sx={{
              width: '16px',
              height: '16px',
            }}
          />
          &nbsp; Recibo de Férias
        </Typography>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          {payroll.status === 'open' && (
            <Tag
              left={<Edit />}
              emphasis="high"
              color="default"
              slotProps={{
                tooltip: {
                  title:
                    'A prévia é um estado temporário da folha de férias para a sua conferência, ela não tem impacto na folha mensal e na folha de férias, para que seja efetivada você precisa aprovar a prévia.',
                  placement: 'bottom-end',
                  PopperProps: {
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, -5],
                        },
                      },
                    ],
                  },
                },
              }}
            >
              Prévia
            </Tag>
          )}
          {payslip?.status === 'approved' && payroll?.status !== 'open' && (
            <SendStatus
              isSent={false}
              NotSentMessage={() => <> Não enviado </>}
            />
          )}
          {payslip?.status === 'sent' && (
            <SendStatus isSent={true} SentMessage={() => <> Enviado </>} />
          )}
          <ActionMenu
            sx={{
              '--ActionMenu-button-margin': '0px 0px 0px 8px',
            }}
            variant="button"
            menuItems={[
              payroll?.status === 'open' && {
                label: 'Excluir',
                isDisabled: archivePayrollMutation.isLoading,
                isPreventingClose: archivePayrollMutation.isLoading,
                onClick: onClickArchivePayroll,
              },
              payroll?.status !== 'open' &&
                payslip?.status === 'approved' && {
                  label: 'Baixar recibo',
                  onClick: () => downloadPayslipPdf(payslip),
                },
            ]}
          />
        </Box>
      </Box>
      <Box>
        <Typography variant="h1" color="text.primary">
          {payroll.workerData.name}
        </Typography>
        <Typography variant="caption" component="p" color="text.secondary">
          {capitalize(payroll.workerData.jobTitle, true, true)} |{' '}
          {formatMoney(payroll.workerData.salary)}/mês
        </Typography>
        <Typography variant="caption" color="text.secondary">
          Admissão em {formatDateBR(payroll.workerData.admissionDate)}
        </Typography>
      </Box>

      <Divider light sx={{ my: 3 }} />

      <VacationsDetails payroll={payroll} />
      <Box pt={6} />
      <ElementsTables payroll={payroll} />
    </Box>
  );
}

function VacationReceiptPage({
  organizationId,
  companyId,
  payrollId: payrollIdProp,
}: VacationsProps) {
  const { payrollId: payrollIdRouteParam } = useParams<{
    payrollId: string;
  }>();

  const payrollId = payrollIdProp || payrollIdRouteParam;

  const getPayrollPayslip = useGetPayrollPayslip({
    pathParams: {
      companyId,
      organizationId,
      payrollId,
    },
  });

  const hasPayslip =
    !getPayrollPayslip.isLoading && Boolean(getPayrollPayslip.data);
  const payslip = hasPayslip && getPayrollPayslip.data;

  const useFetch = () => {
    return useGetPayroll(
      {
        pathParams: {
          organizationId: organizationId ?? '',
          companyId: companyId ?? '',
          payrollId: payrollId ?? '',
        },
      },
      {
        enabled: !!organizationId && !!companyId && !!payrollId,
      },
    );
  };

  const [isApprovalConfirmationVisible, setIsApprovalConfirmationVisible] =
    useState(false);

  const onClickApprove = () => {
    setIsApprovalConfirmationVisible(true);
  };

  const navigate = useNavigate();
  const archivePayrollMutation = useArchivePayroll();

  const deletePayrollMutation = useDeletePayroll();

  const onClickArchivePayroll = async () => {
    const params = {
      companyId,
      organizationId,
      payrollId: payrollId,
    };

    await archivePayrollMutation.mutateAsync({
      pathParams: params,
    });

    await deletePayrollMutation.mutateAsync({
      pathParams: params,
    });

    navigate('/vacations');
  };

  return (
    <DataFetching
      useHook={useFetch}
      Loading={() => (
        <Container
          maxWidth={false}
          sx={{
            maxWidth: '800px',
          }}
        >
          <Box pt={12}>
            <Typography variant="h3" color="text.primary">
              <Skeleton width={200} />
            </Typography>
            <Typography variant="h1" color="text.primary">
              <Skeleton width="100%" />
            </Typography>
            <Skeleton width={200} />
            <Skeleton width={100} />
            <Divider sx={{ my: 2 }} />
            <Skeleton variant="rounded" height={300} />
            <Divider sx={{ my: 2 }} />
            <Skeleton variant="rounded" height={300} />
          </Box>
        </Container>
      )}
      Data={({ data }) => (
        <>
          <Container
            maxWidth={false}
            sx={{
              maxWidth: '800px',
            }}
          >
            {data && (
              <VacationsReceipt
                organizationId={organizationId}
                companyId={companyId}
                payroll={data}
                payslip={payslip}
              />
            )}
          </Container>
          {data?.status === 'open' && (
            <>
              <ActionBar>
                <ActionBar.Action
                  onClick={onClickArchivePayroll}
                  variantSemantic="tertiary"
                  sx={{
                    color: 'error.main',
                  }}
                >
                  <ActionBar.Action.Adornment>
                    <DeleteOutline
                      sx={{
                        marginRight: 1,
                        color: 'error.main',
                      }}
                    />
                  </ActionBar.Action.Adornment>
                  Excluir Prévia
                </ActionBar.Action>
                <ActionBar.Action
                  to="/vacations"
                  variantSemantic="secondary"
                  sx={{
                    marginRight: 1,
                  }}
                >
                  Sair
                </ActionBar.Action>
                <ActionBar.Action onClick={onClickApprove}>
                  Aprovar férias
                </ActionBar.Action>
              </ActionBar>
              <ApprovalModal
                open={isApprovalConfirmationVisible}
                setOpen={setIsApprovalConfirmationVisible}
                organizationId={organizationId}
                companyId={companyId}
                payrollId={payrollId || ''}
              />
            </>
          )}
        </>
      )}
    />
  );
}

export default VacationReceiptPage;

import { Cancel, CheckCircle } from '@mui/icons-material';

import { fetchApproveAdmissionDraftReview } from '@octopus/api';

import {
  ActionMenu,
  ActionMenuItem,
} from '../../../modules/components/ActionMenu';
import { SnackbarType } from '../../../modules/hooks/snackbarContext';

const getDeleteAction = (
  draftId: string,
  {
    setShowDeleteAdmissionDialog,
    setSelectedAdmissionDraft,
  }: {
    setShowDeleteAdmissionDialog: (open: boolean) => void;
    setSelectedAdmissionDraft: ({ draftId }: { draftId: string }) => void;
  },
) => ({
  label: 'Excluir',
  onClick: () => {
    setShowDeleteAdmissionDialog(true);
    setSelectedAdmissionDraft({ draftId });
  },
});

export function admissionDraftActionMenu(
  draftId: string,
  {
    setShowDeleteAdmissionDialog,
    setSelectedAdmissionDraft,
  }: {
    setShowDeleteAdmissionDialog: (open: boolean) => void;
    setSelectedAdmissionDraft: ({ draftId }: { draftId: string }) => void;
  },
) {
  return createActionMenu([
    getDeleteAction(draftId, {
      setShowDeleteAdmissionDialog,
      setSelectedAdmissionDraft,
    }),
  ]);
}

export function inProgressCandidateActionMenu(
  draftId: string,
  name: string,
  email: string,
  doneSteps: number,
  {
    setShowDeleteAdmissionDialog,
    setShowAdminTakeOverDialog,
    setShowResendInviteDialog,
    setSelectedAdmissionDraft,
  }: {
    setShowDeleteAdmissionDialog: (open: boolean) => void;
    setShowAdminTakeOverDialog: (open: boolean) => void;
    setShowResendInviteDialog: (open: boolean) => void;
    setSelectedAdmissionDraft: ({
      draftId,
      name,
      email,
    }: {
      draftId: string;
      name?: string;
      email?: string;
    }) => void;
  },
) {
  return createActionMenu([
    {
      label: 'Preencher para o candidato',
      onClick: () => {
        setShowAdminTakeOverDialog(true);
        setSelectedAdmissionDraft({ draftId });
      },
    },
    {
      label: 'Reenviar Convite',
      isDisabled: doneSteps > 0,
      onClick: () => {
        setSelectedAdmissionDraft({ draftId, name, email });
        setShowResendInviteDialog(true);
      },
    },
    getDeleteAction(draftId, {
      setShowDeleteAdmissionDialog,
      setSelectedAdmissionDraft,
    }),
  ]);
}

export function inProgressAdminActionMenu(
  draftId: string,
  {
    setShowDeleteAdmissionDialog,
    setSelectedAdmissionDraft,
  }: {
    setShowDeleteAdmissionDialog: (open: boolean) => void;
    setSelectedAdmissionDraft: ({ draftId }: { draftId: string }) => void;
  },
) {
  return createActionMenu([
    {
      label: 'Continuar Preenchimento',
      href: `/admissions/new/${draftId}`,
    },
    getDeleteAction(draftId, {
      setShowDeleteAdmissionDialog,
      setSelectedAdmissionDraft,
    }),
  ]);
}

export function inReviewActionMenu(
  {
    organizationId,
    draftId,
    contractType,
    showSnackbar,
  }: {
    organizationId: string;
    draftId: string;
    contractType: 'br:clt';
    showSnackbar: (snackbar: SnackbarType) => void;
  },
  {
    setShowDeleteAdmissionDialog,
    setSelectedAdmissionDraft,
  }: {
    setShowDeleteAdmissionDialog: (open: boolean) => void;
    setSelectedAdmissionDraft: ({ draftId }: { draftId: string }) => void;
  },
) {
  return createActionMenu([
    {
      label: 'Aprovar',
      onClick: () => {
        fetchApproveAdmissionDraftReview({
          pathParams: {
            organizationId,
            draftId,
          },
          body: {
            contractType,
          },
        })
          .then((_result) => {
            showSnackbar({
              Message: 'Admissão aprovada com sucesso!',
              isOpen: true,
              variant: 'default',
              hasCloseAction: true,
              StartAdornment: <CheckCircle />,
            });
          })
          .catch((error) => {
            showSnackbar({
              Message:
                'Erro ao aprovar admissão. Tente novamente ou contacte o suporte da Tako.',
              isOpen: true,
              variant: 'error',
              hasCloseAction: true,
              StartAdornment: <Cancel />,
            });
            console.log(error);
          });
      },
    },
    getDeleteAction(draftId, {
      setShowDeleteAdmissionDialog,
      setSelectedAdmissionDraft,
    }),
  ]);
}

function createActionMenu(menuItems: ActionMenuItem[]) {
  return (
    <ActionMenu
      sx={{
        '--ActionMenu-button-margin': '0px 0px 0px 0px',
      }}
      menuItems={menuItems}
    />
  );
}

import { Box, Typography, TypographyProps } from '@mui/material';

import { ContractStatus } from '@octopus/api';
import {
  ContractTypes,
  contractStatuses,
  contractTypes,
} from '@octopus/contract-types';

export type StatusBadgeProps = {
  contractType: ContractTypes;
  status: ContractStatus | undefined;
  TypographyProps?: TypographyProps;
};

export function StatusBadge({
  contractType,
  status,
  TypographyProps = {},
}: StatusBadgeProps) {
  if (!status) {
    return null;
  }
  const { color, bgcolor, label } = stylizeStatus(status, contractType);
  const props: TypographyProps = {
    display: 'inline-block',
    borderRadius: '4px',
    variant: 'body2',
    fontWeight: '500',
    px: 1,
    py: 0.5,
    color,
    bgcolor,
    ...TypographyProps,
  };
  return (
    <Box>
      <Typography {...props}>{label}</Typography>
    </Box>
  );
}

export type ContractTypeProps = {
  contractType: ContractTypes;
  TypographyProps?: TypographyProps;
};

export function ContractTypeBadge({
  contractType,
  TypographyProps = {},
}: ContractTypeProps) {
  const label =
    contractType === contractTypes.brClt
      ? 'Colaborador'
      : 'Prestador de serviços';

  const props: TypographyProps = {
    display: 'inline-block',
    borderRadius: '4px',
    variant: 'body2',
    fontWeight: '500',
    px: 1,
    py: 0.5,
    color: 'default.main',
    bgcolor: 'background.default',
    ...TypographyProps,
  };
  return (
    <Box>
      <Typography {...props}>{label}</Typography>
    </Box>
  );
}

function stylizeStatus(status: ContractStatus, contractType: ContractTypes) {
  if (status === contractStatuses.onLeave) {
    return {
      color: 'warning.main',
      bgcolor: 'background.warning',
      label: 'Afastado(a)',
    };
  }
  if (status === contractStatuses.onAdmission) {
    return {
      color: 'warning.main',
      bgcolor: 'background.warning',
      label: 'Em admissão',
    };
  }
  if (status === contractStatuses.terminated) {
    if (contractType === contractTypes.brPj) {
      return {
        color: 'default.main',
        bgcolor: 'background.default',
        label: 'Inativo(a)',
      };
    }

    return {
      color: 'error.main',
      bgcolor: 'background.error',
      label: 'Desligado(a)',
    };
  }

  if (status === contractStatuses.active) {
    return {
      color: 'success.main',
      bgcolor: 'background.success',
      label: 'Ativo(a)',
    };
  }

  return {
    color: 'error.main',
    bgcolor: 'background.error',
    label: 'Não encontrado: ' + status,
  };
}

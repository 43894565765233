import { useRef, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, Link, Popover, Typography } from '@mui/material';

import { Utils } from './utils';

type ContractHistoryButtonProps = {
  onHistorySelected: () => void;
};

export function ContractEventsHistoryButton({
  onHistorySelected,
}: ContractHistoryButtonProps) {
  const [isOpen, setOpen] = useState<boolean>(false);
  const { hoveredStyle, hoverEventHandlers } = Utils.Hooks.useHoverBackground();
  const close = () => setOpen(false);
  const open = () => setOpen(true);

  const menuRef = useRef(null);

  return (
    <>
      <IconButton
        size="small"
        onClick={open}
        sx={{ borderRadius: 1 }}
        ref={menuRef}
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        open={isOpen}
        anchorEl={menuRef.current}
        onClick={(event) => event.stopPropagation()}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
      >
        <Box
          display="flex"
          {...hoverEventHandlers}
          sx={{
            width: '220px',
            height: '32px',
            py: 1,
            px: 0,
          }}
        >
          <Link
            component="button"
            underline="none"
            textAlign="left"
            sx={(theme) => ({
              ...hoveredStyle(theme),
              width: '100%',
              padding: 0,
            })}
            onClick={(event) => {
              event.stopPropagation();
              setOpen(false);
              onHistorySelected();
            }}
          >
            <Typography
              variant="body1"
              color="textPrimary"
              sx={{ px: 2, py: 0.5 }}
            >
              Histórico
            </Typography>
          </Link>
        </Box>
      </Popover>
    </>
  );
}

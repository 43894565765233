import React, { RefObject, useRef } from 'react';

import { Box } from '@mui/material';

import {
  ContractEntry,
  ContractSummary,
  useContractsPerson,
  useGetContract,
} from '@octopus/api';

import { MissingPersonIdAlert } from '../../modules/components/MissingPersonIdAlert';
import { QueryResult } from '../../modules/types';

import { CltContractDetails } from './CltContractDetails';
import { PersonalDataDetails } from './PersonalDataDetails';
import { PersonalDataInfo } from './PersonalDataInfo';
import { PersonalDataSidePanel } from './PersonalDataSidePanel';
import { PersonalDataTitle } from './PersonalDataTitle';
import { PjContractDetails } from './PjContractDetails';

export type PersonProps = {
  organizationId: string | undefined;
  personId: string | undefined;
  companyId: string | undefined;
};

export default function PersonalData({
  organizationId,
  personId,
  companyId,
}: PersonProps) {
  const refs = {
    pageRef: useRef<Element>(null),
    personalDetailsRef: useRef<Element>(null),
    contractDetailsRef: useRef<Element>(null),
  };

  const personContractsQuery = useContractsPerson(
    {
      pathParams: {
        organizationId: organizationId ?? '',
        personId: personId ?? '',
      },
    },
    {
      enabled: !!organizationId && !!personId,
    },
  );

  const contractId = selectContractId(
    personContractsQuery.data?.data ?? [],
    companyId,
  );

  const contractQuery = useGetContract(
    {
      pathParams: {
        organizationId: organizationId ?? '',
        contractId: contractId ?? '',
      },
    },
    {
      enabled: !!organizationId && !!contractId,
    },
  );

  if (!personId) {
    return <MissingPersonIdAlert />;
  }
  return (
    <>
      <Box
        sx={(theme) => ({
          [theme.breakpoints.up('md')]: {
            display: 'none',
          },
        })}
        data-testid="small-page-content"
      >
        <SmallPageContent
          contractQuery={contractQuery}
          hasContracts={!!contractId}
        />
      </Box>
      <Box
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        })}
        data-testid="large-page-content"
      >
        <LargePageContent
          refs={refs}
          contractQuery={contractQuery}
          hasContracts={!!contractId}
        />
      </Box>
    </>
  );
}

function selectContractId(contracts: ContractSummary[], companyId: string) {
  let contractId = contracts.find(
    (contract) =>
      contract.status === 'active' && contract.companyId === companyId,
  )?.contractId;
  if (!contractId) {
    contractId = contracts.find(
      (contract) => contract.companyId === companyId,
    )?.contractId;
  }
  return contractId;
}

function LargePageContent({
  refs,
  contractQuery,
  hasContracts,
}: {
  refs: {
    pageRef: RefObject<Element>;
    personalDetailsRef: RefObject<Element>;
    contractDetailsRef: RefObject<Element>;
  };
  contractQuery: QueryResult<ContractEntry>;
  hasContracts: boolean;
}) {
  const { pageRef, personalDetailsRef, contractDetailsRef } = refs;
  return (
    <Box display="flex">
      <PersonalDataSidePanel refs={refs} contractQuery={contractQuery} />
      <Box
        ref={pageRef}
        display="flex"
        flexDirection="column"
        flexGrow={1}
        alignItems="center"
        width="100%"
        py={15}
      >
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          maxWidth="660px"
          minWidth="400px"
          gap="36px"
        >
          <PersonalDataTitle />
          <Box display="flex" flexDirection="column" gap="56px">
            <PersonalDataDetails
              personalDetailsRef={personalDetailsRef}
              contractQuery={contractQuery}
              showExpandIcon={false}
            />
            <CltContractDetails
              contractDetailsRef={contractDetailsRef}
              contractQuery={contractQuery}
              hasContracts={hasContracts}
              showExpandIcon={false}
            />
            <PjContractDetails
              contractDetailsRef={contractDetailsRef}
              contractQuery={contractQuery}
              showExpandIcon={false}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function SmallPageContent({
  contractQuery,
  hasContracts,
}: {
  contractQuery: QueryResult<ContractEntry>;
  hasContracts: boolean;
}) {
  return (
    <Box display="flex" flexDirection="column" gap={3} pb={10}>
      <Box pb={4}>
        <PersonalDataInfo contractQuery={contractQuery} />
      </Box>
      <Box px={2.5}>
        <Box display="flex" flexDirection="column" gap={3}>
          <PersonalDataDetails
            contractQuery={contractQuery}
            showExpandIcon={true}
          />
          <CltContractDetails
            contractQuery={contractQuery}
            hasContracts={hasContracts}
            showExpandIcon={true}
          />
          <PjContractDetails
            contractQuery={contractQuery}
            showExpandIcon={true}
          />
        </Box>
      </Box>
    </Box>
  );
}

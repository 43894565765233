import dayjs from 'dayjs';

import { ChevronRight, WarningRounded } from '@mui/icons-material';
import { Box, Tooltip, Typography } from '@mui/material';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import { Button } from '@octopus/ui/design-system';

import ContractTypeTag from '../../../modules/components/contracts/ContractTypeTag';
import { ExpandableTypography } from '../../../modules/components/ExpandableTypography';
import UserAvatar from '../../../modules/components/UserAvatar';
import { formatOptionalDayjsDate } from '../../../modules/formatters';
import { SnackbarType } from '../../../modules/hooks/snackbarContext';

import {
  admissionDraftActionMenu,
  inProgressAdminActionMenu,
  inProgressCandidateActionMenu,
  inReviewActionMenu,
} from './actionMenus';
import { AdmissionTabs } from './types';

const nameColumn: GridColDef = {
  field: 'name',
  headerName: 'Nome',
  renderHeader: (params) => {
    return <Box ml={2}>{params.field}</Box>;
  },
  flex: 1,
  valueGetter: (params: GridValueGetterParams) => {
    return params.row.name;
  },
  renderCell: ({ value }) => {
    return !value ? (
      '-'
    ) : (
      <UserAvatar
        name={value}
        expandNameOnHover={true}
        sx={{
          '--UserAvatar-name-max-width': '12.5em',
        }}
      />
    );
  },
};

const cargoColumn: GridColDef = {
  field: 'title',
  headerName: 'Cargo/Posição',
  flex: 1,
  valueGetter: (params: GridValueGetterParams) => {
    return params.row.jobTitleName;
  },
  renderCell: ({ value }) => {
    return <ExpandableTypography>{value ?? '-'}</ExpandableTypography>;
  },
};

const departmentColumn: GridColDef = {
  field: 'department',
  headerName: 'Departamento',
  flex: 1,
  valueGetter: (params: GridValueGetterParams) => {
    return params.row.department;
  },
  renderCell: ({ value }) => {
    return <ExpandableTypography>{value ?? '-'}</ExpandableTypography>;
  },
};

const admissionDateColumn: GridColDef = {
  field: 'admissionDate',
  headerName: 'Data de admissão',
  flex: 1,
  valueGetter: (params: GridValueGetterParams) => {
    const { admissionDate } = params.row;

    return !admissionDate
      ? '-'
      : formatOptionalDayjsDate({ date: dayjs(admissionDate) });
  },
};

const limitDateColumn: GridColDef = {
  field: 'limiteDate',
  headerName: 'Data limite',
  flex: 1,
  valueGetter: (params: GridValueGetterParams) => {
    const { limitDate } = params.row;
    return !limitDate
      ? '-'
      : formatOptionalDayjsDate({ date: dayjs(limitDate) });
  },
};

const draftProgressColumn: GridColDef = {
  field: 'draftProgress',
  headerName: 'Etapa e progresso',
  valueGetter: (params: GridValueGetterParams) => {
    return {
      doneSteps: params.row.doneSteps,
      totalSteps: params.row.allSteps,
      draftStatus: params.row.draftStatus,
    };
  },
  renderCell: ({ value }) => {
    const { doneSteps, totalSteps, draftStatus } = value;
    if (doneSteps === 0 && draftStatus === 'invite_sent')
      return (
        <ExpandableTypography color={'#B54708'}>
          Convite enviado
        </ExpandableTypography>
      );
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
      height: 10,
      borderRadius: 5,
      [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
          theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
      },
      [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor:
          doneSteps === 0
            ? theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
            : theme.palette.mode === 'light'
              ? '#1a90ff'
              : '#308fe8',
      },
    }));
    return (
      <Tooltip
        placement="top"
        title={`Preenchidas ${doneSteps} de ${totalSteps} etapas`}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: '0px',
            padding: '0px',
          }}
        >
          <Box sx={{ width: '100%', mr: 1 }}>
            <BorderLinearProgress
              variant="determinate"
              value={(doneSteps * 100) / totalSteps}
              valueBuffer={100}
            />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="text.secondary">
              {doneSteps}/{totalSteps}
            </Typography>
          </Box>
        </Box>
      </Tooltip>
    );
  },
};

const resumeColumn: GridColDef = {
  field: 'resumeDraft',
  headerName: '',
  valueGetter: (params: GridValueGetterParams) => {
    return {
      draftId: params.row.draftId,
    };
  },
  renderCell: ({ value }) => {
    const { draftId } = value;
    const redirectLink = `/admissions/new/${draftId}`;
    return createButtonColumn('Continuar', redirectLink);
  },
};
const reviewAdmissionColumn: GridColDef = {
  field: 'reviewAdmission',
  headerName: '',
  valueGetter: (params: GridValueGetterParams) => {
    return {
      draftId: params.row.draftId,
    };
  },
  renderCell: ({ value }) => {
    const { draftId } = value;
    const redirectLink = `/admission/new/${draftId}`;
    return createButtonColumn('Revisar', redirectLink);
  },
};
const viewDetailsColumn: GridColDef = {
  field: 'viewAdmission',
  headerName: '',
  valueGetter: (params: GridValueGetterParams) => {
    return {
      contractId: params.row.contractId,
    };
  },
  renderCell: ({ value }) => {
    const { contractId } = value;
    const redirectLink = `/people/${contractId}`;
    const disabled = !contractId;
    const tooltip = disabled ? 'Perfil em processo de criação' : '';
    return createButtonColumn('Ver perfil', redirectLink, disabled, tooltip);
  },
};
const contractTypeColumn: GridColDef = {
  field: 'contractType',
  headerName: 'Modalidade',
  valueGetter: (params: GridValueGetterParams) => {
    return { contractType: params.row.contractType };
  },
  renderCell: ({ value }) => {
    const { contractType } = value;
    return <ContractTypeTag contractType={contractType} />;
  },
};

export function getActionMenuCol(
  tab: AdmissionTabs,
  showSnackbar: (snackbar: SnackbarType) => void,
  dialogsState?: {
    setShowDeleteAdmissionDialog: (open: boolean) => void;
    setShowAdminTakeOverDialog: (open: boolean) => void;
    setShowResendInviteDialog: (open: boolean) => void;
    setSelectedAdmissionDraft: ({
      draftId,
      name,
      email,
    }: {
      draftId: string;
      name?: string;
      email?: string;
    }) => void;
  },
) {
  const col: GridColDef = {
    field: 'contextMenu',
    headerName: '',
    disableColumnMenu: false,
    valueGetter: (params: GridValueGetterParams) => ({
      draftId: params.row.draftId,
      organizationId: params.row.organizationId,
      contractType: params.row.contractType,
      name: params.row.name,
      email: params.row.email,
      doneSteps: params.row.doneSteps,
    }),
  };

  switch (tab) {
    case AdmissionTabs.draft_created:
      col.renderCell = ({ value }) =>
        admissionDraftActionMenu(value.draftId, dialogsState);
      break;
    case AdmissionTabs.in_progress_candidate:
      col.renderCell = ({ value }) => {
        const { name, draftId, email, doneSteps } = value;
        return inProgressCandidateActionMenu(
          draftId,
          name,
          email,
          doneSteps,
          dialogsState,
        );
      };
      break;
    case AdmissionTabs.in_review:
      col.renderCell = ({ value }) =>
        inReviewActionMenu(
          {
            draftId: value.draftId,
            organizationId: value.organizationId,
            contractType: value.contractType,
            showSnackbar,
          },
          dialogsState,
        );
      break;
    case AdmissionTabs.in_progress_admin:
      col.renderCell = ({ value }) =>
        inProgressAdminActionMenu(value.draftId, dialogsState);
      break;
    default:
      return undefined;
  }
  return col;
}

// todo admission: add limit date column in all tabs
export const columnsByTab: Record<AdmissionTabs, GridColDef[]> = {
  [AdmissionTabs.draft_created]: [
    nameColumn,
    cargoColumn,
    departmentColumn,
    admissionDateColumn,
    limitDateColumn,
    contractTypeColumn,
    resumeColumn,
  ],
  [AdmissionTabs.in_progress_candidate]: [
    nameColumn,
    cargoColumn,
    departmentColumn,
    admissionDateColumn,
    limitDateColumn,
    contractTypeColumn,
    draftProgressColumn,
  ],
  [AdmissionTabs.in_progress_admin]: [
    nameColumn,
    cargoColumn,
    departmentColumn,
    admissionDateColumn,
    limitDateColumn,
    contractTypeColumn,
    draftProgressColumn,
  ],
  [AdmissionTabs.in_review]: [
    nameColumn,
    cargoColumn,
    departmentColumn,
    admissionDateColumn,
    limitDateColumn,
    contractTypeColumn,
    reviewAdmissionColumn,
  ],
  [AdmissionTabs.admission_done]: [
    nameColumn,
    cargoColumn,
    departmentColumn,
    admissionDateColumn,
    limitDateColumn,
    contractTypeColumn,
    viewDetailsColumn,
  ],
};

function createButtonColumn(
  text: string,
  redirectLink: string,
  disabled = false,
  tooltip = '',
) {
  return disabled ? (
    <Tooltip title={tooltip} placement="top">
      {disabled && <WarningRounded width="20px" color="disabled" />}
    </Tooltip>
  ) : (
    <Button
      sx={{ padding: '0px', borderRight: '0px', margin: '0px' }}
      variantSemantic={'tertiary'}
      to={redirectLink}
      disabled={disabled}
    >
      {text}
      <ChevronRight width="20px" />
    </Button>
  );
}
